import React, { useState, useEffect } from "react";
import { useAppSelector } from "../../../../_App/Redux/hooks";
import {
  ListProductCaracteristiques,
  findProductsCaracteristiquesApiBySociete,
} from "../../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";
import ProductCaracteristiquesForm from "../../ProductCaracteristiques/ProductCaracteristiquesForm";

export default function DimmensionMatrice({
  setlist,
  list,
  caracteristique,
  societe,
  listcaracterisqtiqueitems,
  setlistcaracterisqtiqueitems,
  listcaracteristique,
}: any) {
  const [itemcaracteristique, setitemcaracteristique] = useState({});
  console.log("listcaracterisqtiqueitems", listcaracterisqtiqueitems);
  const [
    ShowModalProduct_Caracteristique,
    setShowModalProduct_Caracteristique,
  ] = useState(false);

  useEffect(() => {
    const list: [string][] = [];
    caracteristique.forEach((element: any, index: string | number) => {
      console.log("caracteristiqueelemente", element);
      console.log("caracteristiquelistcaracteristique", listcaracteristique);

      const findcaracteristiqueitem = listcaracteristique.find(
        (item: any) => item._id === element
      );
      console.log(
        "caracteristiquefindcaracteristiqueitem",
        findcaracteristiqueitem
      );

      if (findcaracteristiqueitem) {
        list.push(findcaracteristiqueitem.valeurs);
      }
    });
      console.log('listlist',list)
    setlistcaracterisqtiqueitems(list);
  }, [listcaracteristique, caracteristique]);
 useEffect(()=>{
  console.log("listcaracterisqtiqueitems",listcaracterisqtiqueitems)
 },[listcaracterisqtiqueitems])
  const handelShowModalCarateristique = (index: any) => {
    if (listcaracteristique.length !== 0) {
      const findcaracteristiqueitem = listcaracteristique.find(
        (item: any) => item._id === caracteristique[index]
      );
      if (findcaracteristiqueitem) {
        setitemcaracteristique(findcaracteristiqueitem);
        setShowModalProduct_Caracteristique(true);
      } else {
        ToastWarning("le carateristique selectionner  n'est  existant");
      }
    }
  };
  const selectitems = (index: number, item: any) => {
    let newlist = [...list[index]];
    if (newlist.find((element: any) => element === item)) {
      newlist = newlist.filter((element: any) => element !== item);
    } else {
      newlist.push(item);
    }

    setlist((prev: any) => {
      const newListArray = [...prev];
      newListArray[index] = newlist;
      return newListArray;
    });
  };

  return (
    <>
      {listcaracterisqtiqueitems.length !== 0 ? (
        <div className="row mx-auto">
          <div className="col-md-4">
            <strong>
              {" "}
              {
                listcaracteristique.find(
                  (item: any) => item._id === caracteristique[0]
                )?.libelle
              }
            </strong>

            <div className="col-md-12" style={{ marginRight: "20px" }}>
              {listcaracterisqtiqueitems[0] ? (
                [...listcaracterisqtiqueitems[0], { plus: true }]
                  .reduce((rows: any[], item, index) => {
                    if (index % 4 === 0) {
                      rows.push([]);
                    }
                    rows[rows.length - 1].push(item);
                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <div className="row" key={`row-${rowIndex}`}>
                      {row.map((colItem: any, colIndex: any) => {
                        const globalIndex = rowIndex * 4 + colIndex;
                        return (
                          <div className="col-md-2 marginitems" key={colIndex}>
                            {colItem.plus ? (
                              <div
                                className="c-primary"
                                onClick={() => handelShowModalCarateristique(0)}
                              >
                                {globalIndex < 12 ? (
                                  <i className="fas fa-plus-circle fa-lg"></i>
                                ) : null}
                              </div>
                            ) : (
                              <button
                                key={colIndex}
                                type="button"
                                className={`btn-form-sm ${
                                  list[0].includes(colItem) ? "active" : ""
                                }`}
                                style={{
                                  backgroundColor: listcaracteristique.find(
                                    (item: any) =>
                                      item._id === caracteristique[0]
                                  )?.color
                                    ? colItem.valeur
                                    : null,
                                }}
                                onClick={() => selectitems(0, colItem)}
                              >
                                {listcaracteristique.find(
                                  (item: any) => item._id === caracteristique[0]
                                )?.color
                                  ? colItem.libelle
                                  : colItem}
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ))
              ) : (
                <p>caracteristique introuvable</p>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <strong>
              {
                listcaracteristique.find(
                  (item: any) => item._id === caracteristique[1]
                )?.libelle
              }
            </strong>

            <div className="col-md-12" style={{ marginRight: "20px" }}>
              {listcaracterisqtiqueitems[1] ? (
                [...listcaracterisqtiqueitems[1], { plus: true }]
                  .reduce((rows: any[], item, index) => {
                    if (index % 4 === 0) {
                      rows.push([]);
                    }
                    rows[rows.length - 1].push(item);
                    return rows;
                  }, [])
                  .map((row, rowIndex) => (
                    <div className="row" key={`row-${rowIndex}`}>
                      {row.map((colItem: any, colIndex: any) => {
                        const globalIndex = rowIndex * 4 + colIndex;
                        return (
                          <div className="col-md-2 marginitems" key={colIndex}>
                            {colItem.plus ? (
                              <div
                                className="c-primary"
                                onClick={() => handelShowModalCarateristique(1)}
                              >
                                {globalIndex < 12 ? (
                                  <i className="fas fa-plus-circle fa-lg"></i>
                                ) : null}
                              </div>
                            ) : (
                              <button
                                key={colIndex}
                                type="button"
                                className={`btn-form-sm ${
                                  list[1].includes(colItem) ? "active" : ""
                                }`}
                                style={{
                                  backgroundColor: listcaracteristique.find(
                                    (item: any) =>
                                      item._id === caracteristique[1]
                                  )?.color
                                    ? colItem.valeur
                                    : null,
                                }}
                                onClick={() => selectitems(1, colItem)}
                              >
                                {listcaracteristique.find(
                                  (item: any) => item._id === caracteristique[1]
                                )?.color
                                  ? colItem.libelle
                                  : colItem}
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ))
              ) : (
                <p>caracteristique introuvable</p>
              )}
            </div>
          </div>
          {listcaracterisqtiqueitems.length > 2 ? (
            <div className="col-md-4">
              <strong>
                {
                  listcaracteristique.find(
                    (item: any) => item._id === caracteristique[2]
                  )?.libelle
                }
              </strong>
              <div className="col-md-12" style={{ marginRight: "20px" }}>
                {listcaracterisqtiqueitems[2] ? (
                  [...listcaracterisqtiqueitems[2], { plus: true }]
                    .reduce((rows: any[], item, index) => {
                      if (index % 4 === 0) {
                        rows.push([]);
                      }
                      rows[rows.length - 1].push(item);
                      return rows;
                    }, [])
                    .map((row, rowIndex) => (
                      <div className="row" key={`row-${rowIndex}`}>
                        {row.map((colItem: any, colIndex: any) => {
                          const globalIndex = rowIndex * 4 + colIndex;
                          return (
                            <div
                              className="col-md-2 marginitems"
                              key={colIndex}
                            >
                              {colItem.plus ? (
                                <div
                                  className="c-primary"
                                  onClick={() =>
                                    handelShowModalCarateristique(2)
                                  }
                                >
                                  {globalIndex < 12 ? (
                                    <i className="fas fa-plus-circle fa-lg"></i>
                                  ) : null}
                                </div>
                              ) : (
                                <button
                                  key={colIndex}
                                  type="button"
                                  className={`btn-form-sm ${
                                    list[2].includes(colItem) ? "active" : ""
                                  }`}
                                  style={{
                                    backgroundColor: listcaracteristique.find(
                                      (item: any) =>
                                        item._id === caracteristique[2]
                                    )?.color
                                      ? colItem.valeur
                                      : null,
                                  }}
                                  onClick={() => selectitems(2, colItem)}
                                >
                                  {listcaracteristique.find(
                                    (item: any) =>
                                      item._id === caracteristique[2]
                                  )?.color
                                    ? colItem.libelle
                                    : colItem}
                                </button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ))
                ) : (
                  <p>caracteristique introuvable</p>
                )}
              </div>
            </div>
          ) : null}

          <ProductCaracteristiquesForm
            ShowModal={ShowModalProduct_Caracteristique}
            setShowModal={setShowModalProduct_Caracteristique}
            ProductCaracteristique={itemcaracteristique}
            organisation_id={null}
            societe={societe}
          />
        </div>
      ) : null}
    </>
  );
}
