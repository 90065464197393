export const gouvernoratsTunisie = [
    { code: "11", nom: "Tunis" },
    { code: "12", nom: "Ariana" },
    { code: "13", nom: "Ben Arous" },
    { code: "14", nom: "Manouba" },
    { code: "21", nom: "Nabeul" },
    { code: "22", nom: "Zaghouan" },
    { code: "23", nom: "Bizerte" },
    { code: "31", nom: "Béja" },
    { code: "32", nom: "Jendouba" },
    { code: "33", nom: "Le Kef" },
    { code: "34", nom: "Siliana" },
    { code: "41", nom: "Kairouan" },
    { code: "42", nom: "Kasserine" },
    { code: "43", nom: "Sidi Bouzid" },
    { code: "51", nom: "Sousse" },
    { code: "52", nom: "Monastir" },
    { code: "53", nom: "Mahdia" },
    { code: "61", nom: "Sfax" },
    { code: "71", nom: "Gafsa" },
    { code: "72", nom: "Tozeur" },
    { code: "73", nom: "Kébili" },
    { code: "81", nom: "Gabès" },
    { code: "82", nom: "Medenine" },
    { code: "83", nom: "Tataouine" }
  ];
  
 