import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  SelectedOrganisation,
  SelectedOrganisations,
  changeConnectOrganisation,
  selectUser,
  selectedAutorisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { allOrganisationsApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import { getAddressByMacApi } from "../../_App/Redux/Slices/userMacAdress/userAddressMacSlice";
import { findUserByOrganisationApi } from "../../_App/Redux/Slices/users/userSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { OrganisationDataType } from "../../_App/Types/Forms/OrganisationDataType";
import MacAddressComponent from "../UserMacAdress/AddMacForm";
import OrganisationDetails from "./OrganisationDetails";
import OrganisationsForm from "./OrganisationForm";
import { getTypeActivite, ListTypeActivite } from "../../_App/Redux/Slices/TypeActiviteSlices/typeactiviteSlice";


const styles = {
  label: {
    color: "#333",
  },
  value: {
    fontWeight: "bold",
    color: "#777",
  },
};

const CustomModalBody = styled(Modal.Body)`
  p {
    margin: 5px 0;
    color: #333;
  }
`;

const CustomCard = styled(Card)`
  margin: 0 auto;
  height: 100%;

  img {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .card-title {
    margin-bottom: 1rem;
  }

  .card-text {
    margin-bottom: 1rem;
  }

  .d-flex {
    flex-wrap: nowrap;
  }

  @media (min-width: 576px) {
    max-width: 400px;
  }

  @media (min-width: 768px) {
    max-width: 600px;
  }

  @media (min-width: 992px) {
    max-width: 800px;
  }
`;

const OrganisationComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const organisations = useAppSelector(SelectedOrganisations);
  const connectedorganisations = useAppSelector(SelectedOrganisation);

  const societeid =useAppSelector(societeId)
  useEffect(() => {
    if(societeid) dispatch(allOrganisationsApi(societeid));
  }, [societeid]);

  const currentUser = useAppSelector(selectUser);
  const currentMacAddress = currentUser?.current_mac_address;
  const listActivite = useAppSelector(ListTypeActivite)
  const [showMacAddressPopup, setShowMacAddressPopup] =
    useState<boolean>(false);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<OrganisationDataType | null>(null);
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
  const [organisationUsers, setOrganisationUsers] = useState<any[]>([]);
  const [showAddOrganisationPopup, setShowAddOrganisationPopup] =
    useState<boolean>(false);
  const [showDetailsComponent, setShowDetailsComponent] =
    useState<boolean>(false);
    const autorisations = useAppSelector(selectedAutorisations);
    
    const canAddOrg = autorisations?.CAN_CREATE_ORGANISATION ;

  useEffect(() => {
    const checkAddressExistence = async () => {
      if (currentMacAddress) {
        try {
          const response = await dispatch(
            getAddressByMacApi(currentMacAddress)
          );

          if (response.payload.data === false) {
            setShowMacAddressPopup(true);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    checkAddressExistence();
  }, [currentMacAddress, dispatch]);
  useEffect(()=>{
    dispatch(getTypeActivite())
  },[])

  const handleDetailsClick = async (organisationIdParam: string) => {
    try {
      const users = await dispatch(
        findUserByOrganisationApi(organisationIdParam)
      );
       // await dispatch(findCommissionByOrganisationApi(organisationIdParam))
      if (users.payload) {
        setOrganisationUsers(users.payload);
      }

      const selectedOrg = organisations?.find(
        (org) => org._id === organisationIdParam
      );

      setSelectedOrganisation(selectedOrg || null);
      setShowDetailsComponent(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const [forceMacAddressPopup, setForceMacAddressPopup] =
    useState<boolean>(false);
    const {t}=useTranslation();


  const handlePopupMacClose = (formValid: boolean) => {
    setForceMacAddressPopup(formValid);
  };

  const handlePopupClose = () => {
    setShowMacAddressPopup(false);
    setForceMacAddressPopup(false);
    setShowDetailsPopup(false);
    setShowAddOrganisationPopup(false);
    setShowDetailsComponent(false);
  };

  const handleAddOrganisationClick = () => {
    setShowAddOrganisationPopup(true);
  };

  if (organisations === null) {
    return <div>Loading organisations...</div>;
  }

  const connect =async(item:any)=>{
   dispatch(changeConnectOrganisation(item)) 
  }
  return (
    <div>
       <div className="d-flex justify-content-end align-items-center mt-3">
        {canAddOrg && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-add-org">
                {t("Ajouter une nouvelle organisation")}
              </Tooltip>
            }
          >  
            <button
              className='btn btn-primary-app'
              style={{
                marginLeft: "15px",
                marginBottom: "16px",
              }}
              onClick={handleAddOrganisationClick}
            >
              {t("Ajouter une nouvelle organisation")}
            </button>
          </OverlayTrigger>
        )}
      </div>
      <Row xs={1} md={2} lg={4} className="g-4 gx-2">
        {organisations &&
          organisations.map((organisation) => (
            <Col key={organisation._id} className="mb-4">
              <Card className="component-card_1 h-100 w-100">
                <Card.Body>
                  <Card.Title>{organisation.nom}</Card.Title>
                  <Card.Text>{organisation.description}</Card.Text>
                  <Card.Text>{organisation.numero}</Card.Text>
                  
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      type="button"
                      className="btn btn-primary-app"
                      onClick={() => handleDetailsClick(organisation._id)}
                    >
                      
                      {t("Détails")}
                    </Button>
                    
                    {
                      connectedorganisations?._id &&  connectedorganisations?._id !==organisation._id ?(
                        <Button
                        type="button"
                        className="btn btn-primary-app"
                        onClick={() => connect(organisation)}
                      >
                        
                        {t("Connecter")}
                      </Button>
                      ):

                      <p style={{color:'red'}}>Connecté</p>
                    }
                  
                    
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
     
      <OrganisationsForm
        showPopup={showAddOrganisationPopup}
        onClose={() => setShowAddOrganisationPopup(false)}
        listActivite={listActivite}

      />

      <Modal show={showMacAddressPopup}>
        <Modal.Header closeButton>
          <Modal.Title> {t("Appareil inconnue")}</Modal.Title>
        </Modal.Header>
        <CustomModalBody>
          <p>{t("Voulez-vous enregistrer cet appareil ?")}</p>
        </CustomModalBody>
        <Modal.Footer>
          <MacAddressComponent onClosePopup={handlePopupClose} />
        </Modal.Footer>
      </Modal>

      <Modal show={showDetailsComponent} onHide={handlePopupClose} size="xl">
        {selectedOrganisation && (
          <OrganisationDetails
            organisation={selectedOrganisation}
            organisationId={selectedOrganisation._id}
            onClose={() => setShowDetailsComponent(false)}
            organisationUsers={organisationUsers}
            listActivite={listActivite}
          />
        )}
      </Modal>
    </div>
  );
};

export default OrganisationComponent;
