import React, { useState } from "react";
import { formatDateAndTime } from "../../_App/Helpers/helpers";
import { useNavigate } from "react-router-dom";
import TransfertAnnomalie from "./TransfertAnnomalie";
import DemandeRemiseIncident from "./DemandeRemiseIncident";
import DemandeAnnulationIncident from "./DemandeAnnulationIncident";
import DemandeRetourIncident from "./DemandeRetourIncident";

export default function IncidentList({ list, t }: any) {
  const navigate = useNavigate();
  const [showtransfertAnnomalie, setshowtransfertAnnomalie] = useState(false);
  const [showModalDemandeRemise, setshowModalDemandeRemise] = useState(false);
  const [showModalDemande_annulation, setshowModalDemande_annulation] =
    useState(false);
  const [showModalDemandeRetour, setshowModalDemandeRetour] = useState(false);

  const [incident, setincident] = useState();
  const [commande, setcommande] = useState();

  const detailIncident = (inc: any) => {
    if (inc.action === "Demande remise") {
      setcommande(inc?.demande_Remise_id);

      setshowModalDemandeRemise(true);
    } else if (inc.action === "Demande annulation") {
      setcommande(inc);

      setshowModalDemande_annulation(true);
    } else if (inc.action === "Demande retour") {
      setcommande(inc);

      setshowModalDemandeRetour(true);
    } else {
      setincident(inc);
      setshowtransfertAnnomalie(true);
    }
  };
  return (
    <div>
      <div className="row layout-spacing mt-5">
        <h2> {t("liste des incidents")} </h2>
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th>{t("Type")}</th>
                <th>{t("Action")}</th>
                <th>{t("message")}</th>
                <th>{t("confirmer")}</th>
                <th>{t("Date")}</th>
              </thead>

              <tbody>
                {list &&
                  list.map((item: any) => (
                    <tr>
                      <td
                        className="lien-bleu-souligne"
                        onClick={() => detailIncident(item)}
                      >
                        {item.type}
                      </td>
                      <td>{item.action}</td>
                      <td>
                        {item.type === "demande"
                          ? item?.demande?.msg
                          : item?.annomalie?.msg}
                      </td>
                      <td>
                        {item?.confirmed
                          ? item?.confirmed?.valide
                            ? "confirmé"
                            : "non confirmé"
                          : "en cours"}
                      </td>
                      <td>{formatDateAndTime(item?.createdAt)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showtransfertAnnomalie && (
        <TransfertAnnomalie
          showModal={showtransfertAnnomalie}
          setshowModal={setshowtransfertAnnomalie}
          annomalie={incident}
          t={t}
        />
      )}

      {showModalDemandeRemise && (
        <DemandeRemiseIncident
          showModal={showModalDemandeRemise}
          setshowModal={setshowModalDemandeRemise}
          commande={commande}
          t={t}
        />
      )}
      {showModalDemande_annulation && (
        <DemandeAnnulationIncident
          showModal={showModalDemande_annulation}
          setshowModal={setshowModalDemande_annulation}
          commande={commande}
          t={t}
        />
      )}
      {showModalDemandeRetour && (
        <DemandeRetourIncident
          showModal={showModalDemandeRetour}
          setshowModal={setshowModalDemandeRetour}
          commande={commande}
          t={t}
        />
      )}
    </div>
  );
}
