import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import store, { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";

interface UpdateOrderParams {
  orderId?: string;
  status: string | null;
  paidProducts?: any;
  vendeur: String | null;
}

/**
 * list  of rapports
 * @public
 */
export const findRapportAchatVenteApi = createAsyncThunk(
  "rapport/findRapportAchatVenteApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `rapport/findRapportAchatVenteApi`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of  val stock by filtre .
 * @public
 */
export const ValstockfilterApi = createAsyncThunk(
  "rapport/ValstockfilterApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`rapport/ValstockfilterApi`, data);
      console.log("response.data", response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface rapportState {
  listrapport: any[];
  listValstock: any;
  totalPrix: any;
  totalQte: any;
}

const initialState: rapportState = {
  listrapport: [],
  listValstock: [],
  totalPrix: null,
  totalQte: null,
};

export const rapportSlice = createSlice({
  name: "rapports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findRapportAchatVenteApi.fulfilled, (state, action) => {
      console.log("action.payload;", action.payload);
      state.listrapport = action.payload;
    });
    builder.addCase(ValstockfilterApi.fulfilled, (state, action) => {
      state.listValstock = action.payload.data;
      state.totalQte = action.payload.QteTotal;
      state.totalPrix = action.payload.Totalprix;
    });
  },
});

export const Listrapport = (state: RootState) => state.rapport.listrapport;
export const TotalPrix = (state: RootState) => state.rapport.totalPrix;
export const TotalQte = (state: RootState) => state.rapport.totalQte;
export const ListValstock = (state: RootState) => state.rapport.listValstock;
export default rapportSlice.reducer;
