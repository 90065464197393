import React, { useEffect, useState } from "react";
import ParametragesCaisseType from "../../../_App/Types/Entites/CaisseParametragesType";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  parametrageCaisse,
  saveParametrageCaisseApi,
  updateParametrageCaisseApi,
} from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import _ from "lodash";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { CaisseParametrageSchema } from "../../../_App/Schemas/AppParametrages/CaisseParametrageSchema";
import { number } from "yup";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const CaisseParametrageForm = () => {
  const dispatch = useAppDispatch();
  const parametrage_caisse = useAppSelector(parametrageCaisse);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const [oldQte_min, setoldQte_min] = useState(0);
  const { register, handleSubmit, reset, formState } =
    useForm<ParametragesCaisseType>();

  useEffect(() => {
    if (parametrage_caisse) {
      reset({
        _id: parametrage_caisse._id,
        barcodeOnly: parametrage_caisse.barcodeOnly,
        defaultCurrency: parametrage_caisse.defaultCurrency,
        direct_payment: parametrage_caisse.direct_payment,
        groupement_produits: parametrage_caisse.groupement_produits,
        message_promo: parametrage_caisse.message_promo,
        table: parametrage_caisse.table,
        instantRemise: parametrage_caisse.instantRemise,
        organisation_id: parametrage_caisse.organisation_id,
        vente_stock_negative: parametrage_caisse.vente_stock_negative,
        minimalStock: parametrage_caisse.minimalStock,
        methodeCloture: parametrage_caisse.methodeCloture,
        duree_bon: parametrage_caisse.duree_bon,
        vendeur_obligatoire: parametrage_caisse.vendeur_obligatoire,
      });
      setoldQte_min(parametrage_caisse.minimalStock);
    } else {
      reset({
        barcodeOnly: false,
        defaultCurrency: "",
        direct_payment: false,
        groupement_produits: false,
        message_promo: "",
        table: false,
        instantRemise: false,
        organisation_id: currentOrganisation,
        vente_stock_negative: false,
        minimalStock: 0,
        methodeCloture: "",
        duree_bon: 7,
        vendeur_obligatoire: false,
      });
    }
  }, [parametrage_caisse]);

  const MsgVerification = (
    data: any
  ): Promise<{ changeMin: boolean; save: boolean }> => {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Vous êtes sûr ?",
        text:
          Number(oldQte_min) !== Number(data.minimalStock)
            ? `Vous voulez modifier la quantité minimale de tous les articles à ${data.minimalStock}`
            : `Vous voulez enregistrer ce paramétrage `,
        icon: "warning",
        showCancelButton: true,
        showDenyButton: Number(oldQte_min) !== Number(data.minimalStock),
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#b2baba",
        confirmButtonText:
          Number(oldQte_min) !== Number(data.minimalStock)
            ? "Enregistrer"
            : "Confirmer",
        cancelButtonText: "Annuler",
        denyButtonText: "Enregistrer et Modifier la quantité ",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve({
            changeMin: false,
            save: true,
          });
        } else if (result.isDenied) {
          resolve({
            changeMin: true,
            save: true,
          });
        } else {
          resolve({
            changeMin: false,
            save: false,
          });
        }
      });
    });
  };

  const onSubmit: SubmitHandler<ParametragesCaisseType> = async (data) => {
    try {
      let res;
      const resp = await MsgVerification(data);
      if (resp.save) {
        const new_data = { ...data, changeMin: resp.changeMin };
        if (parametrage_caisse) {
          res = await dispatch(updateParametrageCaisseApi(new_data));
        } else {
          data.organisation_id = currentOrganisation;
          res = await dispatch(saveParametrageCaisseApi(new_data));
        }
        if (res.payload.success) {
          toast.success("Paramétrages enregistrés avec succès!");
        } else {
          toast.error("Erreur lors de l'enregistrement des paramètres.");
        }
      }
    } catch (error) {
      console.error("Error in API call:", error);
      // Handle the error as needed
    }
  };
  const { t } = useTranslation();
  return (
    <div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox box box-shadow">
          <div className="widget-header">
            <div className="row">
              <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                <h4
                  onClick={() => {
                    console.log(parametrage_caisse);
                    console.log(
                      "Default direct_payment:",
                      parametrage_caisse.direct_payment
                    );
                    console.log("Default table:", parametrage_caisse.table);
                  }}
                >
                  {t("Paramétrages de caisse")}
                </h4>
              </div>
            </div>
          </div>
          {parametrage_caisse ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Paiement directe")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("direct_payment")}
                      defaultChecked={parametrage_caisse.direct_payment}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Tables")} {" (Restauration)"}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("table")}
                      defaultChecked={parametrage_caisse.table}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Groupement de produits")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("groupement_produits")}
                      defaultChecked={parametrage_caisse.groupement_produits}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Vente stock negative")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("vente_stock_negative")}
                      defaultChecked={parametrage_caisse.vente_stock_negative}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Vente code a barre seulement")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("barcodeOnly")}
                      defaultChecked={parametrage_caisse.barcodeOnly}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4  mr-2">
                    {t("Type recherche")}
                  </label>
                </div>
                <div className="col-6  mb-4" style={{ textAlign: "left" }}>
                  <select
                    id="SearchCaisse"
                    className="form-control"
                    {...register("SearchCaisse")}
                  >
                    <option
                      value="ref"
                      selected={parametrage_caisse.SearchCaisse === "ref"}
                    >
                      {t(
                        "Par reference"
                      )}
                    </option>
                    <option
                      value="codeBarre"
                      selected={
                        parametrage_caisse.SearchCaisse === "codeBarre"
                      }
                    >
                      {t(
                        "Par code a barre"
                      )}
                    </option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Remise instantané")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("instantRemise")}
                      defaultChecked={parametrage_caisse.instantRemise}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Vendeur obligatoire
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("vendeur_obligatoire")}
                      defaultChecked={parametrage_caisse.vendeur_obligatoire}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    Message promo
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <textarea
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    {...register("message_promo")}
                    defaultValue={
                      parametrage_caisse && parametrage_caisse.message_promo
                        ? parametrage_caisse.message_promo.replace(/\\n/g, "\n") // Ensures line breaks are preserved
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Durée de validité du contre bon")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    {...register("duree_bon")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.duree_bon !== null
                        ? parametrage_caisse.duree_bon?.toString()
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Durée de validité d'avance")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    {...register("duree_avance")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.duree_avance !== null
                        ? parametrage_caisse.duree_avance?.toString()
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Durée de validité d'échange & retour")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    {...register("duree_retour")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.duree_retour !== null
                        ? parametrage_caisse.duree_retour?.toString()
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Stock minimal")}
                  </label>
                </div>
                <div className="col-4" style={{ textAlign: "left" }}>
                  <input
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    type="number"
                    {...register("minimalStock")}
                    defaultValue={
                      parametrage_caisse &&
                      parametrage_caisse.minimalStock !== null
                        ? parametrage_caisse.minimalStock?.toString()
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Methode de cloture journal")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label htmlFor="methodeCloture" className="form-label">
                    {t("Méthode de clôture")}
                  </label>
                  <select
                    id="methodeCloture"
                    className="form-control"
                    {...register("methodeCloture")}
                  >
                    <option
                      value="total"
                      selected={parametrage_caisse.methodeCloture === "total"}
                    >
                      {t(
                        "Total : Le caissier prend tout l'argent (espèces + cartes)"
                      )}
                    </option>
                    <option
                      value="cashOnly"
                      selected={
                        parametrage_caisse.methodeCloture === "cashOnly"
                      }
                    >
                      {t(
                        "Espèces seulement : Le caissier laisse un fonds de caisse"
                      )}
                    </option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Devise")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <select
                    className="form-control"
                    {...register("defaultCurrency")}
                  >
                    <option>
                      {parametrage_caisse
                        ? parametrage_caisse.defaultCurrency
                        : "Choisir un devise"}
                    </option>
                    <option value={"TND"}>{t("Dinar tunisien")}</option>
                    <option value={"$"}>{t("Dollar $")}</option>
                    <option value={"€"}>{t("Euro €")}</option>
                  </select>
                </div>
              </div>
              <button type="submit" className="btn btn-primary-app">
                {t("Enregistrer")}
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Paiement directe")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="checkbox" {...register("direct_payment")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Tables")} {" (Restauration)"}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="checkbox" {...register("table")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Groupement de produits")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("groupement_produits")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Durée de validité de contre bon")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="text" {...register("duree_bon")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Vente stock negative")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input
                      type="checkbox"
                      {...register("vente_stock_negative")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Vente code a barre seulement")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="switch s-icons s-outline  s-outline-success  mb-4 mr-2">
                    <input type="checkbox" {...register("barcodeOnly")} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Message promo")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <textarea
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    {...register("message_promo")}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Stock minimal")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <input
                    type="number"
                    className="form-control mb-2"
                    id="exampleFormControlTextarea1"
                    {...register("minimalStock")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Methode de cloture journal")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <select
                    className="form-control"
                    {...register("methodeCloture")}
                  >
                    <option>{"Choisir une methode de cloture "}</option>
                    <option value={"total"}>Total</option>
                    <option value={"cashOnly"}>Especes seulement </option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-6" style={{ textAlign: "left" }}>
                  <label className="s-outline  s-outline-success  mb-4 mr-2">
                    {t("Devise")}
                  </label>
                </div>
                <div className="col-6" style={{ textAlign: "left" }}>
                  <select
                    className="form-control"
                    {...register("defaultCurrency")}
                  >
                    <option>{"Choisir un devise"}</option>
                    <option value={"TND"}>{t("Dinar tunisien")}</option>
                    <option value={"$"}>{t("Dollar $")}</option>
                    <option value={"€"}>{t("Euro €")}</option>
                  </select>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary-app"
                style={{ width: "100%", marginTop: "20px" }}
              >
                {t("Enregistrer")}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaisseParametrageForm;
