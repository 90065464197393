import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  Panier,
  resetPanier,
  resetReduction,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";

import Cart from "./cart/Cart";
import Order from "./order/Order";
import PaidOrder from "./order/PaidOrder";
import {
  order,
  resetRetrievedOrder,
  resetSelectedOrder,
  selectedOrder,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { useLocation, useParams } from "react-router-dom";
import { ActiveMenu } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { selectedFullTable } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import TableOrders from "./tableOrders/tableOrders";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import PartialPaidOrder from "./order/PartialPaidOrder";
import CalculatorModal from "../../../Components/Caisses/Calculator/Calculator";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { selectUser } from "../../../_App/Redux/Slices/Auth/authSlice";

interface PaidProduct {
  itemId: string;
  paid_quantity: number;
}
interface newOrder {
  orderId: string;
  paidProducts: PaidProduct[];
  newPrice: number;
}
interface RightBarPanierProps {
  visible: boolean;
  setVisible: any;
  onArticlesToPayChange: (newArticlesToPay: newOrder | undefined) => void;
  miniOrder: (newOrder: OrderType | undefined) => void;
}

const RightBarPanier: React.FC<RightBarPanierProps> = ({
  visible,
  setVisible,
  onArticlesToPayChange,
  miniOrder,
}) => {
  const dispatch = useAppDispatch();
  const openedOrder = useAppSelector(selectedOrder);
  const retrievedOrder = useAppSelector(selectedOrder);
  const location = useLocation();
  const cart = useSelector(Panier);
  const { orderId } = useParams();
  const currentPath = location.pathname;
  const user = useAppSelector(selectUser);

  const Menu = useAppSelector(ActiveMenu);
  const table = useAppSelector(selectedFullTable);
  const parametrage = useAppSelector(parametrageCaisse);
  const bgClass =
    parametrage && parametrage.barcodeOnly ? " light-background" : "";

  useEffect(() => {
    if (!orderId && user && user.role_id?.libelle === "onlineSale") {
      //  dispatch(resetPanier());
      dispatch(resetReduction());
      dispatch(resetSelectedOrder());
      dispatch(resetRetrievedOrder());
    }
  }, [orderId]);
  return (
    <div
      className={
        parametrage && parametrage.barcodeOnly ? "fixed-col-right" : "col-right"
      }
      style={{
        zIndex: "1000",
        overflowY: "hidden",
        position: "fixed",
        left:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "left"
              ? "150px"
              : "0px"
            : Menu?.menuPosition.position === "left"
            ? "unset"
            : "unset",
        right:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "right"
              ? "740px" // Try changing this to "0" or adjusting dynamically
              : "610px"
            : Menu?.menuPosition.position === "right"
            ? "0px" // This should align it correctly
            : 0,
        top:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "up"
              ? "calc(130px + 20%)"
              : "20%"
            : Menu?.menuPosition.position === "up"
            ? "130px"
            : 0,
        bottom:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "down"
              ? "130px"
              : 0
            : Menu?.menuPosition.position === "down"
            ? "130px"
            : 0,
        width: parametrage && parametrage.barcodeOnly ? "auto" : "490px",
      }}
    >
      <div className={"col-right-content" + bgClass} style={{ padding: "0 0" }}>
        <div
          className="col-right-content-container"
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="activity-section"
            style={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {parametrage && parametrage.barcodeOnly ? (
              retrievedOrder || cart.selectedOrders?.length ? (
                retrievedOrder &&
                retrievedOrder.status !== "Payée" &&
                retrievedOrder.status !== "En cours" &&
                retrievedOrder.status !== "Retour" &&
                retrievedOrder.status !== "En livraison" &&
                cart.operationType !== "Retour" ? (
                  <Cart setVisible={setVisible} hidePaymentButton={visible} />
                ) : (
                  <Order
                    setVisible={setVisible}
                    hidePaymentButton={visible}
                    onArticlesToPayChange={onArticlesToPayChange}
                    miniOrder={miniOrder}
                    retrievedOrder={retrievedOrder}
                  />
                )
              ) : table ? (
                <TableOrders
                  setVisible={setVisible}
                  hidePaymentButton={visible}
                  onArticlesToPayChange={onArticlesToPayChange}
                />
              ) : (
                <Cart setVisible={setVisible} hidePaymentButton={visible} />
              )
            ) : openedOrder ? (
              openedOrder.status === "Payée" ? (
                <Cart setVisible={setVisible} hidePaymentButton={visible} />
              ) : (
                <Order
                  setVisible={setVisible}
                  hidePaymentButton={visible}
                  onArticlesToPayChange={onArticlesToPayChange}
                  miniOrder={miniOrder}
                  retrievedOrder={null}
                />
              )
            ) : table ? (
              <TableOrders
                setVisible={setVisible}
                hidePaymentButton={visible}
                onArticlesToPayChange={onArticlesToPayChange}
              />
            ) : (
              <Cart setVisible={setVisible} hidePaymentButton={visible} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightBarPanier;
