import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CategorieType } from "../../../Types/Entites/CategorieType";
import axios from "axios";
import { PaginationType } from "../../../Types/Entites/PaginationType";
import CategorieDataType from "../../../Types/Forms/CategorieDataType";
import _ from "lodash";

/**
 * Get list of categories by organisation
 * @public
 */
export const findCategoriesApi = createAsyncThunk(
  "categorie/findCategoriesApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`categorie/findCategoriesApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of carte's categories.
 * @public
 */
export const findCategoriesByCarteApi = createAsyncThunk(
  "categorie/findCategorieByCarteApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `categorie/findCategorieByCarteApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of  categorie PAR SOCIETE.
 * @public
 */
export const findCategorieBySocieteApi = createAsyncThunk(
  "categorie/findCategorieBySocieteApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `categorie/findCategorieBySocieteApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create a category.
 * @public
 */

export const createCategorieApi = createAsyncThunk(
  "categorie/createCategorieApi",
  async (data: CategorieDataType, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { libelle, image, organisation_id, carte_id, societe_id } = data;
      var body = new FormData();
      body.append("libelle[fr]", libelle.fr);
      body.append("libelle[en]", libelle.en);
      body.append("libelle[ar]", libelle.ar);
      if (image) {
        if (image.length > 0) {
          body.append("image", image[0]);
        }
      }
      const carte =
        carte_id && carte_id !== null ? carte_id.toString() : "null";
      body.append("societe_id", societe_id.toString());
      const organisation =
        organisation_id !== null ? organisation_id.toString() : organisation_id;
      body.append("organisation_id", organisation.toString());
      body.append("carte_id", carte);
      const response = await axios.post(
        "categorie/createCategorieApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * update a category.
 * @public
 */

export const updateCategorieApi = createAsyncThunk(
  "categorie/updateCategorieApi",
  async (data: CategorieType, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { _id, libelle, image, carte_id, organisation_id, societe_id } =
        data;
      var body = new FormData();
      body.append("_method", "put");
      if (_id) body.append("_id", _id.toString());
      body.append("societe_id", societe_id.toString());
      const organisation =
        organisation_id !== null ? organisation_id.toString() : organisation_id;
      const carte =
        carte_id && carte_id !== null ? carte_id.toString() : "null";

      body.append("organisation_id", organisation.toString());
      body.append("libelle[fr]", libelle.fr);
      body.append("libelle[abr]", libelle.abr);
      body.append("carte_id", carte);
      if (image) {
        if (typeof image === "string") {
          body.append("image", image.toString());
        } else {
          body.append("image", image[0]);
        }
      }

      const response = await axios.put(
        "categorie/updateCategorieApi",
        body,
        config
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get pagination.
 * @public
 */
export const updatePagination = createAsyncThunk(
  "categorie/updatePagination",
  async (data: PaginationType, { rejectWithValue, dispatch }) => {
    try {
      // Insert in the database or handle other logic
      return data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface CategorieState {
  listCategorie: CategorieType[];
  listCategorieByCarte: {
    [carte_id: string]: CategorieType[];
  };
  listCartegoriesParOrganisation: { [organisation: string]: CategorieType[] };

  pagination: PaginationType;
}

const initialPagination: PaginationType = {
  itemsPerPage: 10,
  NumberColone: 4,
};

const initialState: CategorieState = {
  listCategorie: [],
  listCategorieByCarte: {},
  listCartegoriesParOrganisation: {},
  pagination: initialPagination,
};

export const categorieSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findCategoriesApi.fulfilled, (state, action) => {
      const groupedByCarteId = _.groupBy(action.payload, "carte_id");
      return {
        ...state,
        listCategorie: action.payload,
        listCategorieByCarte: groupedByCarteId,
      };
    });
    builder.addCase(findCategoriesByCarteApi.fulfilled, (state, action) => {
      return {
        ...state,
        listCategorie: action.payload,
      };
    });
    builder.addCase(createCategorieApi.fulfilled, (state, action) => {
      state.listCategorie.splice(0, 0, action.payload.data);
    });
    builder.addCase(updateCategorieApi.fulfilled, (state, action) => {
      const updated_categorie = action.payload.data;
      var index = _.findIndex(state.listCategorie, {
        _id: updated_categorie._id,
      });
      // Replace item at index using native splice
      state.listCategorie.splice(index, 1, updated_categorie);
    });
    builder.addCase(findCategorieBySocieteApi.fulfilled, (state, action) => {
      const categorieOrganisationNotNull = action.payload.filter(
        (item: any) => item.organisation_id !== null
      );
      const groupedByOrganisation = _.groupBy(
        categorieOrganisationNotNull,
        "organisation_id"
      );
      const groupedByCarteId = _.groupBy(action.payload, "carte_id");

      state.listCategorie = action.payload;
      state.listCategorieByCarte = groupedByCarteId;
      state.listCartegoriesParOrganisation = groupedByOrganisation;
    });

    builder.addCase(updatePagination.fulfilled, (state, action) => {
      return {
        ...state,
        pagination: action.payload,
      };
    });
  },
});

export const ListCategories = (state: RootState) =>
  state.categories.listCategorie;
export const ListCategoriesByCarte = (state: RootState) =>
  state.categories.listCategorieByCarte;
export const ListCartegoriesParOrganisation = (state: RootState) =>
  state.categories.listCartegoriesParOrganisation;
export const PaginationCategories = (state: RootState) =>
  state.categories.pagination;

export default categorieSlice.reducer;
