import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { selectUserId } from "../../_App/Redux/Slices/Auth/authSlice";
import { Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";
import { annulerOrderApi, updateOrderApi } from "../../_App/Redux/Slices/Orders/OrderSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function DemandeAnnulationIncident({ showModal , setshowModal ,commande, t }: any) {
   const dispatch = useAppDispatch();
    const user_id = useAppSelector(selectUserId);
  
   
   const annulerOrder = async (etat:boolean) => {
 
     const obj = { orderId: commande?.order_id?._id ,etat:etat,user:user_id};
     const resp = await dispatch(annulerOrderApi(obj));
     if (resp && resp.payload.success) {
       ToastSuccess(resp.payload.message);
       window.location.reload()
     }
   };
   return (
     <Modal
     show={showModal}
     onHide={() => {
       setshowModal(false);
     }}
     style={{ display: "flex", justifyContent: "center" }}
     size="lg">
         <Modal.Header>
               {t('Numero vente')}{commande?.order_id?.order_number}
       
               </Modal.Header>
       <Modal.Body>
        {commande?.user_confirm_id ? (
          <div className="container center-text">
            <h3>
              {t("Demande deja traité Par ")} {commande?.user_confirm_id?.nom||'' +''+commande?.user_confirm_id?.prenom||''}
              {" "}
            </h3>
            <h3>{t(`Status de commande est ${commande?.order_id?.status}`)}</h3>
          </div>
        ) : (
          <div className="container mt-4">
          <h3>{t("Demande d'annulation une vente")}</h3>
        <h3>
          {t("Vendeur : ")}
          {commande?.order_id?.user_id.nom + " " + commande?.order_id?.user_id.prenom}{" "}
        </h3>

        <h3>
          {t("Client")}{" "}
          {commande?.order_id?.client_id?.defaultClient
            ? commande?.order_id?.client_id.nom
            : commande?.order_id?.client_id.nom ||''+ " " + commande?.order_id?.client_id.prenom||''}
        </h3>
        {commande?.order_id?.items?.length !== 0 &&
          commande?.order_id?.items?.map((item: any, index: any) => (
            <div
              className="row mb-4 p-3 rounded shadow-sm bg-light align-items-center"
              key={index}
            >
              {/* Image Section */}
              <div className="col-3 d-flex justify-content-center align-items-center">
                <Zoom>
                  <img
                    alt={item?.article?.titre?.fr || "Item Image"}
                    src={
                      item?.article?.image && item?.article?.image[0]
                        ? `${process.env.REACT_APP_API_PUBLIC_URL}article/${item?.article?.image[0]}`
                        : "/assets/assets/img/no-image.jpg"
                    }
                    className="img-fluid rounded-circle shadow"
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      objectFit: "cover",
                    }}
                  />
                </Zoom>
              </div>

              {/* Title Section */}
              <div className="col-8 d-flex flex-column justify-content-center">
                <p
                  
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  {item?.article?.titre?.fr || "No title"}
                </p>
              </div>

              {/* Price Section */}
              <div className="col-3 d-flex justify-content-center align-items-center">
                <p className="mb-0">
                  {" "}
                  prix initiale {item?.article?.prix_vente_ttc.toFixed(3) || "N/A"}
                </p>
              </div>

              {/* Discount Section */}
              <div className="col-3 d-flex justify-content-center align-items-center">
                <p className="mb-0">
                  Remise: {item?.remiseprop || item.remise}%
                </p>
              </div>

              {/* Net Price Section */}
              <div className="col-4 d-flex justify-content-center align-items-center">
                <p className="mb-0"> Net à payer: {item?.prixNet.toFixed(3)}</p>
              </div>
            </div>
          ))}
        {/* Action Buttons */}
        <div className="d-flex justify-content-end  row">
        <button className="btn btn-danger ml-1 col-5" onClick={()=>annulerOrder(false)}>{t("Refusé")}</button>

          <button className="btn btn-success col-5" onClick={()=>annulerOrder(true)}>{t("Confirmer")}</button>
       
        </div>
      </div>
        )}
      </Modal.Body>
        
      
       
      
     </Modal>
   );
 }
 