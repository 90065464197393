import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  Filter,
  changeFilter,
} from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import { useDrop } from "react-dnd";
import { updateProductRaccourciApi } from "../../../_App/Redux/Slices/product_raccourci/ProductRaccourciSlice";
import { ProductRaccourciType } from "../../../_App/Types/Entites/ProductRaccourciType";
import {
  Stocks,
  findAllArticleStockApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { resetSelectedOrder } from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  Panier,
  addToPanier,
  setQuantity,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import Swal from "sweetalert2";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";

const RaccourciProductItem = ({ Item, col, onDrop }: any) => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(Filter);
  const cart = useAppSelector(Panier);

  const [{ isOver }, drop] = useDrop({
    accept: "caisseProduct",
    drop: (item) => onDrop(item, Item._id), // Pass the raccourciId to the onDrop handler
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleEmptyRaccourci = async (raccourci: any) => {
    Swal.fire({
      title: "Voulez vous vraiment vider ce raccourci ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(
          updateProductRaccourciApi({
            raccourciId: raccourci._id,
            newProductId: null, // or "" depending on your needs
          })
        );
      }
    });
  };
  const addToCart = async (article: any) => {
    console.log(article);
    dispatch(resetSelectedOrder());
    dispatch(
      addToPanier({
        remise: article.remise ? article.remise : cart.InstantRemise,
        remiseClient: cart.clientRemise,
        prixNet: article.prix_vente_ttc * cart.calculatedQuantity,
        type: article?.type,
        defaut_id: article.defaut_id,
        qtemax: article?.qtemax,
        article: article,
        quantity: cart.calculatedQuantity,
        prix_unitaire: article.prix_vente_ttc,
        prix_total: article.prix_vente_ttc * cart.calculatedQuantity,
        paid_quantity: 0,
      })
    );
    dispatch(setQuantity(1));
  };
  const currentDepot = useAppSelector(DepotDefautOrganisation);

  const stocks = useAppSelector(Stocks);

  const [pressTimer, setPressTimer] = useState<NodeJS.Timeout | null>(null);

  const handlePressStart = () => {
    setPressTimer(setTimeout(() => handleEmptyRaccourci(Item), 1000)); // 1000ms for a 1-second long press
  };

  const handlePressEnd = () => {
    if (pressTimer) {
      clearTimeout(pressTimer);
    }
  };
  return (
    <>
      <div
        className={col + " col layout-spacing product-item"}
        onClick={() => {
          addToCart(Item.product_id);
        }}
        onMouseDown={handlePressStart}
        onMouseUp={handlePressEnd}
        onMouseLeave={handlePressEnd}
      >
        <div className="color-box">
          <div className="round-container">
            {stocks && stocks[Item.product_id._id] ? (
              <span
                className="quantity-badge"
                style={{
                  background:
                    stocks[Item.product_id._id][0]?.stock > 0 ? "green" : "red",
                }}
              >
                {" "}
                {stocks[Item.product_id._id][0].stock}{" "}
              </span>
            ) : null}
            <img
              src={
                Item.product_id.image && Item.product_id.image[0]
                  ? process.env.REACT_APP_API_PUBLIC_URL +
                    "article/" +
                    Item.product_id.image[0]
                  : "/assets/assets/img/no-image.jpg"
              }
              alt="Your"
            />
          </div>
          <span className="product-detail-badge">
            <span className="text" onClick={() => console.log(currentDepot)}>
              {Item.product_id.titre.fr}
              {Item.product_id.caracteristiques.length
                ? Item.product_id.caracteristiques.map(
                    (item: any) => item.value + " "
                  )
                : null}{" "}
            </span>{" "}
          </span>
        </div>
      </div>
    </>
  );
};

export default RaccourciProductItem;
