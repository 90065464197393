import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { OrganisationType } from "../../../Types/Entites/OrganisationType";
import { OrganisationSchema } from "../../../Schemas/OrganisationSchema";


const initialState: {
  organisations: OrganisationType[];
  organisationsWithUsers: OrganisationType[];
} = {
  organisations: [],
  organisationsWithUsers: [],
};

export const createOrganisationApi = createAsyncThunk(
  "organisation/createOrganisationApi",
  async (data: OrganisationType, { rejectWithValue }) => {
    try {
      await OrganisationSchema.validate(data, { abortEarly: false });

      const response = await axios.post(
        "/organisation/createOrganisationApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.message,
      });
    }
  }
);

export const allOrganisationsApi = createAsyncThunk(
  "organisations/allOrganisationsApi",
  async (societeid:string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/organisation/allOrganisationsApi/"+societeid);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findOrganisationsWithUsersApi = createAsyncThunk(
  "organisations/findOrganisationsWithUsersApi",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/organisation/findOrganisationsWithUsersApi"
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findOrganisationByCodeApi = createAsyncThunk(
  "organisations/findOrganisationByCodeApi",
  async (code:string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/organisation/findOrganisationByCodeApi/"+code);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteUserFromOrganisationApi = createAsyncThunk(
  "user/deleteUserFromOrganisationApi",
  async (
    { userId, organisationId }: { userId: string; organisationId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.delete(
        `/user/deleteUserFromOrganisationApi/${userId}/${organisationId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateOrganisationApi = createAsyncThunk(
  "organisation/updateOrganisationApi",
  async (data: OrganisationType, { rejectWithValue }) => {
    try {

      const response = await axios.put(
        "/organisation/updateOrganisationApi",
        data
      );


      return response.data;
    } catch (error: any) {
      console.error("API call failed:", error);
      return rejectWithValue({
        success: false,
        message: error.message,
      });
    }
  }
);

const organisationSlice = createSlice({
  name: "organisations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allOrganisationsApi.fulfilled, (state, action) => {
        state.organisations = action.payload.data;
      })
      .addCase(findOrganisationsWithUsersApi.fulfilled, (state, action) => {
        state.organisationsWithUsers = action.payload;
      })
      .addCase(deleteUserFromOrganisationApi.fulfilled, (state, action) => {})
      .addCase(updateOrganisationApi.fulfilled, (state, action) => {
        const updatedOrganisation = action.payload.data;
        const index = state.organisations.findIndex(
          (org) => org._id === updatedOrganisation._id
        );

        if (index !== -1) {
          state.organisations[index] = updatedOrganisation;
        }
      })
      .addCase(updateOrganisationApi.rejected, (state, action) => {
        console.error("API updateOrganisationApi failed:", action.error);
      })

      .addCase(createOrganisationApi.fulfilled, (state, action) => {
        state.organisations.push(action.payload.data);
      });
  },
});

export const ListOrganisations = (state: RootState) =>
  state.organisations.organisations;
export const ListOrganisationsWithUsers = (state: RootState) =>
  state.organisations.organisationsWithUsers;

export default organisationSlice.reducer;
