import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisation,
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Filtre from "./Filtre";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { Form, Spinner } from "react-bootstrap";

import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import { findDepotsBySocieteApi } from "../../_App/Redux/Slices/depot/depotSlice";
import Select from "react-select";
import {
  findRapportAchatVenteApi,
  ValstockfilterApi,
} from "../../_App/Redux/Slices/rapport/rapportSlice";

export default function ValStockTableArticle({
  t,
  setindex,
  handleSearch,
  setkeyword,
  keyword,
  spinner,
  setspinner,
  setdatafilter,
  dispatch,
  societe,
  totalPrix,
  totalQte,
}: any) {
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [intervaleRef, setintervaleRef] = useState<any>();
  const [intervalePrix, setintervalePrix] = useState<any>();
  const listOrganisations = useAppSelector(ListOrganisations);
  const [selectedOrganisationsId, setSelectedOrganisationsId] = useState<any[]>(
    []
  );
  const [selectedOrganisations, setSelectedOrganisations] = useState<any[]>([]);

  const currentOrganisation = useAppSelector(SelectedOrganisation);
  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  interface SelectionsState {
    [key: string]: any[];
  }
  const [selections, setSelections] = useState<SelectionsState>({});
  const optionOrganisation = listOrganisations?.map((option) => ({
    value: option._id,
    label: option.nom,
  }));
  useEffect(() => {
    if (currentOrganisation) {
      const fetchData = async () => {
        setSelectedOrganisations([currentOrganisation]);
        setSelectedOrganisationsId([currentOrganisation._id]);
        setspinner(true);

        const data = {
          listOrganisation: [currentOrganisation._id],
        };
        setindex(1);
        setdatafilter(data);

        await dispatch(ValstockfilterApi(data));
        // dispatch(findRapportAchatVenteApi(data))
      };

      fetchData();
    }
  }, [currentOrganisation]);

  const searchfiltre = async () => {
    setspinner(true);
    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      motcle: keyword,
      index: 1,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      listOrganisation: selectedOrganisationsId,
    };
    setindex(1);

    setdatafilter(data);
    await dispatch(ValstockfilterApi(data));
    //  await dispatch(findRapportAchatVenteApi(data))
  };
  const handleChange = (selectedOption: any) => {
    const org = selectedOption.map((item: any) => item.value);
    const findOrganisation = listOrganisations.filter((item) =>
      selectedOption.find((e: any) => e.value === item._id)
    );

    setSelectedOrganisations(findOrganisation);
    setSelectedOrganisationsId(org);
  };
  return (
    <div>
      <div className="row layout-spacing">
        <div className="row layout-spacing">
          <div className="col-lg-12   bg-white  ">
            <div className="row mt-2">
              <div className="col-1">
                <label>{t("Magasins")}</label>
              </div>
              <div className="col-4">
                <Select
                  isMulti
                  options={optionOrganisation}
                  isSearchable={true}
                  onChange={handleChange}
                  value={selectedOrganisationsId?.map((item: any) =>
                    optionOrganisation?.find((cat: any) => cat.value === item)
                  )}
                />
              </div>
            </div>
            <Filtre
              spinner={spinner}
              handleSearch={handleSearch}
              setkeyword={setkeyword}
              t={t}
              setspinner={setspinner}
              setdatafilter={setdatafilter}
              setindex={setindex}
              searchfiltre={searchfiltre}
              selectedCarte={selectedCarte}
              setSelectedCarte={setSelectedCarte}
              selectedCategorie={selectedCategorie}
              setSelectedCategorie={setSelectedCategorie}
              selectedSousCategorie={selectedSousCategorie}
              setSelectedSousCategorie={setSelectedSousCategorie}
              categoriesOptions={categoriesOptions}
              setcategoriesdOptions={setcategoriesdOptions}
              souscategoriesOptions={souscategoriesOptions}
              setsouscategoriesdOptions={setsouscategoriesdOptions}
              selections={selections}
              setintervaleRef={setintervaleRef}
              intervaleRef={intervaleRef}
              setintervalePrix={setintervalePrix}
              intervalePrix={intervalePrix}
              setSelections={setSelections}
            />
          </div>
        </div>
      </div>
     
        <div className="row bg-white ">
          <div className=" col-5     " style={{ padding: "20px" }}>
            <div>
              <strong style={{ fontWeight: "bold " }}>
                {t("Total Stock")} : {totalQte}{" "}
              </strong>
            </div>
          </div>
          <div className=" col-5  " style={{ padding: "20px" }}>
            <div>
              <strong style={{ fontWeight: "bold " }}>
                {t("Total Prix Vente TTC")} : {totalPrix}{" "}
              </strong>
            </div>
          </div>
        </div>
      
    </div>
  );
}
/*   <td key={key}> {/* Ensure each cell has a unique key */
//{stock.find((st: any) => (
//  st.depots_id._id === key && // Check if depots_id matches the current key
//st.article_id !== null && // Ensure article_id is not null
// st.article_id._id === item.article_id._id// Check if article_id matches the specific ID
//))?.stock || key}
//</td>*/
/*          {lists.map((item , indexl)=> (
    <tr key={indexl}> 
    <td>{item.article._id}</td>
    {list_depots.map((element:any , index:number) => (
        <td key={index}>{item[element]}</td>
    ))}
</tr>
))}*/
