import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { layout } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  Currentpage,
  ListOnlineOrders,
  ListOrders,
  TotalNet,
  TotalPages,
  findOrdersFiltreApi,
  resetSelectedOrder,
  selectOrder,
  updateOrderApi,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  selectUser,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { formatdate, statusList } from "../../../_App/Helpers/helpers";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { findPaymentsByOrderApi } from "../../../_App/Redux/Slices/payments/PaymentSlice";
import {
  addClient,
  addToPanier,
  confirmOrder,
  ReservationToPanier,
  resetPanier,
  SelectedOrders,
  setClientRemise,
  setNetPriceArticle,
  setOperationType,
  setOrderid,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { useNavigate } from "react-router-dom";
import OnlineOrderInvoice from "./OnlineOrderInvoice";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import OrderStatusPopUp from "./OrderStatusPopUp";
import {
  confirmReservationApi,
  findReservationByorderId,
} from "../../../_App/Redux/Slices/Reservation/ReservationSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { UserDataType } from "../../../_App/Types/Forms/UserDataType";
import Select, { MultiValue } from "react-select";
import {
  findUserByOrganisationApi,
  ListUsers,
} from "../../../_App/Redux/Slices/users/userSlice";
import { Spinner } from "react-bootstrap";
import Pagination from "../../../Shared/Pagination/Pagination";
import PaginationLayout from "../../../_Layouts/Pagination/PaginationLayout";
import Swal from "sweetalert2";
import {
  findTicketsByOrganisationApi,
  initialeListTicket,
  ordersTickets,
} from "../../../_App/Redux/Slices/tickets/TicketSlice";
import TicketAvance from "../TicketDeCaisse/TicketAvance";
import TicketType from "../../../_App/Types/Entites/TicketType";

const OrdersList = () => {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const parametrage = useAppSelector(parametrageCaisse);
  const navigate = useNavigate();
  const selectedOrganisation = useAppSelector(SelectedOrganisation);
  const user = useAppSelector(selectUser);

  const isOnlineSale = user?.role_id?.libelle === "onlineSale";

  const Orders = useAppSelector(isOnlineSale ? ListOnlineOrders : ListOrders);
  const currentpage = useAppSelector(Currentpage);
  const totalPages = useAppSelector(TotalPages);
  const totalNet = useAppSelector(TotalNet);

  const listusers = useAppSelector(ListUsers);
  const [index, setindex] = useState(1);
  const [loader, setloader] = useState(false);
  //// gestion avance
  const [ticketId, setTicketId] = useState<string | null>(null);
  const [showModalTicketAvance, setShowModalTicketAvance] = useState(false);
  const [Reservation, setReservation] = useState();
  /*****************filtre*************** */
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);

  interface FiltreInterface {
    organisation: any;
    dateDebut: any;
    dateFin: any;
    listcaissiers: UserDataType[];
    listvendeur: UserDataType[];
    status: any;
    numOrder: any;
    index: any;
    codeOrder: any;
  }
  const [filtre, setFiltre] = useState<FiltreInterface>({
    organisation: currentOrganisation,
    dateFin: formatdate(today),
    dateDebut: formatdate(lastMonth),
    listcaissiers: [],
    listvendeur: [],
    status: "Tout",
    numOrder: null,
    index: 1,
    codeOrder: "",
  });
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleSelectChangeUsers = (selectedUsers: any) => {
    const copie = { ...filtre };
    copie.listcaissiers = selectedUsers.map((item: any) => item.value);
    setFiltre(copie);
  };
  const handleSelectChangeVendeur = (selectedUsers: any) => {
    const copie = { ...filtre };
    copie.listvendeur = selectedUsers.map((item: any) => item.value);
    setFiltre(copie);
  };
  const handleSelectMultipleOrders = async () => {
    if (selectedOrders.length) {
      await dispatch(SelectedOrders(selectedOrders));
      await dispatch(setOperationType({ type: "Pay", refunded: [] }));

      window.location.href = "/caisse";
    }
  };
  const handleSelectOrder = async (order: any) => {
    console.log(order);
    if (order.status === "reservation") {
      const findReservation = await dispatch(
        findReservationByorderId(order._id)
      );
      if (findReservation) {
        const todayDate = new Date();
        const echeanceDate = new Date(findReservation.payload?.date_echeance);

        if (todayDate <= echeanceDate) {
          if (parametrage && parametrage.barcodeOnly) {
            await dispatch(findPaymentsByOrderApi(order._id));
            console.log("findReservation", findReservation);
            await dispatch(
              selectOrder({ order_id: order._id, orderdata: order })
            );

            await dispatch(
              ReservationToPanier({
                avance: findReservation.payload?.avance?.reduce(
                  (accumulator: any, currentValue: any) =>
                    accumulator + currentValue.total,
                  0
                ),
                order_id: order._id,
                totalNet: findReservation.payload?.achat_totalNet,
                items: order.items,
                client: findReservation.payload?.infoclient.client,
                reservation: findReservation.payload,
              })
            );
            await dispatch(
              setClientRemise(findReservation.payload?.infoclient.client.remise)
            );
            await dispatch(setNetPriceArticle());
            // window.location.href = "/caisse";
            navigate("/caisse");
          }
        } else {
          ToastWarning("vous avez dépassé la date  d'échéance");
        }
      }
    } else if (
      order.status === "Payée" ||
      order.status === "demande annulation"
    ) {
      dispatch(resetPanier());

      navigate(`/PaidOrder/${order._id}`);
    } else if (
      order.status === "demande" ||
      order.status === "avance en cours de validation"
    ) {
      Swal.fire({
        title: `Commande numéro ${order.order_number}`,
        text: "est toujours en état de demande, elle n'a pas encore été traitée.",
      });
    } else if (order.status === "demande avance valide") {
      Swal.fire({
        title: `La demande de la commande numéro ${order.order_number} a été acceptée`,
        text: "Voulez-vous confirmer la commande ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await dispatch(
            confirmReservationApi({ orderId: order._id })
          );
          if (res.payload.success) {
            const idticket = res.payload.data.ticket;
            setTicketId(idticket);
            setReservation(res.payload.data.Reservation);
            setShowModalTicketAvance(true);
          }
        } else {
          console.log("Commande annulée");
        }
      });
    } else if (
      order.status === "demande Invalide" ||
      order.status === "avance non valide"
    ) {
      Swal.fire({
        title: `Commande numéro ${order.order_number}`,
        text: "La demande n'est pas acceptée.",
      });
    } else if (order.status === "Annulée" || order.status === "Avoir") {
      Swal.fire({
        title: `Commande numéro ${order.order_number}`,
        text: ` La demande est ${order.status}`,
      });
    } else {
      await dispatch(addClient(order?.client_id));
      await dispatch(setOrderid(order._id));
      order.items.forEach(async (item: any) => {
        await dispatch(
          addToPanier({
            article: item.article,
            quantity: item.quantity, // Negative quantity for returns
            outOFStock: item.outOFStock,
            sold_quantity: item.sold_quantity,
            prix_unitaire: item.prix_unitaire, // Negative price for returns
            prix_total: item.prix_unitaire * item.quantity, // Total price negative
            paid_quantity: 0,
            remise: item.remise || 0,
            remisepropsition: item.remisepropsition,
            remiseClient: 0,
            type:
              order.status === "demande Valide"
                ? "defaut"
                : item?.type
                ? item.type
                : "fils",
            note: item?.note,
            prixNet: -(
              item.prix_unitaire *
              item.Qteretour *
              (item.remise / 100)
            ), // Negative net price
          })
        );
      });
      dispatch(confirmOrder());

      if (isOnlineSale) {
        await dispatch(selectOrder({ order_id: order._id, orderdata: order }));
        navigate(`/caisse/${order._id}`);
      } else {
        navigate("/caisse");
      }
    }
  };

  useEffect(() => {
    getListOrder("");
  }, [index]);
  function formatDateAndTime(timestamp: Date) {
    const dateObject = new Date(timestamp);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const seconds = dateObject.getSeconds().toString().padStart(2, "0");

    const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateAndTime;
  }
  
  const [stateShow, setStateShow] = useState(false);
  const [show, setShow] = useState("");

  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findUserByOrganisationApi(currentOrganisation));
      dispatch(findTicketsByOrganisationApi(currentOrganisation));
    }
  }, [currentOrganisation]);

  const tickets = useAppSelector(ordersTickets);

  const getListOrder = async (codeOrder: any) => {
    let copiefiltre = {
      ...filtre,
      index: index,
      codeOrder: codeOrder,
      type:  isOnlineSale ? "online" : "caisse",
    };
    setFiltre(copiefiltre);
    await dispatch(findOrdersFiltreApi(copiefiltre));
  };

  const { t } = useTranslation();
  const [facture, setFacture] = useState(false);
  const [orderId, setOrderId] = useState<String | undefined>(undefined);
  const [ticket, setTicket] = useState<String | undefined>(undefined);

  useEffect(() => {
    if (orderId) {
      setFacture(true);
    }
  }, [orderId]); // Run this effect whenever orderId changes

  const handleOpenTicket = (order_id: string, ticket: any) => {
    setOrderId(order_id); // Trigger the state change
    setTicket(ticket);
  };
  const handleCloseModal = () => {
    setFacture(false); // Close the modal
    setOrderId(""); // Optionally clear the orderId or reset it
  };
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({ id: "", status: "" });
  const handleStatusClick = (order: OrderType) => {
    setSelectedOrder({ id: order._id, status: order.status });
    setShowStatusModal(true);
  };

  // Update selected status
  const handleStatusChange = (newStatus: any) => {
    setSelectedOrder((prev) => ({ ...prev, status: newStatus }));
  };

  const UsersOptions = listusers?.map((option) => ({
    value: option._id,
    label: option.nom + " " + option.prenom,
  }));

  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<OrderType[]>([]);

  const handleSelectToggle = () => {
    setSelectMode((prev) => !prev);
    setSelectedOrders([]); // Reset selection when exiting select mode
  };
  const selectAll = () => {
    setSelectedOrders(Orders);
  };

  const handleOrderSelect = (orderId: string, orders: OrderType[]) => {
    setSelectedOrders((prev) => {
      const isSelected = prev.some((order) => order._id === orderId);

      if (isSelected) {
        return prev.filter((order) => order._id !== orderId);
      } else {
        const selectedOrder = orders.find((order) => order._id === orderId);
        return selectedOrder ? [...prev, selectedOrder] : prev;
      }
    });
  };

  const saveStatusChange = async (
    orders: OrderType[],
    choosenStatus: string
  ) => {
    Swal.fire({
      title: "Voulez-vous confirmer ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        orders.map(async (item) => {
          const res = await dispatch(
            updateOrderApi({
              orderId: item._id,
              status: choosenStatus,
              paidProducts: [],
              vendeur: null,
            })
          );
          if (res.payload.success) {
            setSelectMode(false);
          }
        });
      }
    });
  };
  const handleChange = (e: any) => {
    const { value } = e.target;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      getListOrder(value);
    }, 2000);
  };
  useEffect(() => {
    // Ajout de l'écouteur lors du montage
    const handleKeyPress = (e: any) => {
      const currentElement = document.activeElement;
      // Si l'élément actif n'est pas un champ input,
      // alors on redirige le focus vers l'input "code_input"
      if (currentElement && currentElement.tagName.toLowerCase() !== "input") {
        const myInputElement = document.getElementById("code_input");
        if (myInputElement) {
          myInputElement.focus();
        }
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);
  const getSpecialProcessTicket = (tickets: TicketType[], order: OrderType) => {
    const hasEchange = tickets.some((ticket) => ticket.process === "Echange");
    const hasRetour =
      tickets.some((ticket) => ticket.process === "Retour") ||
      order.status === "Retour";

    if (hasEchange && hasRetour) {
      return "Echange&Retour"; // Both exist
    } else if (hasEchange) {
      return "Echange"; // Only Echange exists
    } else if (hasRetour) {
      return "Retour"; // Only Retour exists
    } else {
      return null; // No special process tickets
    }
  };
  return (
    <>
      <div className="row layout-spacing">
        <div className=" row statbox widget box box-shadow">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-9">
              {" "}
              <h3>{t("Orders List")}</h3>
            </div>
          </div>
          <div className="row">
            <div className="search-bar">
              <input
                autoFocus
                style={{ background: "#f1f2f3", border: "none" }}
                type="text"
                id="code_input"
                className={"form-control   col-6"}
                placeholder={t("scan code ")}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className=" col-lg-2 col-sm-6  col-md-6">
              <div className="search-bar">
                <input
                  style={{ background: "#f1f2f3", border: "none" }}
                  type="text"
                  className="form-control "
                  placeholder={t("search")}
                  value={filtre.numOrder}
                  onChange={(e) =>
                    setFiltre((prev) => ({
                      ...prev,
                      numOrder: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <input
              type="Date"
              className="form-control col-lg-2  col-sm-6  col-md-6"
              style={{
                background: "#f1f2f3",
                border: "none",
                width: "auto",
                height: "auto",
              }}
              id="datepicker"
              value={filtre.dateDebut}
              onChange={(e) =>
                setFiltre((prev) => ({
                  ...prev,
                  dateDebut: e.target.value,
                }))
              }
            />{" "}
            <input
              type="Date"
              className="form-control col-lg-2  col-sm-6  col-md-6"
              style={{
                background: "#f1f2f3",
                border: "none",
                width: "auto",
                height: "auto",
              }}
              id="datepicker"
              value={filtre.dateFin}
              onChange={(e) =>
                setFiltre((prev) => ({
                  ...prev,
                  dateFin: e.target.value,
                }))
              }
            />{" "}
            <Select
              isMulti
              options={UsersOptions}
              isSearchable={true}
              placeholder="caissiers"
              className="col-lg-2    col-sm-3"
              onChange={(newSelectedOptions) =>
                handleSelectChangeUsers(newSelectedOptions)
              }
            />
            <Select
              isMulti
              options={UsersOptions}
              isSearchable={true}
              placeholder="vendeurs"
              className=" col-lg-2  col-sm-3"
              onChange={(newSelectedOptions) =>
                handleSelectChangeVendeur(newSelectedOptions)
              }
            />
            <div
              className=" col-lg-2  col-sm-6  col-md-6 "
              onClick={open}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <div
                className={"dropdown custom-dropdown-icon " + show}
                style={{ cursor: "pointer" }}
              >
                <a
                  className="dropdown-toggle btn"
                  href="#"
                  role="button"
                  id="customDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={stateShow}
                >
                  <span> {t(filtre.status)} </span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </a>
                <div
                  className={"dropdown-menu dropdown-menu-right " + show}
                  aria-labelledby="customDropdown"
                >
                  {statusList?.map(({ status, label }, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      data-value={label} // If needed for tracking
                      onClick={() =>
                        setFiltre((prev) => ({
                          ...prev,
                          status: status,
                        }))
                      }
                    >
                      {t(label)} {/* Assuming t is for translation */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <span className="col-lg-1 " onClick={() => getListOrder("")}>
              <i style={{ fontSize: "20px" }} className=" fas fa-search"></i>
            </span>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "right",
              marginBottom: "5px",
            }}
          >
            {user && user.role_id?.libelle === "onlineSale" && (
              <div className="col-3">
                {" "}
                {selectMode ? (
                  <button
                    className="btn btn-primary"
                    onClick={selectAll}
                    style={{ height: "fit-content" }}
                  >
                    Sélectionner tous
                  </button>
                ) : null}
                <button
                  className={selectMode ? "btn btn-danger" : "btn btn-primary"}
                  onClick={handleSelectToggle}
                  style={{ height: "fit-content" }}
                >
                  {selectMode ? "Annuler" : "Sélectionner"}
                </button>
              </div>
            )}
          </div>
          {user &&
          user.role_id?.libelle === "onlineSale" &&
          selectedOrders.length &&
          selectMode ? (
            <div className="row">
              <div className="col-2" onClick={() => console.log(Orders)}>
                {" "}
                Status commande
              </div>
              {!Orders?.some((item) => item.status === "Pre-confirmée") ? (
                <>
                  <div className="col-2">
                    {" "}
                    <button
                      className="btn btn-primary mb-2"
                      onClick={() =>
                        saveStatusChange(selectedOrders, "En livraison")
                      }
                      style={{ height: "fit-content", width: "100%" }}
                    >
                      En livraison
                    </button>
                  </div>

                  <div className="col-2">
                    {" "}
                    <button
                      className="btn btn-danger"
                      onClick={() => saveStatusChange(selectedOrders, "Retour")}
                      style={{ height: "fit-content", width: "100%" }}
                    >
                      Retour
                    </button>
                  </div>

                  <div className="col-2">
                    {" "}
                    <button
                      className="btn btn-success"
                      onClick={handleSelectMultipleOrders}
                      style={{ height: "fit-content", width: "100%" }}
                    >
                      Payer
                    </button>
                  </div>
                </>
              ) : null}
              <div className="col-2">
                {" "}
                <button
                  className="btn btn-danger"
                  onClick={() => saveStatusChange(selectedOrders, "Annulée")}
                  style={{ height: "fit-content", width: "100%" }}
                >
                  Annulée
                </button>
              </div>
            </div>
          ) : null}

          <div
            className="widget-content widget-content-area"
            style={{ overflowX: "auto" }}
          >
            {/* <p style={{ float: "right" }}>Total Net : {totalNet?.toFixed(3)}</p> */}

            <table id="style-2" className="table style-2  table-hover">
              <thead>
                <tr>
                  <th
                    className="checkbox-column dt-no-sorting"
                    style={{ fontSize: "15px" }}
                  >
                    {t("N° commande")}
                  </th>
                  <th style={{ fontSize: "15px" }}>{t("Date")}</th>
                  {selectedOrganisation &&
                    selectedOrganisation?.Type?.table && (
                      <th style={{ fontSize: "15px" }}>{t("Table")}</th>
                    )}
                  <th style={{ fontSize: "15px" }}>{t("client")}</th>

                  <th className="text-center" style={{ fontSize: "15px" }}>
                    {t("status")}
                  </th>
                  <th className="text-center" style={{ fontSize: "15px" }}>
                    {t("facture")}
                  </th>
                </tr>
              </thead>
              {loader ? (
                <Spinner />
              ) : (
                <tbody>
                  {Orders && Orders.length > 0 && (
                    <>
                      {Orders.map((item) => {
                        // Check if the item has a ticket with 'echange' or 'retour' process
                        const specialProcess = getSpecialProcessTicket(
                          item.tickets || [], item
                        );

                        return (
                          <tr key={item._id}>
                            <td
                              className="checkbox-column"
                              style={{ fontSize: "15px", position: "relative" }}
                            >
                              {selectMode && (
                                <input
                                  type="checkbox"
                                  checked={selectedOrders.includes(item)}
                                  onChange={() =>
                                    handleOrderSelect(item._id, Orders)
                                  }
                                  style={{ marginRight: "5px" }}
                                />
                              )}
                              <a
                                className="link"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleSelectOrder(item)}
                              >
                                {item.order_number}
                                {/* Render badge in the top-right corner of the table cell */}
                                {specialProcess && (
                                  <span
                                    className="shadow-none badge badge-warning"
                                    style={{
                                      position: "absolute", // Position relative to the <td> (now it's the parent)
                                      top: "5px", // Adjust to move it down slightly
                                      right: "5px", // Move it to the right side
                                      padding: "2px 6px",
                                      fontSize: "12px",
                                      borderRadius: "4px",
                                      backgroundColor: "#f39c12", // Customize the badge color
                                      color: "white",
                                    }}
                                  >
                                    {specialProcess}
                                  </span>
                                )}
                              </a>
                            </td>

                            <td style={{ fontSize: "15px" }}>
                              {formatDateAndTime(item.date)}
                            </td>
                            {selectedOrganisation?.Type?.table && (
                              <td style={{ fontSize: "15px" }}>
                                {item.table_id?.numero || 1}
                              </td>
                            )}
                            <td style={{ fontSize: "15px" }}>
                              {item.client_id?.nom}
                            </td>

                            <td
                              className="text-center"
                              style={{ fontSize: "15px" }}
                            >
                              <span
                                className={
                                  item.status === "Nouvelle"
                                    ? "shadow-none badge badge-info"
                                    : item.status === "En attente" ||
                                      item.status === "En cours"
                                    ? "shadow-none badge badge-warning"
                                    : item.status === "Payée"
                                    ? "shadow-none badge badge-success"
                                    : item.status === "Partiellement payée"
                                    ? "shadow-none badge badge-danger"
                                    : item.status === "En livraison"
                                    ? "shadow-none badge badge-primary"
                                    : "shadow-none badge badge-danger"
                                }
                                style={{
                                  width: "100%",
                                  height: "25px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                              >
                                {t(item.status)}
                              </span>
                            </td>
                            <td
                              style={{ textAlign: "center", display: "grid" }}
                            >
                              {isOnlineSale ? (
                                item.status !== "Pre-confirmée" &&
                                item.tickets
                                  ?.filter(
                                    (ticket) =>
                                      ticket.process === "Echange" ||
                                      ticket.process === "Online"
                                  )
                                  .map((ticket, index) => (
                                    <a
                                      onClick={() => {
                                        ticket &&
                                          ticket._id &&
                                          handleOpenTicket(
                                            item._id,
                                            ticket?._id
                                          );
                                      }}
                                      key={index}
                                      className="link"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {"facture "}{" "}
                                      {ticket.process === "Online"
                                        ? "Vente"
                                        : ticket.process}
                                    </a>
                                  ))
                              ) : (
                                <a
                                  className="link"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleOpenTicket(item._id, null)
                                  }
                                >
                                  {"facture"}
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              )}
            </table>
          </div>
          <PaginationLayout
            index={index}
            nbrpage={currentpage}
            totalpages={totalPages}
            setindex={setindex}
          />
        </div>
      </div>
      {ticketId ? (
        <>
          <TicketAvance
            ShowModal={showModalTicketAvance}
            setShowModal={setShowModalTicketAvance}
            ticket={ticketId}
            reservation={Reservation}
          />
        </>
      ) : null}
      <OnlineOrderInvoice
        order_id={orderId}
        ticket={ticket}
        show={facture}
        setShow={setFacture}
        onClose={handleCloseModal} // Pass the close handler to reset the state
      />
      <OrderStatusPopUp
        show={showStatusModal}
        order={selectedOrder}
        onClose={() => setShowStatusModal(false)}
        selectedStatus={selectedOrder.status}
        onChangeStatus={handleStatusChange}
      />
    </>
  );
};

export default OrdersList;
