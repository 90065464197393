// PanierSlice.js
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ArticleType } from "../../../Types/Entites/ArticleType";
import ClientType from "../../../Types/Entites/ClientType";
import axios from "axios";
import { forEach } from "lodash";
import {
  calculateDiscountedPrice,
  calculateRemise,
  maxRemise,
} from "../../../Helpers/helpers";
import {
  GratuiteType,
  ProgramFideliteType,
} from "../../../Types/Entites/RemiseType";
import _ from "lodash";

import { number } from "yup";

import { OrderType } from "../../../Types/Entites/Orders/OrderType";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";

export const findDefaultClientsBySociete = createAsyncThunk(
  "client/findDefaultClientsBySociete",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `client/findDefaultClientsBySociete/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findDefaultClientsBySocieteVerifUserApi = createAsyncThunk(
  "client/findDefaultClientsBySocieteVerifUserApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `client/findDefaultClientsBySocieteVerifUserApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface PanierItem {
  article: ArticleType;
  quantity: number;
  prix_unitaire: number;
  prix_total: number;
  paid_quantity: number;
  sold_quantity: number;
  remise: any;
  remiseClient: number;
  prixNet: number;
  free: boolean;
  type?: String;
  note?: String;
  remisepropsition?: any;
  defaut_id?: any;
  outOFStock: boolean;
  qtemax?: any;
}

interface PanierState {
  items: PanierItem[];
  total: number;
  totalNet: number;
  client_id: string | ClientType;
  defaultClient: any;
  orderConfirmed: boolean;
  calculatedQuantity: number;
  order_id: string | null;
  refundedOrder: OrderType | null;

  reduction: number;
  operationType: string;
  refundedArticle: any;
  avance?: any;
  Bons: any;
  InstantRemise: number;
  clientRemise: number;
  programmesFidelite: ProgramFideliteType[];
  gratuites: GratuiteType[];
  selectedOrders: OrderType[];
  reservation: any;
}

const initialState: PanierState = {
  items: [],
  total: 0,
  totalNet: 0,
  orderConfirmed: false,
  client_id: "",
  defaultClient: {},
  calculatedQuantity: 1,
  order_id: null,
  refundedOrder: null,
  reduction: 0,
  Bons: [],
  operationType: "Vente",
  refundedArticle: null,
  InstantRemise: 0,
  clientRemise: 0,
  programmesFidelite: [],
  gratuites: [],
  selectedOrders: [],
  reservation: null,
};

const PanierSlice = createSlice({
  name: "Panier",
  initialState,
  reducers: {
    addToPanier: (state, action) => {
      const {
        article,
        quantity,
        sold_quantity,
        prix_unitaire,
        prixHt,
        defaut,
        prix_total,
        totalHT,
        remise,
        remiseClient,
        prixNet,
        type,
        netHT,
        note,
        remisepropsition,
        defaut_id,
        qtemax,
      } = action.payload;
      // Check if the item is already in the cart
      const existingItem = state.items.find(
        (item) =>
          item.article._id === article._id &&
          item.free === false &&
          item.type === type &&
          item.remise === remise
      );

      if (existingItem) {
        const updatedItems = state.items.map((item) =>
          item.article._id === article._id &&
          item.type === type &&
          item.remise === remise
            ? {
                ...item,
                quantity: item.quantity + quantity,
                prix_total:
                  (item.quantity + quantity) * item.article.prix_vente_ttc,
                // prix_totalHT:
                //   (item.quantity + quantity) * item.article.prix_vente_ht,
              }
            : item
        );
        const finditem = updatedItems.find(
          (item) =>
            item.article._id === article._id &&
            item.free === false &&
            item.type === type &&
            item.remise === remise
        );
        // If the item already exists, update its quantity and total price
        if (finditem && type === "defaut" && finditem?.quantity > qtemax) {
          ToastWarning("la quantite depasse la quantite d'article");
          return;
        }

        return {
          ...state,
          items: updatedItems,
          totalNet: state.totalNet + article.prix_vente_ttc,
          total: state.total + article.prix_vente_ttc,
        };
      } else {
        // If the item is not in the cart, add it with quantity 1 and calculate total price
        const newItem: PanierItem = {
          article: article,
          outOFStock: false,
          quantity: quantity,
          sold_quantity: sold_quantity,
          prix_unitaire: prix_unitaire,
          // prix_unitaireHt: prixHt,
          prix_total: prix_total,
          // prix_totalHT: totalHT,
          paid_quantity: 0,
          remise: remise,
          remisepropsition: remisepropsition,
          remiseClient: remiseClient,
          prixNet: prixNet,
          // prixNetHT: netHT,
          note: note,
          free: false,
          type: type,
          defaut_id: defaut_id,
          qtemax: qtemax,
        };
        console.log("echange article added");
        return {
          ...state,
          items: [...state.items, newItem],
          totalNet: state.totalNet + 10,
          total: state.totalNet + 10,
        };
      }
    },
    changetotal: (state, action) => {
      const { total } = action.payload;

      return {
        ...state,
        totalNet: total,
      };
    },
    SelectedOrders: (state, action) => {
      const totalSum = action.payload.reduce(
        (sum: any, order: OrderType) => sum + order.totalNet,
        0
      );
      return {
        ...state,
        selectedOrders: action.payload,
        total: totalSum,
        totalNet: totalSum,
      };
    },
    setFideliteLIst: (state, action) => {
      return {
        ...state,
        programmesFidelite: action.payload,
      };
    },

    ReservationToPanier: (state, action) => {
      const { order_id, totalNet, items, client, avance, reservation } =
        action.payload;
      //alert(avance)
      let listItems: PanierItem[] = [];
      items.forEach((element: any) => {
        const dataitem: PanierItem = {
          article: element.article,
          outOFStock: false,
          quantity: element.quantity,
          sold_quantity: element.quantity,
          prix_unitaire: element.prix_unitaire,
          prix_total: element.prix_unitaire * element.quantity,
          remise: element.article.remise,
          paid_quantity: 0,
          remiseClient: 0,
          prixNet: element.prix_unitaire * element.quantity,
          free: false,

          // prix_unitaireHt: element.prix_unitaireHt,
          // prix_totalHT: element.prix_totalHT,
          // prixNetHT: element.prixNetHT,
        };
        listItems.push(dataitem);
      });
      return {
        ...state,
        items: listItems,
        total: Number(totalNet),
        order_id: order_id,
        client_id: client._id,
        totalNet: Number(totalNet),
        avance: avance,
        reservation: reservation,
      };
    },
    setOrderid: (state, action) => {
      return {
        ...state,
        order_id: action.payload,
      };
    },
    setReduction: (state, action) => {
      return {
        ...state,
        reduction: state.reduction + action.payload.amount,
        Bons: [...state.Bons, action.payload.bon],
        totalNet: state.totalNet,
        total: state.total,
      };
    },
    setRefundedOrder: (state, action) => {
      return {
        ...state,
        refundedOrder: action.payload,
      };
    },
    setInstantRemise: (state, action) => {
      return {
        ...state,
        items: state.items.map((item) =>
          item.free === false ? { ...item, remise: action.payload } : item
        ),
        InstantRemise: action.payload,
      };
    },

    addClient: (state, action: PayloadAction<string>) => {
      console.log(action.payload);
      return {
        ...state,
        client_id: action.payload,
      };
    },
    setClientRemise: (state, action) => {
      return {
        ...state,
        items: state.items.map((item) => ({
          ...item,
          remiseClient: action.payload,
        })),
        clientRemise: action.payload,
      };
    },
    setNetPrice: (state) => {
      const updatedItems = state.items.map((item) => ({
        ...item,

        prixNet:
          item.prix_unitaire * item.quantity -
          calculateRemise(item).totalRemise,
        // prixNetHT:
        //  ( item.prix_unitaireHt * item.quantity) -
        //   calculateRemise(item).totalRemise,
      }));

      const cartTotalNet = updatedItems.reduce(
        (total, item) => total + item.prixNet,
        0
      );
      const cartTotal = updatedItems.reduce(
        (total, item) =>
          state.operationType === "Echange" && item.quantity < 0
            ? total + item.prixNet
            : total + item.prix_total,
        0
      );

      return {
        ...state,
        items: updatedItems,
        totalNet: cartTotalNet,
        total: cartTotal,
      };
    },
    setNetPriceArticle: (state) => {
      const updatedItems = state.items.map((item) => ({
        ...item,
        prixNet:
          item.prix_unitaire * item.quantity -
          calculateRemise(item).totalRemise,
      }));

      return {
        ...state,
        items: updatedItems,
      };
    },

    setItemInstantRemise: (state, action: PayloadAction<any>) => {
      const itemToIncreaseId = action.payload.id;
      const remise = action.payload.remise;
      const itemToIncrease = state.items.find(
        (item) => item.article._id === itemToIncreaseId
      );
      if (itemToIncrease) {
        const updatedItems = state.items.map((item) =>
          item.article._id === itemToIncreaseId
            ? {
                ...item,
                remise: remise,
              }
            : item
        );
        return {
          ...state,
          items: updatedItems,
        };
      } else {
        return state;
      }
    },
    setItemAvailability: (state, action: PayloadAction<any>) => {
      console.log("tesssttttt :::::::::", action.payload);
      const itemToIncreaseId = action.payload.article;
      const stock = action.payload.stock;

      const itemToIncrease = state.items.find(
        (item) => item.article._id === itemToIncreaseId
      );
      if (itemToIncrease) {
        const updatedItems = state.items.map((item) =>
          item.article._id === itemToIncreaseId
            ? item.quantity > stock
              ? {
                  ...item,
                  outOFStock: true,
                }
              : {
                  ...item,
                  outOFStock: false,
                }
            : item
        );

        return {
          ...state,
          items: updatedItems,
        };
      } else {
        return state;
      }
    },
    setRemises: (state, action: PayloadAction<any>) => {
      const remises = action.payload;
      const cartDefaultRemise = state.InstantRemise;

      let updatedItems = state.items.map((item) => ({
        ...item,
        remise: item.remise || cartDefaultRemise,
      }));

      const productRemises = remises.filter((remise: any) =>
        updatedItems.some(
          (item) =>
            item.article._id === remise?.conditions_produits[0]?.article_id ||
            item.article.articleparent ===
              remise?.conditions_produits[0]?.article_id
        )
      );
      const globalRemises = remises.filter(
        (remise: any) =>
          !updatedItems.some(
            (item) =>
              item.article._id === remise?.conditions_produits[0]?.article_id ||
              item.article.articleparent ===
                remise?.conditions_produits[0]?.article_id
          )
      );

      const remisesByProduct = _.groupBy(
        productRemises,
        "conditions_produits[0].article_id"
      );

      const remisesBySSCategorie = _.groupBy(
        remises,
        "conditions_produits[0].sous_categorie_id"
      );
      const remisesByCatgorie = _.groupBy(
        remises,
        "conditions_produits[0].categorie_id"
      );
      const remisesByCarte = _.groupBy(
        remises,
        "conditions_produits[0].carte_id"
      );
      if (remises) {
        Object.entries(remisesBySSCategorie).map(([key, value]) => {
          updatedItems.map((item) => {
            return item.article.souscategorie === key
              ? (item.remise = maxRemise(value))
              : null;
          });
        });
        Object.entries(remisesByCatgorie).map(([key, value]) => {
          updatedItems.map((item) => {
            return item.article.categorie === key
              ? (item.remise = maxRemise(value))
              : null;
          });
        });
        Object.entries(remisesByCarte).map(([key, value]) => {
          updatedItems.map((item) => {
            return item.article.carte === key
              ? (item.remise = maxRemise(value))
              : null;
          });
        });
        Object.keys(remisesByProduct).forEach((key) => {
          updatedItems = updatedItems.map((item) => {
            if (String(item.article._id) === String(key)) {
              const maxRemiseValue = maxRemise(remisesByProduct[key]);

              return { ...item, remise: maxRemiseValue }; // Returning a new object with updated remise
            } else if (String(item.article.articleparent) === String(key)) {
              const maxRemiseValue = maxRemise(remisesByProduct[key]);

              return { ...item, remise: maxRemiseValue }; // Returning a new object with updated remise
            } else {
              return item;
            }
          });
        });

        if (globalRemises && globalRemises.length !== 0) {
          updatedItems.map((item) => {
            return !Object.keys(remisesByProduct).includes(item.article._id)
              ? (item.remise = maxRemise(globalRemises))
              : null;
          });
        }

        return {
          ...state,
          items: updatedItems,
        };
      } else {
        return state;
      }
    },
    addFreeItem: (state, action: PayloadAction<any>) => {
      const freeItem = action.payload.article;
      const freeQte = action.payload.quantity;

      let initialCart = state.items.filter((item) => item.free === false);
      const existingItem = initialCart.find(
        (item) => item.article._id === freeItem._id
      );
      if (existingItem) {
        if (existingItem.quantity === freeQte) {
          const updatedItems = initialCart.map((item) =>
            item.article._id === freeItem._id
              ? {
                  ...item,
                  remise: 100,
                  free: true,
                  prix_total: 0,
                }
              : item
          );

          return {
            ...state,
            items: updatedItems,
            totalNet: state.totalNet - existingItem.prix_total, // assuming totalPrice is correct
            total: state.total - existingItem.prix_total, // assuming totalPrice is correct
          };
        }
        if (existingItem.quantity > freeQte) {
          const updatedItems = initialCart.map((item) =>
            item.article._id === freeItem._id
              ? {
                  ...item,
                  quantity: item.quantity - freeQte,
                  prix_total:
                    (item.quantity - freeQte) * item.article.prix_vente_ttc,
                }
              : item
          );

          const newItem: PanierItem = {
            article: freeItem,
            outOFStock: false,
            quantity: freeQte,
            sold_quantity: freeQte,
            prix_unitaire: freeItem.prix_vente_ttc,
            prix_total: 0,
            paid_quantity: 0,
            remise: 100,
            remiseClient: 0,
            prixNet: 0,
            free: true,
            // prix_totalHT: 0,
            // prix_unitaireHt: 0,
            // prixNetHT: 0,
          };

          return {
            ...state,
            items: [...updatedItems, newItem],

            totalNet: state.totalNet - existingItem.prix_unitaire * freeQte, // assuming totalPrice is correct
            total: state.total + existingItem.prix_unitaire * freeQte, // assuming totalPrice is correct
          };
        }
        if (existingItem.quantity < freeQte) {
          const newItem: PanierItem = {
            article: freeItem,
            outOFStock: false,
            quantity: freeQte,
            sold_quantity: freeQte,
            prix_unitaire: freeItem.prix_vente_ttc,
            prix_total: 0,
            paid_quantity: 0,
            remise: 100,
            remiseClient: 0,
            prixNet: 0,
            free: true,
            // prix_totalHT: 0,
            // prix_unitaireHt: 0,
            // prixNetHT: 0,
          };

          return {
            ...state,
            items: [...initialCart, newItem],
          };
        }
      } else {
        // If the item is not in the cart, add it with quantity 1 and calculate total price
        const newItem: PanierItem = {
          article: freeItem,
          outOFStock: false,
          quantity: freeQte,
          sold_quantity: freeQte,
          prix_unitaire: freeItem.prix_vente_ttc,
          prix_total: 0,
          paid_quantity: 0,
          remise: 100,
          remiseClient: 0,
          prixNet: 0,
          free: true,
          // prix_totalHT: 0,
          // prix_unitaireHt: freeItem.prix_vente_ht,
          // prixNetHT: 0,
        };

        return {
          ...state,
          items: [...initialCart, newItem],
        };
      }
    },
    setOperationType: (state, action) => {
      const newState = {
        ...state,
        operationType: action.payload.type,
      };

      return newState;
    },

    resetOperationType: (state) => {
      return {
        ...state,
        operationType: initialState.operationType,
      };
    },
    resetReduction: (state) => {
      return {
        ...state,
        reduction: initialState.reduction,
        Bons: initialState.Bons,
      };
    },
    setQuantity: (state, action) => {
      return {
        ...state,
        calculatedQuantity: action.payload,
      };
    },

    removeFromPanier: (state, action) => {
      const { article, type, remise } = action.payload;
      const itemIdToRemove = article._id;

      // Trouver l'élément à supprimer
      const itemToRemove = state.items.find(
        (item) =>
          item.article._id === itemIdToRemove &&
          item.type === type &&
          item.remise === remise
      );

      if (itemToRemove) {
        // Filtrer les éléments pour créer une liste mise à jour
        const updatedItems = state.items.filter(
          (item) => item !== itemToRemove
        );

        return {
          ...state,
          items: updatedItems,
          totalNet: state.totalNet - (itemToRemove.prixNet || 0),
          total: state.total - (itemToRemove.prix_total || 0),
        };
      }

      // Si l'élément à supprimer n'est pas trouvé, retourner l'état initial
      return state;
    },

    decreaseQuantity: (state, action) => {
      const itemToIncreaseId = action.payload.article._id;
      const itemToIncrease = state.items.find(
        (item) =>
          item.article._id === itemToIncreaseId &&
          item.type === action.payload.type &&
          item.remise === action.payload.remise
      );
      if (itemToIncrease) {
        const updatedItems = state.items.map((item) =>
          item === itemToIncrease
            ? {
                ...item,
                quantity: item.quantity - 1,
                prix_total: (item.quantity - 1) * item.article.prix_vente_ttc,
              }
            : item
        );

        return {
          ...state,
          items: updatedItems,
          totalNet: state.totalNet - itemToIncrease.article.prix_vente_ttc,
          total: state.total - itemToIncrease.article.prix_vente_ttc,
        };
      } else {
        return state;
      }
    },
    increaseQuantity: (state, action) => {
      console.log(action.payload);
      const itemToIncreaseId = action.payload.item.article._id;
      const quantity = action.payload.quantity;
      const itemToIncrease = state.items.find(
        (item) =>
          item.article._id === itemToIncreaseId &&
          item.type === action.payload.item.type &&
          item.remise === action.payload.item.remise
      );
      if (itemToIncrease) {
        const updatedItems = state.items.map((item) =>
          item === itemToIncrease
            ? {
                ...item,
                quantity: item.quantity + quantity,
                prix_total:
                  (item.quantity + quantity) * itemToIncrease.article.prix_vente_ttc,
                // prix_totalHT:
                //   (item.quantity + 1) * itemToIncrease.article.prix_vente_ht,
              }
            : item
        );
        const finditem = updatedItems.find(
          (item) =>
            item.article._id === itemToIncreaseId &&
            item.type === action.payload.item.type &&
            item.remise === action.payload.item.remise
        );
        // If the item already exists, update its quantity and total price
        if (
          finditem &&
          finditem.type === "defaut" &&
          finditem?.quantity > finditem.qtemax
        ) {
          ToastWarning("la quantite depasse la quantite d'article");
          return;
        }
        return {
          ...state,
          items: updatedItems,
          totalNet: state.totalNet + itemToIncrease.article.prix_vente_ttc,
          total: state.total + itemToIncrease.article.prix_vente_ttc,
        };
      } else {
        return state;
      }
    },
    resetPanier: (state) => {
      return {
        ...state,
        items: [],
        total: 0,
        totalNet: 0,
        client_id: "",
        orderConfirmed: false,
        order_id: null,
        defaultClient: state.defaultClient || {},
        refundedOrder: initialState.refundedOrder,
        refundedArticle: initialState.refundedArticle,
        reduction: 0,
        InstantRemise: 0,
        clientRemise: 0,
        programmesFidelite: [],
        gratuites: [],
        operationType: "Vente",
        avance: 0,
      };
    },
    confirmOrder: (state) => {
      return {
        ...state,
        orderConfirmed: true,
      };
    },
    payQuantity: (state, action: PayloadAction<string>) => {
      const itemToIncreaseId = action.payload;
      const itemToIncrease = state.items.find(
        (item) => item.article._id === itemToIncreaseId
      );
      if (itemToIncrease) {
        const updatedItems = state.items.map((item) =>
          item.article._id === itemToIncreaseId
            ? {
                ...item,
                paid_quantity: item.paid_quantity + 1,
              }
            : item
        );

        return {
          ...state,
          items: updatedItems,
        };
      } else {
        return state;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findDefaultClientsBySociete.fulfilled, (state, action) => {
      const defaultClientId = action.payload._id;
      return {
        ...state,
        defaultClient: action.payload,
        // client_id: defaultClientId,
      };
    });
    builder.addCase(
      findDefaultClientsBySocieteVerifUserApi.fulfilled,
      (state, action) => {
        const defaultClientId = action.payload._id;
        return {
          ...state,
          defaultClient: action.payload,
          //   client_id: defaultClientId,
        };
      }
    );
  },
});

export const {
  addToPanier,
  addClient,
  removeFromPanier,
  decreaseQuantity,
  increaseQuantity,
  resetPanier,
  confirmOrder,
  payQuantity,
  ReservationToPanier,
  setQuantity,
  changetotal,
  setReduction,
  resetReduction,
  setOperationType,
  resetOperationType,
  setInstantRemise,
  setItemInstantRemise,
  setClientRemise,
  setNetPrice,
  setNetPriceArticle,
  setFideliteLIst,
  addFreeItem,
  setRemises,
  setRefundedOrder,
  SelectedOrders,
  setOrderid,
  setItemAvailability,
} = PanierSlice.actions;
export const Panier = (state: RootState) => state.panier;
export const PanierItems = (state: RootState) => state.panier.items;
export const DefaultClient = (state: RootState) => state.panier.defaultClient;
export const Reduction = (state: RootState) => state.panier.reduction;
export const Bons = (state: RootState) => state.panier.Bons;

export default PanierSlice.reducer;
