import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUserId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  DepotDefautOrganisation,
  findDepotsByCodeApi,
  findDepotsBySocieteApi,
  findDepotsDefautByOrganisationApi,
  ListDepotByOrganisation,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import { t } from "i18next";
import {
  findArticleByBarCode,
  findArticleStockByBarCodeApi,
  findArticleStockByRefApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  createTransfertApi,
  creeDistributionApi,
} from "../../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { toast } from "react-toastify";
import { UserDataType } from "../../../_App/Types/Forms/UserDataType";
import { findUsersByCodeApi } from "../../../_App/Redux/Slices/users/userSlice";
import {
  allOrganisationsApi,
  findOrganisationByCodeApi,
  ListOrganisations,
} from "../../../_App/Redux/Slices/organisations/organisationSlice";
import { Button, Spinner } from "react-bootstrap";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import ArticleListReference from "../../../_Layouts/RightBar/RightBar/cart/ArticleListReference";

export default function DistributionParDepot() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const organisationid = useAppSelector(SelectedOrganisationId);
  const listDepots = useAppSelector(ListDepotByOrganisation);
  const [selectedorganisation, setselectedorganisation] = useState("");
  const [ListReference, setListReference] = useState<any[]>();
  const [ShowModalListReference, setShowModalListReference] = useState(false);
  const [selecteddepot, setselecteddepot] = useState("");
  const [selecteddepotSource, setselecteddepotSource] = useState("");
  const parametrage = useAppSelector(parametrageCaisse);
  const TypeInputParametrage = parametrage?.SearchCaisse;

  const [TypeInput, setTypeInput] = useState(TypeInputParametrage);

  const societe = useAppSelector(societeId);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const [distribution, setdistribution] = useState<any[]>([]);
  const lastKeyPressTime = useRef<number>(0);

  const [codeabarre, setcodeabarre] = useState("");
  const user_id = useAppSelector(selectUserId);
  const [userlivraison, setuserlivraison] = useState<any>(" ");
  const [loading, setLoading] = useState(false);

  const SourceRef = useRef<HTMLInputElement | null>(null);
  const DestinationRef = useRef<HTMLInputElement | null>(null);
  const userRef = useRef<HTMLInputElement | null>(null);
  const ArticleRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTypeInput(TypeInputParametrage);
  }, [TypeInputParametrage]);
  useEffect(() => {
    setTimeout(() => {
      DestinationRef.current?.focus();
    }, 0);
  }, []);
  useEffect(()=>{
    if(societe) dispatch(findDepotsBySocieteApi(societe))
  },[societe])
  

  useEffect(() => {
    if (
      organisationid &&
      listDepots &&
      listDepots[organisationid]?.length > 0
    ) {
      setselecteddepotSource(
        listDepots[organisationid].find((item) => item.defaut)?._id || ""
      );
    }
  }, [organisationid,listDepots]);

 
  const handleChange = async (e: any) => {
    const value = e.target.value;
    const now = Date.now();
    const timeDiff = now - lastKeyPressTime.current;
    lastKeyPressTime.current = now;
    const isScannerInput = timeDiff < 50;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(
      async () => {
        if (value !== "") {
          setLoading(true);
          await findArticle(value);
        }
      },
      isScannerInput ? 700 : 1500
    );

    setTimeoutId(newTimeoutId);
  };

  const findArticle = async (codeabarre: string) => {
    if (selecteddepotSource) {
      if (TypeInput === "codeBarre") {
        const resultArticle = await dispatch(
          findArticleStockByBarCodeApi({
            barcode: codeabarre,
            depots: selecteddepotSource,
          })
        );

        if (resultArticle.payload.success) {
          await addItemToList(resultArticle.payload);
        } else {
          ToastWarning("l'article n'existe pas");
          setcodeabarre("");
        }
      } else {
        const resultArticleref = await dispatch(
          findArticleStockByRefApi({
            ref: codeabarre,
            depots: selecteddepotSource,
          })
        );

        if (resultArticleref.payload.success) {
          setListReference(resultArticleref.payload.data);
          setShowModalListReference(true);
        } else {
          ToastWarning("le reference n'existe pas");
          setcodeabarre("");
        }
      }

      setLoading(false);
    }
  };
  const addItemToList = async (item: any) => {
    setShowModalListReference(false);
    setcodeabarre("");

    const findarticle = distribution.findIndex(
      (e) => e.article._id === item.article._id
    );

    if (findarticle === -1) {
      const obj = {
        article: item.article,
        Quantite: 1,
        stockinitiale: item.stock.stock,
        stock: item.stock._id,
      };
      setdistribution((prev) => [...prev, obj]);
      setcodeabarre("");
    } else {
      const copiedistribution = [...distribution];

      copiedistribution[findarticle].Quantite += 1;
      setdistribution(copiedistribution);
      setcodeabarre("");
    }
  };
  const inputchange = async (value: string, index: number) => {
    const copie = [...distribution];

    copie[index].Quantite = parseFloat(value) || 0;
    setdistribution(copie);
  };
  const saveDistribution = async () => {
    if ( selecteddepot !== "") {
      const val = selecteddepotSource === selecteddepot;
      if (val) {
        ToastWarning(
          t("Vous ne pouvez choisir le même dépôt déstocke que le dépôt source")
        );
      } else {
        const listtransfert = distribution.map((element) => {
          if (element.Quantite > 0) {
            return {
              article_id: element.article._id,
              Quantite: element.Quantite,
              Quantiteenvoyee: element.Quantite,
              stock: element.stock,
            };
          }
        });
        const filteredArray = listtransfert.filter(Boolean);

        const data = {
          depots_id: selecteddepotSource,
          depots_reception: selecteddepot,
          organisation_demande:organisationid,
          organisation_transfer: organisationid,
          articles: listtransfert,
          societe_id: societe,
          demander: null,
          lignes: filteredArray,
          accepter_transfer: {
            etat: true,
            user_id: user_id,
            date: new Date(),
          },
          approver_reception: {
            etat: false,
          },
          agentLivraison: userlivraison,
        };

        if (filteredArray.length > 0) {
          const resp = await dispatch(creeDistributionApi([data]));
          if (resp.payload.success) {
            ToastSuccess("la distributrion est cree ");
            navigate("/listdemande/societe/apprové");
          } else {
            ToastWarning(resp.payload.message);
          }
        } else {
          ToastWarning("Veuillez renseigner les champs des distributions.");
        }
      }
    } else {
      ToastWarning("vous devez choisir l'organisation et le dépôt receveur");
    }
  };
  const deleteItem = (index: number) => {
    const newDistribution = distribution.filter((_, i) => i !== index);
    setdistribution(newDistribution);
  };

  const removeOrg = (field: string) => {
    if (field === "source") {
      setselecteddepotSource("");
      setTimeout(() => {
        SourceRef.current?.focus();
      }, 0);
    } else {
      setselecteddepot("");
      setTimeout(() => {
        DestinationRef.current?.focus();
      }, 0);
    }
  };

  const handleInputChange = async (code: string, field: string) => {
    const now = Date.now();
    const timeDiff = now - lastKeyPressTime.current;
    lastKeyPressTime.current = now;

    // Si le temps entre deux entrées est court, c'est probablement un scan
    const isScannerInput = timeDiff < 50;
    if (timeoutId) clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(
      async () => {
        setLoading(true);
        await findDepotBycode(code, field);

      },
      isScannerInput ? 700 : 1500
    );

    setTimeoutId(newTimeoutId);
  };


  const findDepotBycode = async (code: string, field: string) => {
    if (field === "source") {
      const resp = await dispatch(findDepotsByCodeApi(code));
      if (resp.payload.success) {
        setselecteddepotSource(resp.payload.data._id);
        if (selectedorganisation === "") {
          setTimeout(() => {
            DestinationRef.current?.focus();
          }, 0);
        } else {
          if (userlivraison && userlivraison.nom) {
            setTimeout(() => {
              ArticleRef.current?.focus();
            }, 0);
          } else {
            setTimeout(() => {
              userRef.current?.focus();
            }, 0);
          }
        }
      } else {
        ToastWarning(resp.payload.message);
      }
    } else {
      const resp = await dispatch(findDepotsByCodeApi(code));
      if (resp.payload.success) {
        setselecteddepot(resp.payload.data._id);
        if (userlivraison && userlivraison.nom) {
          setTimeout(() => {
            userRef.current?.focus();
          }, 0);
        }
      } else {
        ToastWarning(resp.payload.message);
      }
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="row ">
        <div className="col-4">
          <strong>{t("Depots  Source : ")}</strong>
          {selecteddepotSource === "" ? (
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                handleInputChange(e.target.value, "source");
              }}
              ref={SourceRef}
            />
          ) : (
            <>
              <span className="form-control">
                {organisationid&&
                  listDepots[organisationid].find(
                    (item) => item._id.toString() === selecteddepotSource
                  )?.libelle
                }
                <i
                  onClick={() => removeOrg("source")}
                  className="fas fa-times"
                ></i>
              </span>
            </>
          )}

        
        </div>
        <div className="col-4">
          <strong>{t("Depots  Destinataire  : ")}</strong>
          {selecteddepot === "" ? (
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                handleInputChange(e.target.value, "Destinataire");
              }}
              ref={SourceRef}
            />
          ) : (
            <>
              <span className="form-control">
                {organisationid&&
                  listDepots[organisationid].find(
                    (item) => item._id.toString() === selecteddepot
                  )?.libelle
                }
                <i
                  onClick={() => removeOrg("Destinataire")}
                  className="fas fa-times"
                ></i>
              </span>
            </>
          )}

        
        </div>
        
        <div className="col-4">
          <strong>{t("Article : ")}</strong>
          <div className="search-bar" style={{ position: "relative" }}>
            <input
              className={"form-control search-form-control ml-lg-auto"}
              value={codeabarre}
              onChange={(e) => {
                setcodeabarre(e.target.value);
                handleChange(e);
              }}
              ref={ArticleRef}
            />{" "}
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              {TypeInput === "codeBarre" ? (
                <button
                  className="btn-primary"
                  onClick={() => setTypeInput("ref")}
                  style={{
                    width: "60px",
                    height: "40px",
                  }}
                >
                  {t("CB")}
                </button>
              ) : (
                <button
                  className="btn-primary"
                  onClick={() => setTypeInput("codeBarre")}
                  style={{
                    width: "60px",
                    height: "40px",
                  }}
                >
                  {t("REF")}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-1">
          {loading && (
            <Spinner
              animation="border"
              role="status"
              style={{ width: "3rem", height: "3rem" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
        </div>
      </div>
      <div className="row">
        <div className="row layout-spacing mt-5">
          <div className="col-lg-12   bg-white  ">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <th colSpan={3}>Article</th>
                  <th>{t("Quantite depot destocké")}</th>
                  <th>{t("Quantite")}</th>
                </thead>
                <tbody>
                  {distribution.map((item, index) => (
                    <tr>
                      <td onClick={() => deleteItem(index)}>
                        <i className="fas fa-trash-alt c-primary"></i>
                      </td>
                      <td>
                        {item.article.image &&
                        item.article.image !== null &&
                        item.article.image.length !== 0 ? (
                          <Zoom>
                            <img
                              alt="image"
                              src={
                                process.env.REACT_APP_API_PUBLIC_URL +
                                "article/" +
                                item?.article.image
                              }
                              width="100"
                            />
                          </Zoom>
                        ) : (
                          <img
                            src="assets/assets/img/no-image.jpg"
                            className="card-img-top"
                            alt="..."
                            style={{ width: "50px" }}
                          />
                        )}
                      </td>
                      <td>{item.article?.titre?.fr}</td>
                      <td>{item.stockinitiale}</td>
                      <td>
                        {" "}
                        <input
                          className="form-control"
                          value={distribution[index].Quantite}
                          onChange={(e) => inputchange(e.target.value, index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {true && (
              <button
                className="btn btn-primary-app"
                onClick={saveDistribution}
              >
                {t("Enregistrer")}
              </button>
            )}
          </div>
        </div>
      </div>
      <ArticleListReference
        t={t}
        ShowModal={ShowModalListReference}
        setShowModal={setShowModalListReference}
        selectAricle={addItemToList}
        list={ListReference}
        reference={codeabarre}
      />
    </div>
  );
}
