import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import { Form } from "react-bootstrap";
import {
  ListOrdersByTables,
  findOrdersByTableApi,
  resetSelectedOrder,
  selectedOrder,
} from "../../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  resetSelectedFullTable,
  selectedFullTable,
} from "../../../../_App/Redux/Slices/Caisse/CaisseSlice";
import _ from "lodash";

interface OrderProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hidePaymentButton: boolean | undefined;
  onArticlesToPayChange: (newArticlesToPay: newOrder | undefined) => void;
}
interface PaidProduct {
  itemId: string;
  paid_quantity: number;
}
interface newOrder {
  orderId: string;
  paidProducts: PaidProduct[];
  newPrice: number;
}

interface ArticlesToPay {
  paidProducts: PaidProduct[];
}

const TableOrders: React.FC<OrderProps> = ({
  setVisible,
  hidePaymentButton,
  onArticlesToPayChange,
}) => {
  const dispatch = useAppDispatch();
  const table = useAppSelector(selectedFullTable);
  const orders = useAppSelector(ListOrdersByTables);
  const openedOrder = orders[0];

  useEffect(() => {
    dispatch(findOrdersByTableApi(table._id));
  }, [dispatch, table._id]);

  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [articlesToPay, setArticlesToPay] = useState<ArticlesToPay>(() => {
    const initialPaidProducts: PaidProduct[] = openedOrder?.items?.map(
      (item) => ({
        itemId: item.article._id,
        paid_quantity: 0,
      })
    );
    console.log(initialPaidProducts);
    return {
      paidProducts: initialPaidProducts,
    };
  });

  useEffect(() => {
    setIsPartialPayment(false);
  }, [openedOrder]);

  // Function to handle the switch change
  const handleSwitchChange = () => {
    setIsPartialPayment(!isPartialPayment);
    const initialPaidProducts: PaidProduct[] = openedOrder!.items?.map(
      (item) => ({
        itemId: item.article._id,
        paid_quantity: 0,
      })
    );

    setArticlesToPay({
      paidProducts: initialPaidProducts,
    });
  };
  const handleSelectQuantityToPay = (itemId: string, quantity: number) => {
    let tempArray = _.cloneDeep(articlesToPay);
    tempArray.paidProducts.map((item: any) => {
      if (item.itemId === itemId) {
        return (item.paid_quantity = quantity);
      }
    });

    setArticlesToPay(tempArray);
  };
  const itemPrice = (item: any, index: number) => {
    if (isPartialPayment) {
      return (
        item.prix_unitaire * articlesToPay.paidProducts[index].paid_quantity +
        "$"
      );
    } else
      return item.prix_unitaire * (item.quantity - item.paid_quantity) + "$";
  };

  function calculateTotalPrice(items: PaidProduct[]) {
    let total = 0;

    if (openedOrder && openedOrder.items) {
      items.forEach((item: PaidProduct) => {
        const foundItem = openedOrder.items?.find(
          (element) => element.article._id === item.itemId
        );

        if (
          item &&
          item.itemId !== undefined &&
          foundItem &&
          foundItem.prix_unitaire !== undefined
        ) {
          total += item.paid_quantity * foundItem.prix_unitaire;
        }
      });
    }

    return total > 0 && isPartialPayment ? total : openedOrder?.total;
  }
  function paidAmount() {
    let total = 0;

    if (openedOrder && openedOrder.items) {
      openedOrder.items?.forEach((item: any) => {
        total += item.prix_unitaire * item.paid_quantity;
      });
    }
    return total;
  }
  const articleQuantity = (item: any) => {
    return item.quantity - item.paid_quantity;
  };

  const handleButtonClick = () => {
    const params = {
      orderId: openedOrder?._id || "",
      paidProducts: articlesToPay.paidProducts,
      newPrice: calculateTotalPrice(articlesToPay.paidProducts) || 0,
    };
    if (isPartialPayment) {
      onArticlesToPayChange(params ? params : undefined);
    } else {
      onArticlesToPayChange(undefined);
    }
    setVisible(true);
  };
  return (
    <div>
      <div className="cart-header">
        <div className="widget-message" style={{ padding: "10px" }}>
          <div className="widget-title mb-10">
            <h5>{"Commande " + openedOrder?.order_number}</h5>
            <button
              className="delete-button"
              style={{ padding: "1%" }}
              onClick={() => {
                dispatch(resetSelectedFullTable());
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      {openedOrder &&
        openedOrder?.items?.map((item: any, index: number) =>
          articleQuantity(item) > 0 ? (
            <div className="widget-profile" key={item.article._id}>
              <div className="w-profile-view">
                <div className="col-6">
                  <div
                    className="w-profile-content"
                    style={{ display: "flex" }}
                  >
                    <img
                      src={
                        item.image && item.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="admin-profile"
                      className="img-fluid"
                    />
                    <div>
                      <h6 style={{ fontSize: "14px", fontWeight: "lighter" }}>
                        {item.article.titre.fr}
                      </h6>
                      {item.article.caracteristiques.length ? (
                        <p>
                          {item.article.caracteristiques.map(
                            (caracteristique: any) =>
                              caracteristique.value?.libelle
                                ? caracteristique.value?.libelle + " "
                                : caracteristique.value + " "
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{
                    alignItems: "center",
                    justifyContent: "right",
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <div
                    className="w-profile-links"
                    style={{
                      padding: "0",
                      alignItems: "center",
                    }}
                  >
                    {!hidePaymentButton ? (
                      <>
                        <h6 className="mb-0" style={{ marginRight: "8px" }}>
                          {item.paid_quantity && !isPartialPayment
                            ? "Reste :"
                            : ""}
                        </h6>
                        <span className="w-p-link">
                          <p>{item.quantity - item.paid_quantity}</p>
                        </span>
                      </>
                    ) : isPartialPayment ? (
                      <>
                        <span className="w-p-link">
                          <p>{item.quantity - item.paid_quantity}</p>
                        </span>
                        <span className="w-p-link">
                          <p>
                            {articlesToPay.paidProducts[index].paid_quantity}
                          </p>
                        </span>
                      </>
                    ) : (
                      <span className="w-p-link">
                        <p>{item.quantity - item.paid_quantity}</p>
                      </span>
                    )}

                    {isPartialPayment && !hidePaymentButton ? (
                      <span className="w-p-link">
                        <input
                          className="quantity-input"
                          type="text"
                          value={
                            articlesToPay.paidProducts[index].paid_quantity
                          }
                          min="0"
                          max={articleQuantity(item)}
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            const parsedValue = parseInt(inputValue, 10);
                            const isValid =
                              !isNaN(parsedValue) &&
                              parsedValue >= 0 &&
                              parsedValue <= articleQuantity(item);

                            if (isValid) {
                              handleSelectQuantityToPay(
                                item.article._id,
                                parsedValue
                              );
                            } else {
                              handleSelectQuantityToPay(item.article._id, 0);
                            }
                          }}
                        />
                      </span>
                    ) : null}

                    <span
                      className="w-p-link"
                      style={{ width: "50px", justifyContent: "center" }}
                    >
                      <p>{item.prix_unitaire.toFixed(3) + "$"}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        )}
      <div className="cart-footer">
        <div className="widget-todo">
          {isPartialPayment ? (
            <>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="align-self-center">Prix Total</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{openedOrder?.total.toFixed(3)}</p>
                </div>
              </div>
              <div className="todo-content">
                <div className="">
                  <h6>
                    <span className="align-self-center">
                      Prix a payer :{" "}
                      {calculateTotalPrice(articlesToPay.paidProducts)}
                    </span>
                  </h6>
                </div>
              </div>
            </>
          ) : openedOrder?.status === "Partiellement payée" ? (
            <>
              <div className="todo-content">
                <div className="">
                  <h6>
                    <span className="align-self-center">
                      Prix total : {openedOrder?.total.toFixed(3)}
                    </span>
                    <span
                      className="align-self-center"
                      style={{ position: "absolute", right: "30px" }}
                    >
                      Montant payé : {paidAmount()}
                    </span>
                  </h6>
                </div>
              </div>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="icon">
                      {/* <i className="fas fa-dollar-sign"></i> */}
                    </span>{" "}
                    <span className="align-self-center">Reste a payer</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{(openedOrder.total - paidAmount()).toFixed(3)}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="todo-content">
              <div className="todo-title">
                <h6>
                  <span className="align-self-center">Prix Total</span>
                </h6>
              </div>
              <div className="todo-text">
                <p>{openedOrder?.total.toFixed(3)}</p>
              </div>
            </div>
          )}
        </div>
        {!hidePaymentButton && openedOrder?.status !== "Payée" ? (
          <label className="switch">
            <input
              type="checkbox"
              checked={isPartialPayment}
              onChange={handleSwitchChange}
            />
            <span className="slider"></span>
            <span style={{ marginLeft: "10px" }}>Paiement partiel</span>
          </label>
        ) : null}

        <div
          className="widget-calendar-lists-scroll"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!hidePaymentButton && openedOrder?.status !== "Payée" ? (
            <button
              className="btn btn-success mb-2"
              style={{ width: "100%", background: "#009688" }}
              onClick={(e) => handleButtonClick()}
            >
              Payer
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TableOrders;
