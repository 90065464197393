import "./auth.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-simple-keyboard/build/css/index.css";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  loginApi,
  loginBadgeApi,
  selectIsLoggedIn,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { LoginSchema } from "../../_App/Schemas/LoginSchema";
import { LoginDataType } from "../../_App/Types/Forms/LoginDataType";
import PasswordResetForm from "./PasswordInitialResetForm";
import NumericKeypad from "./PinKeyboard";
import { findUserByBarcode } from "../../_App/Redux/Slices/users/userSlice";


interface LoginParCodeProps {
  setBadge:any,
  badge:any
}

const LoginParCode : React.FC<LoginParCodeProps> = ({
  setBadge,
  badge
}) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [pin, setPin] = useState("");
  const [codebarre, setcodebarre] = useState("");

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const handlePinComplete = (input: string) => {
    setPin(input);
  };

  const [pinInput, setPinInput] = useState("");

  const navigate = useNavigate();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (timeoutId) clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      setcodebarre(value)
      loginfunction(value);
    }, 1000); 

    setTimeoutId(newTimeoutId);
  };

  const loginfunction = async (value: any) => {
    const response = await dispatch(
      loginBadgeApi({ pin: pin, code_a_barre: value })
    );

    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      setShowPinModal(true);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  const clearPin = () => {
    setPinInput("");
  };

  return (
    <div className="form">
      <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap" style={{ paddingTop: "350px" }}>
            <div>
              <div className="form-content">
                <Modal
                  show={showPasswordResetModal}
                  onHide={() => setShowPasswordResetModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <div className="header-message">
                      Réinitialisation du Mot de Passe
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <PasswordResetForm />
                  </Modal.Body>
                </Modal>
                <h1 className="">
                  <span> {('Login caisse ')}</span>
                </h1>
                <div className="form">
                  <div id="username-field" className="field-wrapper input">
                    <input
                      autoFocus
                      className={`form-control`}
                      placeholder="Code de badge "
                      onChange={handleInputChange}
                    />
                    <div className="pre-icon os-icon os-icon-user-male-circle"></div>
                  </div>
                </div>
                <p className="terms-conditions">
                 
                  <span  className="pointer lien-bleu-souligne" onClick={()=>setBadge(badge==="Badge" ? "mail":"Badge")}>Login par email </span >
                </p>
                <p className="terms-conditions">
                  © 2023 All Rights Reserved{" "}
                  <a href="https://www.nomadis.online">Clediss</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-image">
          <div className="l-image"></div>
        </div>
        <Modal
          show={showPinModal}
          onHide={() => setShowPinModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <div className="header-message">
              Le code PIN a été envoyé par mail et SMS{" "}
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row className="text-center mt-2">
              <Col className="mb-2">
                <NumericKeypad
                  onComplete={handlePinComplete}
                  email={''}
                  password={''}
                  code_a_barre={codebarre}
                  bagde={badge}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default LoginParCode;
