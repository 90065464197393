import React, { useEffect, useState } from "react";
import VerifAdmin from "../../Components/VerifUserCode/VerifAdmin";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  findReservationByorderId,
  SelectedReservation,
  ValidationeservationApi,
  
} from "../../_App/Redux/Slices/Reservation/ReservationSlice";
import Zoom from "react-medium-image-zoom";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

export default function DemandeReservation() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { orderid } = useParams();
  const [Admin, setAdmin] = useState();
  const [confirm, setConfirmer] = useState(false);
  const [showModalAdmin, setshowModalAdmin] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const Reservation = useAppSelector(SelectedReservation);

  useEffect(() => {
    setAdmin(undefined);
    setshowModalAdmin(true);
  }, []);

  useEffect(() => {
    if (orderid) {
      dispatch(findReservationByorderId(orderid));
    }
  }, [orderid, dispatch]);

  useEffect(() => {
    console.log('reservation en cours de validation',Reservation)
    if (confirm && Reservation && !Reservation.demande &&Reservation.code_commande.status==="reservation en cours de validation") {
      alert('ok')
      setIsBlocked(true);
      Swal.fire({
        title: `Commande N° ${Reservation.code_commande?.order_number}`,
        text: `La demande a déjà été traitée.`,
        icon: "info",
      });
    }
  }, [Reservation, confirm]);

  const ValideDemande = async (Etat:Boolean) => {
    const obj = { orderId: orderid, etat: Etat };
    const res = await dispatch(ValidationeservationApi(obj))
    if(res.payload.success){
      ToastSuccess(Etat?'demande validé':'demande non valide')
    }else{
      ToastSuccess(res.payload.message)

    }
   
  };

  return (
    <div className="container mt-5">
      <ToastContainer />
      {confirm && isBlocked ? (
        <div className="alert alert-warning text-center">
          {t("Cette demande a déjà été traitée.")}
        </div>
      ) : (
        <div className="card shadow p-4">
          <h3 className="text-center mb-4 text-primary">
            {t("Demande d'augmentation de la durée d'avance")}
          </h3>

          <div className="mb-3">
            <h5>{t("Vendeur : ")}
              {Reservation?.code_commande?.user_id.nom} {Reservation?.code_commande?.user_id.prenom}
            </h5>
            <h5>{t("Client : ")}
              {Reservation?.infoclient?.client?.defaultClient
                ? Reservation?.infoclient?.nom
                : `${Reservation?.infoclient?.client?.nom} ${Reservation?.infoclient?.client?.prenom}`}
            </h5>
          </div>

          {Reservation?.items?.map((item:any, index:any) => (
            <div key={index} className="card mb-3 p-3 d-flex flex-row align-items-center">
              <div className="col-3 text-center">
                <Zoom>
                  <img
                    alt={item?.article?.titre?.fr || "Item Image"}
                    src={item?.article?.image?.[0] ? `${process.env.REACT_APP_API_PUBLIC_URL}article/${item.article.image[0]}` : "/assets/assets/img/no-image.jpg"}
                    className="img-fluid rounded shadow-sm"
                    style={{ maxWidth: "100px", maxHeight: "100px", objectFit: "cover" }}
                  />
                </Zoom>
              </div>
              <div className="col-9">
                <h6 className="fw-bold">{item?.article?.titre?.fr || "Sans titre"}</h6>
                <p className="mb-0">Quantité : {item?.quantity}</p>
              </div>
            </div>
          ))}

          <div className="card bg-light p-3 mt-3">
            <p className="mb-1">{t("Total commande : ")} <strong>{Reservation?.achat_totalNet} </strong></p>
            <p className="mb-1">{t("Avance : ")} <strong>{Reservation?.avance?.[0]?.total} </strong></p>
            <p className="mb-1">{t("Durée : ")} <strong>{Reservation?.duree} jours</strong></p>
          </div>

          <div className="d-flex justify-content-end mt-4">
            <button className="btn btn-success mx-2" onClick={() => ValideDemande(true)}>
              {t("Valider")}
            </button>
            <button className="btn btn-danger mx-2" onClick={() => ValideDemande(false)}>
              {t("Non valide")}
            </button>
          </div>
        </div>
      )}

      <VerifAdmin
        ShowModal={showModalAdmin}
        setShowModal={setshowModalAdmin}
        societe={null}
        user={Admin}
        setuser={setAdmin}
        setConfirmer={setConfirmer}
      />
    </div>
  );
}
