import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import { IncidentType } from "../../../Types/Entites/IncidentType";

export const findListIncidentApi = createAsyncThunk(
  "incident/findListIncidentApi",
  async ({societe_id,index}:any, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `incident/findListIncidentApi/${societe_id}/${index}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface IncidentState {
  listIncident: IncidentType[];
  currentpage:any;
  totalpages:any

}
const initialState: IncidentState = {
  listIncident: [],
  currentpage:0,
  totalpages:0
};
export const IncidentSlice = createSlice({
  name: "incient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findListIncidentApi.fulfilled, (state, action) => {
      state.listIncident = action.payload.data;
      state.currentpage=action.payload.currentPage
      state.totalpages=action.payload.totalPages
    });
  },
});

export const listIncident = (state: RootState) =>
  state.incident.listIncident;
export const currentpage = (state: RootState) =>
  state.incident.currentpage;
export const totalpages = (state: RootState) =>
  state.incident.totalpages;
export default IncidentSlice.reducer;
