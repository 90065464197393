import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import ActionModel from "./ActionModel";
import Filtre from "../Stock/Filtre";
import Select from "react-select";
import {
  currentpage,
  findActionApi,
  ListMouvement,
  mouvementArticleVenteApi,
  Total,
  totalPages,
} from "../../_App/Redux/Slices/mouvement/mouvementSlice";
import TicketDeCaissePaid from "../Caisses/TicketDeCaisse/TicketDeCaissePaid";
import { exportExcel } from "../../_App/Helpers/helpers";
import { useTranslation } from "react-i18next";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";

export default function MouvementVente() {
  const { t } = useTranslation();

  type datafilterType = {
    carte: any;
    categories: any;
    sousCategories: any;
    carateristique: any;
    index: number;
    ref1: any;
    ref2: any;
    prix1: any;
    prix2: any;
  };
  const dispatch = useAppDispatch();
  const societe_id = useAppSelector(societeId);

  const listMouvement = useAppSelector(ListMouvement);
  const [keyword, setkeyword] = useState("");
  const [date_debut, setdate_debut] = useState("");
  const [date_fin, setdate_fin] = useState("");
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [order_id, setorder_id] = useState(null);
  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  const [intervaleRef, setintervaleRef] = useState<any>();
  const [intervalePrix, setintervalePrix] = useState<any>();
  const [ticket, SetTicket] = useState(null);

  interface SelectionsState {
    [key: string]: any[];
  }
  const [datafilter, setdatafilter] = useState<datafilterType>();

  const [selections, setSelections] = useState<SelectionsState>({});
  const [spinner, setspinner] = useState(false);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const total = useAppSelector(Total);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const [action, setaction] = useState();
  const [modelAction, setmodelAction] = useState(false);
  const listOrganisations = useAppSelector(ListOrganisations);
  const [selectedOrganisations, setSelectedOrganisations] = useState<any[]>([]);
  const currentOrganisation = useAppSelector(SelectedOrganisation);

  useEffect(() => {
    if (societe_id) dispatch(allOrganisationsApi(societe_id));
  }, [societe_id]);
  useEffect(() => {
    if (currentOrganisation) {
      setSelectedOrganisations([currentOrganisation._id]);
    }
  }, [currentOrganisation]);
  const optionOrganisation = listOrganisations?.map((option) => ({
    value: option._id,
    label: option.nom,
  }));
  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setdate_debut(formatDate(lastMonth));
    setdate_fin(formatDate(today));
    const data = {
      carte: [],
      categories: [],
      sousCategories: [],
      carateristique: [],
      index: index,
      keyword: "",
      dateDebut: formatDate(lastMonth),
      dateFin: formatDate(today),
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      organisationlist: selectedOrganisations,
    };
    setindex(index);
    setdatafilter(data);
    dispatch(mouvementArticleVenteApi(data));
  }, [selectedOrganisations]);

  useEffect(() => {
    setspinner(false);
  }, [listMouvement]);
  const findMouvement = async () => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: 1,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      organisationlist: selectedOrganisations,
    };
    setindex(1);
    setdatafilter(data);
    dispatch(mouvementArticleVenteApi(data));
  };

  useEffect(() => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: index,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      organisationlist: selectedOrganisations,
    };
    setindex(index);
    setdatafilter(data);
    dispatch(mouvementArticleVenteApi(data));
  }, [index]);

  const handleChange = (selectedOption: any) => {
    const org = selectedOption.map((item: any) => item.value);
    console.log("org", org);
    setSelectedOrganisations(org);
  };
  return (
    <>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
        <h3>{t("Liste des mouvements  des ventes des articles")}</h3>
        <div className="col-4">
          <label>{t("Date debut")}</label>
          <input
            type="date"
            className="form-control "
            value={date_debut}
            onChange={(e) => setdate_debut(e.target.value)}
          />
        </div>
        <div className="col-4">
          <label>{t("Date fin")}</label>
          <input
            type="date"
            className="form-control "
            value={date_fin}
            onChange={(e) => setdate_fin(e.target.value)}
          />
        </div>
        <div className="col-4">
          <label>{t("Magasin")}</label>
          <Select
            isMulti
            options={optionOrganisation}
            isSearchable={true}
            onChange={handleChange}
            value={selectedOrganisations?.map((item: any) =>
              optionOrganisation?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
        <Filtre
          spinner={spinner}
          handleSearch={findMouvement}
          setkeyword={setkeyword}
          t={t}
          setspinner={setspinner}
          setdatafilter={setdatafilter}
          setindex={setindex}
          searchfiltre={findMouvement}
          selectedCarte={selectedCarte}
          setSelectedCarte={setSelectedCarte}
          selectedCategorie={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          selectedSousCategorie={selectedSousCategorie}
          setSelectedSousCategorie={setSelectedSousCategorie}
          categoriesOptions={categoriesOptions}
          setcategoriesdOptions={setcategoriesdOptions}
          souscategoriesOptions={souscategoriesOptions}
          setsouscategoriesdOptions={setsouscategoriesdOptions}
          selections={selections}
          setintervaleRef={setintervaleRef}
          intervaleRef={intervaleRef}
          setintervalePrix={setintervalePrix}
          intervalePrix={intervalePrix}
          setSelections={setSelections}
        />

        {/*<div className="col-3">
          {listMouvement && listMouvement.length !== 0 && (
            <>
              <strong>
                {" "}
                {t("Article")} : {listMouvement[0]?.id_article?.titre?.fr}
              </strong>
              <br />
              <strong>
                {" "}
                {t(" Code a barre")} :{" "}
                {listMouvement[0]?.id_article?.code_a_barre[0]}
              </strong>
            </>
          )}
        </div>*/}
      </div>
      <div className="row layout-spacing mt-5">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th colSpan={9}> </th>
                <th>
                  {t("Total Payée ")}
                  {total}
                </th>
                <th></th>
              </thead>
              <thead>
                <th className="text-center ">
                  {t("Famille")}
                </th>
                <th className="text-center ">{t("Article")}</th>
                <th className="text-center ">{t("Organisation")}</th>
                <th className="text-center ">{t("Depots")}</th>
                <th className="text-center ">{t("Quantite")}</th>
                <th className="text-center ">{t("Prix U TTC")}</th>
                <th className="text-center ">{t("Prix TTC")}</th>
                <th className="text-center ">{t("Remise")}</th>
                <th className="text-center ">{t("Prix Payée")}</th>
                <th className="text-center ">{t("Date  mouvement")}</th>
              </thead>
              <tbody>
                {listMouvement &&
                  listMouvement.length !== 0 &&
                  listMouvement.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        background:
                          item?.transfert &&
                          !item?.transfert.approver_reception?.etat
                            ? "red"
                            : "",
                      }}
                    >
                    

                      <td  className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.article?.souscategorie?.libelle?.fr}
                      </td>
                      <td  className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.article?.titre?.fr}
                      </td>

                      <td className="text-center "style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.organisation_id?.nom}
                      </td>
                      <td  className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.position_depot?.libelle}
                      </td>

                      <td  className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.infoPrix?.quantity}
                      </td>

                      <td className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.infoPrix?.prix_unitaire}
                      </td>
                      <td className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.infoPrix?.prix_total?.toFixed(3)}
                      </td>
                      <td  className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {(
                          Number(item?.infoPrix?.prix_total) *
                          (Number(item?.infoPrix?.remise) / 100)
                        ).toFixed(3)}
                      </td>
                      <td className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.infoPrix?.prixNet?.toFixed(3)}
                      </td>

                      <td  className="text-center " style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.createdAt?.split("T")[0]}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <th ></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="text-center ">
                  {listMouvement &&
                    listMouvement
                      .reduce((acc, item) => {
                        return acc + Number(item?.infoPrix?.quantity);
                      }, 0)
                      .toFixed(3)}
                </th>
                <th >
                  {listMouvement &&
                    listMouvement
                      .reduce((acc, item) => {
                        return acc + Number(item?.infoPrix?.prix_unitaire);
                      }, 0)
                      .toFixed(3)}
                </th>
                <th>
                  {listMouvement &&
                    listMouvement
                      .reduce((acc, item) => {
                        return acc + Number(item?.infoPrix?.prix_total);
                      }, 0)
                      .toFixed(3)}
                </th>
                <th>
                  {listMouvement &&
                    listMouvement
                      .reduce((acc, item) => {
                        return (
                          acc +
                          (Number(item?.infoPrix?.prix_total) *
                            (Number(item?.infoPrix?.remise) / 100) || 0)
                        );
                      }, 0)
                      .toFixed(3)}
                </th>
                <th>
                  {listMouvement &&
                    listMouvement
                      .reduce((acc, item) => {
                        return acc + Number(item?.infoPrix?.prixNet);
                      }, 0)
                      .toFixed(3)}
                </th>
                <th></th>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
      <ActionModel
        t={t}
        data={action}
        showModal={modelAction}
        setshowModal={setmodelAction}
      />

      <TicketDeCaissePaid
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        ticket={ticket}
      />
    </>
  );
}
