import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import TransfertArticleType from "../../_App/Types/Entites/TransfertArticle/TransfertArticleType";
import {
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUser,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  Listdemande_transfer_cree,
  confirmTransfertApi,
  getlesDemandeTransfertCreeApi,
  retourTransfertApi,
} from "../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import { Modal } from "react-bootstrap";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import BonTransfert from "./files/BonTransfert";
import Accordion from "react-bootstrap/Accordion";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { setListItems } from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { findClientByBarcode } from "../../_App/Redux/Slices/client/clientSlice";
import { findArticleByBarCode } from "../../_App/Redux/Slices/article/articleSlice";
import Swal from "sweetalert2";

export default function 
ListTransfertCree({ ShowModal, setShowModal }: any) {
  const { t } = useTranslation();
  const selectedOrganisationId = useAppSelector(SelectedOrganisationId);
  const list_organisation = useAppSelector(SelectedOrganisations);
  const [etat, setetat] = useState("apprové");
  const [list, setlist] = useState<TransfertArticleType[]>([]);
  const user = useAppSelector(selectUserId);
  const societeid = useAppSelector(societeId);
  const userinfo = useAppSelector(selectUser);

  const listdemande_transfer_cree = useAppSelector(Listdemande_transfer_cree);
  const dispatch = useAppDispatch();
  const [showbon, setshowbon] = useState(false);
  const [dataBon, setDatataBon] = useState();
  const [expandedIndex, setExpandedIndex] = useState<any>(null);
  const [scanIndex, setScanIndex] = useState<any>(null);
  const [scanValue, setscanValue] = useState<any>("");

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (userinfo?.role_id?.libelle == "societe") {
      dispatch(getlesDemandeTransfertCreeApi("null"));
    } else {
      if (selectedOrganisationId)
        dispatch(getlesDemandeTransfertCreeApi(selectedOrganisationId));
    }
  }, [userinfo, selectedOrganisationId]);

  useEffect(() => {
    if (etat === "apprové") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) =>
          !!item?.accepter_transfer?.etat &&
          !item?.approver_reception?.etat &&
          item?.approver_reception?.user_id === undefined
      );
      setlist(newlist);
    } else if (etat === "nonapprové") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) => !item.accepter_transfer.etat && item.accepter_transfer.user_id
      );
      setlist(newlist);
    } else if (etat === "encours") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) =>
          !item.accepter_transfer.etat &&
          item.accepter_transfer.user_id === undefined
      );

      setlist(newlist);
    } else if (etat === "confirmé") {
      var newlist = listdemande_transfer_cree?.filter(
        (item) => !!item.approver_reception?.etat
      );

      setlist(newlist);
    } else {
      var newlist = listdemande_transfer_cree?.filter(
        (item) =>
          !item.approver_reception?.etat &&
          item.approver_reception.user_id !== undefined
      );

      setlist(newlist);
    }
  }, [listdemande_transfer_cree, etat]);

  const confirmer = async (idTransfert: string, index: number) => {
    const data = {
      _id: idTransfert,
      user: user,
      societe_id: societeid,
      note: list[index].note,
      lignes: list[index].lignes,
    };
    await Confirmfunction(data);
  };
  const confirmDirect = async (idTransfert: string, index: number) => {
    const listligne = list[index].lignes.map((item) => {
      return { ...item, QuantitReception: item.Quantiteenvoyee };
    });
    const data = {
      _id: idTransfert,
      user: user,
      societe_id: societeid,
      note: list[index].note,
      lignes: listligne,
    };
    await Confirmfunction(data);
  };
  const Confirmfunction = async (data: any) => {
    Swal.fire({
      title: "Voulez vous vraiment confirmer la reception?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(confirmTransfertApi(data));
        if (response?.payload.status) {
          ToastSuccess("reception confirmé");
          setDatataBon(response.payload.data);
          setshowbon(true);
          if (userinfo?.role_id?.libelle) {
            dispatch(getlesDemandeTransfertCreeApi("null"));
          } else {
            if (selectedOrganisationId)
              dispatch(getlesDemandeTransfertCreeApi(selectedOrganisationId));
          }
        }
      }
    });
  };
  const retour = async (idTransfert: string, index: number) => {
    const data = { _id: idTransfert, user: user, note: list[index].note };
    const response = await dispatch(retourTransfertApi(data));
    if (response?.payload.success) {
      ToastSuccess(response?.payload.message);
      setShowModal(false);
    }
  };
  const ajoutnote = async (index: number, value: string) => {
    const copie = list.map((item, i) =>
      i === index ? { ...item, note: value } : item
    );
    setlist(copie);
  };
  const closenote = async (index: number) => {
    const updatedList = list.map((item, i) =>
      i === index ? { ...item, note: undefined } : item
    );
    setlist(updatedList);
  };
  const voirbon = (item: any) => {
    setDatataBon(item);
    setshowbon(true);
  };

  const handelScan = async (value: any) => {
    setscanValue(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      await scanReception(value);
    }, 1000);
  };
  const scanReception = async (value: any) => {
    if (scanIndex === null || scanIndex === undefined) {
      console.error("Aucun scanIndex défini");
      return;
    }

    const findTransfert = list[scanIndex];
    if (!findTransfert) {
      console.error("Aucun transfert trouvé pour cet index");
      return;
    }

    const copie = [...list];
    const findArticleIndex = findTransfert.lignes.findIndex(
      (item) => item.article_id.code_a_barre.includes(value) // Utilisation de `includes` au lieu de `find`
    );

    if (findArticleIndex !== -1) {
      const updatedArticle = {
        ...findTransfert.lignes[findArticleIndex],
        QuantitReception:
          (findTransfert.lignes[findArticleIndex].QuantitReception || 0) + 1,
      };

      // Mise à jour de la liste
      copie[scanIndex] = {
        ...findTransfert,
        lignes: [...findTransfert.lignes],
      };

      copie[scanIndex].lignes[findArticleIndex] = updatedArticle;

      // Mise à jour de l'état
      setlist(copie);
    } else {
      const findArticle = await dispatch(findArticleByBarCode(value));
      if (findArticle.payload) {
        const ArticleToAdd = {
          article_id: findArticle.payload,
          stock: null,
          Quantite: 0,
          Quantiteenvoyee: 0,
          QuantitReception: 1,
        };

        copie[scanIndex] = {
          ...findTransfert,
          lignes: [...findTransfert.lignes, ArticleToAdd],
        };
        setlist(copie);
      }
    }

    setscanValue("");
  };

  const findlignenonidentique = (index: any, ligneindex: any) => {
    const findTransfert = list[index];
    const findArticle = findTransfert.lignes[ligneindex];

    if (findArticle) {
      if (
        findArticle &&
        findArticle &&
        findArticle.Quantiteenvoyee === findArticle.QuantitReception
      ) {
        return true;
      }
      return false;
    }
    return false;
  };
  const toggleBody = (index: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div>
      <div className="row layout-spacing">
        <div className="col-lg-12    bg-white">
          <div className="inv--product-table-section ">
            <div className="row mt-5">
              <div className="col-6">
                <h3 className="c-primary">
                  {" "}
                  {t("Les demandes de Transfert")}{" "}
                </h3>
              </div>
              <div className="col-1 ">
                <span>Etat :</span>
              </div>
              <div className=" col-4 ">
                <select
                  className="form-control"
                  onChange={(e) => setetat(e.target.value)}
                >
                  {" "}
                  <option value="apprové">{t("approuvé")}</option>
                  <option value="confirmé"> {t("réception confirmé")}</option>
                  <option value="encours">{t("encours")}</option>
                  <option value="nonapprové">{t("non approuvé")}</option>
                </select>
              </div>
            </div>

            {list && list.length > 0 && (
              <div className="row">
                <div className="col-1">{t("Organisation  ")} </div>
                <div className="col-1">{t("Depots ")} </div>
                <div className="col-1">{t("Date ")} </div>
                <div className="col-2">{t("Agent de livraison ")} </div>
              </div>
            )}

            <Accordion
              activeKey={
                expandedIndex !== null ? expandedIndex.toString() : null
              }
            >
              {list &&
                list.map((item: any, index: number) => (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header
                      onClick={(e) => {
                        e.stopPropagation(); // Empêche Bootstrap d'ouvrir l'accordion automatiquement
                        e.preventDefault(); // Empêche tout comportement par défaut
                      }}
                      className="row"
                    >
                      <div className="col-1">
                        {item?.organisation_transfer==='null' ? 'Societe':item?.organisation_transfer?.nom}{" "}
                      </div>
                      <div className="col-1">{item.depots_id?.libelle}</div>
                      {etat === "encours" && (
                        <div className="col-2">
                          {item?.demander?.date.split("T")[0]}
                        </div>
                      )}

                      {etat === "apprové" && (
                        <>
                          <div className="col-1">
                            {item?.accepter_transfer?.date?.split("T")[0]}
                          </div>
                          <div className="col-2">
                            {(item?.agentLivraison?.nom || "") +
                              " " +
                              (item?.agentLivraison?.prenom || "")}
                          </div>
                          {item.note !== undefined ? (
                            <div className="col-2">
                              <textarea
                                value={item.note}
                                onChange={(e) =>
                                  ajoutnote(index, e.target.value)
                                }
                              />
                              <i
                                onClick={() => closenote(index)}
                                className="far fa-window-close"
                              ></i>
                            </div>
                          ) : (
                            <div className="col-1">
                              <span
                                className=" btn-primary-app  pointer"
                                onClick={() => ajoutnote(index, "")}
                              >
                                <i className="fas fa-sticky-note"></i>
                              </span>
                            </div>
                          )}

                          <div className="col-1">
                            <span
                              className=" btn-primary-app  pointer"
                              onClick={() => confirmDirect(item._id, index)}
                            >
                              <i className="fas fa-check-circle"></i>
                            </span>
                          </div>
                          <div className="col-1">
                            <span
                              className=" btn-primary-app  pointer"
                              onClick={() => retour(item._id, index)}
                            >
                              <i className="fas fa-undo-alt"></i>
                            </span>
                          </div>
                          {scanIndex !== index && (
                            <div className="col-1">
                              <span
                                className=" btn-primary-app  pointer"
                                onClick={() => {
                                  setExpandedIndex(index);
                                  setScanIndex(index);
                                }}
                              >
                                <i className="fas fa-barcode"></i>{" "}
                              </span>
                            </div>
                          )}

                          {scanIndex === index && (
                            <>
                              <input
                                type="text"
                                autoFocus={scanIndex === index}
                                onChange={(e) => handelScan(e.target.value)}
                                value={scanIndex === index ? scanValue : ""}
                              />
                              <i
                                className="fas fa-times"
                                onClick={() => {
                                  setExpandedIndex(null);
                                  setScanIndex(null);
                                }}
                              ></i>
                            </>
                          )}
                          {
                            <div className="col-1">
                              <span
                                className=" btn-primary-app pointer"
                                onClick={() => {
                                  setDatataBon(item);
                                  setshowbon(true);
                                }}
                              >
                                <i className="fas fa-file-powerpoint"></i>{" "}
                                {t("B.R")}
                              </span>
                            </div>
                          }
                        </>
                      )}
                      {etat === "confirmé" && (
                        <>
                          <div className="col-1">
                            {item?.approver_reception?.date?.split("T")[0]}
                          </div>
                          <div className="col-2">
                            {(item?.agentLivraison?.nom || "") +
                              " " +
                              (item?.agentLivraison?.prenom || "")}
                          </div>
                          <div className="col-3">
                            <span
                              className=" btn-primary-app pointer"
                              onClick={() => {
                                setDatataBon(item);
                                setshowbon(true);
                              }}
                            >
                              <i className="fas fa-file-powerpoint"></i>{" "}
                              {t("Bon de reception")}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="col-1">
                        <button
                          onClick={() => toggleBody(index)}
                          className="btn btn-link c-primary"
                        >
                          {expandedIndex === index ? "▲" : "▼"}
                        </button>
                      </div>
                    </Accordion.Header>
                    {expandedIndex === index && (
                      <Accordion.Body>
                        <div className="row mb-2 bg-primary-app ">
                          <div className="col-1"></div>
                          <div className=" fs-17 text-center col-3">{t("Article")}</div>

                          {etat === "encours" && (
                            <div className=" fs-17 text-center col-3">{t("Quantite demandé")}</div>
                          )}

                          {(etat === "apprové" || etat === "confirmé") && (
                            <>
                              <div className=" fs-17 text-center col-2">
                                {t("Quantite demandé")}
                              </div>
                              <div className=" fs-17 text-center col-2">
                                {t("Quantite envoyé")}
                              </div>
                              <div className="fs-17  text-center col-2">
                                {t("Quantite Reception")}
                              </div>
                            </>
                          )}

                          {etat === "nonapprové" && (
                            <>
                              <div className="fs-17 col-3">
                                {t("Quantite demandé")}
                              </div>
                            </>
                          )}
                        </div>
                        {item.lignes.map((l: any, i: number) => (
                          <>
                            <div
                              className="row"
                              style={{
                                padding:'10px',
                                background: findlignenonidentique(index, i)
                                  ? ""
                                  : "#e5adad",
                              }}
                            >
                              <div className=" user-profile col-1">
                                {l.article_id?.image &&
                                l.article_id?.image !== null &&
                                l.article_id?.image?.length !== 0 ? (
                                  <Zoom>
                                    <img
                                      alt="image"
                                      src={
                                        process.env.REACT_APP_API_PUBLIC_URL +
                                        "article/" +
                                        l.article_id?.image
                                      }
                                      width="100"
                                    />
                                  </Zoom>
                                ) : (
                                  <img
                                    src="assets/assets/img/no-image.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    style={{ width: "40px" }}
                                  />
                                )}
                              </div>
                              <div className="text-center fs-17 col-3">
                                {l.article_id?.titre?.fr}
                              </div>

                              {etat === "encours" && (
                                <div className="col-3 fs-17  text-center">{l.Quantite}</div>
                              )}
                              {(etat === "apprové" || etat === "confirmé") && (
                                <>
                                  <div className=" fs-17 col-2   text-center">{l.Quantite}</div>
                                  <div className="col-2 fs-17  text-center">
                                    {l.Quantiteenvoyee || l.Quantite}
                                  </div>
                                  <div className="col-2 fs-17  text-center">
                                    {l.QuantitReception || 0}
                                  </div>
                                </>
                              )}

                              {etat === "nonapprové" && (
                                <>
                                  <div className="col-3 fs-17  text-center">{l.Quantite}</div>
                                </>
                              )}
                            </div>
                            <div className="mb-2"></div>
                          </>
                        ))}
                        <div className="d-flex justify-content-end mb-3">
                          <button
                            className="btn btn-primary-app"
                            onClick={() => confirmer(item._id, index)}
                          >
                            {t("confirmer")}
                          </button>
                        </div>
                      </Accordion.Body>
                    )}
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
        </div>
      </div>
      {showbon && (
        <BonTransfert
          societeid={societeid}
          datainfo={dataBon}
          type="reception"
          t={t}
          setshowbon={setshowbon}
          ShowBon={showbon}

        />
      )}
    </div>
  );
}
