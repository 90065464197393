import React, { useEffect, useState } from "react";
import ApportRetraitCaisse from "../../Components/Caisses/mouvementCaisse/ApportRetraitCaisse";
import MouvementCaisse from "./Modules/MouvementCaisse";
import Navbar from "../../_Layouts/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  BestClientApi,
  BestOrganisationApi,
  BestVendeurApi,
  ListClientstat,
  ListOrganisationstat,
  ListVendeurstat,
} from "../../_App/Redux/Slices/statistique/statistiqueSlice";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Spinner } from "react-bootstrap";
import { compareDates, formatdate } from "../../_App/Helpers/helpers";
import { useTranslation } from "react-i18next";
ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const DashboardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const societe_id = useAppSelector(societeId);
  const OrganisationList = useAppSelector(SelectedOrganisations);
  // Function to generate random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  //client
  const [date_debut_client, setdate_debut_client] = useState("");
  const [date_fin_client, setdate_fin_client] = useState("");
  const [Organisation_client, setOrganisation_client] = useState("tout");
  const [loader_client, setloader_client] = useState(false);
  const listclientstat = useAppSelector(ListClientstat);
  const clientNames = listclientstat.map((client) =>
    client?.client?.defaultClient
      ? client?.client?.nom
      : (client?.client?.nom || "inconnu") +
        " " +
        (client?.client?.prenom || "")
  );
  const totalNets = listclientstat.map((client) => client?.totalNet);

  const backgroundColors = clientNames.map(() => getRandomColor());

  const chartData = {
    labels: clientNames,
    datasets: [
      {
        label: "Total Net",
        data: totalNets,
        backgroundColor: backgroundColors,
      },
      
    ],
  };

  useEffect(()=>{
    compareDates(date_fin_client,date_debut_client)
  }, [date_debut_client,date_fin_client])
 
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("Analyse des 10 Principaux Clients par Montant Net à Payer"),
      },
    },
  };
  useEffect(() => {
    if (listclientstat) setloader_client(false);
  }, [listclientstat]);

  //vendeur
  const [date_debut_vendeur, setdate_debut_vendeur] = useState("");
  const [date_fin_vendeur, setdate_fin_vendeur] = useState("");
  const [Organisation_vendeur, setOrganisation_vendeur] = useState("tout");
  const [loader_vendeur, setloader_vendeur] = useState(false);
  const listVendeurstat = useAppSelector(ListVendeurstat);

  const VendeursNames = listVendeurstat.map(
    (v) => `${v.vendeur?.prenom || "inconnu"} ${v.vendeur?.nom || ""}`
  );
  const totalNetsVendeur = listVendeurstat.map((v) => v.totalNet);
  const backgroundVendeurColors = VendeursNames.map(() => getRandomColor());
  useEffect(()=>{
    compareDates(date_fin_vendeur,date_debut_vendeur)
  }, [date_debut_vendeur,date_fin_vendeur])

  const chartDataVendeur = {
    labels: VendeursNames,
    datasets: [
      {
        label: t("Total Net"),
        data: totalNetsVendeur,
        backgroundColor: backgroundVendeurColors,
      },
    ],
  };

  const optionsVendeur: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("Analyse des 10 Principaux Vendeurs par Montant Net à Payer"),
      },
    },
  };

  useEffect(() => {
    if (listVendeurstat) setloader_vendeur(false);
  }, [listVendeurstat]);

  //organisation
  const [date_debut_Organisation, setdate_debut_Organisation] = useState("");
  const [date_fin_Organisation, setdate_fin_Organisation] = useState("");
  const [loader_Organisation, setloader_Organisation] = useState(false);
  const listOrganisationstat = useAppSelector(ListOrganisationstat);
  const clientOrganisations = listOrganisationstat.map(
    (org) => `${org.Organisation?.nom}`
  );
  const totalNetsOrganisation = listOrganisationstat.map((org) => org.totalNet);

  const backgroundColorsOrganisation = clientOrganisations.map(() =>
    getRandomColor()
  );
  useEffect(()=>{
    compareDates(date_fin_Organisation,date_debut_Organisation)
  }, [date_debut_Organisation,date_fin_Organisation])

  const chartDataOrganisation = {
    labels: clientOrganisations,
    datasets: [
      {
        label: t("Total Net"),
        data: totalNetsOrganisation,
        backgroundColor: backgroundColorsOrganisation,
      },
    ],
  };
  const optionsOrganisation: ChartOptions<"pie"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("Total Net par Organisation"),
      },
    },
  };

  useEffect(() => {
    if (listOrganisationstat) setloader_Organisation(false);
  }, [listOrganisationstat]);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    
    setdate_debut_client(formatdate(lastMonth));
    setdate_fin_client(formatdate(today));

    setdate_debut_vendeur(formatdate(lastMonth));
    setdate_fin_vendeur(formatdate(today));

    setdate_debut_Organisation(formatdate(lastMonth));
    setdate_fin_Organisation(formatdate(today));
    const data = {
      date_debut: formatdate(lastMonth),
      date_fin: formatdate(today),
      organisation_id: "tout",
      societe_id: societe_id,
    };
    const dataOrganisation = {
      date_debut: formatdate(lastMonth),
      date_fin: formatdate(today),
      societe_id: societe_id,
    };
    setloader_client(true);
    setloader_vendeur(true);
    setloader_Organisation(true);
    dispatch(BestClientApi(data));
    dispatch(BestVendeurApi(data));
    dispatch(BestOrganisationApi(dataOrganisation));
  }, []);

  const findClientStat = () => {
   

    const data = {
      date_debut: date_debut_client,
      date_fin: date_fin_client,
      organisation_id: Organisation_client,
      societe_id: societe_id,
    };
    setloader_client(true);

    dispatch(BestClientApi(data));
  };

  const findVendeurStat = () => {
    const data = {
      date_debut: date_debut_vendeur,
      date_fin: date_fin_vendeur,
      organisation_id: Organisation_vendeur,
      societe_id: societe_id,
    };

    setloader_vendeur(true);

    dispatch(BestVendeurApi(data));
  };

  const findOrganisationStat = () => {
    const data = {
      date_debut: date_debut_client,
      date_fin: date_fin_client,
      societe_id: societe_id,
    };
    setloader_Organisation(true);

    dispatch(BestOrganisationApi(data));
  };
  return (
    <div className="admin-data-content layout-top-spacing ">
      <div className="row text-center">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center ">
          <div className="widget widget-card-four ">
            <div className="widget-content">
              <div className="w-header ">
                <div className="row">
                  <div className="col-3">{t("Date Debut")}</div>
                  <div className="col-3">{t("Date Fin")}</div>
                  <div className="col-4">{t("Organisation")}</div>
                  <div className="col-2"></div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <input
                      type="date"
                      className="form-control"
                      value={date_debut_client}
                      onChange={(e) => setdate_debut_client(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <input
                      type="date"
                      className="form-control"
                      value={date_fin_client}
                      onChange={(e) => setdate_fin_client(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <select
                      className="form-control"
                      value={Organisation_client}
                      onChange={(e) => setOrganisation_client(e.target.value)}
                    >
                      <option value="tout">{t("Tout les Organisation")}</option>
                      {OrganisationList?.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item?.nom}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-2">
                    <i
                      onClick={findClientStat}
                      className="fas fa-search fa-lg"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="w-body">
                <div className="w-info">
                  {loader_client ? (
                    <Spinner animation="grow" />
                  ) : (
                    <div>
                      <Bar data={chartData} options={options} />
                      <table className="table">
                        <thead>
                          <th>{t("Client")}</th>
                          <th>{t("Total net a payer")}</th>
                          <th>{t("Nombre des tickets")}</th>
                          <th>{t("Nombre des  piéces")}</th>

                          <th>{t("Moyenne Net panier")}</th>
                        </thead>
                        <tbody>
                          {listclientstat.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item?.client?.defaultClient
                                  ? item?.client?.nom
                                  : (item?.client?.nom || "inconnu") +
                                    " " +
                                    (item?.client?.prenom || "")}
                              </td>
                              <td>{item?.totalNet?.toFixed(3)}</td>
                              <td>{item?.countArticle}</td>
                              <td>{item?.totalOrders}</td>
                              <td>
                                {(item?.totalNet / item?.totalOrders).toFixed(
                                  3
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center ">
          <div className="widget widget-card-four ">
            <div className="widget-content">
              <div className="w-header ">
                <div className="row">
                  <div className="col-3">{t("Date Debut")}</div>
                  <div className="col-3">{t("Date Fin")}</div>
                  <div className="col-4">{t("Organisation")}</div>
                  <div className="col-2"></div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <input
                      type="date"
                      className="form-control"
                      value={date_debut_vendeur}
                      onChange={(e) => setdate_debut_vendeur(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <input
                      type="date"
                      className="form-control"
                      value={date_fin_vendeur}
                      onChange={(e) => setdate_fin_vendeur(e.target.value)}
                    />
                  </div>
                  <div className="col-4">
                    <select
                      className="form-control"
                      value={Organisation_vendeur}
                      onChange={(e) => setOrganisation_vendeur(e.target.value)}
                    >
                      <option value="tout">Tout les Organisation</option>
                      {OrganisationList?.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item?.nom}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-2">
                    <i
                      onClick={findVendeurStat}
                      className="fas fa-search fa-lg"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="w-body">
                <div className="w-info">
                  {loader_vendeur ? (
                    <Spinner animation="grow" />
                  ) : (
                    <div>
                      <Bar data={chartDataVendeur} options={optionsVendeur} />
                      <table className="table">
                        <thead>
                          <th>{t("Vendeur")}</th>
                          <th>{t("Total net a payer")}</th>
                          <th>{t("Nombre des tickets")}</th>
                          <th>{t("Nombre des  piéces")}</th>
                          <th>{t("Moyenne Net panie")}</th>
                        </thead>
                        <tbody>
                          {listVendeurstat.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item?.vendeur === null
                                  ? "inconnu"
                                  : item?.vendeur?.nom +
                                    " " +
                                    item?.vendeur?.prenom}
                              </td>
                              <td>{item?.totalNet?.toFixed(3)}</td>
                              <td>{item?.totalOrders}</td>
                              <td>{item?.countArticle}</td>
                              <td>
                                {(item?.totalNet / item?.totalOrders).toFixed(
                                  3
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12   layout-spacing text-center ">
          <div className="widget widget-card-four ">
            <div
              className="widget-content"
              style={{ height: "10px !important" }}
            >
              <div className="w-header ">
                <div className="row">
                  <div className="col-4">{t("Date Debut")}</div>
                  <div className="col-4">{"Date Fin"}</div>
                  <div className="col-2"></div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <input
                      type="date"
                      className="form-control"
                      value={date_debut_Organisation}
                      onChange={(e) =>
                        setdate_debut_Organisation(e.target.value)
                      }
                    />
                  </div>
                  <div className="col-4">
                    <input
                      type="date"
                      className="form-control"
                      value={date_fin_Organisation}
                      onChange={(e) => setdate_fin_Organisation(e.target.value)}
                    />
                  </div>

                  <div className="col-2">
                    <i
                      onClick={findOrganisationStat}
                      className="fas fa-search fa-lg"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="w-body">
                <div className="w-info">
                  {loader_Organisation ? (
                    <Spinner animation="grow" />
                  ) : (
                    <div>
                      <Pie
                        data={chartDataOrganisation}
                        options={optionsOrganisation}
                      />
                      <table className="table">
                        <thead>
                          <th>{t("Organisation")}</th>
                          <th>{t("Total net a paye")}r</th>
                          <th>{t("Nombre des tickets")}</th>
                          <th>{t("Nombre des  piéces")}</th>

                          <th>{t('Moyenne Net panier')}</th>

                        </thead>
                        <tbody>
                          {listOrganisationstat.map((item, index) => (
                            <tr key={index}>
                              <td>{item.Organisation?.nom}</td>
                              <td>{item?.totalNet?.toFixed(3)}</td>
                              <td>{item?.totalOrders}</td>
                              <td>{item?.countArticle}</td>

                              <td>{(item?.totalNet/item?.totalOrders).toFixed(3)}</td>

                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12   layout-spacing text-center ">
          <div className="widget widget-card-four ">
            <div className="widget-content">
              <div className="w-body">
                <div className="w-info">
                  <ul className="list-group list-group-media">
                    <li
                      className="list-group-item list-group-item-action"
                      onClick={() => navigate("/Mouvementpaiement")}
                    >
                      <div className="media">
                        <div className="mr-3">
                          <i
                            className="fas fa-file-invoice-dollar fa-lg"
                            style={{ color: "#18517c;", fontSize: "60px" }}
                          ></i>
                        </div>
                        <div className="media-body">
                          <h1 className="tx-inverse">
                            {t("Mouvement paiement")}
                          </h1>
                          <p className="mg-b-0">
                            {" Consulter les mouvements des paiements"}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li
                      className="list-group-item list-group-item-action "
                      onClick={() => navigate("/mouvementvente")}
                    >
                      <div className="media">
                        <div className="mr-3">
                          <i
                            className="fas fa-cash-register fa-lg"
                            style={{ color: "#18517c;", fontSize: "60px" }}
                          ></i>
                        </div>
                        <div className="media-body">
                          <h1 className="tx-inverse">
                            {t("Mouvement Tickets")}
                          </h1>
                          <p className="mg-b-0">
                            {t("Consulter les mouvements des Tickets")}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li
                      className="list-group-item list-group-item-action "
                      onClick={() => navigate("/mouvement")}
                    >
                      <div className="media">
                        <div className="mr-3">
                          <i
                            className="fab fa-product-hunt"
                            style={{ color: "#18517c;", fontSize: "60px" }}
                          ></i>
                        </div>
                        <div className="media-body">
                          <h6 className="tx-inverse">
                            {t("Mouvement produits")}
                          </h6>
                          <p className="mg-b-0">
                            {t(" Consulter les mouvements des produits")}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardPage;
/** 
 *
   <div className="admin-data-content layout-top-spacing">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center ">
          <div
            className="widget widget-card-four cardstyle"
            onClick={() => navigate("/mouvement")}
          >
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h1>Mouvement</h1>
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center "
          onClick={() => navigate("/mouvementvente")}
        >
          <div className="widget widget-card-four cardstyle">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h1>Mouvement Vente</h1>
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing text-center  "
          onClick={() => navigate("/Mouvementpaiement")}
        >
          <div className="widget widget-card-four cardstyle">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h1>Mouvement Payement</h1>
                </div>
              </div>

              <div className="w-progress-stats"></div>
            </div>
          </div>
        </div>
        
      </div>
    </div> */
/* <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Mouvements Caisse</h6>
                </div>
              </div>

              <div className="w-content">
                <div className="w-info">
                  <MouvementCaisse />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-card-four">
            <div className="widget-content">
              <div className="w-header">
                <div className="w-info">
                  <h6 className="value">Expenses</h6>
                </div>
                <div className="task-action">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle"
                      href="#"
                      role="button"
                      id="pendingTask"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-more-horizontal"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </a>

                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="pendingTask"
                      style={{ willChange: "transform" }}
                    >
                      <a className="dropdown-item" href="javascript:void(0);">
                        This Week
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        Last Week
                      </a>
                      <a className="dropdown-item" href="javascript:void(0);">
                        Last Month
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-content">
                <div className="w-info">
                  <p className="value">
                    $ 45,141 <span>this week</span>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-trending-up"
                    >
                      <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline>
                    </svg>
                  </p>
                  <p className="">Expenses</p>
                </div>
              </div>

              <div className="w-progress-stats">
                <div className="progress">
                  <div
                    className="progress-bar bg-gradient-secondary"
                    role="progressbar"
                    style={{ width: "57%" }}
                    aria-valuenow={57}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>

                <div className="">
                  <div className="w-icon">
                    <p>57%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/
