import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  findsocieteinfoApi,
  societeinfo,
} from "../../../_App/Redux/Slices/societe/societeSlice";
import { societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  numeroBonApi,
  updateTransfertApi,
} from "../../../_App/Redux/Slices/transferArticle/transferArticleSlice";

import ReactToPrint from "react-to-print";
import { Modal } from "react-bootstrap";
import { formatDateAndTime } from "../../../_App/Helpers/helpers";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";

export default function BonTransfert({
  societeid,
  datainfo,
  t,
  type,
  setshowbon,
  ShowBon,
}: any) {
  const dispatch = useAppDispatch();
  const infosociete = useAppSelector(societeinfo);
  const BonRef = useRef<HTMLDivElement>(null);
  const [modifier, setModifier] = useState(false);
  const [data, setdata] = useState<any>({});
  console.log('data',data)
  useEffect(() => {
    setdata(datainfo);
  }, [datainfo]);
  useEffect(() => {
    dispatch(findsocieteinfoApi(societeid));
  }, [dispatch, societeid]);
  const SaveModification = async () => {
    const resp = await dispatch(
      updateTransfertApi({
        _id: data._id,
        nbr_colis: data?.nbr_colis,
        matriculecamion: data?.matriculecamion,
      })
    );
    if (resp.payload.success) {
      ToastSuccess(resp.payload.message);

      setModifier(false);
    } else {
      ToastWarning(resp.payload.message);
    }
  };
  const annulerModification = () => {
    setdata({
      ...data,
      nbr_colis: datainfo?.nbr_colis,
      matriculecamion: datainfo?.matriculecamion,
    });
    setModifier(false);
  };
  return (
    <Modal
      show={ShowBon}
      onHide={() => {
        setshowbon(false);
      }}
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <div ref={BonRef} className="bon">
        <h1 style={{ textAlign: "center" }}>
          {type === "transfert"
            ? data.NumBontransfert
              ? `${t(`Bon de ${type}   N°`)} ${data.NumBontransfert} `
              : t("Brouillon")
            : data.NumBonreception
            ? `${t(`Bon de ${type}   N°`)} ${data.NumBonreception} `
            : t("Brouillon")}
        </h1>
        <div className=" row mb-5">
          <div className="col-6">
            <p className=" color-black fs-17"> {infosociete?.nom}</p>
            <p className=" color-black fs-17">
              {t("MF")} : {infosociete?.matricule_fiscale}
            </p>
            <p className=" color-black fs-17">{infosociete?.address}</p>
            <p className="color-black fs-17">{infosociete?.tel}</p>
          </div>
          <div className=" color-black col-6">
            {" "}
            {type === "transfert"
              ? data?.organisation_transfer?.gouvernorat
              : data?.organisation_demande?.gouvernorat}{" "}
            {t("le")} {formatDateAndTime(new Date())}
          </div>
        </div>

        {type === "transfert" ? (
          <>
            {data?.organisation_transfer !== null ? (
              <p className=" color-black">
                {t("Magasin Source:")} {data?.organisation_transfer?.nom}
              </p>
            ) : (
              <p className=" color-black">
                {t("depots Source:")} {data?.depots_id?.libelle}
              </p>
            )}
            {data?.organisation_demande !== null ? (
              <p className=" color-black">
                {t("Magasin Destinataire:")}: {data?.organisation_demande?.nom}
              </p>
            ) : (
              <p className=" color-black">
                {t("Depots Destinataire:")} {data?.depots_reception?.libelle}
              </p>
            )}
            <p className=" color-black">
              {t("Utilisateur")}:
              {(data?.accepter_transfer?.user_id.nom || "") +
                " " +
                (data?.accepter_transfer?.user_id.prenom || "")}
            </p>
            <p className=" color-black">
              {t("Date Transfert")}:{" "}
              {formatDateAndTime(data?.accepter_transfer?.date)}
            </p>
          </>
        ) : (
          <>
            {data?.organisation_transfer !== null ? (
              <p className=" color-black">
                {t("Magasin Source:")} {data?.organisation_transfer?.nom}
              </p>
            ) : (
              <p className=" color-black">
                {t("Depots Source:")} {data?.depots_id?.libelle}
              </p>
            )}

            {data?.organisation_demande !== null ? (
              <p className=" color-black">
                {t("Magasin Destinataire:")}: {data?.organisation_demande?.nom}
              </p>
            ) : (
              <p className=" color-black">
                {t("Depots Destinataire:")} {data?.depots_reception?.libelle}
              </p>
            )}

            <p className=" color-black">
              {t("Address Destinataire:")} {data?.organisation_demande?.address}
            </p>

            <p className=" color-black">
              {t("Utilisateur ")} :
              {(data?.approver_reception?.user_id?.nom || "") +
                " " +
                (data?.approver_reception?.user_id?.prenom || "")}
            </p>
            <p className=" color-black">
              {t("Date Reception")}:{" "}
              {formatDateAndTime(data?.approver_reception?.date)}
            </p>
          </>
        )}

        <p className=" color-black">
          {t("Agent de livraison")} :
          {(data?.agentLivraison?.nom || "") +
            " " +
            (data?.agentLivraison?.prenom || "")}
        </p>
        {type === "transfert" ? (
          <div className="row mt-3 mb-3 ">
            <strong className="col12">
              {t("Matricule camion")} :{" "}
              {modifier ? (
                <input
                  type="text"
                  value={data?.matriculecamion || ""}
                  onChange={(e) =>
                    setdata({ ...data, matriculecamion: e.target.value })
                  }
                />
              ) : (
                data?.matriculecamion
              )}
            </strong>
            <strong className="col12">
              {t("Nombre des colis ")} :{" "}
              {modifier ? (
                <input
                  type="number"
                  value={data?.nbr_colis || ""}
                  onChange={(e) =>
                    setdata({ ...data, nbr_colis: e.target.value })
                  }
                />
              ) : (
                data?.nbr_colis
              )}
            </strong>
          </div>
        ) : (
          <div className="row mt-3 mb-3 ">
            <strong className="col12">
              {t("Matricule camion")} : {data?.matriculecamion}
            </strong>
            <strong className="col12">
              {t("Nombre des colis ")} : {data?.nbr_colis}
            </strong>
          </div>
        )}
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Article")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Code Article")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Famille")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Quantité Envoyée")}
              </th>
              {type !== "transfert" && (
                <th
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                    backgroundColor: "#e0e0e0",
                  }}
                >
                  {t("Quantité Reception")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data?.lignes?.map((item: any) => (
              <tr>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.titre?.fr}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.code_article}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.souscategorie?.libelle?.fr}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.Quantiteenvoyee || 0}
                </td>
                {type !== "transfert" && (
                  <td
                    style={{
                      border: "1px solid #000",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  >
                    {item.QuantitReception || 0}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        <div className="row mt-3 mb-3 ">
          <strong className="col-2">Note : </strong>
          <p>{data?.note}</p>
        </div>

        {type === "transfert" ? (
          <div className="row">
            <div className="col-6">
              <strong>{t("Signature de l'emetteur")}</strong>
            </div>
            <div className="col-6">
              <strong>{t("Signature  du demandeur")}</strong>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-6">
              <strong>{t("Signature de l'emetteur")}</strong>
            </div>
            <div className="col-6">
              <strong>{t("Signature  du récepteur")}</strong>
            </div>
          </div>
        )}
        <div className="row ">
          <div className="col-6"></div>
          <div className="col-6"></div>
        </div>
      </div>

      {!modifier ? (
        <div className="row">
          {!data?.approver_reception?.etat && (
            <div className="col-6">
              <button
                className="  btn-primary-app pointer"
                style={{ margin: "30px" }}
                onClick={() => setModifier(true)}
              >
                {t("Modifier")}
              </button>
            </div>
          )}

          <div className="col-6">
            <ReactToPrint
              trigger={() => (
                <button
                  className=" col-6 btn-primary-app pointer"
                  style={{ margin: "30px" }}
                >
                  {t("Print")}
                </button>
              )}
              content={() => BonRef.current}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-6">
            <button
              className="  btn-primary-app pointer"
              style={{ margin: "30px" }}
              onClick={() => SaveModification()}
            >
              {t("Enregistrer")}
            </button>
          </div>
          <div className="col-6">
            <button
              className="  btn-primary-app pointer"
              style={{ margin: "30px" }}
              onClick={() => annulerModification()}
            >
              {t("Annuler")}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}
