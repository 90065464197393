import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUser,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  Listdemande_transfer_recp,
  annulerTransfertApi,
  approverTransfertApi,
  confirmretourTransfertApi,
  getlesDemandeTransfertApi,
} from "../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import TransfertArticleType from "../../_App/Types/Entites/TransfertArticle/TransfertArticleType";

import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import BonTransfert from "./files/BonTransfert";
import Accordion from "react-bootstrap/Accordion";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";
import { findUsersByCodeApi } from "../../_App/Redux/Slices/users/userSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { Button, Card, Col, Modal, Nav, Row, Tab, Form } from "react-bootstrap";
import styled from "styled-components";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
export default function ApproverTransfert({
  ShowModal,
  setShowModal,
  etatini,
  statusini
}: any) {
  const CustomCard = styled(Card)`
    margin: 0 auto;
    height: 100%;

    img {
      width: 90px;
      height: 90px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .card-title {
      margin-bottom: 1rem;
    }

    .card-text {
      margin-bottom: 1rem;
    }

    .d-flex {
      flex-wrap: nowrap;
    }

    @media (min-width: 576px) {
      max-width: 400px;
    }

    @media (min-width: 768px) {
      max-width: 600px;
    }

    @media (min-width: 992px) {
      max-width: 800px;
    }
  `;
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleBody = (index: any) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const { t } = useTranslation();
  const selectedOrganisationId = useAppSelector(SelectedOrganisationId);
  const societeid = useAppSelector(societeId);
  const [carduser, setcarduser] = useState(false);
  const [userlivraison, setuserlivraison] = useState<UserDataType>();
  const userinfo = useAppSelector(selectUser)
  const listdemande_transfer_recp = useAppSelector(Listdemande_transfer_recp);
  const list_organisation = useAppSelector(SelectedOrganisations);
  const [etat, setetat] = useState(etatini ? etatini : "encours");
  const [status, setstatus] = useState(statusini ? statusini : "org");

  const [list, setlist] = useState<TransfertArticleType[]>([]);
  const user = useAppSelector(selectUserId);
  const [showbon, setshowbon] = useState(false);
  const [dataBon, setDatataBon] = useState();
  const dispatch = useAppDispatch();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
      if((userinfo && userinfo.role_id &&  userinfo.role_id.libelle==='admin societe')||statusini==='societe'){
        dispatch(getlesDemandeTransfertApi('null'));

      }else{   if (selectedOrganisationId)
        dispatch(getlesDemandeTransfertApi(selectedOrganisationId));
      }
  }, [userinfo,selectedOrganisationId]);
  useEffect(() => {
    if (etat === "apprové") {
      var newlist = listdemande_transfer_recp?.filter(
        (item) => item.accepter_transfer.etat
      );
      setlist(newlist);
    } else if (etat === "nonapprové") {
      var newlist = listdemande_transfer_recp?.filter(
        (item) => !item.accepter_transfer.etat && item.accepter_transfer.user_id
      );
      setlist(newlist);
    } else if (etat === "encours") {
      var newlist = listdemande_transfer_recp?.filter(
        (item) =>
          !item.accepter_transfer.etat &&
          item.accepter_transfer.user_id === undefined
      );
      const l = newlist.map((item) => {
        const copie = { ...item };

        copie.lignes = copie.lignes.map((i) => {
          const c = { ...i };
          c.Quantiteenvoyee = c.Quantite;
          return c;
        });

        return copie;
      });

      setlist(l);
    } else if (etat === "retour") {
      var newlist = listdemande_transfer_recp?.filter(
        (item) =>
          !item.approver_reception.etat && item.approver_reception.user_id
      );
      setlist(newlist);
    }
  }, [listdemande_transfer_recp, etat]);
  const approver = async (idTransfert: string, index: number) => {
    const data = {
      _id: idTransfert,
      user: user,
      societe_id: societeid,
      lignes: list[index].lignes,
      agentLivraison: list[index].agentLivraison,
    };
    const resp = await dispatch(approverTransfertApi(data));
    if (resp.payload._id) {
      ToastSuccess("la demande est apprové");
      setDatataBon(resp.payload);
      setshowbon(true);

      if(userinfo?.role_id?.libelle){
        dispatch(getlesDemandeTransfertApi('null'));
  
      }else{
        if (selectedOrganisationId)
          dispatch(getlesDemandeTransfertApi(selectedOrganisationId));
      }
     
    }
  };
  const annuler = async (idTransfert: string) => {
    const data = { _id: idTransfert, user: user };
    const resp = await dispatch(annulerTransfertApi(data));
    if (resp.payload._id) {
      ToastSuccess("la demande  annulé");
    }
  };
  const confirmretour = async (idTransfert: string, index: number) => {
    const data = { _id: idTransfert, user: user };
    const resp = await dispatch(confirmretourTransfertApi(data));
    if (resp.payload._id) {
      ToastSuccess("le retour  confirmé");
    }
  };
  const changeinput = async (index: number, i: number, value: any) => {
    const copielist = [...list];
    copielist[index].lignes[i].Quantiteenvoyee = parseFloat(value) || 0;
    setlist(copielist);
  };
  const handleChange = async (index: number, e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        const data = { societe: societeid, code: value };
        const resultArticle = await dispatch(findUsersByCodeApi(data));
        if (resultArticle.payload.success) {
          const user = resultArticle.payload.data;
          setuserlivraison(user);
          addagentlivraison(index, user);
        } else {
          ToastWarning(resultArticle.payload.message);
        }
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const addagentlivraison = async (index: number, value: string) => {
    const copie = list.map((item, i) =>
      i === index ? { ...item, agentLivraison: value } : item
    );
    setlist(copie);
  };
  const closeaddagentlivraison = async (index: number) => {
    const updatedList = list.map((item, i) =>
      i === index ? { ...item, agentLivraison: undefined } : item
    );
    setlist(updatedList);
  };
  return (
    <div>
      {/*<Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-2"
        dialogClassName="modal-40w"
        backdropClassName="modal-backdrop-2"
      >*/}

      <div className="row layout-spacing">
        <div className="col-lg-12    bg-white">
          <div className="inv--product-table-section ">
            <div className="row mt-5">
              <div className="col-6">
                <h3 className="c-primary"> {t("Transfert des demandes")} </h3>
              </div>
              <div className="col-1">
                <span>Etat :</span>
              </div>
              <div className=" col-4">
                <select
                  className="form-control"
                  onChange={(e) => setetat(e.target.value)}
                  value={etat}
                >
                  <option value="encours">{t("encours")}</option>
                  <option value="apprové">{t("approuvé")}</option>
                  <option value="retour">{t("retour")}</option>
                  <option value="nonapprové">{t("non approuvé")}</option>
                </select>
              </div>
            </div>
            {list && list.length > 0 && (
              <div className="row">
                <div className="col-2">{t("Organisation ")} </div>
                <div className="col-2">{t("Depots ")} </div>
                <div className="col-2">{t("Date ")} </div>
              </div>
            )}

            <Accordion>
              {list.map((item: any, index: number) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header className="row ">
                    <div
                      className="col-2 "
                      style={{
                        background: item?.approver_reception?.etat
                          ? "#89cd9a"
                          : "#ff3e3e   ",
                      }}
                    >
                      {item.organisation_demande.nom}{" "}
                    </div>
                    <div
                      className="col-2"
                      style={{
                        background: item?.approver_reception?.etat
                          ? "#89cd9a"
                          : "#ff3e3e ",
                      }}
                    >
                      {item.depots_reception?.libelle}
                    </div>
                    <div
                      className="col-2"
                      style={{
                        background: item?.approver_reception?.etat
                          ? "#89cd9a"
                          : "#ff3e3e ",
                      }}
                    >
                      {item.createdAt.split("T")[0]}{" "}
                    </div>
                    {etat === "encours" && (
                      <>
                        <div className="col-2">
                          {item.agentLivraison !== undefined ? (
                            <>
                              {item.agentLivraison === "" ? (
                                <textarea
                                  onChange={(e) => handleChange(index, e)}
                                />
                              ) : (
                                <span
                                //onClick={()=>{setcarduser(true)}}
                                >
                                  {item.agentLivraison.nom +
                                    " " +
                                    item.agentLivraison.prenom}
                                </span>
                              )}
                              <span
                                className="btn-primary-app pointer"
                                onClick={() => closeaddagentlivraison(index)}
                              >
                                <i className="far fa-window-close"></i>
                              </span>
                            </>
                          ) : (
                            <span
                              className="btn-primary-app pointer"
                              onClick={() => addagentlivraison(index, "")}
                            >
                              <i className="fas fa-plus"></i>
                              {t("Ajouter agent de livraison ")}
                            </span>
                          )}
                        </div>
                        <div className="col-2">
                          <span
                            className="btn-primary-app pointer"
                            onClick={() => approver(item._id, index)}
                          >
                            <i className="fas fa-check-double"></i>
                            {t("confirmer")}
                          </span>
                        </div>
                        <div className="col-1">
                          <span
                            className="btn-primary-app pointer"
                            onClick={() => annuler(item._id)}
                          >
                            <i className="fas fa-ban"></i>
                            {t("Annuler")}
                          </span>
                        </div>
                      </>
                    )}
                    {etat === "retour" && (
                      <div
                        className="col-3"
                        style={{
                          background: item?.approver_reception?.etat
                            ? "#89cd9a"
                            : "#ff3e3e ",
                        }}
                      >
                        <span
                          className="btn-primary-app pointer"
                          onClick={() => confirmretour(item._id, index)}
                        >
                          <i className="fas fa-check-double"></i>
                          {t("Confirmer retour")}
                        </span>
                      </div>
                    )}
                    {etat === "apprové" && (
                      <div className="col-3">
                        <span
                          className="btn-primary-app pointer"
                          style={{
                            background: item?.approver_reception?.etat
                              ? "#89cd9a"
                              : "#ff3e3e ",
                          }}
                          onClick={() => {
                            setDatataBon(item);
                            setshowbon(true);
                          }}
                        >
                          <i className="fas fa-file-powerpoint"></i>{" "}
                          {t("Bon de transfert")}
                        </span>
                      </div>
                    )}
                    <div>
                      <button
                        onClick={() => toggleBody(index)}
                        className="btn btn-link c-primary"
                      >
                        {expandedIndex === index ? "▲" : "▼"}
                      </button>
                    </div>
                  </Accordion.Header>
                  {expandedIndex === index && (
                    <Accordion.Body>
                      <div className="row mb-2 bg-primary-app">
                        <div className="col-2"></div>
                        <div className={etat === "encours" ? "col-2" : "col-3"}>
                          {t("Article")}
                        </div>
                        <div className="col-3">{t("famille")}</div>
                        {etat === "encours" && (
                          <>
                            <div className="col-2">{t("Quantite demandé")}</div>
                            <div className="col-2">{t("Quantite envoyé")}</div>
                          </>
                        )}
                        {etat === "apprové" && (
                          <div className="col-3">{t("Quantite envoyé")}</div>
                        )}
                        {(etat === "retour" || etat === "nonapprové") && (
                          <div className="col-3">
                            <div className="col-3">{t("Quantite demandé")}</div>
                          </div>
                        )}
                      </div>
                      {item.lignes.map((l: any, i: any) => (
                        <div className="row" key={i}>
                          <div className="col-2">
                            {l?.article_id?.image &&
                            l?.article_id?.image.length !== 0 ? (
                              <Zoom>
                                <img
                                  alt="image"
                                  src={
                                    process.env.REACT_APP_API_PUBLIC_URL +
                                    "article/" +
                                    l?.article_id?.image
                                  }
                                  width="100"
                                />
                              </Zoom>
                            ) : (
                              <img
                                src="/assets/assets/img/no-image.jpg"
                                className="card-img-top"
                                alt="..."
                                style={{ width: "100px" }}
                              />
                            )}
                          </div>
                          <div
                            className={etat === "encours" ? "col-2" : "col-3"}
                          >
                            {l.article_id?.titre?.fr || l.article_id?.titre?.ar}
                          </div>
                          <div className="col-3">
                            {l.article_id?.souscategorie?.libelle?.fr}
                          </div>
                          {etat === "encours" && (
                            <>
                              <div className="col-2">{l.Quantite}</div>
                              <div className="col-2">
                                <input
                                  type="text"
                                  onKeyPress={handleKeyPress_number}
                                  value={l.Quantiteenvoyee}
                                  onChange={(e) =>
                                    changeinput(index, i, e.target.value)
                                  }
                                />
                              </div>
                            </>
                          )}
                          {etat === "apprové" && (
                            <div className="col-3">
                              {l.Quantiteenvoyee || l.Quantite}
                            </div>
                          )}
                          {(etat === "retour" || etat === "nonapprové") && (
                            <div className="col-3">{l.Quantite}</div>
                          )}
                        </div>
                      ))}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      {showbon && (
        <BonTransfert
          societeid={societeid}
          datainfo={dataBon}
          type="transfert"
          setshowbon={setshowbon}
          ShowBon={showbon}
          t={t}
        />
      )}
      {carduser && (
        <CustomCard className="user-card">
          <div className="card-body" style={{ height: "100%" }}>
            <img
              src={userlivraison?.image}
              className="rounded-circle"
              alt="User Avatar"
            />

            <h5 className="card-title">
              {userlivraison?.nom}
              <div>{userlivraison?.prenom}</div>
            </h5>

            <p className="card-text">
              <i
                className="fas fa-user-tag"
                style={{
                  color: "#0858d9",
                  marginRight: "5px",
                }}
              ></i>
              {userlivraison?.role_id?.libelle}
            </p>
            <p className="card-text">
              {" "}
              <i
                className="fas fa-phone"
                style={{
                  color: "#0bcb4e",
                  marginRight: "5px",
                }}
              ></i>{" "}
              {userlivraison?.mobile}
            </p>
            <p className="card-text">
              <i
                className="far fa-envelope"
                style={{
                  color: "#8b1593",
                  marginRight: "5px",
                }}
              ></i>
              {userlivraison?.email}{" "}
            </p>
          </div>
        </CustomCard>
      )}
      {/** </Modal>*/}
    </div>
  );
}
