import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import AddVentePage from "./Pages/Ventes/AddVentes/AddVentePage";
import Layout from "./_Layouts/Layout/Layout";
import SessionsJournaux from "./Pages/SessionJournauxCaisse/SessionJournaux";

import OrganisationPage from "./Pages/Organisations/OrganisationPage";

import LoginPage from "./Pages/Auth/LoginPage";
import ResetPasswordPage from "./Pages/Auth/ResetPasswordPage";
import AutorisationPage from "./Pages/UserAutorisation/AutorisationPage";
import UserRolePage from "./Pages/UserRole/RolePage";
import UserItemPage from "./Pages/Users/UserItemPage";
import LoginLayout from "./_Layouts/Layout/LoginLayout";

import ProductCaracteristiquesPage from "./Pages/Products/ProductCaracteristiques/ProductCaracteristiquesPage";
import ProductCouleursPage from "./Pages/Products/ProductCouleurs/ProductCouleursPage";
import ProductTaillePage from "./Pages/Products/ProductTailles/ProductTaillePage";

import CartePage from "./Pages/Cartes/CartesPage";
import CategoriesPage from "./Pages/Categories/CategoriesPage";

import ProductMarquesPage from "./Pages/Products/ProductMarques/ProductMarquesPage";

import ProductsPage from "./Pages/Products/Products/ProductsPage";

import AchatPage from "./Pages/Achat/AchatPage";
import CaissePage from "./Pages/Caisses/CaissePage";
import OrdersPage from "./Pages/Caisses/OrdersPage";
import TablesPage from "./Pages/Caisses/TablesPage";
import ClientPage from "./Pages/Client/ClientPage";
import DepotsPage from "./Pages/Depot/DepotsPage";
import InventairePage from "./Pages/InventairePage/InventairePage";
import Facture from "./Pages/JournalCaisse/Facture";
import HomeCaissePage from "./Pages/JournalCaisse/HomeCaissePage";
import JournauxCaisse from "./Pages/JournalCaisse/JournauxCaisse";
import MouvementsJournal from "./Pages/JournalCaisse/MouvementsJournalPages";
import ArticlePage from "./Pages/Products/Article/ArticlePage";
import FournisseurPage from "./Pages/Products/Fournisseur/FournisseurPage";
import ProductDetailsPage from "./Pages/Products/ProductDetails/ProductDetailsPage";
import ProductTarifPage from "./Pages/Products/ProductTarifs/ProductTarifPage";
import SousCategoriesPage from "./Pages/SousCategories/SousCategoriesPage";
import StockPage from "./Pages/Stock/StockPage";
import TablePage from "./Pages/Table/TablePage";
import {
  SelectedOrganisationId,
  selectIsLoggedIn,
  selectToken,
  selectUserId,
} from "./_App/Redux/Slices/Auth/authSlice";
import {
  closeSessionUserApi,
  findUserByIdApi,
  openSessionUserApi,
} from "./_App/Redux/Slices/users/userSlice";
import { useAppDispatch, useAppSelector } from "./_App/Redux/hooks";
import LayoutCaisse from "./_Layouts/Layout/LayoutCaisse";
import I18nInitializer from "./i18n";

import PinTimeOut from "./Components/Auth/PinTimeOut";
import UnauthorizedPage from "./Pages/Auth/Unauthorized";
import JournalCaisseStat from "./Pages/Statistiques/JournalCaisseStat";

import ReceptionPage from "./Pages/Reception/ReceptionPage";
import Matrice from "./Pages/Products/Matrice/Matrice";
import CommissionPage from "./Pages/Commission/CommissionPage";
import { selectUser } from "./_App/Redux/Slices/Auth/authSlice";
import { User } from "./_App/Types/Entites/User";
import { ToastSuccess } from "./Shared/Toasts/ToastSuccess";
import { ToastWarning } from "./Shared/Toasts/ToastWarning";
import CaisseParametrageForm from "./Components/AppParametrages/Parametrages/CaisseParametrageForm";
import DemandeReception from "./Pages/DemandeReception/DemandeReception";

import BarcodeOnlyCaissePage from "./Pages/Caisses/BarcodeOnlyCaissePage";
import { ListReservations } from "./_App/Redux/Slices/Reservation/ReservationSlice";
import Reservation from "./Pages/Reservation/ReservationPage";
import { findParametragesCaisseByOrganisationApi } from "./_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { findDepotsDefautByOrganisationApi } from "./_App/Redux/Slices/depot/depotSlice";
import PageListDemande from "./Pages/Transfert/PageListDemande";
import PageDemandeTransfert from "./Pages/Transfert/PageDemandeTransfert";
import GratuitePage from "./Pages/Caisses/Remise/GratuitePage";
import FidelitePage from "./Pages/Client/FidelitePage";
import RemisePage from "./Pages/Caisses/Remise/RemisePage";
import Distribution from "./Components/TransfertArticles/Distribution/Distribution";
import DistributionParMagasin from "./Components/TransfertArticles/Distribution/DistributionParMagasin";
import Mouvement from "./Components/Mouvement/Mouvement";
import Mouvementcaisse from "./Components/Mouvement/Mouvementcaisse";
import ChequesPage from "./Pages/Caisses/ChequesPage";
import ReptureStock from "./Pages/Caisses/ReptureStockPage";
import Mouvementpaiement from "./Components/Mouvement/Mouvementpaiement";
import ServerError from "./Pages/ServerError";
import LoadingPage from "./Pages/LoadingPage";
import Loader from "./_Layouts/Loader/Loader";
import PaidOrder from "./_Layouts/RightBar/RightBar/order/PaidOrder";
import Rapport from "./Pages/Rapport/Rapport";
import FactureSansEspeces from "./Pages/JournalCaisse/FactureSansEspeces";
import Verificationdemande from "./Pages/DemandeRemise/Verificationdemande";
import DemandeRemise from "./Pages/DemandeRemise/DemandeRemise";
import ArticleDefectueuxPage from "./Pages/ArticleDefectueux/ArticleDefectueuxPage";
import RetourEchangeConfirmationPage from "./Pages/RetourEchangeConfirmationPage";
import Pagevide from "./Pages/DemandeRemise/pagevide";
import NonVendu from "./Pages/NonVendu/NonVendu";
import AnnulerOrder from "./Pages/Caisses/AnnulerOrder";
import Swal from "sweetalert2";
import DemandeRemiseTransction from "./Pages/DemandeRemise/DemandeRemiseTransction";
import VentesPage from "./Pages/Caisses/VentesPage";
import { error } from "console";
import DemandeReservation from "./Pages/Caisses/DemandeReservation";
import MouvementVente from "./Components/Mouvement/MouvementVente";
import ValorisationStockPage from "./Pages/Stock/ValorisationStockPage";
import LayoutProductsCaisse from "./_Layouts/Layout/LayoutProductsCaisse";
import DistributionSociete from "./Components/TransfertArticles/Distribution/DistributionSociete";
import IncidentPage from "./Pages/Incident/IncidentPage";
import DistributionParDepot from "./Components/TransfertArticles/Distribution/DistributionParDepot";
import SouchesPage from "./Pages/Souches/SouchesPage";

function App() {
  /**
   * axios config
   */
  const TOKEN = useAppSelector(selectToken);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const userId = useAppSelector(selectUserId);
  // console.log('-------------> ', process.env.REACT_APP_API_URL , ' <-------------')
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  // axios.defaults.headers.Authorization = `${localStorage.getItem(process.env.REACT_APP_TOKEN_NAME as string)}`
  axios.defaults.headers.Authorization = `${TOKEN}`;
  const dispatch = useAppDispatch();
  const [showPinModal, setShowPinModal] = useState(false);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [isOnline, setIsOnline] = useState<boolean | null>(null);

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    axios.interceptors.request.use((config) => {
      const EXCLUDE_URLS = [
        "article/findArticleByBarCode/",
        "client/findClientByBarcode/",
        "user/findUserByCodeApi/",
        "article/findArticleStockByBarCodeApi",
        "souscategorie/findSousCategorieBySocieteApi",
        "remises/remiseCheck",
        "remises/fideliteCheck",
        "remises/gratuiteCheck",
        "user/findUsersApi",
        "depots/findDepotsByOrganisationsApi",
        "inventaire/CalculStockInventaireArticleApi/",
        "inventaire/CalculStockInventaireApi/",
        "client/findClientHistorique/",
        "orders/findOrdersByOrganisation",
        "client/findDefaultClientsBySociete",
        "article/findArticlebyParentCode",
        "souscategorie/findSousCategorieLikeLibelleApi",
        "articledefectueux/findArticleDefectueuxBarCode",
        "user/findUserByOrganisationAp",
        "article/findArticleRefParentApi",
        "user/findvendeurs",
        "ticket/findTicketsByOrderApi",
        "parametragesmenu/findParametragesMenusApi",
        "article/findArticleStockByRefApi",
      ];

      const shouldSetLoading = !EXCLUDE_URLS.some((urlPart) =>
        config?.url?.includes(urlPart)
      );

      if (shouldSetLoading) {
        setloading(true); // Show loader

        // console.log("Loading initiated for URL:", config?.url);
      }
      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        setloading(false); // Hide loader
        return response;
      },
      (error) => {
        setloading(false); // Hide loader
        /*if (error && error?.response?.status === 401) {
          document.body.innerHTML = `
          <div style="display: flex; justify-content: center; align-items: center; height: 100vh; background-color: #FFFFFF; color: #333; font-family: Arial, sans-serif; text-align: center;">
              <div>
                  <h1>401 - Unauthorized</h1>
                  <p>You are not authorized to view this page.</p>
              </div>
          </div>
      `;
        }*/
        /*  if (error.response && error.response.status === 500) {
          window.location.href = "/serverError";
        }
        if (error.code === "ERR_NETWORK") {
          window.location.href = "/serverError";
        }*/
        return Promise.reject(error);
      }
    );
  }, [dispatch]);
  useEffect(() => {
    const checkUserOnlineStatus = async () => {
      try {
        if (userId) {
          const action = await dispatch(findUserByIdApi(userId));

          if (findUserByIdApi.fulfilled.match(action)) {
            const user = action.payload;
            setCurrentUser(user);

            if (user && user.online !== undefined) {
              setIsOnline(user.online);
            }
          } else {
            console.error(
              "Erreur lors de la récupération des informations de l'utilisateur :",
              action.payload
            );
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification du statut en ligne de l'utilisateur :",
          error
        );
      }
    };

    checkUserOnlineStatus();
  }, [userId]);

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findParametragesCaisseByOrganisationApi(currentOrganisation));
      dispatch(findDepotsDefautByOrganisationApi(currentOrganisation));
    }
  }, [dispatch, currentOrganisation]);

  useEffect(() => {
    if (isOnline === false && isLoggedIn !== false) {
      setShowPinModal(true);
    }
  }, [isOnline, isLoggedIn]);

  useEffect(() => {
    let idleTimer: NodeJS.Timeout;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      if (isOnline) {
        idleTimer = setTimeout(handleOnIdle, 300000);
      }
    };

    const handleOnIdle = () => {
      if (userId) {
        dispatch(closeSessionUserApi(userId)).then((response) => {
          if (response.payload?.success) {
            const user = response.payload.data;
            setIsOnline(user.online);
          }
        });
      }

      resetIdleTimer();
    };

    resetIdleTimer();

    window.addEventListener("mousemove", resetIdleTimer);
    window.addEventListener("keydown", resetIdleTimer);

    return () => {
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keydown", resetIdleTimer);
      clearTimeout(idleTimer);
    };
  }, [dispatch, userId, isOnline]);

  const handlePinModalComplete = (pinValue: string) => {
    if (userId) {
      dispatch(openSessionUserApi({ userId, pin: pinValue })).then(
        (response) => {
          if (response.payload?.success) {
            setShowPinModal(false);
            setIsOnline(true);
          } else {
            ToastWarning("Pin invalide");
          }
        }
      );
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        isOnline === false &&
        isLoggedIn !== false && (
          <Modal show={showPinModal} centered>
            <Modal.Header>
              <div className="header-message">
                Merci de saisir votre code PIN pour reconnecter{" "}
              </div>
            </Modal.Header>
            <Modal.Body>
              <Row className="text-center mt-2">
                <Col className="mb-2">
                  <PinTimeOut
                    onComplete={handlePinModalComplete}
                    userId={userId!}
                  />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        )
      )}

      <BrowserRouter>
        <I18nInitializer />

        <Routes>
          <Route path="/serverError" element={<ServerError />} />
          <Route
            path="/confirmation/:societe/:id"
            element={<RetourEchangeConfirmationPage />}
          />

          {isLoggedIn !== false ? (
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/ventes/:type" element={<AddVentePage />} />
              <Route path="/ventes/:type/:id" element={<AddVentePage />} />
              <Route path="/cheques" element={<ChequesPage />} />
              <Route path="/rupture" element={<ReptureStock />} />
              <Route path="/rapport" element={<Rapport />} />
              <Route
                path="/articledefectueux"
                element={<ArticleDefectueuxPage />}
              />
              <Route
                path="/admin_validation/:orderid"
                element={<Verificationdemande />}
              />
              <Route path="/users" element={<UserItemPage />} />
              <Route path="/roles" element={<UserRolePage />} />
              <Route path="/autorisations" element={<AutorisationPage />} />
              <Route path="/organisations" element={<OrganisationPage />} />
              <Route path="/depots" element={<DepotsPage />} />
              <Route path="/HomeCaisse" element={<HomeCaissePage />} />
              <Route
                path="/sessionsJournauxStat"
                element={<SessionsJournaux />}
              />

              <Route path="/cartes" element={<CartePage />} />
              <Route
                path="/cartes/categories/:idcarte"
                element={<CategoriesPage />}
              />

              <Route
                path="/categories/souscategories/:idcategorie"
                element={<SousCategoriesPage />}
              />
              <Route path="/categories" element={<CategoriesPage />} />

              <Route path="/souscategories" element={<SousCategoriesPage />} />
              <Route
                path="/caracteristique"
                element={<ProductCaracteristiquesPage />}
              />
              <Route path="/tailles" element={<ProductTaillePage />} />
              <Route path="/couleurs" element={<ProductCouleursPage />} />
              <Route path="/marques" element={<ProductMarquesPage />} />
              <Route path="/product" element={<ProductsPage />} />
              <Route path="/productdetails" element={<ProductDetailsPage />} />
              <Route path="/producttarifs" element={<ProductTarifPage />} />
              <Route path="/fournisseurs" element={<FournisseurPage />} />
              <Route path="/achat" element={<AchatPage />} />
              <Route path="/reception" element={<ReceptionPage />} />
              <Route path="/distribution" element={<Distribution />} />
              <Route
                path="/DistributionParMagasin"
                element={<DistributionParMagasin />}
              />
              <Route
                path="/DistributionParDepots"
                element={<DistributionParDepot />}
              />
              <Route
                path="/DistributionParSociete"
                element={<DistributionSociete />}
              />

              <Route path="/matrice" element={<Matrice />} />
              <Route path="/commission" element={<CommissionPage />} />
              <Route path="/demandeR" element={<DemandeReception />} />

              <Route path="/inventaire" element={<InventairePage />} />
              <Route path="/article" element={<ArticlePage />} />
              <Route path="/stock" element={<StockPage />} />
              <Route path="/souche" element={<SouchesPage />} />

              <Route
                path="/Valorisationstock"
                element={<ValorisationStockPage />}
              />

              <Route path="/mouvement" element={<Mouvement />} />
              <Route path="/mouvementArticle" element={<MouvementVente />} />

              <Route path="/nonvendu" element={<NonVendu />} />
              <Route
                path="/mouvementpaiement"
                element={<Mouvementpaiement />}
              />
              <Route path="/incident" element={<IncidentPage />} />
              <Route path="/mouvementvente" element={<Mouvementcaisse />} />

              <Route
                path="/listdemande/:status/:etat"
                element={<PageListDemande />}
              />
              <Route
                path="/listdemande/:status"
                element={<PageListDemande />}
              />

              <Route
                path="/demandeTransfert"
                element={<PageDemandeTransfert />}
              />

              <Route path="/unauthorized" element={<UnauthorizedPage />} />

              <Route
                path="/mouvementcaisse/:id"
                element={<MouvementsJournal />}
              />
              <Route path="/facture/:id" element={<Facture />} />
              <Route
                path="/factureSansEspeces/:id"
                element={<FactureSansEspeces />}
              />

              <Route path="/journauxcaisse" element={<JournauxCaisse />} />
              <Route path="/article" element={<ArticlePage />} />
              <Route path="/client" element={<ClientPage />} />
              <Route path="/fidelite" element={<FidelitePage />} />
              <Route path="/remise" element={<RemisePage />} />
              <Route path="/gratuite" element={<GratuitePage />} />
              <Route path="/commandes" element={<OrdersPage />}></Route>
              <Route path="/ventes" element={<VentesPage />}></Route>

              <Route path="/PaidOrder/:OrderId" element={<PaidOrder />}></Route>

              <Route path="/table" element={<TablePage />} />
              <Route path="/reservation" element={<Reservation />} />
              <Route
                path="/DemandeRemises"
                element={<DemandeRemiseTransction />}
              />
            </Route>
          ) : (
            <>
              <Route path="/clickok" element={<Pagevide />}></Route>
              <Route
                path="/annulerOrder/:orderid"
                element={<AnnulerOrder />}
              ></Route>
              <Route
                path="/avance/:orderid"
                element={<DemandeReservation />}
              ></Route>
              <Route
                path="/validation/:orderid"
                element={<Verificationdemande />}
              />
            </>
          )}

          {isLoggedIn !== false ? (
            <Route path="/" element={<LayoutCaisse />}>
              <Route path="/caisse" element={<CaissePage />}></Route>
              <Route path="/caisse/:orderId" element={<CaissePage />}></Route>

              <Route path="/test" element={<BarcodeOnlyCaissePage />} />
              <Route
                path="/caisse/:tableId/:table"
                element={<CaissePage />}
              ></Route>

              <Route path="/tables" element={<TablesPage />}></Route>
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" />} />
          )}

          <Route path="/login" element={<LoginLayout />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
          <Route
            path="/reset-password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="/Demande" element={<DemandeRemise />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
