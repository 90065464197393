import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import ActionModel from "./ActionModel";
import Filtre from "../Stock/Filtre";
import {
  currentpage,
  findActionApi,
  ListMouvement,
  mouvementArticleApi,
  totalPages,
} from "../../_App/Redux/Slices/mouvement/mouvementSlice";
import TicketDeCaissePaid from "../Caisses/TicketDeCaisse/TicketDeCaissePaid";
import { exportExcel, formatDateAndTime } from "../../_App/Helpers/helpers";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  ListActionsMouvement,
  optionsActionsMouvement,
} from "../../_App/Data/ListActionsMouvement";

export default function Mouvement() {
  const { t } = useTranslation();

  type datafilterType = {
    carte: any;
    categories: any;
    sousCategories: any;
    carateristique: any;
    index: number;
    ref1: any;
    ref2: any;
    prix1: any;
    prix2: any;
  };
  const dispatch = useAppDispatch();

  const listMouvement = useAppSelector(ListMouvement);
  const [keyword, setkeyword] = useState("");
  const [date_debut, setdate_debut] = useState("");
  const [date_fin, setdate_fin] = useState("");
  const [dataOrder, setdataOrder] = useState("DESC");
  const [selectedActions, setSelectedActions] =
    useState<any[]>(ListActionsMouvement);
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [order_id, setorder_id] = useState(null);
  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  const [intervaleRef, setintervaleRef] = useState<any>();
  const [intervalePrix, setintervalePrix] = useState<any>();
  const [ticket, SetTicket] = useState(null);

  interface SelectionsState {
    [key: string]: any[];
  }
  const [datafilter, setdatafilter] = useState<datafilterType>();

  const [selections, setSelections] = useState<SelectionsState>({});
  const [spinner, setspinner] = useState(false);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const [action, setaction] = useState();
  const [modelAction, setmodelAction] = useState(false);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setdate_debut(formatDate(lastMonth));
    setdate_fin(formatDate(today));
    const data = {
      carte: [],
      categories: [],
      sousCategories: [],
      carateristique: [],
      index: index,
      keyword: "",
      dateDebut: formatDate(lastMonth),
      dateFin: formatDate(today),
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      order: dataOrder,
      actions: selectedActions,
    };
    setindex(index);
    setdatafilter(data);
    dispatch(mouvementArticleApi(data));
  }, []);

  useEffect(() => {
    setspinner(false);
  }, [listMouvement]);
  const findMouvement = async () => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: 1,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      order: dataOrder,
      actions: selectedActions,
    };
    setindex(1);
    setdatafilter(data);
    dispatch(mouvementArticleApi(data));
  };

  useEffect(() => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: index,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      order: dataOrder,
      actions: selectedActions,
    };
    setindex(index);
    setdatafilter(data);
    dispatch(mouvementArticleApi(data));
  }, [index]);
  const findAction = async (id: string) => {
    const action = await dispatch(findActionApi(id));
    if (action?.payload && action?.payload?.data?.length !== 0) {
      setaction(action.payload);
      setmodelAction(true);
    }
  };

  const exportToExcel = async () => {
    setspinner(true);

    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      index: 1,
      keyword: encodeURIComponent(keyword),
      dateDebut: date_debut,
      dateFin: date_fin,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
      order: dataOrder,
      excel: true,
      actions: selectedActions,
    };
    const resp = await dispatch(mouvementArticleApi(data));
    if (resp.payload.success) {
      const list = resp.payload.data.data.map((item: any) => ({
        Famille: item.article?.souscategorie?.libelle?.fr,
        Article: item.article?.titre?.fr,
        Action: item.movements?.order_id
          ? "Vente"
          : item.movements?.id_achat
          ? "Reception"
          : item.movements?.id_Transfert
          ? "Transfert"
          : item.movements?.id_Inventaire
          ? "Inventaire"
          : item.movements?.id_Reservation
          ? "Reservation"
          : "",
        Organisation: item.movements?.organisation_id?.nom,
        Depots: item.movements?.position_depot?.libelle,
        Sens: item.movements?.sens,
        Source_Destination:
          item?.movements?.sens === "sortie"
            ? item?.movements.id_Transfert?.organisation_demande?.nom
            : item?.movements.id_Transfert?.organisation_transfer?.nom,
        Type: item.movements?.type,
        Quantite: item.movements?.quantite,
        "Prix TTC": item.movements?.prix_ttc,
        "Prix Total":
          Number(item.movements?.prix_ttc) * Number(item.movements?.quantite) ||
          "",
        Date: formatDateAndTime(item.movements?.createdAt),
      }));
      exportExcel("mouvements.xlsx", "Mouvements", list);
    }
    setspinner(false);
  };
  const handleSelectChange = (selectedOptions: any) => {
    setSelectedActions(selectedOptions.map((option: any) => option.value));
  };

  return (
    <>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
        <h3>{t("Liste des mouvements des articles")}</h3>
        <div className="col-2">
          <label>{t("Date debut")}</label>
          <input
            type="date"
            className="form-control "
            value={date_debut}
            onChange={(e) => setdate_debut(e.target.value)}
          />
        </div>
        <div className="col-2">
          <label>{t("Date fin")}</label>
          <input
            type="date"
            className="form-control "
            value={date_fin}
            onChange={(e) => setdate_fin(e.target.value)}
          />
        </div>
        <div className="col-3">
          <label>{t("Filre action")}</label>
          <Select
            isMulti
            name="actions"
            options={optionsActionsMouvement}
            value={optionsActionsMouvement.filter((option) =>
              selectedActions.includes(option.value)
            )}
            onChange={handleSelectChange}
          />
        </div>
        <div className="col-2">
          <label>{t("Movement Order")}</label>
          <select
            className="form-control"
            value={dataOrder}
            onChange={(e) => setdataOrder(e.target.value)}
          >
            <option value={"DESC"}>{t("DESC")}</option>
            <option value={"ASC"}>{t("ASC")}</option>
          </select>
        </div>
        <div className="col-3">
          <button
            onClick={exportToExcel}
            className="btn btn-primary"
            style={{ float: "right" }}
          >
            <i className="far fa-file-excel fa-lg mb-2"></i>
            {t("Export Excel")}
          </button>
        </div>
        <Filtre
          spinner={spinner}
          handleSearch={findMouvement}
          setkeyword={setkeyword}
          t={t}
          setspinner={setspinner}
          setdatafilter={setdatafilter}
          setindex={setindex}
          searchfiltre={findMouvement}
          selectedCarte={selectedCarte}
          setSelectedCarte={setSelectedCarte}
          selectedCategorie={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          selectedSousCategorie={selectedSousCategorie}
          setSelectedSousCategorie={setSelectedSousCategorie}
          categoriesOptions={categoriesOptions}
          setcategoriesdOptions={setcategoriesdOptions}
          souscategoriesOptions={souscategoriesOptions}
          setsouscategoriesdOptions={setsouscategoriesdOptions}
          selections={selections}
          setintervaleRef={setintervaleRef}
          intervaleRef={intervaleRef}
          setintervalePrix={setintervalePrix}
          intervalePrix={intervalePrix}
          setSelections={setSelections}
        />

        {/*<div className="col-3">
          {listMouvement && listMouvement.length !== 0 && (
            <>
              <strong>
                {" "}
                {t("Article")} : {listMouvement[0]?.id_article?.titre?.fr}
              </strong>
              <br />
              <strong>
                {" "}
                {t(" Code a barre")} :{" "}
                {listMouvement[0]?.id_article?.code_a_barre[0]}
              </strong>
            </>
          )}
        </div>*/}
      </div>
      <div className="row layout-spacing mt-5">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th colSpan={2}>{t("Famille")}</th>
                <th>{t("Article")}</th>
                <th>{t("Action")}</th>
                <th>{t("Organisation")}</th>
                <th>{t("Depots")}</th>
                <th>{t("Sens")}</th>
                <th>{t("Source / Destination")}</th>
                <th> {t("Type")}</th>
                <th>{t("Quantite")}</th>
                <th>{t("P.U TTC")}</th>
                <th>{t("Prix TTC")}</th>
                <th>{t("Date  ")}</th>
              </thead>
              <tbody>
                {listMouvement &&
                  listMouvement.length !== 0 &&
                  listMouvement.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        background:
                          item?.transfert &&
                          !item?.transfert.approver_reception?.etat
                            ? "red"
                            : "",
                      }}
                    >
                      <td style={{ color: "red" }}>
                        {item?.movements?.removed
                          ? t("Vente annulée")
                          : item?.movements?.orderAnnuleNumber}
                      </td>

                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.article?.souscategorie?.libelle?.fr}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.article?.titre?.fr}
                      </td>
                      <td
                        style={{ background: "inherit", fontSize: "16px" }}
                        onClick={() => {
                          if (item?.movements?.order_id) {
                            setorder_id(item?.movements?.order_id);
                            SetTicket(item?.movements?.ticket_id);
                            setShowModalTicket(true);
                          } else {
                            findAction(
                              item?.movements?.id_achat
                                ? item?.movements?.id_achat
                                : item?.movements?.id_Transfert
                                ? item?.movements?.id_Transfert
                                : item?.movements?.id_Inventaire
                                ? item?.movements?.id_Inventaire
                                : item?.movements?.id_Reservation
                                ? item?.movements?.id_Reservation
                                : item?.movements?.id_BonSortie
                            );
                          }
                        }}
                      >
                        <span className="lien-bleu-souligne">
                          {item?.movements?.id_achat && "Reception"}
                          {item?.movements?.id_Transfert && "Transfert"}
                          {item?.movements?.id_Inventaire && "Inventaire"}
                          {item?.movements?.id_Reservation && " Reservation"}
                          {item?.movements?.order_id && item?.movements?.type}
                          {item?.movements?.id_BonSortie && "Bon Sortie"}
                        </span>
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.organisation_id?.nom}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.position_depot?.libelle}
                      </td>

                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.sens}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.sens === "sortie" ? (
                          <p>
                            {" "}
                            {
                              item?.movements.id_Transfert?.organisation_demande
                                ?.nom
                            }
                          </p>
                        ) : (
                          <p>
                            {
                              item?.movements.id_Transfert
                                ?.organisation_transfer?.nom
                            }
                          </p>
                        )}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.type}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.quantite}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {item?.movements?.prix_ttc?.toFixed(3)}
                      </td>
                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {Number(
                          (
                            item?.movements?.prix_ttc *
                            item?.movements?.quantite
                          )?.toFixed(3)
                        ) || ""}
                      </td>

                      <td style={{ background: "inherit", fontSize: "16px" }}>
                        {formatDateAndTime(item?.movements?.createdAt)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
      <ActionModel
        t={t}
        data={action}
        showModal={modelAction}
        setshowModal={setmodelAction}
      />

      <TicketDeCaissePaid
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        ticket={ticket}
      />
    </>
  );
}
