import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  findSouchesByOrganisationApi,
  soucheList,
} from "../../_App/Redux/Slices/souche/soucheSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import { useSSR, useTranslation } from "react-i18next";
import SoucheTable from "../../Components/Souche/SoucheTable";
import SoucheForm from "../../Components/Souche/SoucheForm";
import { Navbar } from "react-bootstrap";

export default function SouchesPage() {
  
  const [ShowModalSouche,setShowModalSouche]=useState(false)
  const [souche,setsouche]=useState()

  const societe_id = useAppSelector(societeId);
  const listSouche = useAppSelector(soucheList);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const listOrganisation = useAppSelector(ListOrganisations);
  const[selectedOrganisation , setSelelectedOrganisation ]=useState<any>()
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (societe_id) dispatch(allOrganisationsApi(societe_id));
  }, [societe_id]);
  useEffect(() => {
    if (currentOrganisation){

        setSelelectedOrganisation(currentOrganisation)
    }
  }, [currentOrganisation]);
  useEffect(()=>{
    if(selectedOrganisation)        dispatch(findSouchesByOrganisationApi({selectedOrganisation,societe_id}));

  },[selectedOrganisation])
  return (
  <>
  <div>
        <Navbar />
        {/*<div className="d-flex justify-content-end mb-3">
        <button
          onClick={() => {
            setShowModalSouche(true);
          }}
          className="btn btn-primary-app"
        >
          {t("Ajouter Souche")}
        </button>{" "}
      </div>*/}
      <SoucheTable
      listOrganisation={listOrganisation}
      t={t}
      listSouche={listSouche}
      selectedOrganisation={selectedOrganisation}
      setSelelectedOrganisation={setSelelectedOrganisation}
      setShowModal={setShowModalSouche}
      setsouche={setsouche}
     />
      <SoucheForm
      ShowModal={ShowModalSouche}
      setShowModal={setShowModalSouche}
      souche={souche}
      t={t}
      listOrganisations={listOrganisation}
      currentOrganisation={currentOrganisation}
      />
      </div>
    
      
  </>
);
}
