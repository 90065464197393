import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import {
  DefaultClient,
  Panier,
  Reduction,
  addClient,
  addFreeItem,
  addToPanier,
  confirmOrder,
  decreaseQuantity,
  findDefaultClientsBySociete,
  increaseQuantity,
  removeFromPanier,
  resetOperationType,
  resetPanier,
  resetReduction,
  setClientRemise,
  setFideliteLIst,
  setInstantRemise,
  setItemAvailability,
  setItemInstantRemise,
  setNetPrice,
  setOperationType,
  setQuantity,
  setReduction,
  setRemises,
} from "../../../../_App/Redux/Slices/Caisse/PanierSlice";
import { selectedTable } from "../../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  ListArticles,
  findArticleByBarCode,
  findArticleRefParentApi,
  findArticleStockDepotApi,
  updateArticleStockApi,
} from "../../../../_App/Redux/Slices/article/articleSlice";
import {
  ListOnlineOrders,
  ListOrders,
  order,
  resetRetrievedOrder,
  resetSelectedOrder,
  saveOrderApi,
} from "../../../../_App/Redux/Slices/Orders/OrderSlice";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  societeId,
} from "../../../../_App/Redux/Slices/Auth/authSlice";
import {
  findClientByBarcode,
  ListClient,
} from "../../../../_App/Redux/Slices/client/clientSlice";
import ClientType, {
  FideliteType,
} from "../../../../_App/Types/Entites/ClientType";
import { updateTableStatusApi } from "../../../../_App/Redux/Slices/table/tableSlice";
import CalculatorModal from "../../../../Components/Caisses/Calculator/Calculator";
import { useTranslation } from "react-i18next";
import { DepotDefautOrganisation } from "../../../../_App/Redux/Slices/depot/depotSlice";
import FicheClient from "../../../../Components/Client/FicheClient";
import StockGlobale from "../../../../Components/Caisses/Articles/StockGlobale";
import { parametrageCaisse } from "../../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import Swal from "sweetalert2";
import {
  allCartRemises,
  checkDiscountsByArticle,
  getGratuitesApplicable,
  getProgramFideliteApplicable,
  getRemiseApplicable,
  getTestRemiseApplicable,
} from "../../../../_App/Redux/Slices/remise/remiseSlice";
import {
  calculateRemise,
  findSoucheCommande,
  handleKeyPressCart,
  maxRemise,
  sumGratuiteQuantity,
} from "../../../../_App/Helpers/helpers";
import { ArticleType } from "../../../../_App/Types/Entites/ArticleType";
import {
  GratuiteType,
  ProgramFideliteType,
} from "../../../../_App/Types/Entites/RemiseType";
import _ from "lodash";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { toast } from "react-toastify";
import { findBonByBarcode } from "../../../../_App/Redux/Slices/reduction/reductionSlice";
import ArticleDefectueuxPopUP from "../../../../Components/ArticleDefectueux/ArticleDefectueuxPopUP";
import { findArticleDefectueuxBarCode } from "../../../../_App/Redux/Slices/articledefectueux/articledefectueuxSlice";
import ArticleListReference from "./ArticleListReference";
import { DepotsType } from "../../../../_App/Types/Entites/DepotsType";

interface OrderProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hidePaymentButton: Boolean | undefined;
}
const WideCart: React.FC<OrderProps> = ({ setVisible, hidePaymentButton }) => {
  const today = new Date();
  const [ShowModalDefectueux, setShowModalDefectueux] = useState(false);
  const [ShowModalListReference, setShowModalListReference] = useState(false);
  const [ListReference, setListReference] = useState<any[]>();
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [loadingsearch, setloadingsearch] = useState(false);
  const parametrage = useAppSelector(parametrageCaisse);

  const TypeInputParametrage = parametrage?.SearchCaisse;

  const [TypeInput, setTypeInput] = useState(TypeInputParametrage);

  const [ArticleDefectueu, setArticleDefectueux] = useState(null);
  const retrievedOrder = useAppSelector(order);
  const [load, setLoad] = useState(true);
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const reduction = useAppSelector(Reduction);
  const user = useAppSelector(selectUser);
  const [refresh, setRefresh] = useState(true);
  const listClient = useAppSelector(ListClient);
  const Orders = useAppSelector(ListOrders);

  useEffect(() => {
    setTypeInput(TypeInputParametrage);
  }, [TypeInputParametrage]);

  useEffect(() => {
    if (!cart.avance && cart.items.length !== 0) {
      const remise = async () => {
        const obj = {
          order: cart.items,
          client_id: selectedClient?._id,
          achat: cart.totalNet,
          organisation_id: currentOrganisation,
        };
        const responseRemises = await dispatch(allCartRemises(obj));
        const responseGratuite = await dispatch(getGratuitesApplicable(obj));
        if (responseRemises && responseRemises.payload) {
          if (responseRemises.payload.length > 0) {
            dispatch(setRemises(responseRemises.payload));
          }
        }
        if (
          responseGratuite &&
          responseGratuite.payload &&
          responseGratuite.payload.length
        ) {
          responseGratuite?.payload?.map((item: GratuiteType) => {
            dispatch(
              addFreeItem({
                article: item.article,
                quantity: item.quantity,
              })
            );
          });
        }

        dispatch(setNetPrice());
      };
      const fidelite = async () => {
        const obj = {
          order: cart.items,
          client_id: selectedClient?._id,
          achat: cart.totalNet,
          organisation_id: currentOrganisation,
        };
        const response = await dispatch(getProgramFideliteApplicable(obj));
        if (response && response.payload) {
          dispatch(setFideliteLIst(response.payload));
        }
      };
      remise();
      fidelite();
    }
  }, [refresh]);

  const btnClass = " big-btn";
  const boxClass = " light-box";
  const bgClass = " light-background";

  const handleKeyPress = () => {
    const currentElement = document.activeElement;
    if (currentElement && currentElement.tagName.toLowerCase() !== "input") {
      const myInputElement = document.getElementById("code_input");

      if (myInputElement) {
        myInputElement.focus();
      }
    }
  };
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastKeyPressTime = useRef<number>(0);

  const handleChange = (event: any) => {
    const { value } = event.target;
    setSearchTerm(value);

    const now = Date.now();
    const timeDiff = now - lastKeyPressTime.current;
    lastKeyPressTime.current = now;

    // Si le temps entre deux entrées est court, c'est probablement un scan
    const isScannerInput = timeDiff < 50;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(
      () => {
        if (value !== "" || isScannerInput) {
          handleInputChange(value);
        }
      },
      isScannerInput ? 100 : 2000
    );
  };
  const handleSetTypeOperation = async (amount: any, refunded: any) => {
    Swal.fire({
      title: "Voulez vous vraiment retourner ces articles ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // dispatch(setOperationType({ type: "Retour", refunded: refunded }));
        dispatch(setNetPrice());
        refunded.map((item: any) => {
          dispatch(
            updateArticleStockApi({
              id: item.article._id,
              depotId: currentDepot._id,
              soldQuantity: -item.quantity,
              type: item?.type,
              organisation: currentOrganisation,

              defaut_id: item?.defaut_id,
            })
          );
        });
      }
    });
  };

  const selectedtable = useAppSelector(selectedTable);
  const dispatch = useAppDispatch();

  const cart = useAppSelector(Panier);

  const orders = useAppSelector(ListOrders);
  const defaultClient = useAppSelector(DefaultClient);
  const societe_id = useAppSelector(societeId);
  const journal = useAppSelector(selectJournalCaisse);

  useEffect(() => {
    if (societe_id && cart.client_id === "") {
      dispatch(findDefaultClientsBySociete(societe_id));
    } else if (societe_id && !cart.client_id) {
      dispatch(findDefaultClientsBySociete(societe_id));
    }
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [societe_id, load]);

  const [refundedItems, setRefundedItems] = useState<any>([]);
  const { t } = useTranslation();
  const [selectedClient, setSelectedClient] = useState<ClientType | undefined>(
    cart.client_id ? cart.client_id : defaultClient
  );

  const [openCalculator, setOpenCalculator] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalStock, setShowModalStock] = useState(false);
  const [selectedArticle, setSelectedArticles] = useState(null);

  /* useEffect(() => {
    if (societe_id) dispatch(findDefaultClientsBySociete(societe_id));
    if (cart.client_id === defaultClient._id) setSelectedClient(defaultClient);
  }, [dispatch]);*/

  useEffect(() => {
    if (typeof cart.client_id === "string") {
      if (defaultClient) {
        setSelectedClient(defaultClient);
      } else if (cart.operationType === "Retour")
        setSelectedClient(cart.refundedOrder?.client_id);
    }
  }, [defaultClient]);

  useEffect(() => {
    if (typeof cart.client_id === "string") {
      const findclient = listClient?.find(
        (item) => item._id === cart.client_id
      );

      setSelectedClient(findclient);
    } else {
      setSelectedClient(cart.client_id);
    }
  }, [cart.client_id]);

  const articles = useAppSelector(ListArticles);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const resetCartToDefault = async () => {
    await dispatch(resetSelectedOrder());
    await dispatch(resetRetrievedOrder());
    await dispatch(setQuantity(1));
    //await dispatch(resetOperationType());
    await dispatch(setInstantRemise(0));
    await dispatch(resetPanier());
    await dispatch(resetReduction());

    if (defaultClient) {
      await dispatch(addClient(defaultClient._id));
      await setSelectedClient(defaultClient);
    }
  };

  const [searchTerm, setSearchTerm] = useState<string>("");
  const reptureAlerts = async (orderItems: any, depot: DepotsType) => {
    for (const value of orderItems) {
      const res = await dispatch(
        findArticleStockDepotApi({
          article_id: value.article._id,
          depot_id: depot._id,
        })
      );
      if (res.payload) {
        dispatch(
          setItemAvailability({
            article: value.article._id,
            stock: res.payload.stock,
          })
        );
      }
    }
  };
  useEffect(() => {
    if (
      !cart.orderConfirmed &&
      cart.operationType === "Vente" &&
      user &&
      user.role_id?.libelle === "onlineSale"
    )
      reptureAlerts(cart.items, currentDepot);
  }, [cart.totalNet]);

  const addToCart = async (item: any) => {
    setSearchTerm("");
    setShowModalListReference(false);
    if (item?.code_a_barre?.length && item) {
      const resAdd = await dispatch(
        addToPanier({
          article: item,
          quantity: cart.calculatedQuantity,
          prix_unitaire: item.prix_vente_ttc,
          prix_total: item.prix_vente_ttc * cart.calculatedQuantity,
          paid_quantity: 0,
          remise: item.remise ? item.remise : cart.InstantRemise,
          remiseClient: cart.clientRemise,
          prixNet: item.prix_vente_ttc * cart.calculatedQuantity,
          type: item?.type,
          defaut_id: item.defaut_id,
          qtemax: item?.qtemax,
        })
      );
      await dispatch(setNetPrice());
      await dispatch(setQuantity(1));
      return item;
    } else {
      console.log("error occurred");
    }
  };
  const selectItem = async (item: any) => {
    await addToCart(item.article);
  };
  const handleInputChange = async (inputValue: any) => {
    setloadingsearch(true);
    setSearchTerm(inputValue);
    if (user && user.role_id?.libelle === "onlineSale") {
      const resultReference = await dispatch(
        findArticleRefParentApi(inputValue)
      );
      if (resultReference.payload) {
        const list = resultReference.payload.map((e: any) => {
          return { article: e };
        });
        setListReference(list);
        setShowModalListReference(true);
      } else {
        const resultClient = await dispatch(findClientByBarcode(inputValue));
        if (resultClient.payload) {
          await dispatch(addClient(resultClient.payload._id));
          setSelectedClient(resultClient.payload);
          setSearchTerm("");
          if (resultClient.payload.note) {
            toast.info(resultClient.payload.note);
          }
          setRefresh(!refresh);
          await dispatch(setClientRemise(resultClient.payload.remise));
          await dispatch(setNetPrice());
        } else {
          const resultArticleDef: any = await dispatch(
            findArticleDefectueuxBarCode(inputValue)
          );
          if (resultArticleDef.payload) {
            const prix =
              resultArticleDef?.payload?.prix_vente_ttc ||
              resultArticleDef?.payload.article?.prix_vente_ttc;
            const resAdd = await addToCart({
              ...resultArticleDef.payload.article,
              prix_vente_ttc: prix,
              type: "defaut",
              remise: resultArticleDef.payload.remise,
              defaut_id: resultArticleDef.payload._id,
              qtemax: resultArticleDef.payload.Quantite,
            });
            if (resAdd) {
              // Fetch remises only after item is added to the cart
              setRefresh(!refresh);
            }
            setSearchTerm("");
          } else {
            ToastWarning(t("Ce code n'est associé à aucun article ou client"));
            setSearchTerm("");
          }
        }
      }
    } else {
      if (TypeInput === "codeBarre") {
        const resultArticle = await dispatch(findArticleByBarCode(inputValue));

        // const resultBon = await dispatch(findBonByBarcode(inputValue));
        if (resultArticle.payload) {
          // Add to cart first
          const resAdd = await addToCart(resultArticle.payload);
          if (resAdd) {
            // Fetch remises only after item is added to the cart
            setRefresh(!refresh);
          }

          setSearchTerm("");
        } else {
          const resultClient = await dispatch(findClientByBarcode(inputValue));
          if (resultClient.payload) {
            await dispatch(addClient(resultClient.payload._id));
            setSelectedClient(resultClient.payload);
            setSearchTerm("");
            if (resultClient.payload.note) {
              toast.info(resultClient.payload.note);
            }
            setRefresh(!refresh);
            await dispatch(setClientRemise(resultClient.payload.remise));
            await dispatch(setNetPrice());
          } else {
            const resultArticleDef: any = await dispatch(
              findArticleDefectueuxBarCode(inputValue)
            );
            if (resultArticleDef.payload) {
              const prix =
                resultArticleDef?.payload?.prix_vente_ttc ||
                resultArticleDef?.payload.article?.prix_vente_ttc;
              const resAdd = await addToCart({
                ...resultArticleDef.payload.article,
                prix_vente_ttc: prix,
                type: "defaut",
                remise: resultArticleDef.payload.remise,
                defaut_id: resultArticleDef.payload._id,
                qtemax: resultArticleDef.payload.Quantite,
              });
              if (resAdd) {
                // Fetch remises only after item is added to the cart
                setRefresh(!refresh);
              }
              setSearchTerm("");
            } else {
              ToastWarning(
                t("Ce code n'est associé à aucun article ou client")
              );
              setSearchTerm("");
            }
          }
        }
      } else {
        const resultReference = await dispatch(
          findArticleRefParentApi(inputValue)
        );

        // const resultBon = await dispatch(findBonByBarcode(inputValue));
        if (resultReference.payload) {
          const list = resultReference.payload.map((e: any) => {
            return { article: e };
          });
          setListReference(list);
          setShowModalListReference(true);
        } else {
          const resultClient = await dispatch(findClientByBarcode(inputValue));
          if (resultClient.payload) {
            await dispatch(addClient(resultClient.payload._id));
            setSelectedClient(resultClient.payload);
            setSearchTerm("");
            if (resultClient.payload.note) {
              toast.info(resultClient.payload.note);
            }
            setRefresh(!refresh);
            await dispatch(setClientRemise(resultClient.payload.remise));
            await dispatch(setNetPrice());
          } else {
            const resultArticleDef: any = await dispatch(
              findArticleDefectueuxBarCode(inputValue)
            );
            if (resultArticleDef.payload) {
              const prix =
                resultArticleDef?.payload?.prix_vente_ttc ||
                resultArticleDef?.payload.article?.prix_vente_ttc;
              const resAdd = await addToCart({
                ...resultArticleDef.payload.article,
                prix_vente_ttc: prix,
                type: "defaut",
                remise: resultArticleDef.payload.remise,
                defaut_id: resultArticleDef.payload._id,
                qtemax: resultArticleDef.payload.Quantite,
              });
              if (resAdd) {
                // Fetch remises only after item is added to the cart
                setRefresh(!refresh);
              }
              setSearchTerm("");
            } else {
              ToastWarning(
                t("Ce code n'est associé à aucun article ou client")
              );
              setSearchTerm("");
            }
          }
        }
        setTypeInput(TypeInputParametrage);
      }
    }

    setloadingsearch(false);

    // else if (resultBon.payload) {
    //   setSearchTerm("");

    //   const expireDate = new Date(resultBon.payload.dateEcheance);

    //   const expireDateLocal = new Date(
    //     expireDate.getTime() + expireDate.getTimezoneOffset() * 60000
    //   );
    //   if (expireDateLocal < today) {
    //     ToastWarning("Ce bon de réduction est expiré !");
    //   } else {
    //     dispatch(setReduction(resultBon.payload.amount));
    //     setRefresh(!refresh);
    //   }
    // }
  };

  const removeFromCart = async (item: any) => {
    dispatch(removeFromPanier(item));
    setRefresh(!refresh);
    dispatch(setNetPrice());
  };

  const removeSingleProductFromCart = async (element: any) => {
    dispatch(decreaseQuantity(element));
    dispatch(setNetPrice());
    const item = cart.items.find(
      (item) => item.article._id === element.article._id
    );

    setRefresh(!refresh);
    dispatch(setNetPrice());
  };

  const addSingleProductToCart = async (element: any) => {
    dispatch(increaseQuantity({ item: element, quantity: 1 }));
    dispatch(setNetPrice());
    const item = cart.items.find(
      (item) => item.article._id === element.article._id
    );

    setRefresh(!refresh);
    dispatch(setNetPrice());
  };

  /*function generateOrderNumber(): string {
    let uniqueId: string;
    const existingOrderNumbers = orders.map((order) => order.order_number);
    do {
      uniqueId = "#" + (Math.floor(Math.random() * 90000) + 10000).toString();
    } while (
      existingOrderNumbers.some((orderNumber) => orderNumber === uniqueId)
    );
    return uniqueId;
  }*/

  const [isLoading, setIsLoading] = useState(false);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const handleConfirmOrder = async (data: any, status: string) => {
    let order = { ...data };
    setIsLoading(true);
    let response;
    if (currentOrganisation) {
      order.organisation_id = currentOrganisation;
    }
    order.items = cart.items;
    order.date = new Date();
    order.table_id = selectedtable ? selectedtable._id : null;
    order.total = cart.total;
    order.totalNet = cart.totalNet;

    order.order_number = await findSoucheCommande(currentOrganisation, dispatch);
    order.status = status;
    order.client_id = selectedClient ? selectedClient._id : null;
    order.journal_id = journal?._id;
    order.depots_id = currentDepot._id;
    order.vendeur = null;
    order.user_id = user?._id;
    order.societe_id = societe_id;
    order.type = "caisse";
    try {
      response = await dispatch(saveOrderApi(order));
      order.items.forEach((value: any) => {
        dispatch(
          updateArticleStockApi({
            id: value.article._id,
            depotId: currentDepot._id,
            soldQuantity: value.quantity,
            type: value?.type,
            organisation: currentOrganisation,

            defaut_id: value?.defaut_id,
          })
        );
      });
      if (response.payload.success) {
        dispatch(confirmOrder());
        resetCartToDefault();
        if (selectedtable) {
          dispatch(
            updateTableStatusApi({
              table_id: selectedtable._id,
              status: "Occupée",
            })
          );
        }
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      // Handle error appropriately
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CalculatorModal
        ShowModal={openCalculator}
        setShowModal={setOpenCalculator}
      />
      <StockGlobale
        article={selectedArticle}
        setSelectedArticles={setSelectedArticles}
        ShowModal={showModalStock}
        setShowModal={setShowModalStock}
      />
      {selectedClient && selectedClient !== defaultClient ? (
        <FicheClient
          client={selectedClient}
          ShowModalClient={showModal}
          setShowModalClient={setShowModal}
        />
      ) : null}
      <div className="cart-header">
        <div className="widget-message" style={{ padding: "10px" }}>
          <div className="widget-title mb-10">
            <h5>{t("cart")}</h5>
            <button
              className="delete-button"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              onClick={() => {
                resetCartToDefault();
                setLoad(!load);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col-2"
            style={{ display: "flex", alignItems: "center", width: "auto" }}
          >
            <button
              className="delete-button btn-primary"
              onClick={(e) => setOpenCalculator(true)}
              style={{ width: "50px", height: "50px", fontSize: "26px" }}
            >
              <i className="fas fa-calculator"></i>
            </button>
            <p
              style={{
                fontSize: "1.5em",
                margin: "0",
                color: "#3e3e3e",
              }}
            >
              {cart.calculatedQuantity}
            </p>
          </div>
          <div className="col-5">
            {!cart.orderConfirmed || user?.role_id?.libelle === "onlineSale" ? (
              <div className="navbar-item flex-row navbar-dropdown">
                <div className="nav-item align-self-center search-animated">
                  <i className="fas fa-barcode"></i>
                  <form
                    className="form-inline search-full form-inline search"
                    role="search"
                    onSubmit={handleSubmit}
                  >
                    <div
                      className="search-bar"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        className={
                          "form-control search-form-control ml-lg-auto" +
                          boxClass
                        }
                        value={searchTerm}
                        onChange={handleChange}
                        placeholder={t("Enter product code")}
                        autoFocus
                        id="code_input"
                        style={{ height: "40px", paddingRight: "2.5rem" }}
                        disabled={cart.operationType === "Retour"}
                      />
                      {loadingsearch ? (
                        <div
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <div
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {TypeInput === "codeBarre" ? (
                            <button
                              className="btn-primary"
                              onClick={() => setTypeInput("ref")}
                              style={{
                                width: "60px",
                                height: "40px",
                              }}
                            >
                              CB
                            </button>
                          ) : (
                            <button
                              className="btn-primary"
                              onClick={() => setTypeInput("codeBarre")}
                              style={{
                                width: "60px",
                                height: "40px",
                              }}
                            >
                              REF
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={
              parametrage && parametrage.instantRemise ? "col-3" : "col-5"
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #000",
              borderRadius: "10px",
              height: "40px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (selectedClient && selectedClient._id !== defaultClient._id)
                setShowModal(true);
            }}
          >
            {selectedClient?.nom}{" "}
            {selectedClient?.remise
              ? " | " + selectedClient?.remise + "%"
              : null}{" "}
            <br></br>
            {cart?.programmesFidelite?.length &&
            cart.client_id.toString() !== defaultClient._id
              ? " + " +
                cart.programmesFidelite?.reduce(
                  (acc, item) => acc + item.points,
                  0
                ) +
                " points"
              : null}
          </div>
          {/* <button onClick={() => alert(calculateRemise(cart.items[0]))}>
            test
          </button> */}
          {parametrage && parametrage.instantRemise ? (
            <div className="col-2">
              <div className="navbar-item flex-row navbar-dropdown">
                <div className="nav-item align-self-center search-animated">
                  <i className="fas fa-percentage"></i>
                  <form
                    className="form-inline search-full form-inline search"
                    role="search"
                  >
                    <div className="search-bar">
                      <input
                        type="text"
                        className={
                          "form-control search-form-control ml-lg-auto" +
                          boxClass
                        }
                        value={cart.InstantRemise}
                        max={100}
                        onKeyPress={handleKeyPressCart}
                        onChange={(e) => {
                          let value = Number(e.currentTarget.value);
                          if (value > 100) value = 100;
                          dispatch(setInstantRemise(e.currentTarget.value));
                          dispatch(setNetPrice());
                          setLoad(!load);
                        }}
                        style={{ height: "40px" }}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {parametrage && cart.items && cart.items.length > 0 ? (
        <div
          className="widget-content searchable-container list"
          style={{
            margin: 0,
            width: "100%",
            maxWidth: "unset",
            overflow: "hidden",
          }}
        >
          <div className="searchable-items list" style={{ overflow: "hidden" }}>
            <div className="items items-header-section item-content">
              <div className="user-email col-4" style={{ textAlign: "center" }}>
                <h4 style={{ marginLeft: "0px" }}>Article</h4>
              </div>
              <div className="user-email col-1" style={{ textAlign: "center" }}>
                <h4 style={{ marginLeft: "0px" }}>Prix</h4>
              </div>
              <div className="user-email col-2" style={{ textAlign: "center" }}>
                <h4 style={{ marginLeft: "0px" }}> Quantité</h4>
              </div>
              <div className="user-email col-2" style={{ textAlign: "center" }}>
                <h4 style={{ marginLeft: "0px" }}>Remise</h4>
              </div>
              <div className="user-email col-2" style={{ textAlign: "center" }}>
                <h4 style={{ marginLeft: "0px" }}> Total</h4>
              </div>
              <div className="user-email col-2" style={{ textAlign: "center" }}>
                <h4 style={{ marginLeft: "0px" }}></h4>
              </div>
            </div>
            {cart.items.map((item: any, index: number) => (
              <div
                className={
                  item.remise > 0
                    ? "items item-content remise"
                    : item.outOFStock
                    ? "items item-content outOfStock"
                    : "items item-content"
                }
                key={index}
              >
                <div className="user-profile col-4 p-0">
                  {/* <img
                      src={
                        item.article.image && item.article.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.article.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="avatar"
                      className="ml-0"
                    /> */}
                  <Zoom>
                    <img
                      style={{ margin: 0 }}
                      alt=""
                      src={
                        item.article.image && item.article.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.article.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      width="100"
                    />
                  </Zoom>
                  <div
                    className="user-meta-info"
                    onClick={(e) => {
                      setSelectedArticles(item.article);
                      setShowModalStock(true);
                    }}
                  >
                    <p className="user-name" data-name="Alan Green">
                      {item?.article?.titre?.fr}{" "}
                    </p>
                    <p className="user-work" data-occupation="Web Developer">
                      {item.article.caracteristiques !== undefined
                        ? item.article.caracteristiques
                            .map((caracteristique: any) =>
                              caracteristique.value?.libelle
                                ? caracteristique.value?.libelle + " "
                                : caracteristique.value + " "
                            )
                            .join("")
                        : null}
                    </p>
                    {}
                    <p className="color_red">
                      {item.type === "defaut" ? "defectueux" : ""}
                    </p>
                  </div>
                </div>
                <div
                  className="user-email col-1"
                  style={{ textAlign: "center" }}
                >
                  <span
                    className={"w-p-link" + btnClass}
                    style={{ width: "auto", justifyContent: "center" }}
                  >
                    <p>{item.prix_unitaire}</p>
                  </span>
                </div>
                <div
                  className="user-email col-2"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="w-profile-links"
                    style={{
                      alignItems: "center",
                      justifyContent: "right",
                      display: "flex",
                      padding: 0,
                    }}
                  >
                    <button
                      className={"minus-button" + btnClass + bgClass}
                      onClick={() => {
                        removeSingleProductFromCart(item);
                      }}
                      disabled={
                        cart.orderConfirmed ||
                        item.prix_total === 0 ||
                        (cart.order_id &&
                          Orders?.find((i) => i._id === cart.order_id)
                            ?.status === "demande Valide")
                          ? true
                          : false
                      }
                    >
                      <i className="fas fa-minus"></i>
                    </button>
                    <span className={"w-p-link" + btnClass}>
                      <p>{item.quantity}</p>
                    </span>
                    <button
                      className={"minus-button" + btnClass + bgClass}
                      onClick={() => {
                        addSingleProductToCart(item);
                      }}
                      disabled={
                        cart.orderConfirmed ||
                        item.prix_total === 0 ||
                        (cart.order_id &&
                          Orders?.find((i) => i._id === cart.order_id)
                            ?.status === "demande Valide")
                          ? true
                          : false
                      }
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="col-2" style={{ textAlign: "center" }}>
                  <span
                    className={"w-p-link" + btnClass}
                    style={{ width: "auto", justifyContent: "center" }}
                  >
                    <form
                      className="form-inline search-full form-inline search"
                      role="search"
                    >
                      <div className="search-bar">
                        <input
                          type="text"
                          className={
                            "form-control search-form-control ml-lg-auto" +
                            boxClass
                          }
                          value={item.remise}
                          onKeyPress={handleKeyPressCart}
                          max={100}
                          disabled={parametrage && !parametrage.instantRemise}
                          onChange={(e) => {
                            // Ensure the value does not exceed 100
                            let value = e.currentTarget.value;
                            value = value.replace(/^0+/, "") || "0";
                            let numericValue = Number(value);
                            if (numericValue > 100) value = "100";
                            if (numericValue < 0) value = "0";
                            dispatch(
                              setItemInstantRemise({
                                id: item.article._id,
                                remise: value,
                              })
                            );
                            dispatch(setNetPrice());
                            setLoad(!load);
                          }}
                          style={{ height: "40px", width: "inherit" }}
                        />
                        {item?.remisepropsition && (
                          <span style={{ fontSize: "10px" }}>
                            Proposition initiale {item?.remisepropsition}
                          </span>
                        )}
                      </div>
                    </form>
                  </span>
                </div>

                <div
                  className="user-email col-2"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span className={"w-p-link" + btnClass}>
                    <p className={item.prixNet > 0 ? "" : "text-success"}>
                      {item.prixNet?.toFixed(3)}
                    </p>
                  </span>
                </div>
                <div className="user-email col-2 " style={{ display: "flex" }}>
                  <span
                    className={"w-p-link" + btnClass}
                    style={{ width: "auto", justifyContent: "center" }}
                  >
                    <button
                      className={"delete-button" + btnClass}
                      onClick={() => removeFromCart(item)}
                      disabled={cart.orderConfirmed}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </span>

                  {user && item.type !== "defaut" && (
                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <button
                        className={"delete-button" + btnClass}
                        style={{ background: "#fbb205" }}
                        onClick={() => {
                          setArticleDefectueux(item.article);
                          setShowModalDefectueux(true);
                        }}
                      >
                        <i
                          className="fas fa-exclamation-triangle "
                          style={{ color: "#fff" }}
                        ></i>{" "}
                      </button>
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          {user && user.role_id?.libelle !== "onlineSale" ? (
            <div className="cart-footer">
              {/*{cart && cart.operationType !== "Retour" ? (
                  <button
                    className={"btn btn-success mb-2" + btnClass}
                    style={{ width: "100%", background: "#009688" }}
                    onClick={(e) => {
                      handleSetTypeOperation(
                        cart.totalNet,
                        cart.items.length === 1
                          ? [cart.items[0]]
                          : refundedItems
                      );
                    }}
                  >
                    Echange / Retour
                  </button>
                ) : null}*/}
              {!hidePaymentButton && reduction === 0 ? (
                <>
                  <Button
                    variant="success"
                    className={"mb-2" + btnClass}
                    style={{
                      width: "100%",
                      background: isLoading ? "#ddd" : "#009688",
                      height: "50px",
                    }}
                    onClick={() => {
                      handleConfirmOrder(cart, "En attente");
                    }}
                    disabled={cart.orderConfirmed}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="mr-2"
                        />
                        Loading...
                      </>
                    ) : (
                      t("put_on_hold")
                    )}
                  </Button>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="w-profile-view" style={{ padding: "12px" }}>
          <div className="w-profile-content">
            <div>
              <p>{t("your_cart_is_empty")}</p>
            </div>
          </div>
        </div>
      )}
      <ArticleDefectueuxPopUP
        ShowModal={ShowModalDefectueux}
        setShowModal={setShowModalDefectueux}
        t={t}
        article={ArticleDefectueu}
        order={retrievedOrder}
      />
      <ArticleListReference
        t={t}
        ShowModal={ShowModalListReference}
        setShowModal={setShowModalListReference}
        selectAricle={selectItem}
        list={ListReference}
        reference={searchTerm}
      />
    </>
  );
};
export default WideCart;
