import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";
import TicketArticle from "../TicketArticle/TicketArticle";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SelectedOrganisations } from "../../_App/Redux/Slices/Auth/authSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { use } from "i18next";
import { TransferArticleDefectueuxApi } from "../../_App/Redux/Slices/articledefectueux/articledefectueuxSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { useNavigate } from "react-router-dom";

export default function ListArticleDefectueux({
  listdefectueux,
  t,
  TicketItem,
  uodateArticleDefectueux,
  organisationId,
  listOragnisation,
  user_id,
  societe_id,
}: any) {
  const dispatch=useAppDispatch()
  const [Indexchange, setIndexchange] = useState(-1);
  const [Transfert, setTransfert] = useState(false);
  const navigate = useNavigate();

  const [SelectedOrganisation, setSelectedOrganisation] = useState<string>(
    listOragnisation[0]?._id
  );

  const [list, setlist] = useState<any[]>([]);
  const [listTransferet, setlistTransferet] = useState<any[]>([]);
  useEffect(() => {
    if (listdefectueux && listdefectueux.length !== 0) {
      setlist(listdefectueux);
    }
  }, [listdefectueux]);
  useEffect(()=>{
    setSelectedOrganisation(  listOragnisation[0]?._id)
  },[listOragnisation])
  const changeRemise = (index: any, value: string) => {
    const updatedItem = { ...list[index], remise: value };

    const updatedList = [...list];
    updatedList[index] = updatedItem;

    setlist(updatedList);
  };
  const checkbox = (article: any) => {
    const indexItem = listTransferet.findIndex(
      (item) => item._id === article._id
    );

    if (indexItem === -1) {
      setlistTransferet([...listTransferet, article]);
    } else {
      setlistTransferet(listTransferet.filter((i) => i._id !== article._id));
    }
  };
  const ChngeQteTransfer = (article: any, value: any) => {
    const indexItem = listTransferet.findIndex(
      (item) => item._id === article._id
    );

    if (indexItem !== -1) {
      if (parseInt(value) <= parseInt(article.Quantite)) {
        const copie = [...listTransferet];
        const updatedItem = { ...copie[indexItem], Quantite: value };
        copie[indexItem] = updatedItem;
        setlistTransferet(copie);
      } else {
        ToastWarning(
          "la quantite voulait transférer dépasse la quantite existante"
        );
      }
    }
  };
  const TransferArticles = async() => {
    const dataArticle = {
      organisation: organisationId,
      organisationReception: SelectedOrganisation,
      societe_id: societe_id,
      user_id: user_id,
      articles: listTransferet,
    };

   const resp= await dispatch(TransferArticleDefectueuxApi(dataArticle))
   if(resp.payload.success){
    ToastSuccess(resp.payload.message)
    navigate("/listdemande/org/apprové");

   }
  };
useEffect(()=>{
  if(!Transfert){
    setlistTransferet([])
  }
},[Transfert])
  return (
    <div>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
        <div className="col-2 mt-3">
          <button
            className="btn btn-primary"
            onClick={() => setTransfert(!Transfert)}
          >
            {Transfert ? t("Annuler transfert") : t("Transfert")}
          </button>
        </div>
        {Transfert && listOragnisation && (
          <div className="col-4">
            <strong>{t("Organisation  recerveur : ")}</strong>
            <select
              className="form-control"
              onChange={(e) => setSelectedOrganisation(e.target.value)}
              value={SelectedOrganisation}
            >
              {listOragnisation?.map(
                (item: any, index: number) =>
                  item._id !== organisationId && (
                    <option key={index} value={item._id}>
                      {item?.nom}
                    </option>
                  )
              )}
            </select>
          </div>
        )}
        {Transfert&&
        <div className="col-2 mt-3">
           <button
         className="btn btn-primary"
         onClick={() => TransferArticles()}
       >
          {t('Enregistrer')}
       </button>
          </div>
        }
      </div>
      <div className="row widget-content searchable-container list full-width">
        <div className="searchable-items list full-width">
          <div className="items items-header-section item-content full-width">
            <div className="user-email text-center  col-2">
              <h4>{t("Article")}</h4>
            </div>
            <div className="user-email text-center col-2">
              <h4>{t("Order")}</h4>
            </div>

            <div className="user-email text-center  col-1">
              <h4>{t("Quantite")}</h4>
            </div>
            {Transfert && (
              <div className="user-email text-center  col-1">
                <h4>{t("Quantite T")}</h4>
              </div>
            )}
            <div className="user-email text-center col-2">
              <h4>{t("Note")}</h4>
            </div>

            <div className="user-email text-center col-2">
              <h4>{t("Images")}</h4>
            </div>
            <div className="user-email text-center col-2">
              <h4>{t("Remise")}</h4>
            </div>
            <div className="user-email text-center col-1">
              <h4>{t("")}</h4>
            </div>
          </div>
        </div>
      </div>
      {list &&
        list.map((item: any, index: number) => (
          <div
            key={index}
            className="row widget-content searchable-container list full-width"
          >
            <div className="searchable-items list full-width">
              <div className="items items-header-section item-content full-width">
                
                <div className="user-email text-center  col-2">
                {Transfert && (
                    <input
                      type="checkbox"
                      value={listTransferet.find((i) => i._id === item._id)}
                      onClick={() => checkbox(item)}
                    />
                  )}
                  <h4>{item?.article?.titre?.fr}</h4>
                  {item?.article?.caracteristiques?.length ? (
                    <p>
                      {item?.article?.caracteristiques.map(
                        (caracteristique: any) =>
                          caracteristique?.value?.libelle
                            ? caracteristique?.value?.libelle + " "
                            : caracteristique?.value + " "
                      )}
                    </p>
                  ) : null}
                  <p
                    className="lien-bleu-souligne"
                    onClick={() => TicketItem(item)}
                  >
                    {" "}
                    {t("Code a barre")} : {item?.codeabarre}
                  </p>
                </div>
                <div className="user-email text-center col-2">
                  <h4>{item?.order_id?.order_number}</h4>
                </div>

                <div className="user-email text-center col-1">
                  <h4>{item?.Quantite}</h4>
                </div>
                {Transfert && (
                  <div className="user-email text-center col-1">
                    <input
                      type="number"
                      value={
                        listTransferet.find((i) => i._id === item._id)?.Quantite
                      }
                      onChange={(e) => ChngeQteTransfer(item, e.target.value)}
                      disabled={!listTransferet.find((i) => i._id === item._id)}
                    />
                  </div>
                )}
                <div className="user-email text-center col-2">
                  <h4>{item?.note}</h4>
                </div>

                <div className="user-email text-center col-2">
                  <Zoom>
                    <img
                      alt=""
                      src={
                        item?.image && item?.image
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "articledefectueux/" +
                            item?.image
                          : "/assets/assets/img/no-image.jpg"
                      }
                      className="img-fluid "
                      style={{
                        maxWidth: "80px",
                        maxHeight: "80px",
                        objectFit: "cover",
                      }}
                    />
                  </Zoom>
                </div>
                <div className="user-email text-center col-2">
                  {Indexchange === index ? (
                    <input
                      type="number"
                      className="form-control"
                      value={item?.remise || 0}
                      onChange={(e) => changeRemise(index, e.target.value)}
                    />
                  ) : (
                    <h4>{item?.remise || 0}%</h4>
                  )}
                </div>
                <div className="user-email text-center col-1">
                  {Indexchange === index ? (
                    <></>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => setIndexchange(index)}
                    >
                      {t("Modifier remise")}{" "}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      <button
        className="btn btn-primary"
        onClick={() => uodateArticleDefectueux(list)}
      >
        {t("Enregistrer")}
      </button>
    </div>
  );
}
