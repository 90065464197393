import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  selectUserId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import SousCategorieSelecter from "../../Shared/SousCategorie/SousCategorieSelecter";
import ListArticleDefectueux from "../../Components/ArticleDefectueux/ListArticleDefectueux";
import { useTranslation } from "react-i18next";
import {
  findArticleDefectueuxApi,
  ListArticledefectueux,
  updateArticleDefectueux,
} from "../../_App/Redux/Slices/articledefectueux/articledefectueuxSlice";
import TicketArticle from "../../Components/TicketArticle/TicketArticle";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function ArticleDefectueuxPage() {
  const { t } = useTranslation();
  const [showModelTicketArticle, setshowModelTicketArticle] = useState(false);
  const [listTicket, setlistTicket] = useState<any[]>([]);
  const user_id = useAppSelector(selectUserId);

  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const listArticleDefectueux = useAppSelector(ListArticledefectueux);
  const organisationId = useAppSelector(SelectedOrganisationId);
  const listOragnisation = useAppSelector(ListOrganisations);
  const [listsouscategorie, setlistsouscategorie] = useState([]);
  const [filtre, setFlitre] = useState({
    societe_id: societe,
    organistaion: organisationId as string, // Fix: Use 'organistaion' here
    codearticle: null as unknown as string,
    souscategorie: [] as string[], // Add type for souscategorie
  });

  useEffect(() => {

    if (societe) {
      dispatch(allOrganisationsApi(societe));
      dispatch(findArticleDefectueuxApi(filtre));
    }
  }, [societe]);

  const handleSearch = async () => {
    const copie = { ...filtre };
    copie.souscategorie = listsouscategorie;
    await dispatch(findArticleDefectueuxApi(filtre));
  };
  const getAllTicket = () => {
    const listticket = listArticleDefectueux.map((element: any) => {
      return {
        marque: element?.article?.souscategorie?.libelle?.fr,
        ref: element?.article?.articleparent?.code_article,
        taille:
          element?.article?.caracteristiques.length !== 0
            ?  element?.article?.caracteristiques[1]?.value?.libelle ?element?.article?.caracteristiques[0]?.value:element?.article?.caracteristiques[1]?.value
            : element?.article?.code_article.split("-")[2],
        couleur:
          element?.article?.caracteristiques.length !== 0
            ? element?.article?.caracteristiques[0]?.value?.libelle ?element?.article?.caracteristiques[0]?.value?.libelle:element?.article?.caracteristiques[1]?.value?.libelle
            : element?.article?.code_article.split("-")[1],
        prix: element?.prix_vente_ttc,
        code_a_barre: element?.codeabarre,
        qte: element?.Quantite,
        solde: null,
      };
    });
    setlistTicket(listticket);
    setshowModelTicketArticle(true);
  };
  const TicketItem = (item: any) => {
    setlistTicket([
      {
        marque: item?.article?.souscategorie?.libelle?.fr,
        ref: item?.article?.articleparent?.code_article,
        taille:
          item?.article?.caracteristiques.length !== 0
            ? item?.article?.caracteristiques[0]?.value?.libelle
            : item?.article?.code_article.split("-")[2],
        couleur:
          item?.article?.caracteristiques.length !== 0
            ? item?.article.caracteristiques[1]?.value?.libelle
            : item?.article?.code_article.split("-")[1],
        prix: item?.prix_vente_ttc,
        code_a_barre: item?.codeabarre,
        qte: item?.Quantite,
        solde: null,
      },
    ]);
    setshowModelTicketArticle(true);
  };

  
  const uodateArticleDefectueux = async(data:any)=>{
    const resp= await dispatch(updateArticleDefectueux(data))
    if(resp&& resp.payload.success){
      ToastSuccess(t( resp.payload.message))
    }else{
      ToastWarning(resp.payload.message)
    }
  }
  return (
    <div>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
        <div className="col-3">
          <label>{t("Organisation")}</label>
          <select
            className="form-control "
            value={filtre.organistaion}
            onChange={(e) => {
              setFlitre({
                ...filtre,
                organistaion: e.target.value,
              });
            }}
          >
            {listOragnisation &&
              listOragnisation.map((item, index) => (
                <option key={index} value={item?._id}>
                  {item?.nom}
                </option>
              ))}
          </select>
        </div>
        <div className="col-3">
          <SousCategorieSelecter
            listsouscategorie={listsouscategorie}
            setlistsouscategorie={setlistsouscategorie}
          />
        </div>
        <div className="col-3">
          <label>{t("Code Article")}</label>
          <input
            type="text"
            className="form-control "
            value={filtre.codearticle}
            onChange={(e) => {
              setFlitre({
                ...filtre,
                codearticle: e.target.value,
              });
            }}
          />
        </div>
        <div className="col-1 mt-3 fs-25 " onClick={handleSearch}>
          {" "}
          <i className="fas fa-search"></i>
        </div>
        <div className="col-2 mt-4">
          <button className="btn btn-primary" onClick={getAllTicket}>
           {t('Code a barre')}
          </button>
        </div>
      </div>
      <ListArticleDefectueux 
            t={t} 
            listOragnisation={listOragnisation}
            listdefectueux={listArticleDefectueux}  
            organisationId={organisationId}
            user_id={user_id}
            societe_id={societe}
            TicketItem={TicketItem}
            uodateArticleDefectueux={uodateArticleDefectueux}/>
      <TicketArticle
        t={t}
        ShowModal={showModelTicketArticle}
        setShowModal={setshowModelTicketArticle}
        tickets={listTicket}
        stateQte={false}
      />
    </div>
  );
}
