import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import RaccourciProductList from "../../Components/Caisses/ProductsRaccourcis/RaccourciProductList";

import {
  ListCartes,
  findCartesApi,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import CaisseCartesList from "../../Components/Caisses/Cartes/CaisseCartesList";
import {
  ListCategories,
  ListCategoriesByCarte,
  findCategoriesApi,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  ListSousCategories,
  ListSousCategoriesByCategorie,
  findSousCategoriesApi,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  Display,
  Filter,
  changeDisplay,
  layout,
  selectedTable,
} from "../../_App/Redux/Slices/Caisse/CaisseSlice";

import CaisseCategoriesList from "../../Components/Caisses/Categories/CaisseCategoriesList";
import CaisseSousCategoriesList from "../../Components/Caisses/SousCategories/CaisseSousCategoriesList";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  ListRaccourci,
  findProductRaccourciByOrganisationApi,
} from "../../_App/Redux/Slices/product_raccourci/ProductRaccourciSlice";
import NavbarCaisse from "../../_Layouts/Navbar/NavbarCaisse";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectUser,
  selectedSessionJournal,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  currentpage,
  findArticlebysocieteApi,
  ListArticles,
  totalPages,
} from "../../_App/Redux/Slices/article/articleSlice";
import CaisseArticlesList from "../../Components/Caisses/Articles/CaisseArticlesList";

import { useNavigate } from "react-router-dom";
import Loader from "../../_Layouts/Loader/Loader";
import { ArticleType } from "../../_App/Types/Entites/ArticleType";
import { SousCategorie } from "../../_App/Types/Entites/SousCategorie";
import { parametrageCaisse } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import BarcodeOnlyCaissePage from "./BarcodeOnlyCaissePage";

// Dispatching the action

const CaissePage = () => {
  const dispatch = useAppDispatch();
  const productsRaccourcis = useAppSelector(ListRaccourci);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  //
  const navigate = useNavigate();
  const journalCaisse = useAppSelector(selectJournalCaisse);
  const sessionJournal = useAppSelector(selectedSessionJournal);
  const user = useAppSelector(selectUser);

  const isCaisseClosed =
    !journalCaisse ||
    journalCaisse.cloture ||
    !sessionJournal ||
    sessionJournal.cloture;

  useEffect(() => {
    if (isCaisseClosed || user?.role_id?.libelle !== "onlineSale") {
      // navigate("/HomeCaisse");
    }
  }, [isCaisseClosed, navigate]);

  //
  // const cartes = useAppSelector(ListCartes);
  // const categories = useAppSelector(ListCategories);
  // const categoriesByCarte = useAppSelector(ListCategoriesByCarte);

  const sousCategories = useAppSelector(ListSousCategories);
  const caisseParametrages = useAppSelector(parametrageCaisse);

  const sousCategoriesByCategorie = useAppSelector(
    ListSousCategoriesByCategorie
  );
  const articles = useAppSelector(ListArticles);
  const [list_Article, setlist_Article] = useState<ArticleType[]>();
  const [indexArticle, setindexArticle] = useState(0);
  const [list_SousCAt, setlist_SousCat] = useState<SousCategorie[]>();
  const [indexSousCat, setindexSousCat] = useState(0);

  const filter = useAppSelector(Filter);
  const display = useAppSelector(Display);
  const Layout = useAppSelector(layout);
  const selectedtable = useAppSelector(selectedTable);

  const handleDisplayChange = async ({
    displayColLeft,
    colRight,
    containerRight,
  }: any) => {
    dispatch(
      changeDisplay({
        colLeft: display.colLeft,
        containerLeft: display.containerLeft,
        displayColLeft: displayColLeft,
        colRight: colRight,
        containerRight: containerRight,
      })
    );
  };
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const selectedArticles = articles.slice(indexArticle, indexArticle + 10);
    setlist_Article(selectedArticles);
  }, [articles, indexArticle]);
  useEffect(() => {
    const selectedSousCategories = sousCategories.slice(
      indexSousCat,
      indexSousCat + 4
    );
    setlist_SousCat(selectedSousCategories);
  }, [sousCategories, indexSousCat]);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const [index, setindex] = useState(!current_page ? 1 : current_page);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentOrganisation) {
          setIsLoading(true); // Set loading to true before making API calls

          // Dispatch API calls
          await Promise.all([
            dispatch(
              findProductRaccourciByOrganisationApi(currentOrganisation)
            ),

            dispatch(findArticlebysocieteApi({ id: societe, index: index })),
          ]);

          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, currentOrganisation]);

  return caisseParametrages && caisseParametrages.barcodeOnly ? (
    <></>
  ) : (
    <>
      {caisseParametrages && !caisseParametrages.barcodeOnly ? (
        <NavbarCaisse Table={selectedtable} Title={"Caisse"} />
      ) : null}
      {isLoading ? (
        <Loader />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <div
            className="caisse-data-content"
            style={{ marginLeft: Layout.marginLeft }}
          >
            <div className="row">
              <div
                className={display.containerLeft}
                style={{ display: display.displayColLeft }}
              >
                <span
                  className="quantity-badge"
                  style={{ right: "0" }}
                  onClick={(e) => {
                    handleDisplayChange({
                      displayColLeft:
                        display && display.displayColLeft === "unset"
                          ? "none"
                          : "unset",
                      colRight:
                        display &&
                        display.colRight ===
                          "col-xl-3 col-lg-4 col-md-4 col-sm-6"
                          ? "col-xl-2 col-lg-3 col-md-4 col-sm-6"
                          : "col-xl-3 col-lg-4 col-md-4 col-sm-6",
                      containerRight:
                        display && display.containerRight === "col-8"
                          ? "col-12"
                          : "col-8",
                    });
                  }}
                >
                  {" "}
                  <i className="fas fa-times"></i>
                </span>
                {filter && filter.table === "cartes" ? (
                  <CaisseCartesList display={display} />
                ) : filter.table === "categories" ? (
                  <CaisseCategoriesList
                    filter={filter.filteredBy._id}
                    title={
                      filter.filteredBy && filter.filteredBy
                        ? filter.filteredBy.libelle.fr
                        : "Catégories"
                    }
                    display={display}
                  />
                ) : filter.table === "sous-categories" ? (
                  <CaisseSousCategoriesList
                    SousCatgoriesList={
                      filter.filteredBy && filter.filteredBy.categorie
                        ? sousCategoriesByCategorie[filter.filteredBy._id]
                        : sousCategories
                    }
                    display={display}
                    filter={filter}
                    title={
                      filter.filteredBy && filter.filteredBy.categorie
                        ? filter.filteredBy.libelle.fr
                        : "Sous catégories"
                    }
                  />
                ) : (
                  <CaisseArticlesList
                    productsDetailsList={articles}
                    display={display}
                    setlist_Article={setlist_Article}
                    list_Article={list_Article}
                    setindex={setindexArticle}
                    index={indexArticle}
                  />
                )}
              </div>
              <div className={display.containerRight}>
                {productsRaccourcis && (
                  <RaccourciProductList
                    ProductsList={productsRaccourcis}
                    raccourcisLimit={Object.keys(productsRaccourcis).length}
                    display={display}
                    sousCategories={list_SousCAt}
                    currentOrganisation={currentOrganisation}
                    setlist_SousCat={setlist_SousCat}
                    setIndex={setindexSousCat}
                    index={indexSousCat}
                  />
                )}
              </div>
            </div>
          </div>
        </DndProvider>
      )}
    </>
  );
};

export default CaissePage;
