import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ArticleStockGlobale,
  findFreresStocksByArticleIdApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { Form, Modal, Spinner } from "react-bootstrap";
import {
  findDepotsBySocieteApi,
  ListDepotByOrganisation,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import {
  SelectedOrganisation,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../../_App/Redux/Slices/organisations/organisationSlice";
import { useTranslation } from "react-i18next";
import Zoom from "react-medium-image-zoom";

const StockGlobale = ({
  article,
  setSelectedArticles,
  ShowModal,
  setShowModal,
}: any) => {
  /**gener les couleurs  */
  function generateUniqueColor(index: any) {
    // Constantes pour la génération
    const goldenRatioConjugate = 0.618033988749895; // Nombre d'or
    const saturation = 60; // Saturation fixe pour des couleurs pastel
    const lightness = 85; // Luminosité élevée pour des teintes douces

    // Calculer la teinte (hue) basée sur l'index
    const hue = (index * goldenRatioConjugate * 360) % 360;

    // Retourner la couleur en format HSL
    return `hsl(${Math.round(hue)}, ${saturation}%, ${lightness}%)`;
  }

  /************** */
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [listNewOrganisation, setlistNewOrganisation] = useState<any[]>([]);
  const listDepots = useAppSelector(ListDepotByOrganisation);
  const societe = useAppSelector(societeId);
  const organisationsconnecte = useAppSelector(SelectedOrganisation);

  const listOrganisations = useAppSelector(ListOrganisations);
  const [article_defectueux, setarticle_defectueux] = useState<boolean>(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setarticle_defectueux(event.target.checked);
  };
  useEffect(() => {
    if (organisationsconnecte && listOrganisations) {
      let copie = [...listOrganisations];
      copie = copie.filter((item) => item._id !== organisationsconnecte._id);
      copie.unshift(organisationsconnecte);
      setlistNewOrganisation(copie);
    }
  }, [organisationsconnecte, listOrganisations]);

  const stockGlobale = useAppSelector(ArticleStockGlobale);
  const [spinner, setspinner] = useState(false);

  useEffect(() => {
    if (article?._id) dispatch(findFreresStocksByArticleIdApi(article._id));
  }, [dispatch, article]);

  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
      dispatch(allOrganisationsApi(societe));
    }
  }, []);

  useEffect(() => {
    if (stockGlobale) {
      let nouveauTableau = stockGlobale
        ? stockGlobale.map((element: any) =>
            element ? element["article_id"] : null
          )
        : [];
      function removeDuplicates(objects: any[]) {
        let seen = new Set();
        return objects.filter((obj) => {
          if (seen.has(obj?._id)) {
            return false;
          }
          seen.add(obj?._id);
          return true;
        });
      }
    }
  }, [stockGlobale]);

  useEffect(() => {
    if (stockGlobale) {
      setspinner(false);
    }
  }, [stockGlobale]);

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => {
          setSelectedArticles(null);
          setShowModal(false);
        }}
        className="modal-1"
        dialogClassName="modal-90w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              {t("Stock globale de ")}
              {article?.titre.fr}
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={t(
                    "Consultez le stock des dépôts des articles défectueux"
                  )}
                  checked={article_defectueux}
                  onChange={handleSwitchChange}
                />
              </Form>
            </div>
            <div className="list">
              <div className="row layout-spacing">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <th>{t("Organisation")}</th>
                      <th></th>
                      <th
                        style={{
                          borderRight: "1px solid #eff1f1",
                        }}
                      ></th>

                      {listNewOrganisation &&
                        listNewOrganisation?.map((item, index) => (
                          <div key={index}>
                            <th
                              className="text-center "
                              style={{
                                fontSize: "17px",
                                backgroundColor: generateUniqueColor(index),
                              }}
                              colSpan={
                                article_defectueux
                                  ? listDepots[item._id]?.length
                                  : listDepots[item._id]?.length - 1
                              }
                            >
                              {
                                listNewOrganisation?.find(
                                  (org) => org._id === item._id
                                )?.nom
                              }
                            </th>

                            <th
                              style={{
                                borderRight: "1px solid #eff1f1",
                              }}
                            ></th>
                          </div>
                        ))}
                      <th style={{ fontSize: "15px" }}>{t("societe")}</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <strong>{t("Article")}</strong>
                        </td>

                        <td
                          style={{
                            borderRight: "1px solid #eff1f1",
                          }}
                        >
                          <strong>{t("Prix")}</strong>
                        </td>

                        {listNewOrganisation?.map((item, index) => (
                          <div key={index}>
                            {listDepots[item._id] ? (
                              listDepots[item._id]?.map((dep: any, i) =>
                                !dep.article_defectueux ? (
                                  <td
                                    style={{
                                      fontSize: "17px",
                                      backgroundColor:
                                        generateUniqueColor(index),
                                    }}
                                    key={dep._id}
                                  >
                                    {dep.libelle}
                                  </td>
                                ) : (
                                  article_defectueux && (
                                    <td
                                      style={{
                                        fontSize: "17px",
                                        backgroundColor:
                                          generateUniqueColor(index),
                                      }}
                                      key={dep._id}
                                    >
                                      {dep.libelle}
                                    </td>
                                  )
                                )
                              )
                            ) : (
                              <td></td>
                            )}
                            <td
                              style={{
                                borderRight: "1px solid #eff1f1",
                              }}
                            ></td>
                          </div>
                        ))}
                      </tr>
                      {spinner ? (
                        <tr>
                          {" "}
                          <Spinner animation="border" />
                        </tr>
                      ) : (
                        stockGlobale !== null &&
                        stockGlobale.map((itemdata: any, dataIndex: any) => (
                          <tr key={dataIndex}>
                            <td>
                              {itemdata?.article?.image &&
                              itemdata?.article?.image !== null &&
                              itemdata?.article?.image?.length !== 0 ? (
                                <>
                                  <Zoom>
                                    <img
                                      alt=""
                                      src={
                                        itemdata?.article.image &&
                                        itemdata?.article.image
                                          ? process.env
                                              .REACT_APP_API_PUBLIC_URL +
                                            "article/" +
                                            itemdata?.article.image
                                          : "/assets/assets/img/no-image.jpg"
                                      }
                                      width="100"
                                    />
                                  </Zoom>
                                </>
                              ) : (
                                <>
                                  <img
                                    src="assets/assets/img/no-image.jpg"
                                    className="card-img-top"
                                    alt="..."
                                    style={{ width: "50px", height: "30px" }}
                                  />
                                </>
                              )}
                            </td>
                            <td>
                              {article?._id === itemdata?.article?._id ? (
                                <p style={{ fontSize: "15px", width: "150px" }}>
                                  <mark>{itemdata?.article?.titre?.fr}</mark>
                                </p>
                              ) : (
                                <p style={{ fontSize: "15px", width: "150px" }}>
                                  {itemdata?.article?.titre?.fr}
                                </p>
                              )}
                            </td>
                            <td
                              style={{
                                fontSize: "15px",
                                borderRight: "1px solid #eff1f1",
                              }}
                            >
                              {itemdata?.article?.prix_vente_ttc.toFixed(3)}
                            </td>
                            {listNewOrganisation?.map((itemdepo, indexOrg) => (
                              <React.Fragment key={indexOrg}>
                                {listDepots[itemdepo._id] ? (
                                  listDepots[itemdepo._id]?.map(
                                    (dep: any, index: any) =>
                                      !dep.article_defectueux ? (
                                        <React.Fragment key={indexOrg}>
                                          <td
                                            style={{
                                              fontSize: "17px",
                                              backgroundColor:
                                                generateUniqueColor(indexOrg),
                                            }}
                                          >
                                            <>
                                              {(() => {
                                                const stockData =
                                                  itemdata?.stock[
                                                    itemdepo._id
                                                  ]?.find(
                                                    (e: any) =>
                                                      e.depots_id._id ===
                                                      dep._id
                                                  );
                                                const stockValue = stockData
                                                  ? stockData.stock
                                                  : "0";

                                                return (
                                                  <>
                                                    {article?._id ===
                                                    itemdata?.article?._id ? (
                                                      <mark>{stockValue}</mark>
                                                    ) : (
                                                      <p>{stockValue}</p>
                                                    )}
                                                  </>
                                                );
                                              })()}
                                            </>
                                          </td>
                                        </React.Fragment>
                                      ) : (
                                        article_defectueux && (
                                          <React.Fragment key={index}>
                                            <td
                                              style={{
                                                fontSize: "17px",
                                                backgroundColor:
                                                  generateUniqueColor(indexOrg),
                                              }}
                                            >
                                              <>
                                                {(() => {
                                                  const stockData =
                                                    itemdata?.stock[
                                                      itemdepo._id
                                                    ]?.find(
                                                      (e: any) =>
                                                        e.depots_id._id ===
                                                        dep._id
                                                    );
                                                  const stockValue = stockData
                                                    ? stockData.stock
                                                    : "0";

                                                  return (
                                                    <>
                                                      {article?._id ===
                                                      itemdata?.article?._id ? (
                                                        <mark>
                                                          {stockValue}
                                                        </mark>
                                                      ) : (
                                                        <p>{stockValue}</p>
                                                      )}
                                                    </>
                                                  );
                                                })()}
                                              </>
                                            </td>
                                          </React.Fragment>
                                        )
                                      )
                                  )
                                ) : (
                                  <td></td>
                                )}
                                <td
                                  style={{
                                    borderRight: "1px solid #eff1f1",
                                  }}
                                ></td>
                              </React.Fragment>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StockGlobale;
