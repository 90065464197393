import React, { useEffect, useState } from "react";
import VerifAdmin from "../../Components/VerifUserCode/VerifAdmin";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  getOrderByIdApi,
  order,
  updateOrderApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import Zoom from "react-medium-image-zoom";
import Swal from "sweetalert2";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { useTranslation } from "react-i18next";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";
import { selectIsLoggedIn, selectUserId, societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { findUserByIdApi, findUsersByCodeApi } from "../../_App/Redux/Slices/users/userSlice";

export default function Verificationdemande() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const [Admin, setAdmin] = useState<any>();
  const [confirm, setConfirmer] = useState(false);
  const [showModalAdmin, setshowModalAdmin] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [num, setNum] = useState("");
  const [items, setItems] = useState<any[]>([]);
  const user_id=useAppSelector(selectUserId)
  const { orderid } = useParams();
  const commande = useAppSelector(order);

  useEffect(() => {
    if (isLoggedIn) {

       setConfirmer(true)
       
    } else {
      setAdmin(undefined);
      setshowModalAdmin(true);
    }
  }, [commande,isLoggedIn]);

  useEffect(() => {
    if (orderid) {
      dispatch(getOrderByIdApi(orderid));
    }
  }, [orderid]);

  useEffect(() => {
    setNum(commande?.user_id?.mobile);
  }, [commande]);
  useEffect(() => {
    if (confirm && commande) {
      if (commande.status !== "demande") {
        setIsBlocked(true); // Block the page and show a message
        Swal.fire({
          title: "Demande  deja traitée ",
          text: `Status de commande est ${commande.status}`,
        });
      }
    }
  }, [orderid,commande]);
  const handleValideOrder = async (etat: string) => {
    if (commande && commande._id) {
      if (commande.status === "demande") {
        const copiecommande = { ...commande };
        const listmap = copiecommande.items.map((element: any) => {
          const find = items.find((e) => e._id === element.article._id);
          if (find) {
            return {
              ...element,
              remisepropsition: element.remise,
              remise: parseInt(find.remise),
            };
          } else {
            return {
              ...element,
              remisepropsition: element.remise,
            };
          }
        });
        const data = {
          orderId: commande._id,
          status: etat,
          items: listmap,
          admin:isLoggedIn ? user_id : Admin?._id,
        };

        const respupdate = await dispatch(updateOrderApi(data));
        if (respupdate.payload.success) {
          ToastSuccess("Status a été changé avec succès");
          window.location.reload();
          const phoneNumber = commande?.user_id?.mobile?.replace(/\s+/g, "");

          const message = `La demande de la commande numéro ${
            commande?.order_number
          } était ${etat === "demande Valide" ? "confirmée" : "refusée"}.`;

          const encodedMessage = encodeURIComponent(message);

          const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

          window.location.href = whatsappURL;
        } else {
          ToastWarning(respupdate?.payload?.message);
        }
      } else {
        setIsBlocked(true);
      }
    }
  };

  const changeRemise = (id: string, value: string) => {
    const index = items.findIndex((element: any) => element._id === id);
    const copie = [...items];
    if (index !== -1) {
      copie[index].remise = value;
    } else {
      copie.push({
        _id: id,
        remise: value,
      });
    }
    setItems(copie);
  };
  return (
    <div>
      <ToastContainer />
      {!confirm || isBlocked ? (
        <div></div>
      ) : (
        <div className="container mt-4">
          <h3>
            {t("Demande de ")}
            {commande?.user_id.nom + " " + commande?.user_id.prenom}{" "}
          </h3>
          {t("Numero  ")}{" "}
          <input
            type="text"
            onKeyPress={handleKeyPress_number}
            className="form-control"
            value={num}
            onChange={(e) => setNum(e.target.value)}
          />
          <h3>
            {t("Client")}{" "}
            {commande?.client_id?.defaultClient
              ? commande?.client_id.nom
              : commande?.client_id.nom + " " + commande?.client_id.prenom}
          </h3>
          {commande?.items?.length !== 0 &&
            commande?.items?.map((item: any, index: any) => (
              <div
                className="row mb-4 p-3 rounded shadow-sm bg-light align-items-center"
                key={index}
              >
                {/* Image Section */}
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <Zoom>
                    <img
                      alt={item?.article?.titre?.fr || "Item Image"}
                      src={
                        item?.article?.image && item?.article?.image[0]
                          ? `${process.env.REACT_APP_API_PUBLIC_URL}article/${item?.article?.image[0]}`
                          : "/assets/assets/img/no-image.jpg"
                      }
                      className="img-fluid rounded-circle shadow"
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </Zoom>
                </div>

                {/* Title Section */}
                <div className="col-8 d-flex flex-column justify-content-center">
                  <p
                    className="user-name text-truncate"
                    style={{ fontWeight: "600", fontSize: "1.2rem" }}
                  >
                    {item?.article?.titre?.fr || "No title"}
                  </p>
                </div>

                {/* Price Section */}
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <p className="mb-0">
                    {" "}
                    prix initiale{" "}
                    {item?.article?.prix_vente_ttc.toFixed(3) || "N/A"}
                  </p>
                </div>

                {/* Discount Section */}
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <p className="mb-0">
                    Remise: {item?.remiseprop || item.remise}%
                  </p>
                </div>
                <div className="col-3 d-flex justify-content-center align-items-center">
                  <p className="mb-0">
                    Remise validation:{" "}
                    <input
                      type="number"
                      value={
                        items.find((i) => i._id === item.article._id)?.item
                          ?.remise
                      }
                      onChange={(e) =>
                        changeRemise(item.article._id, e.target.value)
                      }
                      className="form-control"
                    />
                  </p>
                </div>

                {/* Net Price Section */}
                <div className="col-4 d-flex justify-content-center align-items-center">
                  <p className="mb-0">
                    {" "}
                    Net à payer: {item?.prixNet.toFixed(3)}
                  </p>
                </div>
              </div>
            ))}
          {/* Action Buttons */}
          <div className="d-flex justify-content-end mt-3">
            <button
              onClick={() => handleValideOrder("demande Valide")}
              className="btn btn-success mx-2"
            >
              {t("Confirmer")}
            </button>
            <button
              onClick={() => handleValideOrder("demande Invalide")}
              className="btn btn-danger mx-2"
            >
              {t("Refuser")}
            </button>
          </div>
        </div>
      )}

      <VerifAdmin
        ShowModal={showModalAdmin}
        setShowModal={setshowModalAdmin}
        societe={null}
        user={Admin}
        setuser={setAdmin}
        setConfirmer={setConfirmer}
      />
    </div>
  );
}
