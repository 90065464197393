import React from "react";
import { updateRetourEchange } from "../../_App/Redux/Slices/Orders/OrderSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { selectUserId } from "../../_App/Redux/Slices/Auth/authSlice";
import Swal from "sweetalert2";
import { Card, Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";

export default function DemandeRetourIncident({
  showModal,
  setshowModal,
  commande,
  t,
}: any) {
  const dispatch = useAppDispatch();
  const user_id = useAppSelector(selectUserId);

  const handleUpdate = async (confirmed: boolean) => {
    try {
      const res = await dispatch(
        updateRetourEchange({
          id: commande.Objet._id,
          data: { confirmed: confirmed, user: user_id },
        })
      );

      if (res.payload.success) {
        Swal.fire({
          title: "Mise à jour réussie !",
          icon: "success",
        });
        window.location.reload()
      } else {
        throw new Error("La mise à jour a échoué.");
      }
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Une erreur inattendue s'est produite.",
        icon: "error",
      });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setshowModal(false);
      }}
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <Modal.Header>
        {t("Numero vente")}
        {commande?.order_id?.order_number}
      </Modal.Header>
      <Modal.Body>
        {commande?.user_confirm_id ? (
          <div className="container center-text">
            <h3>
              {t("Demande deja traité Par ")}{" "}
              {commande?.user_confirm_id?.nom ||
                "" + "" + commande?.user_confirm_id?.prenom ||
                ""}{" "}
            </h3>
            <h3>{t(`Status de vente est ${commande?.order_id?.status}`)}</h3>
          </div>
        ) : (
          <div className="container mt-4">
            <h3>{t("Demande d'annulation une vente")}</h3>
            <h3>
              {t("Vendeur : ")}
              {commande?.order_id?.user_id.nom +
                " " +
                commande?.order_id?.user_id.prenom}{" "}
            </h3>

            <h3>
              {t("Client")}{" "}
              {commande?.order_id?.client_id?.defaultClient
                ? commande?.order_id?.client_id.nom
                : commande?.order_id?.client_id.nom ||
                  "" + " " + commande?.order_id?.client_id.prenom ||
                  ""}
            </h3>
            {commande?.Objet?.itemsToReturn?.length !== 0 &&
              commande?.Objet?.itemsToReturn?.map((item: any, index: any) => (
                <div
                  className="row mb-4 p-3 rounded shadow-sm bg-light align-items-center"
                  key={index}
                >
                  {/* Image Section */}
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <Zoom>
                      <img
                        alt={item?.article?.titre?.fr || "Item Image"}
                        src={
                          item?.article?.image && item?.article?.image[0]
                            ? `${process.env.REACT_APP_API_PUBLIC_URL}article/${item?.article?.image[0]}`
                            : "/assets/assets/img/no-image.jpg"
                        }
                        className="img-fluid rounded-circle shadow"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </Zoom>
                  </div>

                  {/* Title Section */}
                  <div className="col-8 d-flex flex-column justify-content-center">
                    <p style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                      {item?.article?.titre?.fr || "No title"}
                    </p>
                  </div>

                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      {" "}
                      {t("Qte achetée")} {item?.quantity}
                    </p>
                  </div>

                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      {" "}
                      {t("Qte à retourner")} {item?.Qteretour}
                    </p>
                  </div>
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      {" "}
                      {t("Prix unitaire")} {item?.prix_unitaire}
                    </p>
                  </div>

                  {/* Net Price Section */}
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      {" "}
                      {t("Note")} {item?.note}
                    </p>
                  </div>
                </div>
              ))}
            {/* Action Buttons */}
            <div className="d-flex justify-content-end  row">
              <button
                className="btn btn-danger ml-1 col-5"
                onClick={() => handleUpdate(false)}
              >
                {t("Refusé")}
              </button>

              <button
                className="btn btn-success col-5"
                onClick={() => handleUpdate(true)}
              >
                {t("Confirmer")}
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
