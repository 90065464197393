import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Panier,
  Reduction,
  resetReduction,
} from "../../_App/Redux/Slices/Caisse/PanierSlice";
import Swal from "sweetalert2";
import ReductionModal from "../../Components/Caisses/Reduction/ReductionModal";
import { DepotDefautOrganisation } from "../../_App/Redux/Slices/depot/depotSlice";
import {
  order,
  selectedOrder,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import { useLocation } from "react-router-dom";
import { parametrageCaisse } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { ActiveMenu } from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";

const TopTotalDisplay = () => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(Panier);
  const openedOrder = useAppSelector(order);
  const reduction = useAppSelector(Reduction);
  const [showModal, setShowModal] = useState(false);
  const currentDepot = useAppSelector(DepotDefautOrganisation);
  const retrievedOrder = useAppSelector(order);
  const orderselected = useAppSelector(selectedOrder);
  const parametrage = useAppSelector(parametrageCaisse);
  const Menu = useAppSelector(ActiveMenu);

  const location = useLocation();

  const currentPath = location.pathname;

  const handleResetReduction = async () => {
    Swal.fire({
      title: "Voulez vous vraiment annuler la reduction ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(resetReduction());
      }
    });
  };

  return (
    <div
      className={
        parametrage && parametrage.barcodeOnly ? "fixed-col-right" : "col-right"
      }
      style={{
        zIndex: "auto",
        overflowY: "hidden",
        position: "fixed",
        left:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "left"
              ? "150px"
              : "0px"
            : Menu?.menuPosition.position === "left"
            ? "unset"
            : "unset",
        right:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "right"
              ? "740px"
              : "610px"
            : Menu?.menuPosition.position === "right"
            ? "145px"
            : 0,
        top:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "up"
              ? "130px"
              : 0
            : Menu?.menuPosition.position === "up"
            ? "130px"
            : 0,
        bottom: 0,

        // height: Menu?.menuPosition.position === "down" ? "20%" : "unset",
        width: "unset",
      }}
    >
      <div className={"col-right-content"} style={{ padding: "0 0" }}>
        <div
          className="col-right-content-container"
          style={{ height: "100%", display: "flex", justifyContent: "center" }}
        >
          <div className="activity-section">
            <div className="total">
              <h1
                style={{ color: " #39FF14" }}
                onClick={() => console.log(Menu?.menuPosition.position)}
              >
                {" "}
                Total : {cart.totalNet?.toFixed(3)}{" "}
              </h1>
              {reduction !== 0 ? (
                <div
                  className="badge btn-danger"
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: "81%",
                    width: "186px",
                    height: "47px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                  }}
                >
                  {" "}
                  <i className="fas fa-tags mr-2"></i>
                  {"- " + reduction.toFixed(3)}{" "}
                  <i
                    className="fas fa-times-circle ml-2"
                    onClick={() => handleResetReduction()}
                  ></i>
                </div>
              ) : null}
              <ReductionModal
                ShowModal={showModal}
                setShowModal={setShowModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopTotalDisplay;
