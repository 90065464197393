import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Filtre from "./Filtre";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { Form, Spinner } from "react-bootstrap";
import {
  findStockBySocieteApi,
  stockfilterApi,
} from "../../_App/Redux/Slices/article/articleSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import { findDepotsBySocieteApi } from "../../_App/Redux/Slices/depot/depotSlice";

export default function StockTableArticle({
  t,
  listDepots,
  stock,
  stock_Organisation,
  index,
  setindex,
  liststock,
  handleSearch,
  setkeyword,
  keyword,
  spinner,
  setspinner,
  setdatafilter,
  dispatch,
  societe,
}: any) {
  const [Filtredlist_article, setFiltredlist_article] = useState<any[]>([]);
  const [list_article, setlist_article] = useState<any[]>([]);
  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [intervaleRef, setintervaleRef] = useState<any>();
  const [intervalePrix, setintervalePrix] = useState<any>();
  const [showtotalStock, setShowtotalStock] = useState(false);
  const [showDepots, setshowDepots] = useState(false);
  const [OrgDepots, setOrgDepots] = useState<null | string>(null);

  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  interface SelectionsState {
    [key: string]: any[];
  }
  const [selections, setSelections] = useState<SelectionsState>({});
  const list_organisation = useAppSelector(ListOrganisations);
  const [article_defectueux, setarticle_defectueux] = useState<boolean>(false);
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setarticle_defectueux(event.target.checked);
  };
  useEffect(() => {
    if (stock) {
      let nouveauTableau = stock
        ? stock.map((element: any) => (element ? element["article_id"] : null))
        : [];
      function removeDuplicates(objects: any[]) {
        let seen = new Set();
        return objects.filter((obj) => {
          if (seen.has(obj?._id)) {
            return false;
          }
          seen.add(obj?._id);
          return true;
        });
      }

      // Appeler la fonction pour supprimer les doublons
      if (nouveauTableau.length !== 0) {
        let objectsWithoutDuplicates = removeDuplicates(nouveauTableau);

        setFiltredlist_article(objectsWithoutDuplicates);
        setlist_article(objectsWithoutDuplicates);
      }
    }
  }, [stock]);

  const searchfiltre = () => {
    setspinner(true);
    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const data = {
      carte: selectedCarte,
      categories: selectedCategorie,
      sousCategories: selectedSousCategorie,
      carateristique: carateristiqueOptions,
      motcle: keyword,
      index: 1,
      ref1: intervaleRef?.ref1,
      ref2: intervaleRef?.ref2,
      prix1: intervalePrix?.prix1,
      prix2: intervalePrix?.prix2,
    };
    setindex(1);

    if (
      data?.sousCategories?.length !== 0 ||
      data?.categories?.length !== 0 ||
      (data.ref1 && data.ref2) ||
      (data.prix1 && data.prix2) ||
      data?.carateristique?.length !== 0 ||
      keyword !== ""
    ) {
      setdatafilter(data);
      dispatch(stockfilterApi(data));
    } else {
      if (societe) {
        setspinner(true);

        const data = { societe: societe, index: index };

        dispatch(findStockBySocieteApi(data));

        dispatch(findDepotsBySocieteApi(societe));
      }
    }
  };

  const TestStockArticle = (data: any): number => {
    const totalStockPerOrg: Record<string, number> = {};
    const stockData = data.stock;

    for (const orgId in stockData) {
      totalStockPerOrg[orgId] = stockData[orgId].reduce(
        (sum: number, item: any) => sum + (item.stock || 0),
        0
      );
    }

    const totalStock = Object.values(totalStockPerOrg).reduce(
      (sum: number, value: number) => sum + value,
      0
    );

    return totalStock;
  };

  const TestStockOrganisation = (orgId: any) => {
    let totalOrg = 0;
    stock.forEach((ligne: any) => {
      totalOrg +=
        ligne.stock[orgId]?.reduce(
          (sum: any, item: any) => sum + item.stock,
          0
        ) || 0;
    });
    return totalOrg;
  };
  return (
    <div>
      <div className="row layout-spacing">
        <Filtre
          spinner={spinner}
          handleSearch={handleSearch}
          setkeyword={setkeyword}
          t={t}
          setspinner={setspinner}
          setdatafilter={setdatafilter}
          setindex={setindex}
          searchfiltre={searchfiltre}
          selectedCarte={selectedCarte}
          setSelectedCarte={setSelectedCarte}
          selectedCategorie={selectedCategorie}
          setSelectedCategorie={setSelectedCategorie}
          selectedSousCategorie={selectedSousCategorie}
          setSelectedSousCategorie={setSelectedSousCategorie}
          categoriesOptions={categoriesOptions}
          setcategoriesdOptions={setcategoriesdOptions}
          souscategoriesOptions={souscategoriesOptions}
          setsouscategoriesdOptions={setsouscategoriesdOptions}
          selections={selections}
          setintervaleRef={setintervaleRef}
          intervaleRef={intervaleRef}
          setintervalePrix={setintervalePrix}
          intervalePrix={intervalePrix}
          setSelections={setSelections}
        />
      </div>

      <div className="row layout-spacing  bg-white  ">
        {showDepots && (
          <div className="col-lg-6   bg-white  ">
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label={t(
                  "Consultez le stock des dépôts des articles défectueux"
                )}
                checked={article_defectueux}
                onChange={handleSwitchChange}
              />
            </Form>
          </div>
        )}
        <div className="col-lg-6   bg-white  ">
          <Form>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={t("Consultez le stock 0")}
              checked={showtotalStock}
              onChange={() => setShowtotalStock(!showtotalStock)}
            />
          </Form>
        </div>
        <div className="col-lg-12    ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th></th>
                <th></th>
                <th
                  style={{
                    borderRight: "1px solid black",
                  }}
                ></th>

                {list_organisation &&
                  list_organisation?.map((item, index) => (
                    <>
                      {(showtotalStock ||
                        TestStockOrganisation(item._id) > 0) && (
                        <>
                          <th
                            className="text-center lien-bleu-souligne "
                            style={{ borderRight: "1px solid black" }}
                            colSpan={
                              showDepots && OrgDepots === item._id
                                ? article_defectueux
                                  ? listDepots[item._id]?.length
                                  : listDepots[item._id]?.length - 1
                                : 1
                            }
                            onClick={() => {
                              setOrgDepots(
                                OrgDepots === null ? item._id : null
                              );
                              setshowDepots(!showDepots);
                            }}
                          >
                            {
                              list_organisation?.find(
                                (org) => org._id === item._id
                              )?.nom
                            }{" "}
                          </th>
                        </>
                      )}
                    </>
                  ))}
                {(showtotalStock || TestStockOrganisation("null") > 0) && (
                  <th
                    colSpan={
                      showDepots && OrgDepots === "null"
                        ? listDepots["null"]?.length
                        : 1
                    }
                    onClick={() => {
                      setOrgDepots(OrgDepots === null ? "null" : null);
                      setshowDepots(!showDepots);
                    }}
                    className="lien-bleu-souligne"
                    style={{ borderRight: "1px solid black" }}
                  >
                    {t("societe")}
                  </th>
                )}
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: "16px",
                    }}
                    colSpan={2}
                  >
                    <strong>{t("Article")}</strong>
                  </td>
                  <td
                    style={{
                      borderRight: "1px solid black",
                      fontSize: "16px",
                    }}
                  >
                    <strong>{t("Prix")}</strong>
                  </td>
                  {list_organisation.map(
                    (item: any, index) =>
                      (showtotalStock ||
                        TestStockOrganisation(item._id) > 0) && (
                        <>
                          {showDepots && OrgDepots === item._id ? (
                            <>
                              {listDepots[item._id] ? (
                                listDepots[item._id]?.map((dep: any) =>
                                  !dep.article_defectueux ? (
                                    <>
                                      <td
                                        style={{
                                          borderRight: "1px solid black",
                                          fontSize: "17px",
                                        }}
                                        key={dep._id}
                                      >
                                        {dep.libelle}
                                      </td>
                                    </>
                                  ) : (
                                    article_defectueux && (
                                      <>
                                        <td
                                          style={{
                                            fontSize: "17px",
                                            borderRight: "1px solid black",
                                          }}
                                          key={dep._id}
                                        >
                                          {t("P.Def")}
                                        </td>
                                      </>
                                    )
                                  )
                                )
                              ) : (
                                <td></td>
                              )}
                            </>
                          ) : (
                            <>
                              <td
                                style={{
                                  borderRight: "1px solid black",
                                }}
                              ></td>
                            </>
                          )}
                        </>
                      )
                  )}
                  <>
                    {(showtotalStock || TestStockOrganisation("null") > 0) && (
                      <>
                        {showDepots && OrgDepots === "null" ? (
                          <>
                            {listDepots["null"] ? (
                              listDepots["null"]?.map((dep: any) =>
                                !dep.article_defectueux ? (
                                  <>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        fontSize: "17px",
                                      }}
                                      key={dep._id}
                                    >
                                      {dep.libelle}
                                    </td>
                                  </>
                                ) : (
                                  article_defectueux && (
                                    <>
                                      <td
                                        style={{
                                          fontSize: "17px",
                                          borderRight: "1px solid black",
                                        }}
                                        key={dep._id}
                                      >
                                        {t("P.Def")}
                                      </td>
                                    </>
                                  )
                                )
                              )
                            ) : (
                              <td></td>
                            )}
                          </>
                        ) : (
                          <>
                            <td
                              style={{
                                borderRight: "1px solid black",
                              }}
                            ></td>
                          </>
                        )}
                      </>
                    )}
                  </>
                </tr>

                {stock &&
                  stock !== null &&
                  stock?.map(
                    (itemdata: any, dataIndex: any) =>
                      (showtotalStock || TestStockArticle(itemdata) > 0) && (
                        <tr key={dataIndex}>
                          <td>
                            {itemdata?.article?.image &&
                            itemdata?.article?.image !== null &&
                            itemdata?.article?.image?.length > 0 ? (
                              <>
                                <Zoom>
                                  <img
                                    alt="image"
                                    src={
                                      process.env.REACT_APP_API_PUBLIC_URL +
                                      "article/" +
                                      itemdata?.article.image
                                    }
                                    width="30"
                                    height="30"
                                  />
                                </Zoom>
                              </>
                            ) : (
                              <>
                                <img
                                  src="assets/assets/img/no-image.jpg"
                                  className="card-img-top"
                                  alt="..."
                                  style={{ width: "50px", height: "30px" }}
                                />
                              </>
                            )}
                          </td>
                          <td style={{ fontSize: "17px" }}>
                            <p style={{ width: "150px" }}>
                              {itemdata?.article?.titre?.fr}
                            </p>
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid black",
                              fontSize: "17px",
                            }}
                          >
                            {itemdata?.article?.prix_vente_ttc.toFixed(3)}
                          </td>
                          {list_organisation?.map((orgId, index) => (
                            <React.Fragment key={index}>
                              {(showtotalStock ||
                                TestStockOrganisation(orgId._id)> 0) &&
                                (showDepots && orgId._id === OrgDepots ? (
                                  <React.Fragment key={index}>
                                    {listDepots[orgId._id] ? (
                                      listDepots[orgId._id]?.map(
                                        (dep: any, index: any) => (
                                          <React.Fragment key={index}>
                                            {!dep.article_defectueux ? (
                                              <>
                                                <td
                                                  className="fontBolde "
                                                  onClick={() => {
                                                    setOrgDepots(null);
                                                    setshowDepots(!showDepots);
                                                  }}
                                                  style={{
                                                    borderRight:
                                                      "1px solid black",
                                                  }}
                                                >{}
                                                  {itemdata?.stock[
                                                    orgId._id
                                                  ]?.find(
                                                    (d: any) =>
                                                      d.depots_id._id ===
                                                      dep._id
                                                  )?.stock || 0}
                                                </td>
                                              </>
                                            ) : (
                                              article_defectueux && (
                                                <td
                                                  className="fontBolde "
                                                  style={{
                                                    borderRight:
                                                      "1px solid black",
                                                  }}
                                                  onClick={() => {
                                                    setOrgDepots(null);
                                                    setshowDepots(!showDepots);
                                                  }}
                                                >
                                                  {itemdata?.stock[
                                                    orgId._id
                                                  ]?.find(
                                                    (d: any) =>
                                                      d.depots_id.article_defectueux
                                                  )?.stock || 0}
                                                </td>
                                              )
                                            )}
                                          </React.Fragment>
                                        )
                                      )
                                    ) : (
                                      <td></td>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <>
                                    <td
                                      className="fontBolde "
                                      onClick={() => {
                                        setOrgDepots(orgId._id);
                                        setshowDepots(!showDepots);
                                      }}
                                      style={{
                                        borderRight: "1px solid black",
                                      }}
                                    >
                                      {itemdata?.stock[orgId._id]?.reduce(
                                        (acc: any, rec: any) =>
                                          acc + (rec.stock || 0),
                                        0
                                      ) || 0}
                                    </td>
                                  </>
                                ))}
                            </React.Fragment>
                          ))}
                          <React.Fragment key={index}>
                            {(showtotalStock ||
                              TestStockOrganisation("null") >0) &&
                              (showDepots && "null" === OrgDepots ? (
                                <React.Fragment key={index}>
                                  {listDepots["null"] ? (
                                    listDepots["null"]?.map(
                                      (dep: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {!dep.article_defectueux ? (
                                            <>
                                              <td
                                                className="fontBolde "
                                                onClick={() => {
                                                  setOrgDepots(null);
                                                  setshowDepots(!showDepots);
                                                }}
                                                style={{
                                                  borderRight:
                                                    "1px solid black",
                                                }}
                                              >
                                                {itemdata?.stock["null"]?.find(
                                                  (d: any) =>
                                                    d.depots_id._id === dep._id
                                                )?.stock || 0}
                                              </td>
                                            </>
                                          ) : (
                                            article_defectueux && (
                                              <td
                                                className="fontBolde "
                                                style={{
                                                  borderRight:
                                                    "1px solid black",
                                                }}
                                                onClick={() => {
                                                  setOrgDepots(null);
                                                  setshowDepots(!showDepots);
                                                }}
                                              >
                                                {itemdata?.stock["null"]?.find(
                                                  (d: any) => d.depots_id.article_defectueux
                                                )?.stock || 0}
                                              </td>
                                            )
                                          )}
                                        </React.Fragment>
                                      )
                                    )
                                  ) : (
                                    <td></td>
                                  )}
                                </React.Fragment>
                              ) : (
                                <>
                                  <td
                                    className="fontBolde "
                                    onClick={() => {
                                      setOrgDepots("null");
                                      setshowDepots(!showDepots);
                                    }}
                                    style={{
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    {itemdata?.stock["null"]?.reduce(
                                      (acc: any, rec: any) =>
                                        acc + (rec.stock || 0),
                                      0
                                    ) || 0}
                                  </td>
                                </>
                              ))}
                          </React.Fragment>
                        </tr>
                      )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
/*   <td key={key}> {/* Ensure each cell has a unique key */
//{stock.find((st: any) => (
//  st.depots_id._id === key && // Check if depots_id matches the current key
//st.article_id !== null && // Ensure article_id is not null
// st.article_id._id === item.article_id._id// Check if article_id matches the specific ID
//))?.stock || key}
//</td>*/
/*          {lists.map((item , indexl)=> (
    <tr key={indexl}> 
    <td>{item.article._id}</td>
    {list_depots.map((element:any , index:number) => (
        <td key={index}>{item[element]}</td>
    ))}
</tr>
))}*/
