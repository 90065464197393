import React from "react";
import { useAppSelector } from "../../../../_App/Redux/hooks";
import { parametrageCaisse } from "../../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import WideCart from "./WideCart";
import SideCart from "./SideCart";

const Cart = ({ setVisible, hidePaymentButton }: any) => {
  const parametrages = useAppSelector(parametrageCaisse);
  return parametrages && parametrages.barcodeOnly ? (
    <WideCart setVisible={setVisible} hidePaymentButton={hidePaymentButton} />
  ) : (
    <SideCart setVisible={setVisible} hidePaymentButton={hidePaymentButton} />
  );
};

export default Cart;
