import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import SoucheType from "../../../Types/Entites/SoucheType";

/**
 * Get souche of organisation.
 * @public
 */
export const findSouchesByOrganisationApi = createAsyncThunk(
  "souche/findSouchesByOrganisationApi",
  async (
    { selectedOrganisation, societe_id }: any,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.get(
        `souche/findSouchesByOrganisationApi/${selectedOrganisation}/${societe_id}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * update souche byid.
 * @public
 */
export const updateSoucheApi = createAsyncThunk(
  "souche/updateSoucheApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`souche/updateSoucheApi`, data);

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get souche of product.
 * @public
 */
export const findSoucheProduitApi = createAsyncThunk(
  "produit/findSoucheProduitApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `produit/findSoucheProduitApi/${societe}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get souche of paiement.
 * @public
 */
export const findPaiementSoucheApi = createAsyncThunk(
  "paiementachat/findPaiementSoucheApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `paiementachat/findPaiementSoucheApi/${societe}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get souche of paiement.
 * @public
 */
export const findSoucheArticleApi = createAsyncThunk(
  "article/findSoucheArticleApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `article/findSoucheArticleApi/${societe}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface SoucheState {
  Soucheproduit: SoucheType;
  Souchearticle: SoucheType;
  Souchepaiement: SoucheType;
  soucheList: SoucheType[];
}

const initialState: SoucheState = {
  Soucheproduit: {
    societe_id: "",
    type: "",
    souche: 0,
  },
  Souchearticle: {
    societe_id: "",
    type: "",
    souche: 0,
  },
  Souchepaiement: {
    societe_id: "",
    type: "",
    souche: 0,
  },
  soucheList: [],
};

export const soucheSlice = createSlice({
  name: "souche",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findSouchesByOrganisationApi.fulfilled, (state, action) => {
      state.soucheList = action.payload;
    });
    builder.addCase(findSoucheProduitApi.fulfilled, (state, action) => {
      state.Soucheproduit = action.payload;
    });
    builder.addCase(findPaiementSoucheApi.fulfilled, (state, action) => {
      state.Souchepaiement = action.payload;
    });
    builder.addCase(findSoucheArticleApi.fulfilled, (state, action) => {
      state.Souchearticle = action.payload;
    });

    builder.addCase(updateSoucheApi.fulfilled, (state, action) => {
      const updated_souche = action.payload.data;
      var index = _.findIndex(state.soucheList, {
        _id: updated_souche._id,
      });
      if (index !== -1) {
        state.soucheList[index] = updated_souche;
      }
      state.Souchearticle = action.payload;
    });
  },
});

export const soucheproduct = (state: RootState) => state.souches.Soucheproduit;
export const souchepaiement = (state: RootState) =>
  state.souches.Souchepaiement;
export const souchearticle = (state: RootState) => state.souches.Souchearticle;
export const soucheList = (state: RootState) => state.souches.soucheList;

export default soucheSlice.reducer;
