import React, { useEffect, useState } from "react";
import Filtre from "../../Components/Stock/Filtre";
import { useTranslation } from "react-i18next";
import NonVenduFiltre from "../../Components/Ventes/NonVendu/NonVenduFiltre";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  findDepotsBySocieteApi,
  ListDepotByOrganisation,
  ListDepots,
} from "../../_App/Redux/Slices/depot/depotSlice";
import { findFourinsseurBySocieteApi } from "../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import { findCarteBySocieteApi } from "../../_App/Redux/Slices/cartes/carteSlice";
import { findCategorieBySocieteApi } from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  allOrganisationsApi,
  ListOrganisations,
} from "../../_App/Redux/Slices/organisations/organisationSlice";
import {
  currentpage,
  findArticleNonVenduApi,
  ListArticles,
  totalPages,
} from "../../_App/Redux/Slices/article/articleSlice";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import NonVenduList from "../../Components/Ventes/NonVendu/NonVenduList";

export default function NonVendu() {
  const dispatch = useAppDispatch();
  const [spinner, setspinner] = useState(false);
  const [keyword, setkeyword] = useState("");
  const societeid = useAppSelector(societeId);
  const listDepots = useAppSelector(ListDepotByOrganisation);
  const organisation = useAppSelector(ListOrganisations);
  const organisationid = useAppSelector(SelectedOrganisationId);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const articles = useAppSelector(ListArticles);

  const [selectedCarte, setSelectedCarte] = useState<any[]>([]);
  const [selectedCategorie, setSelectedCategorie] = useState<any[]>([]);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [categoriesOptions, setcategoriesdOptions] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
   const [intervaleRef, setintervaleRef] = useState<any>();
    const [intervalePrix, setintervalePrix] = useState<any>();
  
  const [selections, setSelections] = useState<SelectionsState>({});

  interface SelectionsState {
    [key: string]: any[];
  }

  const [index, setindex] = useState(!current_page ? 1 : current_page);
  const { t } = useTranslation();
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  today.setDate(today.getDate() + 1);
  const [FiltreData, setFiltreData] = useState({
    date_debut: formatDate(lastMonth),
    date_fin: formatDate(today),
    organisation: null as string | null,
    depot: null as string | null,
  });

  useEffect(() => {
    if (organisationid)
      setFiltreData({ ...FiltreData, organisation: organisationid });
  }, [organisationid]);

  useEffect(() => {
    if (FiltreData.organisation !== null && listDepots) {
      if (listDepots[FiltreData.organisation])
        setFiltreData({
          ...FiltreData,
          depot:
            listDepots[FiltreData.organisation].find((item) => item.defaut)
              ?._id || null,
        });
    }
  }, [FiltreData.organisation, listDepots]);

  useEffect(() => {
    if (societeid) {
      dispatch(allOrganisationsApi(societeid));

      dispatch(findDepotsBySocieteApi(societeid));
    }
  }, [societeid]);

  const findArticles = async () => {
    const carateristiqueOptions: any[] = [];

    for (const key in selections) {
      if (Array.isArray(selections[key])) {
        selections[key].forEach((obj) => {
          carateristiqueOptions.push(obj.value);
        });
      }
    }
    const copie = { ...FiltreData, index: index ,ref1:intervaleRef?.ref1 ,ref2:intervaleRef?.ref2 , prix1:intervalePrix?.prix1 , prix2:intervalePrix?.prix2,categories:selectedCategorie ,souscategorie:selectedSousCategorie,carte:selectedCarte,carateristique:carateristiqueOptions};
    await dispatch(findArticleNonVenduApi(copie));
  };

  useEffect(() => {
    
    const copie = { ...FiltreData, index: index };
    dispatch(findArticleNonVenduApi(copie));
  }, [index]);
  return (
    <div>
      <NonVenduFiltre
        t={t}
        FiltreData={FiltreData}
        setFiltreData={setFiltreData}
        listorganisation={organisation}
        listDepots={listDepots}
        find={findArticles}
        selectedCarte={selectedCarte}
        setSelectedCarte={setSelectedCarte}
        selectedCategorie={selectedCategorie}
        setSelectedCategorie={setSelectedCategorie}
        selectedSousCategorie={selectedSousCategorie}
        setSelectedSousCategorie={setSelectedSousCategorie}
        categoriesOptions={categoriesOptions}
        setcategoriesdOptions={setcategoriesdOptions}
        souscategoriesOptions={souscategoriesOptions}
        setsouscategoriesdOptions={setsouscategoriesdOptions}
        selections={selections}
        setSelections={setSelections}
        setintervaleRef={setintervaleRef}
        intervaleRef={intervaleRef}
        setintervalePrix={setintervalePrix}
        intervalePrix={intervalePrix}
      />
      <NonVenduList listArticle={articles} t={t} />
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
    </div>
  );
}
