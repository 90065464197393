import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListJournalMouvementsCaisse,
  findMouvementsCaisseByJournalApi,
  listMouvementsCaisseCommandeBySession,
  listMouvementsCaisseParSession,
} from "../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import {
  SelectedOrganisationId,
  selectUser,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useParams } from "react-router-dom";
import {
  Journal,
  getJournalCaisseByIdApi,
  updateJournal,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { formadate } from "../../_App/Helpers/helpers";
import NavbarCaisse from "../../_Layouts/Navbar/NavbarCaisse";
import NavbarDefault from "../../_Layouts/Navbar/NavbarDefault";
import Navbar from "../../_Layouts/Navbar/Navbar";
import GenerationFactureModal from "../../Components/Caisses/JournalCaisse/GenerationFactureModal";
import { MouvementCaisseType } from "../../_App/Types/Entites/MouvementCaisseType";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import Swal from "sweetalert2";
import TicketDeCaissePaid from "../../Components/Caisses/TicketDeCaisse/TicketDeCaissePaid";

const MouvementsJournal = () => {
  let { id } = useParams<{ id?: string }>(); // Explicitly define the type

  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const journal = useAppSelector(Journal);
  const { t } = useTranslation();

  const user = useAppSelector(selectUser);

  const sessionXorderMouvements = useAppSelector(
    listMouvementsCaisseCommandeBySession
  );
  const mouvementsCaisseParJournal = useAppSelector(
    ListJournalMouvementsCaisse
  );

  useEffect(() => {
    if (currentOrganisation && id) {
      dispatch(findMouvementsCaisseByJournalApi(id));
      dispatch(getJournalCaisseByIdApi(id));
    }
  }, [dispatch]);
  const parametrages = useAppSelector(parametrageCaisse);

  const CalculateRecette = (
    mouvements: MouvementCaisseType[],
    fondInitial: any
  ) => {
    if (!mouvements) return fondInitial;

    const total = mouvements.reduce((totalFond, operation) => {
      if (operation.type_mouvement_id?.sens === "c") {
        return totalFond + operation.montant;
      } else {
        return totalFond - operation.montant;
      }
    }, fondInitial);

    return Number(total).toFixed(3);
  };

  const CalculateTotal = (
    mouvements: MouvementCaisseType[],
    calculationType:
      | "vente"
      | "apport"
      | "retrait"
      | "especes"
      | "giftCard"
      | "creditCard"
      | "cheques"
      | "Points fidelité"
      | "Tickets cadeaux"
      | "Carte bancaire"
      | "bon"
  ) => {
    const total = mouvements.reduce((sum, operation) => {
      switch (calculationType) {
        case "vente":
          if (
            operation.type_mouvement_id?.sens === "c" &&
            (operation.type_mouvement_id?.libelle === "Vente"
            ||operation.type_mouvement_id?.libelle === "Annulation")
          ) {
            return sum + operation.montant;
          }

          break;

        case "apport":
          if (parametrages?.methodeCloture === "cashOnly") {
            if (
              operation.type_mouvement_id?.sens === "c" &&
              operation.payment_method === "Cash" &&
              operation.type_mouvement_id?.libelle !== "Vente"
              &&
              operation.type_mouvement_id?.libelle !== "Annulation"
            ) {
              return sum + operation.montant;
            }
          } else {
            if (
              operation.type_mouvement_id?.sens === "c" &&
              operation.type_mouvement_id?.libelle !== "Vente"
              &&operation.type_mouvement_id?.libelle !== "Annulation"
            ) {
              return sum + operation.montant;
            }
          }
          break;

        case "retrait":
          if (parametrages?.methodeCloture === "cashOnly") {
            if (
              operation.type_mouvement_id?.sens === "d" &&
              operation.payment_method === "Cash"
            ) {
              return sum + operation.montant;
            }
          } else {
            if (operation.type_mouvement_id?.sens === "d") {
              return sum + operation.montant;
            }
          }
          break;

        case "especes":
          if (
            operation.type_mouvement_id?.sens === "c" &&
            operation.payment_method === "Cash"
          ) {
            console.log(operation);
            return sum + operation.montant;
          }
          break;
        case "giftCard":
          if (
            operation.type_mouvement_id?.sens === "c" &&
            operation.payment_method === "Tickets cadeaux"
          ) {
            return sum + operation.montant;
          }
          break;
        case "creditCard":
          if (
            operation.type_mouvement_id?.sens === "c" &&
            operation.payment_method === "Carte bancaire"
          ) {
            return sum + operation.montant;
          }
          break;

        case "cheques":
          if (
            operation.type_mouvement_id?.sens === "c" &&
            operation.payment_method === "Chéque"
          ) {
            return sum + operation.montant;
          }
          break;

        case "bon":
          if (
            operation.type_mouvement_id?.sens === "c" &&
            operation.payment_method === "bon"
          ) {
            return sum + operation.montant;
          }
          break;
        default:
          break;
      }
      return sum;
    }, 0);

    return total.toFixed(3);
  };

  const [physicalCash, setPhysicalCash] = useState(0);
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();

  const handleSubmit = () => {
    console.log(
      physicalCash,
      CalculateRecette(mouvementsCaisseParJournal, journal?.font_initial) -
        (physicalCash + journal?.font_final)
    );
    if (
      CalculateRecette(mouvementsCaisseParJournal, journal?.font_initial) -
        (physicalCash + journal?.font_final) ===
      0
    ) {
      Swal.fire({
        title: "Voulez vous vraiment valider ce journal ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const ecart =
            (CalculateRecette(
              mouvementsCaisseParJournal,
              journal?.font_initial
            ) -
              (physicalCash + journal?.font_final)) *
            -1;

          const res = await dispatch(
            updateJournal({
              id: journal._id,
              data: {
                valide: true,
                Validator: user,
                validatorFoundValue: physicalCash,
                ecart: ecart.toFixed(3),
              },
            })
          );
          if (res.payload.success) {
            window.location.reload();
          }

          Swal.fire("Journal validé avec succés !", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    } else {
      const ecart =
        (CalculateRecette(mouvementsCaisseParJournal, journal?.font_initial) -
          (physicalCash + journal?.font_final)) *
        -1;

      const title = `Ecart de ${ecart > 0 ? "+" : ""}${ecart.toFixed(3)} !`;

      Swal.fire({
        title: title,
        text: "Voulez vous vraiment valider ce journal quand meme ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(
            await updateJournal({
              id: journal._id,
              data: {
                valide: true,
                Validator: user,
                validatorFoundValue: physicalCash,

                ecart: ecart.toFixed(3),
              },
            })
          );
          //   window.location.reload();

          Swal.fire("Journal validé avec succés !", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const difference = () => {
    const total = CalculateRecette(
      mouvementsCaisseParJournal,
      journal.font_initial
    );

    return Number((total - journal.font_final).toFixed(3));
  };

  useEffect(() => {
    // Calculate the difference when data is available
    const calculatedDifference = difference();
    if (parametrages && parametrages.methodeCloture === "total") {
      setPhysicalCash(0);
    } else if (!isNaN(calculatedDifference)) {
      setPhysicalCash(calculatedDifference);
    }
  }, [journal.font_final, mouvementsCaisseParJournal]);

  return (
    <>
      <Navbar />

      <div className="d-flex" style={{ width: "100%", margin: "15px 0" }}>
        <div
          className="page-header row"
          style={{ textAlign: "center", width: "100%" }}
        >
          <div className="page-title col-4">
            <h3> {"Journal  " + journal?.code_journal}</h3>
          </div>
          <div className="page-title col-4">
            <h3> {t("Date") + journal?.date}</h3>
          </div>
          <div className="page-title col-4">
            <h3>{"Fond initial " + journal?.font_initial}</h3>
          </div>
        </div>
      </div>

      <div className="row layout-spacing">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area">
              <table id="style-2" className="table style-2  table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ fontSize: "15px", width: "10%" }}
                      onClick={() =>
                        console.log(
                          CalculateRecette(
                            mouvementsCaisseParJournal,
                            journal?.font_initial
                          )
                        )
                      }
                    >
                      {t("Code opération")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Date")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Type")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Motif")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Commande")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Caissier")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("amount")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Encaissement")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Methode de paiement")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(sessionXorderMouvements).map(
                    ([key, value]) => {
                      let session: any;

                      Object.values(value).forEach((item) => {
                        session = item[0].session_id;
                      });
                      return (
                        <React.Fragment key={key}>
                          {/* <tr>
                            <td
                              className="checkbox-column"
                              colSpan={9}
                              onClick={() => {
                                console.log(session);
                              }}
                            >
                              Session {key}
                            </td>
                          </tr> */}
                          {/* Iterate over the values of the inner object */}
                          {Object.entries(value).map(
                            ([innerKey, innerValue]) => (
                              <React.Fragment key={innerKey}>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].operation_code}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {formadate(innerValue[0].date)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].type_mouvement_id?.libelle}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].commentaire
                                      ? innerValue[0].commentaire
                                      : "_"}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                    onClick={() => {
                                      setSelectedTicket(
                                        innerValue[0].order_id._id
                                      );
                                      setShowModalTicket(true);
                                    }}
                                  >
                                    {innerValue[0].order_id?.order_number}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].user_id?.nom}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].order_id?.total.toFixed(3)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {/* Add other <td> elements for additional properties */}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {/* Add other <td> elements for additional properties */}
                                  </td>
                                </tr>
                                {innerValue.map((item: any, index: any) => (
                                  <tr key={item._id}>
                                    <td style={{ fontSize: "15px" }}></td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>

                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}>
                                      {item.montant.toFixed(3)}{" "}
                                    </td>
                                    <td style={{ fontSize: "15px" }}>
                                      {item.payment_method}{" "}
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("total ventes:")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "vente")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total espéces :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "especes")}
                    </td>
                  </tr>

                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total Chèques : ")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "cheques")}
                    </td>
                  </tr>

                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total points fidelité : ")}{" "}
                    </td>

                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(
                        mouvementsCaisseParJournal,
                        "Points fidelité"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total tickets cadeaux :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "giftCard")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total Carte bancaire :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "creditCard")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total Bon :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "bon")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total apports :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "apport")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total retraits :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateTotal(mouvementsCaisseParJournal, "retrait")}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Fond d'ouverture :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {journal?.font_initial?.toFixed(3)}
                    </td>
                  </tr>

                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Total caisse :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {CalculateRecette(
                        mouvementsCaisseParJournal,
                        journal.font_initial
                      )}
                    </td>
                  </tr>
                  {journal && journal.font_final > 0 ? (
                    <tr>
                      <td
                        className="checkbox-column"
                        style={{ fontSize: "20px" }}
                        colSpan={5}
                      >
                        {t("Fond cloture :")}{" "}
                      </td>
                      <td
                        className="checkbox-column"
                        style={{ fontSize: "20px" }}
                        colSpan={4}
                      >
                        {journal?.font_final?.toFixed(3)}
                      </td>
                    </tr>
                  ) : null}
                  {journal && journal.valide ? (
                    <tr>
                      <td
                        className="checkbox-column"
                        style={{ fontSize: "20px" }}
                        colSpan={5}
                      >
                        {t("Montant trouvé :")}{" "}
                      </td>
                      <td
                        className="checkbox-column"
                        style={{ fontSize: "20px" }}
                        colSpan={4}
                      >
                        {journal?.validatorFoundValue?.toFixed(3)}
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={5}
                    >
                      {t("Ecart :")}{" "}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px" }}
                      colSpan={4}
                    >
                      {journal && journal.ecart
                        ? Number(journal.ecart.toFixed(3)) > 0
                          ? ` + ${Number(journal.ecart.toFixed(3)) || 0}`
                          : Number(journal.ecart.toFixed(3)) || 0
                        : Number(
                            (CalculateRecette(
                              mouvementsCaisseParJournal,
                              journal?.font_initial
                            ) -
                              (physicalCash + journal?.font_final)) *
                              -1
                          ) > 0
                        ? `+ ${Number(
                            (CalculateRecette(
                              mouvementsCaisseParJournal,
                              journal?.font_initial
                            ) -
                              (physicalCash + journal?.font_final)) *
                              -1
                          ).toFixed(3)}`
                        : Number(
                            (CalculateRecette(
                              mouvementsCaisseParJournal,
                              journal?.font_initial
                            ) -
                              (physicalCash + journal?.font_final)) *
                              -1
                          ).toFixed(3)}
                    </td>
                  </tr>
                  {journal && journal.valide === false ? (
                    parametrages && parametrages.methodeCloture === "total" ? (
                      <tr>
                        <td
                          className="checkbox-column"
                          style={{ fontSize: "20px", textAlign: "left" }}
                          colSpan={9}
                        >
                          <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            style={{ width: "100%" }}
                          >
                            {" "}
                            Valider
                          </button>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          className="checkbox-column"
                          style={{ fontSize: "20px" }}
                          colSpan={5}
                        >
                          <input
                            type="number"
                            className="form-control"
                            onChange={(e) =>
                              setPhysicalCash(Number(e.target.value))
                            }
                            value={
                              parametrages &&
                              parametrages.methodeCloture === "total"
                                ? journal.font_final
                                : physicalCash
                            } // Use `value` instead of `defaultValue`
                          />
                        </td>
                        <td
                          className="checkbox-column"
                          style={{ fontSize: "20px", textAlign: "left" }}
                          colSpan={4}
                        >
                          <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            {" "}
                            Valider
                          </button>
                        </td>
                      </tr>
                    )
                  ) : null}
                </tfoot>
              </table>
              <div className="row" style={{ justifyContent: "center" }}>
                <button
                  className="btn btn-success mb-22"
                  style={{
                    width: "80%",
                    background: "#009688",
                  }}
                  onClick={(e) => (window.location.href = "/facture/" + id)}
                >
                  {t("Facture complète")}
                </button>
                <button
                  className="btn btn-success mb-22"
                  style={{
                    width: "80%",
                    background: "#009688",
                  }}
                  onClick={(e) =>
                    (window.location.href = "/factureSansEspeces/" + id)
                  }
                >
                  {t("Facture - autres paiements")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TicketDeCaissePaid
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        ticket={null}
        order_id={selectedTicket}
      />
    </>
  );
};

export default MouvementsJournal;
