import React, { useRef } from 'react'
import Barcode from 'react-barcode';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';

export default function OrganisatioCodeBarre({ShowModal,setShowModal,organisation}:any) {
      const {t}=useTranslation();
  
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = () => {
      const printFrame = document.getElementById(
        "print-frame"
      ) as HTMLIFrameElement | null;
      if (printFrame) {
        printFrame.contentWindow?.print();
      }
    };
  
  return (
    <Modal
    show={ShowModal}
    onHide={() => {
      setShowModal(false);
     
    }}
    style={{ display: "flex", justifyContent: "center" }}
    size="lg"
  >
    <div ref={componentRef}>
      <div className="container-ticket" id="print-content">
        <div className="receipt_header">
          <h1 >
           {t('Magasin')}
          </h1>
        </div>

        <div className="receipt_body">
          <div className="date_time_con">
           
          </div>

          <div className="ticket-items">
            <h1 style={{ textAlign: "center" }}>
              {organisation && organisation.nom}
            </h1>
          </div>
          <div
            className="barcode"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {organisation && <Barcode value={organisation.code} />}
          </div>
        </div>
      
      </div>
    </div>
    <ReactToPrint
      trigger={() => (
        <Button
          variant="contained"
          color=""
          onClick={handlePrint}
          className="btn-info"
        >
          Print
        </Button>
      )}
      content={() => componentRef.current}
    />
    <iframe
      id="print-frame"
      title="print-frame"
      style={{ display: "none" }}
      onLoad={() => handlePrint()}
    />
  </Modal>

  )
}
