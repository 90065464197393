import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useAppDispatch } from "../_App/Redux/hooks";
import {
  getOrderByIdApi,
  updateRetourEchange,
} from "../_App/Redux/Slices/Orders/OrderSlice";
import { useParams } from "react-router-dom";
import { findAdminBySocieteApi } from "../_App/Redux/Slices/users/userSlice";
import { UserDataType } from "../_App/Types/Forms/UserDataType";
import Swal from "sweetalert2";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
const RetourEchangeConfirmationPage = () => {
  let { societe, id } = useParams();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [error, setError] = useState("");
  const [order, setOrder] = useState<any>();
  const [admin, setAdmin] = useState<UserDataType | undefined>(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchOrder = async () => {
      if (id) {
        const response = await dispatch(getOrderByIdApi(id));
        if (response.payload) setOrder(response.payload);
      }
      if (societe) {
        const res = await dispatch(findAdminBySocieteApi(societe));
        if (res.payload.success) setAdmin(res.payload.data);
      }
    };
    fetchOrder();
  }, [dispatch, id]);

  const handleCodeSubmit = () => {
    if (inputCode === admin?.my_code_pin) {
      setIsAuthorized(true);
      setError("");
    } else {
      setError("Code incorrect. Veuillez réessayer.");
    }
  };
  const handleUpdate = async (confirmed: boolean) => {
    try {
      const res = await dispatch(
        updateRetourEchange({
          id: order.retour_echanges._id,
          data: { confirmed:confirmed  ,user:admin?._id},
        })
      );

      if (res.payload.success) {
        Swal.fire({
          title: "Mise à jour réussie !",
          icon: "success",
        });
      } else {
        throw new Error("La mise à jour a échoué.");
      }
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Une erreur inattendue s'est produite.",
        icon: "error",
      });
    }
  };

  return (
    <div className="container mt-5">
      {isAuthorized ? (
        order?.retour_echanges?.confirmed ? (
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 mr-auto mt-5 text-md-left text-center">
                  <a href="index.html" className="ml-md-5">
                    <img
                      alt="image-503"
                      src="assets/img/90x90.jpg"
                      className="theme-logo"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="container-fluid error-content">
              <div className="">
                <h1 className="error-number">503</h1>
                <p className="mini-text">Ooops!</p>
                <p className="error-text">Service Unavailable!</p>
                <a href="index.html" className="btn btn-secondary mt-5">
                  Go Back
                </a>
              </div>
            </div>
          </>
        ) : (
          <Card>
            <Card.Body>
              <Card.Title>Confirmation du Retour</Card.Title>
              <Card.Text>
                Veuillez vérifier les détails ci-dessous :
                <ul>
                  <li>
                    Commande : <strong>{order?.order_number}</strong>
                  </li>
                  {!order?.client_id?.defaultClient && (
                    <li>
                      Client : <strong>{order?.client_id?.nom}</strong>
                    </li>
                  )}
                  <li>
                    Date de création : <strong>{order?.creation_date}</strong>
                  </li>
                  <li onClick={() => console.log(order)}>
                    Articles à retourner :{" "}
                    <strong>
                      {order?.retour_echanges?.itemsToReturn?.length} article(s)
                    </strong>
                  </li>
                  {order?.retour_echanges?.itemsToReturn.map(
                    (item: any, index: number) => (
                      <li>
                        <Zoom>
                          <img
                            alt=""
                            src={
                              item.article.image && item.article.image[0]
                                ? process.env.REACT_APP_API_PUBLIC_URL +
                                  "article/" +
                                  item.article.image[0]
                                : "/assets/assets/img/no-image.jpg"
                            }
                            width="100"
                          />
                        </Zoom>
                        {item.article.titre.fr}
                        <strong>
                          {order?.itemsToReturn?.length} X {item.Qteretour}
                        </strong>
                      </li>
                    )
                  )}
                </ul>
                Êtes-vous sûr de vouloir confirmer le processus de retour ?
              </Card.Text>
              <div className="d-flex justify-content-around">
                <Button variant="success" onClick={() => handleUpdate(true)}>
                  Oui
                </Button>
                <Button variant="danger" onClick={() => handleUpdate(false)}>
                  Non
                </Button>
              </div>
            </Card.Body>
          </Card>
        )
      ) : (
        <Card>
          <Card.Body>
            <Card.Title>Accès Restreint</Card.Title>
            <Card.Text>
              Cette page est protégée. Veuillez entrer le code pour accéder à la
              confirmation du retour échange.
            </Card.Text>
            <Form.Group className="mb-3" controlId="formCode">
              <Form.Label>Code d'accès</Form.Label>
              <Form.Control
                type="password"
                placeholder="Entrez le code"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
              />
            </Form.Group>
            {error && <p className="text-danger">{error}</p>}
            <Button variant="primary" onClick={handleCodeSubmit}>
              Valider
            </Button>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default RetourEchangeConfirmationPage;
