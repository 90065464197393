import React, { useEffect, useState } from "react";
import Stocklist from "../../Components/Stock/Stocklist";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListArticles,
  Liststock,
  Stocks,
  StocksbyOrganisation,
  totalPages,
  currentpage,
  findArticleApi,
  findStockBySocieteApi,
  findStockByarticleApi,
  stockfilterApi,
} from "../../_App/Redux/Slices/article/articleSlice";
import _ from "lodash";
import StockType from "../../_App/Types/Entites/StockType";
import { useTranslation } from "react-i18next";
import Navbar from "../../_Layouts/Navbar/Navbar";
import StockTable from "../../Components/Stock/StockTable";
import StockTableArticle from "../../Components/Stock/StockTableArticle";
import {
  ListDepotByOrganisation,
  ListDepots,
  findDepotsBySocieteApi,
} from "../../_App/Redux/Slices/depot/depotSlice";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { allOrganisationsApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import { Form, Modal, Spinner } from "react-bootstrap";

export default function StockPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const stockOrganisation = useAppSelector(StocksbyOrganisation);
  const liststock = useAppSelector(Liststock);
  const [stock_Organisation, setStockOrganisation] = useState<{
    [key: string]: any[];
  }>({});
  const listDepots = useAppSelector(ListDepotByOrganisation);
  const listarticle = useAppSelector(ListArticles);
  const [keyword, setkeyword] = useState("");
  const [spinner, setspinner] = useState(false);
  const current_page = useAppSelector(currentpage)
  const totalpages = useAppSelector(totalPages)
  const [index, setindex] = useState(!current_page  ? 1 : current_page);

  type datafilterType = {
    carte: any;
    categories: any;
    sousCategories: any;
    carateristique: any;
    index: number;
    ref1:any;
    ref2:any;
    prix1:any;
    prix2:any

  };
  const [datafilter, setdatafilter] = useState<datafilterType>();
  useEffect(()=>{
   if(societe) dispatch(allOrganisationsApi(societe))
  },[societe])
  useEffect(() => {

    if (datafilter&&(datafilter?.sousCategories?.length!==0 ||datafilter?.categories?.length!==0  ||(datafilter.ref1&&datafilter.ref2)||(datafilter.prix1&&datafilter.prix2)|| datafilter?.carateristique?.length!==0  ||keyword !== "")) {
    

      const copie = { ...datafilter , motcle:keyword};
      copie.index = index ;
      dispatch(stockfilterApi(copie));
    }  else {
     
      if (societe) {
       
        setspinner(true);

        const data = { societe: societe, index: index};

        dispatch(findStockBySocieteApi(data));

        dispatch(findDepotsBySocieteApi(societe));
      }
    }
  }, [ societe, index]);

  useEffect(() => { 
    if (liststock) {
      setspinner(false);
    }
  }, [liststock]);

  const handleSearch = () => {
    if (keyword === "") {
      if (societe) {
        setindex(1);
        setspinner(true);
        const data = { societe: societe, index: 1 };
        dispatch(findStockBySocieteApi(data));
        dispatch(findDepotsBySocieteApi(societe));
      }
    } else {

      setspinner(true);
      setindex(1);

      const data = { article: keyword, index: 1};
      
      dispatch(findStockByarticleApi(data));
    }
  };

  useEffect(() => {
    if (stockOrganisation) {
      const updatedStockOrganisation = { ...stockOrganisation }; // Create a copy of stockOrganisation

      for (const key in stockOrganisation) {
        let entries = stockOrganisation[key];
        const groupedBydepots = _.groupBy(entries, "depots_id._id");
        updatedStockOrganisation[key] = groupedBydepots; // Update the entry in the copied object
      }
      // Update the state with the modified stockOrganisation
      setStockOrganisation(updatedStockOrganisation);
    }
  }, [stockOrganisation]);

  return (
    <div>
      <Navbar />
      
      <StockTableArticle
        t={t}
        listDepots={listDepots}
        stock={liststock}
        stock_Organisation={stock_Organisation}
        setindex={setindex}
        index={index}
        listarticle={listarticle}
        liststock={liststock}
        setkeyword={setkeyword}
        keyword={keyword}
        handleSearch={handleSearch}
        spinner={spinner}
        setdatafilter={setdatafilter}
        setspinner={setspinner}
        dispatch={dispatch}
        societe={societe}
      />
      
      <div className="row layout-spacing">
        <PaginationLayout index={index} nbrpage={current_page}  totalpages={totalpages} setindex={setindex}  />
      </div>
    </div>
  );
}
/*
      
  <Stocklist t={t} stockOrganisation={stock_Organisation}/>

<StockTableArticle  
t={t} 
listDepots={listDepots} 
stock={selectedstocks} 
stock_Organisation={stock_Organisation}
setindex={setindex}
index={index}
 />*/
