// PanierSlice.js
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import store, { RootState } from "../../store";
import { OrderType } from "../../../Types/Entites/Orders/OrderType";
import axios from "axios";
import _ from "lodash";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import ReservationSlice from "../Reservation/ReservationSlice";
import { stat } from "fs";
import { updateUserLocally } from "../tickets/TicketSlice";
import { listPaymentOperations } from "../payments/PaymentSlice";
import { ListMouvementsCaisse } from "../mouvementCaisse/MouvementCaisseSlice";
import RetourEchangetype from "../../../Types/Entites/RetourEchangeType";

interface UpdateOrderParams {
  orderId?: string;
  status: string | null;
  paidProducts?: any;
  vendeur?: String | null;
  items?: any;
  admin?: any;
  perteRetour?: Number;
}
interface createRetourEchangeStatus {
  retourEchangeData: RetourEchangetype;
  smsInfo: any;
}

/**
 * create an order.
 * @public
 */

export const saveOrderApi = createAsyncThunk(
  "orders/saveOrderApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("orders/saveOrderApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const ajoutAvanceApi = createAsyncThunk(
  "reservation/ajoutAvance",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("reservation/ajoutAvance", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
/**
 * list  of orders by organisation.
 * @public
 */
export const findOrdersByOrganisation = createAsyncThunk(
  "orders/findOrdersByOrganisation",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findOrdersByOrganisation/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findOrdersFiltreApi = createAsyncThunk(
  "orders/findOrdersFiltreApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`orders/findOrdersFiltreApi`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findOrdersByTableApi = createAsyncThunk(
  "orders/findOrdersByTableApi",
  async (idTable: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findOrdersByTableApi/${idTable}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findOrdersByJournalApi = createAsyncThunk(
  "orders/findOrdersByJournalApi",
  async (idJournal: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findOrdersByJournalApi/${idJournal}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findSoucheOrderApi = createAsyncThunk(
  "orders/findSoucheOrderApi",
  async (organisation_id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findSoucheOrderApi/${organisation_id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findSoucheOrderOnlineApi = createAsyncThunk(
  "orders/findSoucheOrderOnlineApi",
  async (organisation_id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findSoucheOrderOnlineApi/${organisation_id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const getOrderByIdApi = createAsyncThunk(
  "orders/getOrderByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`orders/getOrderByIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updateSoldQuantityApi = createAsyncThunk(
  "orders/updateSoldQuantity",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`orders/updateSoldQuantity`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updateOrderApi = createAsyncThunk(
  "orders/updateOrderApi",
  async (params: UpdateOrderParams, { rejectWithValue }) => {
    try {
      const {
        orderId,
        status,
        paidProducts,
        vendeur,
        items,
        admin,
        perteRetour,
      } = params;
      var response = await axios.put("orders/updateOrderAPi", {
        orderId,
        status,
        paidProducts,
        vendeur,
        items,
        admin,
        perteRetour,
      });
      response.data.data.status = status;
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const UpdateOrderdataApi = createAsyncThunk(
  "orders/UpdateOrderdataApi",
  async (data: any, { rejectWithValue }) => {
    try {
      var response = await axios.put("orders/UpdateOrderdataApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const annulerOrderApi = createAsyncThunk(
  "orders/annulerOrderApi",
  async (data: any, { rejectWithValue }) => {
    try {
      var response = await axios.put("orders/annulerOrderApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const SmSannulerOrderApi = createAsyncThunk(
  "orders/SmSannulerOrderApi",
  async (data: any, { rejectWithValue }) => {
    try {
      var response = await axios.post("orders/SmSannulerOrderApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const findReservationsByOrganisationApi = createAsyncThunk(
  "reservation/findReservationsByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `reservation/findReservationsByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const PayerReservationApi = createAsyncThunk(
  "reservation/PayerReservationApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `reservation/PayerReservationApi`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const createRetourEchangeStatus = createAsyncThunk(
  "retourEchange/createRetourEchange",
  async (data: createRetourEchangeStatus, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `retourEchange/createRetourEchange`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateRetourEchange = createAsyncThunk(
  "retourEchange/updateRetourEchange",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        `retourEchange/updateRetourEchange/${data.id}`,
        data.data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getEtatRetourEchange = createAsyncThunk(
  "retourEchange/getEtatRetourEchangeByOrder",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `retourEchange/getEtatRetourEchangeByOrder/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface OrdersState {
  listOrders: OrderType[];
  listReservations: any[];
  listJournalOrders: any[];
  listOrdersByID: {
    [Id: string]: any[];
  };
  order: any | null;
  selectedOrder: OrderType | null;
  listOrdersByTables: OrderType[];
  listOrdersByClients: { [client: string]: OrderType[] };
  totalPages: any;
  currentpage: any;
  remboursement: any;
  recette: any;
  totalNet: any;

  onLineOrders: OrderType[];
}

const initialState: OrdersState = {
  listReservations: [],
  listOrders: [],
  listJournalOrders: [],
  listOrdersByID: {},
  order: null,
  selectedOrder: null,
  listOrdersByTables: [],
  listOrdersByClients: {},
  totalPages: null,
  currentpage: null,
  totalNet: null,
  remboursement: null,
  recette: null,
  onLineOrders: [],
};

export const OrdersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    selectOrder: (
      state,
      action: PayloadAction<{
        order_id: string;
        orderdata: any;
      }>
    ) => {
      const { order_id, orderdata } = action.payload;
      const order = state.listOrders
        ? state.listOrders.find((item) => item._id?.toString() === order_id)
        : state.onLineOrders.find((item) => item._id?.toString() === order_id);

      if (!order) {
        const reservation = state.listReservations.find(
          (item) => item.order._id === order_id
        );

        if (!reservation) {
          return {
            ...state,
            selectedOrder: order,
          };
        } else {
          console.log("reservationreservation", reservation.order);
          return {
            ...state,
            selectedOrder: reservation.order,
          };
        }
      }
      console.log("orderdata", orderdata);
      return {
        ...state,
        selectedOrder: orderdata,
        order: orderdata,
      };
    },
    updateSelectedOrderStatus: (
      state,
      action: PayloadAction<{
        status: string;
      }>
    ) => {
      const { status } = action.payload;
      if (state.selectedOrder) state.selectedOrder.status = status;
      return {
        ...state,
        selectedOrder: state.selectedOrder,
      };
    },
    resetSelectedOrder: (state) => {
      return {
        ...state,
        selectedOrder: null,
      };
    },
    resetRetrievedOrder: (state) => {
      return {
        ...state,
        order: null,
      };
    },
    updateOrder: (
      state,
      action: PayloadAction<{
        dataOrder: any;
      }>
    ) => {
      const { dataOrder } = action.payload;

      return {
        ...state,
        order: dataOrder,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveOrderApi.fulfilled, (state, action) => {});
    builder.addCase(
      findReservationsByOrganisationApi.fulfilled,
      (state, action) => {
        return {
          ...state,
          listReservations: action.payload.reservation,
        };
      }
    );
    builder.addCase(findOrdersFiltreApi.fulfilled, (state, action) => {
      const groupedByID = _.groupBy(action.payload.data, "_id");
      const groupedByTable = _.groupBy(action.payload.data, "table_id");
      const groupedByClient = _.groupBy(action.payload.data, "client_id._id");
      const groupedByType = _.groupBy(action.payload.data, "type");
      return {
        ...state,
        listOrders: action.payload.data,
        listOrdersByID: groupedByID,
        listOrdersGroupedByTables: groupedByTable,
        listOrdersByClients: groupedByClient,
        totalPages: action.payload.totalPages,
        currentpage: action.payload.currentPage,
        totalNet: action.payload.totalNet,
        remboursement: action.payload.remboursement,
        recette: action.payload.recette,
        onLineOrders: groupedByType["online"],
      };
    });
    builder.addCase(findOrdersByJournalApi.fulfilled, (state, action) => {
      return {
        ...state,
        listJournalOrders: action.payload,
      };
    });
    builder.addCase(findOrdersByTableApi.fulfilled, (state, action) => {
      return {
        ...state,
        listOrdersByTables: action.payload,
      };
    });

    builder.addCase(updateOrderApi.fulfilled, (state, action) => {
      const index = _.findIndex(state.listOrders, {
        _id: action.payload.data._id,
      });

      const indexOnline = _.findIndex(state.onLineOrders, {
        _id: action.payload.data._id,
      });
      if (index !== -1) {
        const updatedListOrders = [...state.listOrders];
        updatedListOrders[index] = {
          ...updatedListOrders[index],
          status: action.payload.data.status,
        };
        return {
          ...state,
          listOrders: updatedListOrders,
        };
      }
      if (indexOnline !== -1) {
        const updatedListOrders = [...state.onLineOrders];
        updatedListOrders[indexOnline] = {
          ...updatedListOrders[indexOnline],
          status: action.payload.data.status,
        };
        return {
          ...state,
          onLineOrders: updatedListOrders,
        };
      }
      return state;
    });
    builder.addCase(annulerOrderApi.fulfilled, (state, action) => {
      const index = _.findIndex(state.listOrders, {
        _id: action.payload.data._id,
      });

      const indexOnline = _.findIndex(state.onLineOrders, {
        _id: action.payload.data._id,
      });
      if (index !== -1) {
        const updatedListOrders = [...state.listOrders];
        updatedListOrders[index] = {
          ...updatedListOrders[index],
          status: action.payload.data.status,
        };
        return {
          ...state,
          listOrders: updatedListOrders,
        };
      }
      if (indexOnline !== -1) {
        const updatedListOrders = [...state.onLineOrders];
        updatedListOrders[indexOnline] = {
          ...updatedListOrders[indexOnline],
          status: action.payload.data.status,
        };
        return {
          ...state,
          onLineOrders: updatedListOrders,
        };
      }
      return state;
    });
    builder.addCase(UpdateOrderdataApi.fulfilled, (state, action) => {
      const index = _.findIndex(state.listOrders, {
        _id: action.payload.data._id,
      });

      const indexOnline = _.findIndex(state.onLineOrders, {
        _id: action.payload.data._id,
      });
      if (index !== -1) {
        const updatedListOrders = [...state.listOrders];
        updatedListOrders[index] = {
          ...updatedListOrders[index],
          status: action.payload.data.status,
        };
        return {
          ...state,
          listOrders: updatedListOrders,
        };
      }
      if (indexOnline !== -1) {
        const updatedListOrders = [...state.onLineOrders];
        updatedListOrders[indexOnline] = {
          ...updatedListOrders[indexOnline],
          status: action.payload.data.status,
        };
        return {
          ...state,
          onLineOrders: updatedListOrders,
        };
      }
      return state;
    });
    builder.addCase(getOrderByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        order: action.payload,
      };
    });
  },
});

// Persist configuration
// const persistConfig = {
//   key: "orders",
//   storage: storage,
//   whitelist: ["selectedOrder"], // Only persist selectedOrder
// };

// Wrap the ordersSlice reducer with persistReducer
// const persistedOrdersReducer = persistReducer(
//   persistConfig,
//   OrdersSlice.reducer
// );
export const {
  selectOrder,
  updateSelectedOrderStatus,
  resetSelectedOrder,
  resetRetrievedOrder,
  updateOrder,
} = OrdersSlice.actions;

export const ListOrders = (state: RootState) => state.orders.listOrders;
export const ListOnlineOrders = (state: RootState) => state.orders.onLineOrders;

export const ListJournalOrders = (state: RootState) =>
  state.orders.listJournalOrders;
export const ListOrdersById = (state: RootState) => state.orders.listOrdersByID;
export const order = (state: RootState) => state.orders.order;

export const selectedOrder = (state: RootState) => state.orders.selectedOrder;
export const ListOrdersByTables = (state: RootState) =>
  state.orders.listOrdersByTables;
export const ListOrdersByClients = (state: RootState) =>
  state.orders.listOrdersByClients;
export default OrdersSlice.reducer;

export const ListReservations = (state: RootState) =>
  state.reservation.listReservations;
export const TotalNet = (state: RootState) => state.orders.totalNet;
export const Remboursement = (state: RootState) => state.orders.remboursement;
export const Recette = (state: RootState) => state.orders.recette;
export const Currentpage = (state: RootState) => state.orders.currentpage;
export const TotalPages = (state: RootState) => state.orders.totalPages;
