import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import BonTransfert from "../TransfertArticles/files/BonTransfert";

export default function TransfertAnnomalie({
  showModal,
  setshowModal,
  annomalie,
  t,
}: any) {
  const [showbon, setshowbon] = useState(false);
  const [dataBon, setDatataBon] = useState();

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setshowModal(false);
      }}
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <Modal.Header>
        {t("annomalie reception N° ")} {annomalie?.Objet?.NumBonreception}
        <span
          className=" btn-primary-app pointer"
          onClick={() => {
            setDatataBon(annomalie?.transfert_id);
            setshowbon(true);
          }}
        >
          <i className="fas fa-file-powerpoint"></i> {t("Voir bon reception")}
        </span>{" "}
      </Modal.Header>
      <Modal.Body>
        {t("MESSAGE :")} {annomalie?.annomalie?.msg}
        {t("List des annomalie ")}
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Article")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Code Article")}
              </th>

              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Quantité Envoyée")}
              </th>
              <th
                style={{
                  border: "1px solid #000",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#e0e0e0",
                }}
              >
                {t("Quantité Reception")}
              </th>
            </tr>
          </thead>
          <tbody>
            {annomalie?.annomalie?.data?.map((item: any) => (
              <tr>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.titre?.fr}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.article_id?.code_article}
                </td>

                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.Quantiteenvoyee || 0}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {item.QuantitReception || 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {showbon && (
          <BonTransfert
            societeid={annomalie?.Objet?.societe_id}
            datainfo={dataBon}
            type="reception"
            t={t}
            setshowbon={setshowbon}
            ShowBon={showbon}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
     
      </Modal.Footer>
    </Modal>
  );
}
