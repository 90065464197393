import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer } from "react-toastify";
import MenuBottom from "../Menus/MenuBottom";
import LeftBar from "../LeftBar/LeftBar";
import RightBarPanier from "../RightBar/RightBar/RightbarPanier";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ActiveMenu } from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import MenuTop from "../Menus/MenuTop";
import MenuLeft from "../Menus/MenuLeft";
import AppMenuParametrageType from "../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import MenuRight from "../Menus/MenuRight";
import ClotureCaisse from "../../Components/Caisses/mouvementCaisse/ApportRetraitCaisse";
import PaymentDrawer from "../RightBar/PaymentDrawer/PaymentDrawer";
import {
  SelectedOrganisationId,
  SelectedRole,
  selectedSessionJournal,
  selectJournalCaisse,
  selectUser,
  userLang,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { parametrageCaisse } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import TopTotalDisplay from "../Navbar/TopTotalDisplay";
import {
  DepotDefautOrganisation,
  findDepotsDefautByOrganisationApi,
} from "../../_App/Redux/Slices/depot/depotSlice";
import {
  getOrderByIdApi,
  resetRetrievedOrder,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import OnlineOrderConfirmation from "../RightBar/PaymentDrawer/OnlineOrderConfirmation";
import { Panier } from "../../_App/Redux/Slices/Caisse/PanierSlice";
import FloatingPaymentDrawer from "../RightBar/PaymentDrawer/FloatingPaymentDrawer";

const RestyledLayout = styled.div<{ $menu: AppMenuParametrageType | null }>`
  ${(props) => {
    var style = ``;
    // check the postion of menu right, left up , down
    switch (props.$menu?.menuPosition.position) {
      case `down`:
        style = `#content {
                    width: 100%;
                    flex-grow: none;
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;
      case `left`:
        style = `#content {
                    width: calc(100% - 150px);
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      case `right`:
        style = `#content {
                    width: calc(100% - 150px);
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      case `up`:
        style = `.main-container {
                   flex-direction: column;
                  }
                  #content {
                    width: 100%;
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      default:
        console.log(`Sorry, we are out of parms.`);
    }
    return style;
  }}

  #content .col-left {
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
    margin-right: 0px;
  }
`;

export default function LayoutCaisse() {
  const { orderId } = useParams();
  const Menu = useAppSelector(ActiveMenu);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const parametrage = useAppSelector(parametrageCaisse);
  const cart = useAppSelector(Panier);

  const navigate = useNavigate();
  const journalCaisse = useAppSelector(selectJournalCaisse);
  const sessionJournal = useAppSelector(selectedSessionJournal);
  const user = useAppSelector(selectUser);

  const isCaisseClosed =
    !journalCaisse ||
    journalCaisse.cloture ||
    !sessionJournal ||
    sessionJournal.cloture;

  useEffect(() => {
    if (isCaisseClosed) {
      if (user?.role_id?.libelle === "onlineSale") {
      } else navigate("/HomeCaisse");
    }
  }, [isCaisseClosed, navigate]);

  const location = useLocation();

  const currentPath = location.pathname;

  const [showPayement, setShowPayment] = useState(false);
  const [articlesToPay, setArticlesToPay] = useState();
  const [miniOrder, setMiniOrder] = useState();
  const dispatch = useAppDispatch();

  const handleArticlesToPayChange = (newArticlesToPay: any) => {
    setArticlesToPay(newArticlesToPay);
  };
  const handleMiniOrder = (order: any) => {
    setMiniOrder(order);
  };
  const [showModal, setShowModal] = useState(false);
  const langue = useAppSelector(userLang);

  const openFormModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (currentOrganisation)
      dispatch(findDepotsDefautByOrganisationApi(currentOrganisation));
  }, [dispatch]);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderByIdApi(orderId));
    }
  }, [currentOrganisation, orderId]);

  return (
    <RestyledLayout $menu={Menu}>
      <ToastContainer />
      {parametrage && parametrage.barcodeOnly ? (
        <div
          className="dashboard-analytics admin-header"
          style={{
            color: "#888ea8",
            height: "100%",
            fontSize: "0.875rem",
            background: "#f1f2f3",
            overflowX: "hidden",
            overflowY: "auto",
            letterSpacing: "0.0312rem",
            fontFamily: "Quicksand, sans-serif",
          }}
        >
          <div className="main-container" id="container">
            {Menu && Menu.menuPosition.position == "up" ? <MenuTop /> : ""}
            {Menu && Menu.menuPosition.position == "down" ? <MenuBottom /> : ""}
            {Menu && Menu.menuPosition.position == "left" ? <MenuLeft /> : ""}
            {!Menu ? <MenuBottom /> : ""}

            <div id="content" className="main-content">
              <LeftBar />
              <div className="layout-px-spacing" style={{ height: "100vh" }}>
                <div
                  className="content-container"
                  style={{ height: "100%", display: "flex" }}
                >
                  {parametrage &&
                  (!parametrage.barcodeOnly ||
                    (parametrage.barcodeOnly &&
                      !currentPath.includes("/caisse"))) ? (
                    <div
                      className="col-left"
                      style={{
                        overflowY: "auto",
                        marginRight:
                          parametrage && parametrage.barcodeOnly
                            ? !currentPath.includes("/caisse")
                              ? "0px"
                              : "600px"
                            : "448px",
                      }}
                    >
                      <div
                        className="col-left-content"
                        style={{
                          direction: langue === "ar" ? "rtl" : "unset",
                          overflow: "hidden",
                        }}
                      >
                        <Outlet />
                      </div>
                    </div>
                  ) : null}

                  {parametrage && parametrage.barcodeOnly ? (
                    <>
                      {currentPath.includes("/caisse") ? (
                        <>
                          <TopTotalDisplay />
                          <RightBarPanier
                            setVisible={setShowPayment}
                            visible={showPayement}
                            onArticlesToPayChange={handleArticlesToPayChange}
                            miniOrder={handleMiniOrder}
                          />
                        </>
                      ) : null}
                      {user &&
                      user.role_id &&
                      user.role_id.libelle === "onlineSale" &&
                      cart.operationType !== "Pay" ? (
                        <OnlineOrderConfirmation
                          visible={
                            parametrage && parametrage.barcodeOnly
                              ? true
                              : showPayement
                          }
                          setVisible={
                            parametrage && parametrage.barcodeOnly
                              ? null
                              : setShowPayment
                          }
                          ArticlesToPay={
                            parametrage && parametrage.barcodeOnly
                              ? null
                              : articlesToPay
                          }
                          miniOrder={
                            parametrage && parametrage.barcodeOnly
                              ? null
                              : miniOrder
                          }
                        />
                      ) : (
                        <PaymentDrawer
                          visible={
                            parametrage && parametrage.barcodeOnly
                              ? true
                              : showPayement
                          }
                          setVisible={
                            parametrage && parametrage.barcodeOnly
                              ? null
                              : setShowPayment
                          }
                          ArticlesToPay={
                            parametrage && parametrage.barcodeOnly
                              ? null
                              : articlesToPay
                          }
                          miniOrder={
                            parametrage && parametrage.barcodeOnly
                              ? null
                              : miniOrder
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <RightBarPanier
                        setVisible={setShowPayment}
                        visible={showPayement}
                        onArticlesToPayChange={handleArticlesToPayChange}
                        miniOrder={handleMiniOrder}
                      />
                      <PaymentDrawer
                        visible={
                          parametrage && parametrage.barcodeOnly
                            ? true
                            : showPayement
                        }
                        setVisible={
                          parametrage && parametrage.barcodeOnly
                            ? null
                            : setShowPayment
                        }
                        ArticlesToPay={
                          parametrage && parametrage.barcodeOnly
                            ? null
                            : articlesToPay
                        }
                        miniOrder={
                          parametrage && parametrage.barcodeOnly
                            ? null
                            : miniOrder
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {Menu && Menu.menuPosition.position == "right" ? <MenuRight /> : ""}
          </div>
        </div>
      ) : (
        <div
          className="dashboard-analytics admin-header"
          style={{
            color: "#888ea8",
            height: "100%",
            fontSize: "0.875rem",
            background: "#f1f2f3",
            overflowX: "hidden",
            overflowY: "auto",
            letterSpacing: "0.0312rem",
            fontFamily: "Quicksand, sans-serif",
          }}
        >
          <div className="main-container" id="container">
            {Menu && Menu.menuPosition.position == "up" ? <MenuTop /> : ""}
            {Menu && Menu.menuPosition.position == "down" ? <MenuBottom /> : ""}
            {Menu && Menu.menuPosition.position == "left" ? <MenuLeft /> : ""}
            {!Menu ? <MenuBottom /> : ""}

            <div id="content" className="main-content">
              <LeftBar />
              <div className="layout-px-spacing" style={{ height: "100vh" }}>
                <div
                  className="content-container"
                  style={{ height: "100%", display: "flex" }}
                >
                  {parametrage &&
                  (!parametrage.barcodeOnly ||
                    (parametrage.barcodeOnly &&
                      !currentPath.includes("/caisse"))) ? (
                    <div
                      className="col-left"
                      style={{
                        overflowY: "auto",
                        marginRight:
                          parametrage && parametrage.barcodeOnly
                            ? !currentPath.includes("/caisse")
                              ? "0px"
                              : "600px"
                            : "470px",
                      }}
                    >
                      <div
                        className="col-left-content"
                        style={{
                          direction: langue === "ar" ? "rtl" : "unset",
                          overflow: "hidden",
                        }}
                      >
                        <Outlet />
                      </div>
                    </div>
                  ) : null}

                  <>
                    <RightBarPanier
                      setVisible={setShowPayment}
                      visible={showPayement}
                      onArticlesToPayChange={handleArticlesToPayChange}
                      miniOrder={handleMiniOrder}
                    />
                    <FloatingPaymentDrawer
                      visible={
                        parametrage && parametrage.barcodeOnly
                          ? true
                          : showPayement
                      }
                      setVisible={
                        parametrage && parametrage.barcodeOnly
                          ? null
                          : setShowPayment
                      }
                      ArticlesToPay={
                        parametrage && parametrage.barcodeOnly
                          ? null
                          : articlesToPay
                      }
                      miniOrder={
                        parametrage && parametrage.barcodeOnly
                          ? null
                          : miniOrder
                      }
                    />
                  </>
                </div>
              </div>
            </div>
            {Menu && Menu.menuPosition.position == "right" ? <MenuRight /> : ""}
          </div>
        </div>
      )}
      <ClotureCaisse ShowModal={showModal} setShowModal={setShowModal} />
    </RestyledLayout>
  );
}
