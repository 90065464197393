import React, { useEffect, useState } from "react";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  currentpage,
  findListIncidentApi,
  listIncident,
  totalpages,
} from "../../_App/Redux/Slices/incident/incidentSlice";
import AnnomalieList from "../../Components/Incident/IncidentList";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { useTranslation } from "react-i18next";

export default function IncidentPage() {
  const { t } = useTranslation();
  const societe_id = useAppSelector(societeId);
  const dispatch = useAppDispatch();
  const ListIncident = useAppSelector(listIncident);
  const current_page = useAppSelector(currentpage);
  const total_pages = useAppSelector(totalpages); 

  const [index, setindex] = useState(!current_page ? 0 : current_page);

  useEffect(() => {
    if (societe_id) dispatch(findListIncidentApi({societe_id,index}));
  }, [index]);
  
  return (
    <div>
      <AnnomalieList list={ListIncident} t={t} />
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={total_pages}
          setindex={setindex}
        />
      </div>
    </div>
  );
}
