import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { selectUserId } from "../../_App/Redux/Slices/Auth/authSlice";
import { Modal } from "react-bootstrap";
import Zoom from "react-medium-image-zoom";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";
import { updateOrderApi } from "../../_App/Redux/Slices/Orders/OrderSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function DemandeRemiseIncident({
  showModal,
  setshowModal,
  commande,
  t,
}: any) {
  const dispatch = useAppDispatch();

  const [num, setNum] = useState("");
  const [items, setItems] = useState<any[]>([]);
  const user_id = useAppSelector(selectUserId);

  useEffect(() => {
    setNum(commande?.order_id?.user_id?.mobile);
  }, [commande]);

  const handleValideOrder = async (etat: string) => {
    if (commande?.order_id && commande?.order_id._id) {
      if (commande?.order_id.status === "demande") {
        const copiecommande = { ...commande?.order_id };
        const listmap = copiecommande.items.map((element: any) => {
          const find = items.find((e) => e._id === element.article._id);
          if (find) {
            return {
              ...element,
              remisepropsition: element.remise,
              remise: parseInt(find.remise),
            };
          } else {
            return {
              ...element,
              remisepropsition: element.remise,
            };
          }
        });
        const data = {
          orderId: commande?.order_id._id,
          status: etat,
          items: listmap,
          admin: user_id,
        };

        const respupdate = await dispatch(updateOrderApi(data));
        if (respupdate.payload.success) {
          ToastSuccess("Status a été changé avec succès");
          window.location.reload();
          const phoneNumber = commande?.order_id?.user_id?.mobile?.replace(
            /\s+/g,
            ""
          );

          const message = `La demande de la commande numéro ${
            commande?.order_id?.order_number
          } était ${etat === "demande Valide" ? "confirmée" : "refusée"}.`;

          const encodedMessage = encodeURIComponent(message);

          const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

          window.location.href = whatsappURL;
        } else {
          ToastWarning(respupdate?.payload?.message);
        }
      }
    }
  };

  const changeRemise = (id: string, value: string) => {
    const index = items.findIndex((element: any) => element._id === id);
    const copie = [...items];
    if (index !== -1) {
      copie[index].remise = value;
    } else {
      copie.push({
        _id: id,
        remise: value,
      });
    }
    setItems(copie);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setshowModal(false);
      }}
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <Modal.Header>
        {t("Numero vente")}
        {commande?.order_id?.order_number}
      </Modal.Header>
      <Modal.Body>
        {commande?.user_confirm_id ? (
          <div className="container center-text">
            <h3>
              {t("Demande deja traité Par ")}{" "}
              {commande?.user_confirm_id?.nom ||
                "" + "" + commande?.user_confirm_id?.prenom ||
                ""}{" "}
            </h3>
            <h3>{t(`Status de commande est ${commande?.order_id?.status}`)}</h3>
          </div>
        ) : (
          <div className="container mt-4">
            <h3>
              {t("Demande de ")}
              {commande?.order_id?.user_id.nom +
                " " +
                commande?.order_id?.user_id.prenom}{" "}
            </h3>
            {t("Numero  ")}{" "}
            <input
              type="text"
              onKeyPress={handleKeyPress_number}
              className="form-control"
              value={num}
              onChange={(e) => setNum(e.target.value)}
            />
            <h3>
              {t("Client")}{" "}
              {commande?.order_id?.client_id?.defaultClient
                ? commande?.order_id?.order_id?.client_id.nom
                : commande?.client_id.nom ||
                  "" + " " + commande?.order_id?.client_id.prenom ||
                  ""}
            </h3>
            {commande?.order_id?.items?.length !== 0 &&
              commande?.order_id?.items?.map((item: any, index: any) => (
                <div
                  className="row mb-4 p-3 rounded shadow-sm bg-light align-items-center"
                  key={index}
                >
                  {/* Image Section */}
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <Zoom>
                      <img
                        alt={item?.article?.titre?.fr || "Item Image"}
                        src={
                          item?.article?.image && item?.article?.image[0]
                            ? `${process.env.REACT_APP_API_PUBLIC_URL}article/${item?.article?.image[0]}`
                            : "/assets/assets/img/no-image.jpg"
                        }
                        className="img-fluid rounded-circle shadow"
                        style={{
                          maxWidth: "100px",
                          maxHeight: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </Zoom>
                  </div>

                  {/* Title Section */}
                  <div className="col-8 d-flex flex-column justify-content-center">
                    <p
                      className="user-name text-truncate"
                      style={{ fontWeight: "600", fontSize: "1.2rem" }}
                    >
                      {item?.article?.titre?.fr || "No title"}
                    </p>
                  </div>

                  {/* Price Section */}
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      {" "}
                      prix initiale{" "}
                      {item?.article?.prix_vente_ttc.toFixed(3) || "N/A"}
                    </p>
                  </div>

                  {/* Discount Section */}
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      Remise: {item?.remiseprop || item.remise}%
                    </p>
                  </div>
                  <div className="col-3 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      Remise validation:{" "}
                      <input
                        type="number"
                        value={
                          items.find((i) => i._id === item.article._id)?.item
                            ?.remise
                        }
                        onChange={(e) =>
                          changeRemise(item.article._id, e.target.value)
                        }
                        className="form-control"
                      />
                    </p>
                  </div>

                  {/* Net Price Section */}
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                      {" "}
                      Net à payer: {item?.prixNet.toFixed(3)}
                    </p>
                  </div>
                </div>
              ))}
            {/* Action Buttons */}
            <div className="d-flex justify-content-end mt-3">
              <button
                onClick={() => handleValideOrder("demande Valide")}
                className="btn btn-success mx-2"
              >
                {t("Confirmer")}
              </button>
              <button
                onClick={() => handleValideOrder("demande Invalide")}
                className="btn btn-danger mx-2"
              >
                {t("Refuser")}
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
