import React, { useEffect, useRef, useState } from "react";
import {
  ArticleData,
  OrderType,
} from "../../../_App/Types/Entites/Orders/OrderType";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  getOrderByIdApi,
  resetRetrievedOrder,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  selectUser,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  findsocieteinfoApi,
  findSocieteLivraison,
  societeLivraison,
} from "../../../_App/Redux/Slices/societe/societeSlice";
import SocieteType from "../../../_App/Types/Entites/SocieteType";
import { numberToWords } from "../../../_App/Helpers/helpers";
import Loader from "../../../_Layouts/Loader/Loader";
import { Panier } from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { getTicketById } from "../../../_App/Redux/Slices/tickets/TicketSlice";
import TicketType from "../../../_App/Types/Entites/TicketType";
import { t } from "i18next";
import { ISO_8601 } from "moment";

const OnlineOrderInvoice = ({
  order_id,
  show,
  setShow,
  onClose,
  type,
  ticket,
  orderstype,
}: any) => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderType | null>(null);
  const cart = useAppSelector(Panier);
  const societe_id = useAppSelector(societeId);
  const invoiceRef = useRef<HTMLDivElement>(null); // Reference for the invoice section
  const [ste, setste] = useState<SocieteType | null>(null);
  const [loading, setLoading] = useState(false); // Loading state
  const user = useAppSelector(selectUser);
  const livraison = useAppSelector(societeLivraison);
  const [ticketData, setTicketData] = useState<TicketType | undefined>(
    undefined
  );
  const [data, setData] = useState<any>(undefined);
  useEffect(() => {
    const fetchData = async () => {
      if (order_id && societe_id) {
        setLoading(true); // Set loading to true before fetching data
        try {
          const res = await dispatch(getOrderByIdApi(order_id));
          if (res.payload && res.payload._id) {
            setOrder(res.payload);
          }
          if (ticket) {
            const resT = await dispatch(getTicketById(ticket));
            if (resT.payload && resT.payload._id) {
              setTicketData(resT.payload);
            }
          }

          const res1 = await dispatch(findsocieteinfoApi(societe_id));
          const res2 = await dispatch(findSocieteLivraison(societe_id));

          if (res1.payload && res1.payload._id) {
            setste(res1.payload);
          }
        } catch (error) {
          console.error("Échec de la récupération de la commande:", error);
        } finally {
          setLoading(false); // Set loading to false after fetching data
        }
      }
    };

    fetchData();
  }, [dispatch, order_id, societe_id]);

  useEffect(() => {
    if (!show) {
      setOrder(null); // Clear order on modal hide
      dispatch(resetRetrievedOrder());
    }
  }, [show]);

  useEffect(() => {
    console.log(ticketData);
    setData(
      ticket ? ticketData?.ticket_order : type === "Echange" ? cart : order
    );
  }, [show, ticket, order, ticketData]);

  const handlePrint = () => {
    if (invoiceRef.current) {
      const printContent = invoiceRef.current.innerHTML;
      const originalContent = document.body.innerHTML;

      document.body.innerHTML = printContent; // Temporarily replace the entire page content with the invoice
      window.print(); // Trigger print
      document.body.innerHTML = originalContent; // Restore original content after printing
      window.location.reload(); // Refresh to ensure the page returns to its previous state
    }
  };
  const calculateTotalHT = () => {
    if (!Array.isArray(data?.items) || data?.items.length === 0) return "0.000";

    return data.items
      .reduce((total: any, item: any) => {
        const itemTotalHT =
          (item.article?.prix_vente_ht || 0) * (item.quantity || 0);
        return total + itemTotalHT;
      }, 0)
      .toFixed(3); // Keep three decimal places for precision
  };
  const [total, setTotal] = useState<any>(0);
  useEffect(() => {
    // Ensure totalNet and frais_livraison are numbers
    const totalNet = Number(data?.totalNet) || 0;
    const fraisLivraison = Number(livraison?.frais_livraison) || 0;

    const somme =
      type === "Echange"
        ? fraisLivraison.toFixed(3) // Safe now
        : (totalNet + fraisLivraison).toFixed(3); // Safe now

    if (user?.role_id?.libelle === "onlineSale" || orderstype === "online") {
      setTotal(somme);
    } else {
      setTotal(totalNet.toFixed(3)); // Safe now
    }
  }, [data, livraison, user, type]);

  return (
    <Modal size="xl" show={show} onHide={() => onClose()}>
      {loading ? ( // Show loader if loading
        <Loader />
      ) : order ? (
        <div className="row invoice layout-spacing">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="doc-container">
              <div className="invoice-container" ref={invoiceRef}>
                <div className="invoice-inbox">
                  <div className="invoice-00001">
                    <div className="content-section">
                      {/* Invoice Header */}
                      <div className="inv--head-section inv--detail-section">
                        <div className="row">
                          <div className="col-sm-6">
                            <div>
                              <h3
                                className="in-heading ml-0"
                                onClick={() => console.log(data)}
                              >
                                {ste?.nom}{" "}
                              </h3>
                              <p className="inv-client-name mb-0">
                                <strong>
                                  {"MF : " + ste?.matricule_fiscale}{" "}
                                </strong>
                              </p>
                              <p className="inv-client-name mb-0">
                                <strong>{ste?.tel} </strong>
                              </p>

                              <p className="inv-client-name">
                                <strong>{ste?.address} </strong>
                              </p>
                            </div>
                            {/* Client Info */}
                            <div>
                              <h3 className="in-heading ml-0">Facturé à :</h3>
                              <p className="inv-client-name mb-0">
                                <strong>Nom: </strong>
                                {order?.client_id?.nom ?? "N/A"}
                              </p>

                              <p className="inv-client-address mb-0">
                                <strong>Adresse: </strong>
                                {order?.client_id?.address ?? "N/A"}
                              </p>
                              <p className="inv-client-contact mb-0">
                                <strong>Contact: </strong>
                                {order?.client_id?.mobile ?? "N/A"}
                              </p>
                              {order?.client_id?.note && (
                                <p className="inv-client-contact mb-0">
                                  <strong>Note: </strong>
                                  {order?.client_id?.note ?? "N/A"}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-6 text-sm-right">
                            {/* Order Details */}
                            <p className="inv-list-number">
                              <span className="inv-title">
                                {type && type === "Echange"
                                  ? "Facture échange : "
                                  : "Facture : "}
                              </span>{" "}
                              <span className="inv-number">
                                {order?.order_number ?? "N/A"}
                              </span>
                            </p>

                            <p className="inv-created-date">
                              <span className="inv-title">Date: </span>{" "}
                              <span className="inv-date">
                                {order?.date?.toString().split("T")[0] ?? "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="inv--product-table-section">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Réf</th>
                                <th scope="col">Article</th>
                                <th className="text-right" scope="col">
                                  Quantité
                                </th>
                                <th scope="col">Prix U. HT</th>
                                <th scope="col">Taux TVA</th>
                                <th className="text-right" scope="col">
                                  Prix U. TTC
                                </th>
                                <th className="text-right" scope="col">
                                  Remise
                                </th>
                                <th className="text-right" scope="col">
                                  Prix T. TTC
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.items?.map((item: any) => (
                                <tr key={item.article._id}>
                                  <td>{item.article?.code_article}</td>
                                  <td>{item.article.titre.fr}</td>
                                  <td className="text-right">
                                    {item.quantity}
                                  </td>
                                  <td className="text-right">
                                    {(
                                      Number(item.article?.prix_vente_ht) || 0
                                    ).toFixed(3)}
                                  </td>
                                  <td className="text-right">
                                    {item.article.tva}
                                  </td>
                                  <td className="text-right">
                                    {(
                                      Number(item.article?.prix_vente_ttc) || 0
                                    ).toFixed(3)}
                                  </td>
                                  <td className="text-right">
                                    {Number(item.remise) || 0}
                                  </td>
                                  <td className="text-right">
                                    {(Number(item.prixNet) || 0).toFixed(3)}
                                  </td>
                                </tr>
                              )) || (
                                <tr>
                                  <td colSpan={3} className="text-center">
                                    Aucun article trouvé.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <h6 className="mb-0">
                          Arrêtée la présente facture à la somme de :{" "}
                          {numberToWords(
                            (Number(data?.totalNet) || 0) +
                              livraison?.frais_livraison
                          )}
                        </h6>
                      </div>

                      <div className="inv--total-amounts">
                        <div className="row mt-4">
                          <div className="col-sm-12 text-sm-right">
                            <div className="grand-total-amount">
                              <h4 className="inv-total">
                                Net à payer HT: {calculateTotalHT()}
                              </h4>
                            </div>
                            <div className="grand-total-amount">
                              <h4 className="inv-total">TVA: 19</h4>
                            </div>
                            <div className="grand-total-title">
                              <h4 className="inv-total-title">
                                Total TTC :{" "}
                                {(Number(data?.total) || 0).toFixed(3)} TND
                              </h4>
                            </div>
                            <div className="grand-total-title">
                              <h4 className="inv-total-title">
                                Montant remise :{" "}
                                {data?.items
                                  ?.reduce(
                                    (total: number, item: ArticleData) =>
                                      total +
                                      (item.prix_total * item.remise) / 100,
                                    0
                                  )
                                  .toFixed(3)}{" "}
                                TND
                              </h4>
                            </div>
                            <div className="grand-total-title">
                              <h4 className="inv-total-title">
                                Total Net TTC :{" "}
                                {(Number(data?.totalNet) || 0).toFixed(3)} TND
                              </h4>
                            </div>
                            <div className="grand-total-amount">
                              <h4
                                className="inv-total"
                                onClick={() =>
                                  console.log(
                                    "livraison::::",
                                    livraison?.frais_livraison
                                  )
                                }
                              >
                                Net à payer TTC inclus frais de livraison:{" "}
                                {total}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="row" style={{ justifyContent: "center" }}>
                <button
                  className="btn btn-success"
                  style={{ width: "50%" }}
                  onClick={handlePrint}
                >
                  {t("Imprimer")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};

export default OnlineOrderInvoice;
