import React, { useEffect, useState } from 'react';
import LoginForm from '../../Components/Auth/LoginForm';
import Login from '../../Components/Auth/Login';
import LoginParCode from '../../Components/Auth/LoginParCode';

const LoginPage: React.FC = () => {
 const [badge , setBadge]=useState(localStorage.getItem('Login'))
 useEffect(()=>{
    if(badge==="Badge"){
      localStorage.setItem('Login', 'Badge');  
    }else{
      localStorage.setItem('Login', 'mail');  

    }
 },[badge])
  return (
    <div>{
      badge==="Badge"?(
        <LoginParCode setBadge={setBadge} badge={badge} />

      ):
      <Login setBadge={setBadge}  badge={badge}/>

}
    </div>
  );
}

export default LoginPage;

