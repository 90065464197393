import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { t } from "i18next";
import ActionModel from "./ActionModel";
import Filtre from "../Stock/Filtre";
import {
  Count,
  currentpage,
  findActionApi,
  ListMouvement,
  ListMouvementCaisse,
  mouvementArticleApi,
  mouvementVenteArticleApi,
  totalPages,
  Totaux,
} from "../../_App/Redux/Slices/mouvement/mouvementSlice";
import {
  findvendeursBySocieteApi,
  Vendeurs,
} from "../../_App/Redux/Slices/users/vendeurSlice";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Select from "react-select";
import {
  findClientBySocieteApi,
  ListClient,
} from "../../_App/Redux/Slices/client/clientSlice";
import Loader from "../../_Layouts/Loader/Loader";
import { Spinner } from "react-bootstrap";
import TicketDeCaissePaid from "../Caisses/TicketDeCaisse/TicketDeCaissePaid";
import * as XLSX from "xlsx";
import { exportExcel, formatDateAndTime } from "../../_App/Helpers/helpers";

export default function Mouvementcaisse() {
  const dispatch = useAppDispatch();
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [ticket, SetTicket] = useState(null);

  const listMouvementCaisse = useAppSelector(ListMouvementCaisse);
  const totaux = useAppSelector(Totaux);
  const current_page = useAppSelector(currentpage);
  const count = useAppSelector(Count);
  const vendeurs = useAppSelector(Vendeurs);
  const clients = useAppSelector(ListClient);
  const societe_id = useAppSelector(societeId);
  const totalpages = useAppSelector(totalPages);
  const OrganisationList = useAppSelector(SelectedOrganisations);
  const [date_debut, setdate_debut] = useState("");
  const [date_fin, setdate_fin] = useState("");
  const [spinner, setspinner] = useState(false);
  const [index, setindex] = useState(!current_page ? 0 : current_page);
  const [vendeurOption, setvendeurOption] = useState<any[]>([]);
  const [selectedvendeur, setselectedvendeur] = useState<any[]>([]);
  const [OrganisationOption, setOrganisationOption] = useState<any[]>([]);
  const [selectedorganisation, setselectedorganisation] = useState<any[]>([]);
  const [clientOption, setclientOption] = useState<any[]>([]);
  const [selectedclient, setselecteclient] = useState<any[]>([]);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    ;

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    setdate_debut(formatDate(lastMonth));
    setdate_fin(formatDate(today));

    setspinner(true);
    const data = {
      clients: selectedclient,
      organisations: selectedorganisation,
      vendeurs: selectedvendeur,
      index: 1,
      dateDebut: lastMonth,
      dateFin: today,
      exportdata: false,
    };
    setindex(1);
    dispatch(mouvementVenteArticleApi(data));
  }, []);

  useEffect(() => {
    setvendeurOption(
      vendeurs?.map((option) => ({
        value: option._id,
        label: option.nom,
      }))
    );
  }, [vendeurs]);

  useEffect(() => {
    if (OrganisationList)
      setOrganisationOption(
        OrganisationList?.map((option) => ({
          value: option._id,
          label: option.nom,
        }))
      );
  }, [OrganisationList]);

  useEffect(() => {
    if (clients)
      setclientOption(
        clients?.map((option) => ({
          value: option._id,
          label: option.nom,
        }))
      );
  }, [clients]);

  useEffect(() => {
    if (societe_id) {
      dispatch(findvendeursBySocieteApi(societe_id));
      dispatch(findClientBySocieteApi(societe_id));
    }
  }, [societe_id]);

  useEffect(() => {
    setspinner(false);
  }, [listMouvementCaisse]);

  useEffect(() => {
    if (index !== 0) {
      setspinner(true);

      const data = {
        clients: selectedclient,
        organisations: selectedorganisation,
        vendeurs: selectedvendeur,
        index: index,
        dateDebut: date_debut,
        dateFin: date_fin,
        exportdata: false,
      };
      setindex(index);
      dispatch(mouvementVenteArticleApi(data));
    }
  }, [index]);

  const findMouvement = async () => {
    setspinner(true);
    const data = {
      clients: selectedclient,
      organisations: selectedorganisation,
      vendeurs: selectedvendeur,
      index: 1,
      dateDebut: date_debut,
      dateFin: date_fin,
      exportdata: false,
    };
    setindex(1);
    dispatch(mouvementVenteArticleApi(data));
  };

  const handelSelectVendeur = (selectedOption: any) => {
    const listvendeurs = selectedOption.map((item: any) => item.value);
    setselectedvendeur(listvendeurs);
  };
  const handelSelectOrganisation = (selectedOption: any) => {
    const listOrganisations = selectedOption.map((item: any) => item.value);
    setselectedorganisation(listOrganisations);
  };
  const handelSelectClient = (selectedOption: any) => {
    const listclient = selectedOption.map((item: any) => item.value);
    setselecteclient(listclient);
  };

  const exportToExcel = async () => {
    const data = {
      clients: selectedclient,
      organisations: selectedorganisation,
      vendeurs: selectedvendeur,
      index: 1,
      dateDebut: date_debut,
      dateFin: date_fin,
      exportdata: true,
    };
    const resp = await dispatch(mouvementVenteArticleApi(data));
    const list = resp.payload.data.list.map((item: any) => ({
      Ticket: item.order_number,
      Vendeur: item.vendeur?.nom,
      Caissier: item.user_id?.nom,
      Client: item.client_id?.nom,
      Organisation: item.organisation_id?.nom,
      Depots: item.depots_id?.libelle,
      "Prix TTC": item.total.toFixed(3),
      Remise: (item?.total - item?.totalNet).toFixed(3),
      "Prix payée": item?.totalNet.toFixed(3),
      Date: item.createdAt.split("T")[0],
    }));
    exportExcel("mouvements_caisse.xlsx", "mouvements_caisse", list);
  };
  return (
    <>
      <div className=" row  bg-white" style={{ padding: "20px" }}>
        <div className="col-2">
          <label>{t("Date debut")}</label>
          <input
            type="date"
            className="form-control "
            value={date_debut}
            onChange={(e) => setdate_debut(e.target.value)}
          />
        </div>
        <div className="col-2">
          <label>{t("Date fin")}</label>
          <input
            type="date"
            className="form-control "
            value={date_fin}
            onChange={(e) => setdate_fin(e.target.value)}
          />
        </div>
        <div className="col-2">
          <label>{t("Vendeur")}</label>
          <Select
            isMulti
            options={vendeurOption}
            isSearchable={true}
            onChange={handelSelectVendeur}
            value={selectedvendeur?.map((item: any) =>
              vendeurOption?.find((cat: any) => cat.value === item)
            )}
          />
        </div>

        <div className="col-2">
          <label>{t("Organisation")}</label>
          <Select
            isMulti
            options={OrganisationOption}
            isSearchable={true}
            onChange={handelSelectOrganisation}
            value={selectedorganisation?.map((item: any) =>
              OrganisationOption?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
        <div className="col-2">
          <label>{t("Client")}</label>
          <Select
            isMulti
            options={clientOption}
            isSearchable={true}
            onChange={handelSelectClient}
            value={selectedclient?.map((item: any) =>
              clientOption?.find((cat: any) => cat.value === item)
            )}
          />
        </div>
        {spinner ? (
          <Spinner animation="border" />
        ) : (
          <div
            className="col-1 mt-4"
            style={{ fontSize: "30px" }}
            onClick={findMouvement}
          >
            {" "}
            <i className="fas fa-search"></i>
          </div>
        )}
      </div>

      <div className=" row  mt-5  bg-white  " style={{ padding: "20px" }}>
        <div className="col-4">
          <strong>
            {t("Total Prix TTC")} : {totaux!==null &&totaux.total_total}{" "}
          </strong>
        </div>
        <div className="col-4">
          <strong>
            {" "}
            {t("Total Remise")} : {totaux?.total_remise}{" "}
          </strong>
        </div>
        <div className="col-4">
          <strong>
            {" "}
            {t("Total Net a payer")} : {totaux?.total_totalNet}{" "}
          </strong>
        </div>

        <div className="col-4 mt-3">
          {totaux?.tiket_max !== null ? (
            <strong
              className="  lien-bleu-souligne"
              onClick={() => {
                SetTicket(totaux?.tiket_max);

                setShowModalTicket(true);
              }}
            >
              {" "}
              {t("Ticket max Net a payer")} :{" "}
              {totaux?.tiket_max?.ticket_order?.order_number}
              {" valeur: " +
                totaux?.tiket_max?.ticket_order?.totalNet}{" "}
            </strong>
          ) : null}
        </div>
        <div className="col-4 mt-3">
          {totaux?.tiket_min !== null ? (
            <strong
              className="  lien-bleu-souligne"
              onClick={() => {
                SetTicket(totaux?.tiket_min);

                setShowModalTicket(true);
              }}
            >
              {t("Ticket min Net a payer")} :
              {totaux?.tiket_min?.ticket_order?.order_number +
                " valeur:" +
                totaux?.tiket_min?.ticket_order?.totalNet}{" "}
            </strong>
          ) : null}
        </div>

        <div className="col-4 mt-3">
          <strong
            className="  lien-bleu-souligne"
            onClick={() => {
              SetTicket(totaux?.tiket_moyenne);

              setShowModalTicket(true);
            }}
          >
            {" "}
            {t("Ticket moyenne Net a payer")}:{" "}
            {totaux?.tiket_moyenne !== null
              ? totaux?.tiket_moyenne.ticket_order?.order_number +
                " valeur:" +
                totaux?.tiket_moyenne?.ticket_order?.totalNet
              : null}{" "}
          </strong>
        </div>
      </div>

      <div className="row layout-spacing mt-5">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th colSpan={2}>{t("Ticket")}</th>
                <th>{t("Process")}</th>
                <th>{t("Vendeur")}</th>
                <th>{t("Caissier")}</th>
                <th>{t("Client")}</th>
                <th>{t("Organisation")}</th>
                <th> {t("Depots")}</th>
                <th>{t("Prix TTC")}</th>
                <th>{t("Remise")}</th>
                <th>{t("Prix payée")}</th>
                <th>{t("Date")}</th>
              </thead>

              <tbody>
                {listMouvementCaisse &&
                  listMouvementCaisse.length !== 0 &&
                  listMouvementCaisse.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="  lien-bleu-souligne"
                        onClick={() => {
                          SetTicket(item);
                          setShowModalTicket(true);
                        }}
                      >
                        {item?.order_number}
                      </td>
                      <td style={{color:'red' }}>{item?.ticket_order?.status ==="Annulée" ?t("Vente annulée") :item?.orderAnnuleNumber}</td>
                      <td>{item?.process}</td>
                      <td>{item?.vendeur?.nom}</td>
                      <td>{item?.user_id?.nom}</td>
                      <td>{item?.client_id?.nom}</td>
                      <td>{item?.organisation_id?.nom}</td>
                      <td>{item?.depots_id?.libelle}</td>
                      <td>{item?.total?.toFixed(3)}</td>
                      <td>{(item?.total - item?.totalNet)?.toFixed(3)}</td>
                      <td>{item?.totalNet?.toFixed(3)}</td>
                      <td>{formatDateAndTime(item?.createdAt)}</td>
                    </tr>
                  ))}
                <tr>
                  <td>
                    <i
                      onClick={exportToExcel}
                      className="far fa-file-excel fa-lg"
                    ></i>
                  </td>
                  <td>
                    <strong>
                      {t("Nombre des tickets")}:{count}
                    </strong>
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>
                      {listMouvementCaisse
                        .reduce((acc, item) => {
                          return acc + Number(item?.totalNet) || 0;
                        }, 0)
                        .toFixed(3)}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {listMouvementCaisse
                        .reduce((acc, item) => {
                          return (
                            acc + (item?.total - Number(item?.totalNet) || 0)
                          );
                        }, 0)
                        .toFixed(3)}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {listMouvementCaisse
                        .reduce((acc, item) => {
                          return acc + Number(item?.total) || 0;
                        }, 0)
                        .toFixed(3)}
                    </strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
      <TicketDeCaissePaid
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        ticket={ticket}
      />
    </>
  );
}
