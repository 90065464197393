import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { SelectedOrganisation } from "../../../_App/Redux/Slices/Auth/authSlice";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { getOrderByIdApi } from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { formatdate, formatTime } from "../../../_App/Helpers/helpers";

const TicketRestaurant = ({ order_id, ShowModal, setShowModal }: any) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const parametrage = useAppSelector(parametrageCaisse);
  const [bon, setBon] = useState<OrderType>();
  const dispatch = useAppDispatch();
  const magasin = useAppSelector(SelectedOrganisation);

  useEffect(() => {
    const fetchData = async () => {
      if (order_id) {
        const res = await dispatch(getOrderByIdApi(order_id));
        if (res.payload._id) {
          setBon(res.payload);
        }
      }
    };

    fetchData();
  }, [dispatch, order_id]);

  const handlePrint = () => {
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      printFrame.contentWindow?.print();
    }
  };

  return (
    <Modal
      show={ShowModal}
      onHide={() => {
        setShowModal(false);
      }}
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <div ref={componentRef}>
        <div className="container-ticket" id="print-content">
          <div className="receipt_header">
            <h1>
              {bon && bon.type} <span>{magasin?.nom} </span>
            </h1>
          </div>

          <div className="receipt_body">
            <div className="date_time_con">
              <div className="date"> {bon?.date.toString().split("T")[0]} </div>
              <div className="time">
                {" "}
                {formatTime({ date: new Date().toISOString() })}
              </div>
            </div>

            <div className="ticket-items">
              <h1 style={{ textAlign: "center" }}>
                {bon && bon.order_number}{" "}
              </h1>
              {bon?.items.map((item, index) => (
                <div>
                  {item.quantity} {" x "} {item.article.titre.fr}
                </div>
              ))}
            </div>
            {/* <div
              className="barcode"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {bon && <Barcode value={bon.code} />}
            </div> */}
          </div>
          <div className="receipt-footer mt-2">
            <p style={{ textAlign: "center" }}>
              {bon && bon.note && "Note : " + bon.note}
            </p>
          </div>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            color=""
            onClick={handlePrint}
            className="btn-info"
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
      <iframe
        id="print-frame"
        title="print-frame"
        style={{ display: "none" }}
        onLoad={() => handlePrint()}
      />
    </Modal>
  );
};

export default TicketRestaurant;
