import React, { useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { findUsersByCodeApi } from "../../_App/Redux/Slices/users/userSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function VerifAdmin({
  ShowModal,
  setShowModal,
  societe,
  user,
  setuser,
  setConfirmer,
}: any) {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const dispatch = useAppDispatch();

  const handlechangeAdmin = async (e: any) => {
    const value = e.target.value;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(async () => {
      if (value !== "") {
        const data = { societe: societe, code: value };

        const resultArticle = await dispatch(findUsersByCodeApi(data));
        if (resultArticle.payload.success) {
          const user = resultArticle.payload.data;

          user.role_id.libelle === "admin"
            ? setuser(user)
            : ToastWarning("Le code n'est pas associé à un administrateur");
        } else {
          ToastWarning(resultArticle.payload.message);
        }
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };
  const removeUser = () => {
    setuser(undefined);
  };
  const confirmer = () => {
    if (user) {
      setConfirmer(true);
      setShowModal(false);
    } else {
      ToastWarning("Entrer code a barre d'un administrateur");
    }
  };

  return (
    <div>
      <Modal
        show={ShowModal}
      
        className="modal-1"
        dialogClassName="modal-90w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header >
          <Modal.Title className="c-primary">
            {" "}
            <span>{"Verif Admin"}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Card>
        <Card.Body>
          <Card.Title>Accès Restreint</Card.Title>
          <Card.Text>
            Cette page est protégée. Veuillez entrer le code pour accéder à la
            confirmation du retour échange.
          </Card.Text>
          <Form.Group className="mb-3" controlId="formCode">
            <Form.Label>Code d'accès</Form.Label>
            {!user ? (
              <Form.Control
                type="password"
                placeholder="Entrez le code"
                onChange={(e) => handlechangeAdmin(e)}
              />
            ) : (
              <span className="form-control">
                {user.nom + " " + user.prenom}
                <i onClick={removeUser} className="fas fa-times"></i>
              </span>
            )}
          </Form.Group>
          {user && (
            <Button variant="primary" onClick={confirmer}>
              Valider
            </Button>
          )}
        </Card.Body>
      </Card>

          
        </Modal.Body>
    
      </Modal>
    </div>
  );
}
