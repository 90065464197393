import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Nav, Row, Tab, Form } from "react-bootstrap";
import styled from "styled-components";
import { deleteUserFromOrganisationApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { OrganisationDataType } from "../../_App/Types/Forms/OrganisationDataType";
import ZoneTableList from "../ZoneTables/ZoneTableList";
import OrganisationsFormUpdate from "./OrganisationsFormUpdate";
import { OrganisationType } from "../../_App/Types/Entites/OrganisationType";
import { useTranslation } from "react-i18next";
import { ListCommissionUser } from "../../_App/Redux/Slices/commission/commissionSlice";
import CommissionUserList from "../Commission/CommissionUserList";
import OrganisatioCodeBarre from "./OrganisatioCodeBarre";

const CustomModalBody = styled(Modal.Body)`
  p {
    margin: 5px 0;
    color: #333;
    padding: 3px;
  }
`;

const CustomModalHeader = styled(Modal.Header)``;

const CustomCard = styled(Card)`
  margin: 0 auto;
  height: 100%;

  img {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .card-title {
    margin-bottom: 1rem;
  }

  .card-text {
    margin-bottom: 1rem;
  }

  .d-flex {
    flex-wrap: nowrap;
  }

  @media (min-width: 576px) {
    max-width: 400px;
  }

  @media (min-width: 768px) {
    max-width: 600px;
  }

  @media (min-width: 992px) {
    max-width: 800px;
  }
`;

const ConfirmDeleteModal: React.FC<{
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ show, onClose, onConfirm }) => {
  const { t } = useTranslation();
   
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title> {t("Confirmation de suppression")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t("Annuler")}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {t("Enregistrer")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const CustomNav = styled(Nav)``;

const OrganisationDetails: React.FC<{
  organisation: OrganisationDataType;
  organisationId: string;
  onClose: () => void;
  organisationUsers: any[];
  listActivite:any[]
}> = ({ organisation, organisationId, onClose, organisationUsers ,listActivite}) => {
  const [localOrganisationUsers, setLocalOrganisationUsers] = useState<any[]>(
    []
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [organisationToUpdate, setOrganisationToUpdate] =
    useState<OrganisationDataType | null>(null);
    const { t } = useTranslation();
    const listCommission = useAppSelector(ListCommissionUser)
    const [showModalCommission , setshowModalCommission]=useState(false)
    const [listCommissionUser , setlistCommissionUser]=useState<any[]>([])
    const [userselected , setuser]=useState()

  const handleModificationTab = () => {
    setOrganisationToUpdate(organisation);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLocalOrganisationUsers(organisationUsers);
  }, [organisationUsers]);

  const handleDeleteUser = (userId: string, organisationId: string) => {
    setShowDeleteConfirmation(true);
    setUserIdToDelete(userId);
  };

  const confirmDeleteUser = async () => {
    try {
      await dispatch(
        deleteUserFromOrganisationApi({
          userId: userIdToDelete,
          organisationId: organisation._id,
        })
      );

      const updatedUsers = localOrganisationUsers.filter(
        (user) => user._id !== userIdToDelete
      );
      setLocalOrganisationUsers(updatedUsers);

      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de l'utilisateur de l'organisation",
        error
      );
    }
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };
  const [showZoneTableForm, setShowZoneTableForm] = useState(false);

  const handleUpdate = (data: OrganisationType) => {};
  const getCommission=(user:any)=>{
    setuser(user)
    setlistCommissionUser(listCommission[user._id]||[])
    setshowModalCommission(true)
  }
  return (
    <>
      <CustomModalHeader closeButton>
        <Modal.Title className="text-primary mb-9">
          {organisation.nom}
        </Modal.Title>
      </CustomModalHeader>
      <CustomModalBody>
        <Tab.Container id="tabs" defaultActiveKey="utilisateurs">
          <CustomNav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="utilisateurs"> {t("Utilisateurs")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="modification">{t("Modification")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="zonesEtTables">zones et tables</Nav.Link>
            </Nav.Item>
          </CustomNav>
          <Tab.Content>
            <Tab.Pane eventKey="utilisateurs">
              <Row xs={1} md={2} lg={3} className="g-4 gx-2">
                {localOrganisationUsers.map((user) => (
                  <Col key={user._id} className="mb-4">
                    <CustomCard className="user-card">
                      <div className="card-body" style={{ height: "100%" }}>
                        <img
                          src={user.image}
                          className="rounded-circle"
                          alt="User Avatar"
                        />

                        <h5 className="card-title">
                          {user.nom}
                          <div>{user.prenom}</div>
                        </h5>

                        <p className="card-text">
                          <i
                            className="fas fa-user-tag"
                            style={{
                              color: "#0858d9",
                              marginRight: "5px",
                            }}
                          ></i>
                          {user.role_id?.libelle}
                        </p>
                        <p className="card-text">
                          {" "}
                          <i
                            className="fas fa-phone"
                            style={{
                              color: "#0bcb4e",
                              marginRight: "5px",
                            }}
                          ></i>{" "}
                          {user.mobile}
                        </p>
                        <p className="card-text">
                          <i
                            className="far fa-envelope"
                            style={{
                              color: "#8b1593",
                              marginRight: "5px",
                            }}
                          ></i>
                          {user.email}{" "}
                        </p>
                        <Row className="d-flex align-items-center">
                          <Col md={4}>
                            <Form.Group controlId="formOnlineStatus">
                              <p className="d-flex align-items-center">
                                <i
                                  className={`fas fa-circle ${
                                    user.online ? "text-success" : "text-danger"
                                  }`}
                                  style={{
                                    marginRight: "8px",
                                    fontSize: "17px",
                                  }}
                                ></i>
                                <span>
                                  {user.online ? "Connecté" : "Déconnecté"}
                                </span>
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="formIsActive">
                              <p className="d-flex align-items-center">
                                {user.is_active ? (
                                  <i
                                    className="fas fa-unlock"
                                    style={{
                                      color: "green",
                                      marginRight: "8px",
                                      marginLeft: "10px",

                                      fontSize: "17px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-ban"
                                    style={{
                                      color: "red",
                                      marginRight: "8px",
                                      marginLeft: "8px",
                                      fontSize: "17px",
                                    }}
                                  ></i>
                                )}
                                <span>
                                  {user.is_active ? "Actif" : "Inactif"}
                                </span>
                              </p>
                            </Form.Group>
                          </Col>

                          <Col md={4}>
                            <Form.Group controlId="formConge">
                              <p className="d-flex align-items-center">
                                {user.conge ? (
                                  <i
                                    className="fas fa-umbrella-beach"
                                    style={{
                                      color: "#ff0000",
                                      marginRight: "8px",
                                      fontSize: "17px",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fas fa-business-time"
                                    style={{
                                      color: "blue",
                                      marginRight: "8px",
                                      fontSize: " 17px",
                                    }}
                                  ></i>
                                )}
                                <span>
                                  {user.conge ? "En congé" : "En travail"}
                                </span>
                              </p>
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            style={{ marginLeft: "auto" }} 
                            type="button"
                            onClick={() =>
                              handleDeleteUser(user._id, organisation._id)
                            }
                            variant="link"
                            className="text-danger"
                          >
                            {t("Supprimer")}
                          </Button>
                          <Button
                            style={{ marginLeft: "auto" }} 
                            type="button"
                            onClick={() =>
                              getCommission(user)
                            }
                            variant="link"
                            className="text-danger"
                          >
                            {t("Commission")}
                          </Button>
                        </div>
                      </div>
                    </CustomCard>
                  
                  </Col>
                ))}
                  <CommissionUserList 
                    t={t}
                    setshowModal={setshowModalCommission}
                    showModal={showModalCommission}
                    listcommission={listCommissionUser}
                    user={userselected}
                    />
              </Row>
            </Tab.Pane>

            <Tab.Pane eventKey="zonesEtTables">
              <ZoneTableList selectedOrganisationId={organisationId} />
            </Tab.Pane>
          </Tab.Content>
          <Tab.Pane eventKey="modification" onEnter={handleModificationTab}>
            {organisationToUpdate && (
              <OrganisationsFormUpdate
                organization={organisationToUpdate}
                onClose={onClose}
                onUpdate={handleUpdate}
                listActivite={listActivite}
              />
            )}
          </Tab.Pane>
        </Tab.Container>
      </CustomModalBody>

      <ConfirmDeleteModal
        show={showDeleteConfirmation}
        onClose={closeDeleteConfirmation}
        onConfirm={confirmDeleteUser}
      />
    
    </>
  );
};

export default OrganisationDetails;
