import React, { useEffect } from 'react'

export default function SoucheTable({listOrganisation,listSouche,t,setSelelectedOrganisation,selectedOrganisation,setShowModal,setsouche}:any) {
  return (
    <div className=' bg-white layout-spacing p-20'>
        <>
      <div className="col-md-3">
      <label>{t('Organisation')}</label>

        <select className="wd-300 form-control product-search br-30 "
        value={selectedOrganisation}
        onChange={(e)=>setSelelectedOrganisation(e.target.value)}
        >
            {listOrganisation.map((org:any)=>(
                <option value={org._id}>
                    {org?.nom}
                </option>
            ))}
            <option value={'null'}>{t('societe')}</option>
        </select>
       
      </div>
      <table className="table ">
        <thead>
          <tr>
            <th>{t("Type")}</th>
            <th>{t("Prefixes")}</th>
            <th>{t(" séquence")}</th>
            <th></th>

          </tr>
        </thead>
        <thead>
            {listSouche.map((item:any)=>(
                <tr>
                    <td>{item.type}</td>
                    <td>{item.seq}</td>
                    <td>{item.souche}</td>
                    <td> <span 
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5em",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    setsouche(item);
                    setShowModal(true);
                  }}
                >
                  <i className="fas fa-edit" style={{ color: "#06958d" }}></i>
                </span></td>
                </tr>
            ))}
        </thead>
       
      </table>
    </>
    </div>
  )
}
