import React, { useEffect, useState } from "react";
import DimmensionMatrice from "./DimmensionMatrice";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";
import { createMatriceApi, updateMatriceApi } from "../../../../_App/Redux/Slices/matrice/matriceSlice";
import MatriceType from "../../../../_App/Types/Entites/MatriceType";
import { societeId } from "../../../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import { ToastSuccess } from "../../../../Shared/Toasts/ToastSuccess";
import { Modal } from "react-bootstrap";
import { areArraysEqual } from "../../../../_App/Helpers/helpers";
import {
  ListProductCaracteristiques,
  findProductsCaracteristiquesApiBySociete,
} from "../../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
const Matrice = ({
  setmatriceselected,
  ShowModal,
  setShowModal,
  caracteristique,
  matrice
}: any) => {
  const societe = useAppSelector(societeId);
  const dispatch = useAppDispatch();
  const [list, setlist] = useState<any[]>([[], [], []]);
  const [checkedlist, setcheckedlist] = useState<any[]>([]);
  const [name_matrice, setname_matrice] = useState("");
  const [error, seterror] = useState(false);

  const [listcaracterisqtiqueitems, setlistcaracterisqtiqueitems] = useState<
    any[]
  >([]);
  const listcaracteristique = useAppSelector(ListProductCaracteristiques);
  useEffect(() => {
    if (societe) {
      findProductsCaracteristiquesApiBySociete(societe);
    }
  }, [societe]);
  useEffect(() => {
   // setlistcaracterisqtiqueitems([]);
    setlist([[], [], []]);
    setcheckedlist([]);
    setname_matrice("")
   
    if(matrice?._id && ShowModal){

      setname_matrice(matrice?.name_matrice)

        setlist((prev: any) => {
          const newListArray = [...prev];
          newListArray[0] = matrice?.list_dimension1;
          return newListArray;
        });
        setlist((prev: any) => {
          const newListArray = [...prev];
          newListArray[1] = matrice?.list_dimension2;
          return newListArray;
        });
        setlist((prev: any) => {
          const newListArray = [...prev];
          newListArray[2] = matrice?.list_dimension3;
          return newListArray;
        });
        setcheckedlist(matrice.checked)
   
    }
  }, [matrice]);

  const setchecked = (item0: any, item1: any, item2: any) => {
    const  itemlist = [item0, item1, item2];

  const check = checkedlist.some((item) =>areArraysEqual(item, itemlist))
    //check
    if (check) {
      //remove
      
      const removedlist = checkedlist.filter((item) => !areArraysEqual(item, itemlist));
      setcheckedlist(removedlist);
    } else {
      //add

      setcheckedlist((prev: any) => [...prev, itemlist]);
    }
  };
  /*const areArraysEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };
*/
  const handelsave = async () => {
    if (name_matrice === "" ) {
      seterror(true);
    } else {
      seterror(false);
      if (societe) {
        const data: MatriceType = {
          societe: societe,
          dimension1: caracteristique[0],
          dimension2: caracteristique[1],
          dimension3: caracteristique[2],
          list_dimension1: list[0],
          list_dimension2: list[1],
          list_dimension3: list[2],
          checked: checkedlist,
          name_matrice: name_matrice,
        };
        let response ; 
        if(matrice._id){
          data._id = matrice._id
          response = await dispatch(updateMatriceApi(data));

        }else{
           response = await dispatch(createMatriceApi(data));

        }
        setmatriceselected(response.payload.data)
        if (response.payload.success) {
       
          ToastSuccess((response.payload.message));
          setShowModal(false)
          setname_matrice("")
        } else {
         
          ToastWarning((response.payload.message));
        }
      }
    }
  };

  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-70w"
    >
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">Ajouter Matrice</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <div className="row ">
            <DimmensionMatrice
              setlist={setlist}
              list={list}
              setlistcaracterisqtiqueitems={setlistcaracterisqtiqueitems}
              listcaracterisqtiqueitems={listcaracterisqtiqueitems}
              caracteristique={caracteristique}
              societe={societe}
              listcaracteristique={listcaracteristique}
            />
          </div>
          <div className="row col-md-4 mx-auto ">
          <label htmlFor="matrice">
        <span style={{ color: "red" }}>*</span> nom matrice{" "}
      </label>
            <input
              type="text"
              defaultValue={ matrice?.name_matrice}
              onBlur={(e) => setname_matrice(e.target.value)}
              className={`form-control ${error ? "is-invalid" : ""}`}
            />{" "}
            {error && <div className="invalid-feedback">Le nom du matrice est obligatoire</div>}
          </div>

          <div className=" mt-3 table-responsive">
            <table
              id="style-2"
              className=" style-2 text-center bg-white "
            >
              <thead style={{ background: "#f1f2f3" }}>
                <tr>
                  <th className="text-center"></th>

                  {list[1]?.map((item1: any, poidsIndex: number) => (
                    <th className="text-center" key={poidsIndex}  >
                      <div  style={{
                      backgroundColor: listcaracteristique.find(
                        (item: any) => item._id === caracteristique[1]
                      )?.color
                        ? item1.valeur
                        : null,
                        height: '40px'
                    }}>{ listcaracteristique.find(
                            (item: any) => item._id === caracteristique[1]
                          )?.color
                            ? item1.libelle
                            : item1}</div>
                      {list[2]?.map((item2: any) => (
                        <th
                          className="border"
                       

                        >
                          <div    style={{
                            cursor: "pointer",
                            width: "100px",
                            height: '30px',
                            backgroundColor: listcaracteristique.find(
                              (item: any) => item._id === caracteristique[2]
                            )?.color
                              ? item2.valeur
                              : null,
                          }}> { listcaracteristique.find(
                            (item: any) => item._id === caracteristique[2]
                          )?.color
                            ? item2.libelle
                            : item2}</div>
                         
                        </th>
                      ))}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {list[0]?.map((item0: any, item0Index: number) => (
                  <tr key={item0Index}>
                    <td
                      className="text-center"
                     
                      
                    >
                      <div  style={{
                        cursor: "pointer",
                        width: "100px",
                        padding: "0 0 0 0",
                        border: "1px solid #ccc",
                        height: '40px',
                          backgroundColor: listcaracteristique.find(
                            (item: any) => item._id === caracteristique[0]
                          )?.color
                            ? item0.valeur
                            : null,
                      
                      }}>{ listcaracteristique.find(
                            (item: any) => item._id === caracteristique[0]
                          )?.color
                            ? item0.libelle
                            : item0}</div>
                    </td>

                    {list[1]?.map((item1: any, item1Index: number) => (
                      <td
                        key={item1Index}
                        style={{
                          cursor: "pointer",
                          width: "50px",
                        }}
                      >
                        {list[2]?.length !== 0 ? (
                          list[2]?.map((item2: any, item2Index: number) => (
                            <td
                              key={item2Index}
                              className="border"
                              style={{
                                height: "50px",
                                width: "100px",
                              }}
                            >
                              <div className="checkbox-container">
                                <input
                                  type="checkbox"
                                  style={{
                                    height: "50px",
                                    width: "100%",
                                  }}
                                  checked={checkedlist.some((item) =>
                                    areArraysEqual(item, [item0?.valeur || item0, item1?.valeur || item1, item2?.valeur || item2])
                                  )}
                                  onChange={() =>
                                    setchecked(item0?.valeur || item0, item1?.valeur || item1, item2?.valeur || item2)
                                  }
                                />
                                <div className="checkbox-label"></div>
                              </div>
                            </td>
                          ))
                        ) : (
                          <td
                            className="border"
                            style={{
                              cursor: "pointer",
                              width: "100px",
                            }}
                          >
                            <div className="checkbox-container">
                              <input
                                type="checkbox"
                                style={{
                                  height: "50px",
                                  width: "100px",
                                }}
                                onChange={() =>
                                  setchecked(item0?.valeur || item0, item1?.valeur || item1, null)

                                }
                                checked={checkedlist.some((item) =>
                                  areArraysEqual(item, [item0?.valeur || item0, item1?.valeur || item1, null])

                                )}
                              />
                              <div className="checkbox-label"></div>
                            </div>
                          </td>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
       
        {!matrice?._id ? (
           <button className="btn btn-primary-app" onClick={handelsave}>
           {" "}
           Enregistrer
         </button>
          
        ):null} 
      </Modal.Footer>
    </Modal>
  );
};

export default Matrice;
