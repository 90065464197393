import { groupBy } from "lodash";
// PanierSlice.js
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { MouvementCaisseType } from "../../../Types/Entites/MouvementCaisseType";
import { MouvementCaisseDataType } from "../../../Types/Forms/MouvementCaisseDataType";
import { TypeMouvementType } from "../../../Types/Entites/TypeMouvementType";

export const saveMouvementsCaisseApi = createAsyncThunk(
  "mouvementcaisse/saveMouvementsCaisseApi",
  async (data: MouvementCaisseDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "mouvementcaisse/saveMouvementsCaisseApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const saveTypeMouvemetApi = createAsyncThunk(
  "mouvementcaisse/saveTypeMouvemetApi",
  async (data: TypeMouvementType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "mouvementcaisse/saveTypeMouvemetApi",
        data
      );
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const deleteTypeMouvement = createAsyncThunk(
  "mouvementcaisse/deleteTypeMouvement",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        "mouvementcaisse/deleteTypeMouvement",
        { data: { id } }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findMouvementsCaisseByOrganisationApi = createAsyncThunk(
  "mouvementcaisse/findMouvementsCaisseByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `mouvementcaisse/findMouvementsCaisseByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTypeMouvementByOrganisationApi = createAsyncThunk(
  "mouvementcaisse/findTypeMouvementByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `mouvementcaisse/findTypeMouvementByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findMouvementsCaisseByJournalApi = createAsyncThunk(
  "mouvementcaisse/findMouvementsCaisseByJournalApi",
  async (idJournal: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `mouvementcaisse/findMouvementsCaisseByJournalApi/${idJournal}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface MouvementCaisseState {
  listMouvementsCaisse: MouvementCaisseType[];
  listJournalMouvementsCaisse: MouvementCaisseType[];
  listMouvementsCaisseParJournal: {
    [journalId: string]: MouvementCaisseType[];
  };
  listMouvementsCaisseParSession: {
    [sessionId: string]: MouvementCaisseType[];
  };
  listMouvementsCaisseCommande: {
    [order_id: string]: MouvementCaisseType[];
  };
  listMouvementsCaisseCommandeBySession: {
    [session_id: string]: {
      [order_id: string]: any;
    }[];
  };
  listMouvementsCaisseOperation:any[]

  listTypesMouvements: TypeMouvementType[];
}

const initialState: MouvementCaisseState = {
  listMouvementsCaisse: [],
  listJournalMouvementsCaisse: [],
  listMouvementsCaisseParJournal: {},
  listMouvementsCaisseParSession: {},
  listMouvementsCaisseCommande: {},
  listMouvementsCaisseCommandeBySession: {},
  listTypesMouvements: [],
  listMouvementsCaisseOperation:[]
};

export const MouvementCaisseSlice = createSlice({
  name: "mouvements_caisse",
  initialState,
  reducers: {
    addMouvementCaisse: (state, action) => {
      return {
        ...state,
        listMouvementsCaisseOperation: [ ...state.listMouvementsCaisse ,action.payload],
      };
    },
    resetMouvementOperation: (state,action) => {
      return {
        ...state,
        listMouvementsCaisseOperation: [],
      };
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(saveMouvementsCaisseApi.fulfilled, (state, action) => {
      state.listMouvementsCaisse.splice(0, 0, action.payload.data);
    });
    builder.addCase(saveTypeMouvemetApi.fulfilled, (state, action) => {
      state.listTypesMouvements.splice(0, 0, action.payload.data);
    });
    builder.addCase(
      findMouvementsCaisseByOrganisationApi.fulfilled,
      (state, action) => {
        const groupedByJournalId = _.groupBy(action.payload, "journal_id._id");
        return {
          ...state,
          listMouvementsCaisse: action.payload,
          listMouvementsCaisseParJournal: groupedByJournalId,
        };
      }
    );
    builder.addCase(
      findTypeMouvementByOrganisationApi.fulfilled,
      (state, action) => {
        return {
          ...state,
          listTypesMouvements: action.payload,
        };
      }
    );
    builder.addCase(deleteTypeMouvement.fulfilled, (state, action) => {
      const deletedType = action.payload.data;
      state.listTypesMouvements = _.remove(
        state.listTypesMouvements,
        (type: TypeMouvementType) => type._id !== deletedType._id
      );
    });
    builder.addCase(
      findMouvementsCaisseByJournalApi.fulfilled,
      (state, action) => {
        var sessionMouvements = _.groupBy(action.payload, "session_id._id");

        var groupedBySession = _.groupBy(action.payload, "session_id._id");
        var groupedByorderss = _.groupBy(action.payload, "order_id._id");

        Object.entries(groupedBySession).forEach(([key, value]) => {
          const groupedByOrder = _.groupBy(value, "order_id.order_number");
          // Assign the modified value back to the groupedBySession object
          groupedBySession[key] = groupedByOrder;
        });
        console.log("action.payload",action.payload)
        return {
          ...state,
          listJournalMouvementsCaisse: action.payload,
          listMouvementsCaisseCommandeBySession: groupedBySession,
          listMouvementsCaisseCommande: groupedByorderss,
          listMouvementsCaisseParSession: sessionMouvements,
        };
      }
    );
  },
});

export const ListMouvementsCaisse = (state: RootState) =>
  state.mouvementsCaisse.listMouvementsCaisse;
export const ListMouvementsCaisseOperation = (state: RootState) =>
  state.mouvementsCaisse.listMouvementsCaisseOperation;
export const ListJournalMouvementsCaisse = (state: RootState) =>
  state.mouvementsCaisse.listJournalMouvementsCaisse;
export const listMouvementsCaisseParJournal = (state: RootState) =>
  state.mouvementsCaisse.listMouvementsCaisseParJournal;
export const listMouvementsCaisseParSession = (state: RootState) =>
  state.mouvementsCaisse.listMouvementsCaisseParSession;
export const listMouvementsCaisseCommandeBySession = (state: RootState) =>
  state.mouvementsCaisse.listMouvementsCaisseCommandeBySession;
export const listMouvementsCaisseParCommandes = (state: RootState) =>
  state.mouvementsCaisse.listMouvementsCaisseCommande;
export const listTypeMouvements = (state: RootState) =>
  state.mouvementsCaisse.listTypesMouvements;
export const {
  addMouvementCaisse,
  resetMouvementOperation
} = MouvementCaisseSlice.actions;
export default MouvementCaisseSlice.reducer;
