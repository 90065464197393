import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { CategorieSchema } from "../../_App/Schemas/CategorieSchema";
import {
  createCategorieApi,
  updateCategorieApi,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  findCarteBySocieteApi,
  ListCartes,
  ListCarteParOrganisation,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import { CarteType } from "../../_App/Types/Entites/CarteType";
export default function CategorieForm({
  ShowModal,
  setShowModal,
  categorie,
  currentOrganisation,
  societe,
}: any) {
  const dispatch = useAppDispatch();
  const listCartes = useAppSelector(ListCartes);
  const listCarteParOrganisation = useAppSelector(ListCarteParOrganisation);
  const [list_carte, setlist_carte] = useState<CarteType[]>([]);
  // init form hooks
  const formOptions = { resolver: yupResolver(CategorieSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const [selectedOrganisation, setselectedOrganisation] = useState(true);

  useEffect(() => {
    if (categorie) {
      reset({
        libelle: categorie.libelle,
        image: categorie.image,
        carte_id: categorie.carte_id,
      });
      categorie.organisation_id !== null
        ? setselectedOrganisation(true)
        : setselectedOrganisation(false);
    } else {
      reset({
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
        carte_id: "",
      });
      setselectedOrganisation(true);
    }
  }, [categorie,ShowModal]);

  useEffect(() => {
    if (societe) {
      dispatch(findCarteBySocieteApi(societe));
    }
  }, [societe, ShowModal]);

  useEffect(() => {
    if (selectedOrganisation) {
      const list = listCarteParOrganisation[currentOrganisation];
      list ? setlist_carte(list) : setlist_carte([]);
    } else {
      setlist_carte(listCartes);
    }
  }, [selectedOrganisation, listCarteParOrganisation, listCartes]);

  const onSubmit = async (data: any) => {
    let response;
    if (selectedOrganisation) {
      data.organisation_id = currentOrganisation;
    } else {
      data.organisation_id = null;
    }
    data.societe_id = societe;
    if (categorie && categorie._id) {
      // If the categorie has an _id, it means it's an update
      data._id = categorie._id;

      response = await dispatch(updateCategorieApi(data));
    } else {
      // data.carte_id = carteId;
      response = await dispatch(createCategorieApi(data));
    }

    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
        carte_id:''
      });
      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  return (
    <Modal 
    className="modal-2"
    dialogClassName="modal-40w"
    backdropClassName="modal-backdrop-2"
    show={ShowModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>
          {categorie && categorie._id ? (
            <span className="modal-title" id="addCategorieLabel">
              Modifier la Catégorie N° : {categorie?._id}
            </span>
          ) : (
            <span className="modal-title c-primary" id="addCategorieLabel">
              Ajouter une categorie
            </span>
          )}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            setShowModal(false);
            reset({
              libelle: {
                fr: "",
                en: "",
                ar: "",
              },
              image: null,
              carte_id:''
            });
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                {/* <input
                  type="hidden"
                  placeholder="Id"
                  id="id"
                  {...register("id")}
                /> */}
                <label htmlFor="libellefr">
                  {" "}
                  <span style={{ color: "red" }}>*</span>Libelle fr
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.fr ? "is-invalid" : ""
                  }`}
                  id="libellefr"
                  {...register("libelle.fr")}
                />
                <div className="invalid-feedback">
                  {errors?.libelle?.fr &&
                    errors.libelle.fr.message &&
                    errors.libelle.fr.message.toString()}
                </div>
                <label htmlFor="libelleen">
                  {" "}
                  <span style={{ color: "red" }}>*</span>Libelle en
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.en ? "is-invalid" : ""
                  }`}
                  id="libelleen"
                  {...register("libelle.en")}
                />
                <div className="invalid-feedback">
                  {errors?.libelle?.en &&
                    errors.libelle.en.message &&
                    errors.libelle.en.message.toString()}
                </div>
                <label htmlFor="libellear">
                  {" "}
                  <span style={{ color: "red" }}>*</span>Libelle ar
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.ar ? "is-invalid" : ""
                  }`}
                  id="libellear"
                  {...register("libelle.ar")}
                />
                <div className="invalid-feedback">
                  {errors?.libelle?.ar &&
                    errors.libelle.ar.message &&
                    errors.libelle.ar.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="image"> <span style={{ color: "red" }}>*</span>Image</label>
              <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="image">
                  <i className="os-icon os-icon-documents-07"></i>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className={`form-control ${errors.image ? "is-invalid" : ""}`}
                  id="image"
                  placeholder="image"
                  {...register("image")}
                />
              </div>
              <div className="invalid-feedback">
                {errors.image &&
                  errors.image.message &&
                  errors.image.message.toString()}
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="image"> <span style={{ color: "red" }}>*</span>Carte</label>
              <div className="input-group mb-3">
                <label htmlFor="carte"></label>
                <select
                  className={`form-control ${
                    errors.carte_id ? "is-invalid" : ""
                  }`}
                  id="carte"
                  {...register("carte_id")}
                >
                  {list_carte.map((item: any) => (
                    <option key={item._id} value={item._id}>
                      {" "}
                      {item?.libelle?.fr}
                    </option>
                  ))}
                </select>
               
              </div>
            </div>
            <div className="n-chk">
              <label className="new-control new-radio radio-classic-primary">
                <input
                  type="radio"
                  checked={selectedOrganisation}
                  onChange={(e) => setselectedOrganisation(true)}
                  value="connectedOnly"
                  className="new-control-input"
                  name="custom-radio-2"
                />
                <span className="new-control-indicator" />
                <p className="fs-15">l'organisation connecté seulement</p>
              </label>
            </div>
            <div className="n-chk">
              <label className="new-control new-radio radio-classic-primary">
                <input
                  type="radio"
                  checked={!selectedOrganisation}
                  onChange={() => setselectedOrganisation(false)}
                  value="allOrganizations"
                  className="new-control-input"
                  name="custom-radio-2"
                />
                <span className="new-control-indicator" />
                <p className="fs-15">Tous les organisations de la societe</p>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => {
              setShowModal(false);
              reset({
                libelle: {
                  fr: "",
                  en: "",
                  ar: "",
                },
                image: null,
              });
            }}
            className="btn btn-primary-app"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
