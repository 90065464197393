import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm, Controller } from "react-hook-form";
import { produitSchema } from "../../../_App/Schemas/ProduitSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  Listfournisseurparproduit,
  createProductApi,
  findProductFournisseurByProductApi,
  insertListProductApi,
  updateProductApi,
} from "../../../_App/Redux/Slices/produits/produitSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { Produit } from "../../../_App/Types/Entites/Produit";
import {
  findSousCategorieBySocieteApi,
  ListSousCategoriesByCategorie,
  ListSousCategories,
} from "../../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  ListProductCaracteristiques,
  ListProductCaracteristiquesParOrganisation,
  findProductsCaracteristiquesApiBySociete,
} from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import {
  findCarteBySocieteApi,
  ListCarteParOrganisation,
  ListCartes,
} from "../../../_App/Redux/Slices/cartes/carteSlice";
import {
  ListCategories,
  ListCategoriesByCarte,
} from "../../../_App/Redux/Slices/categories/categorieSlice";
import ProductCaracteristiquesForm from "../ProductCaracteristiques/ProductCaracteristiquesForm";

import ProductMarquesForm from "../ProductMarques/ProductMarquesForm";
import CategorieForm from "../../Categories/CategorieForm";
import SousCategorieForm from "../../SousCategories/SousCategorieForm";
import CarteForm from "../../Cartes/CarteForm";
import {
  findFourinsseurBySocieteApi,
  ListFournisseurs,
  ListFournisseurParOrganisation,
} from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import Select, { SingleValue } from "react-select";
import FournisseurForm from "../Fournisseur/FournisseurForm";
import { CarteType } from "../../../_App/Types/Entites/CarteType";
import { FournisseurType } from "../../../_App/Types/Entites/FournisseurType";
import { SelectedOrganisations } from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  findProductsMarquesApiBySociete,
  ListMarqueParOrganisation,
  ListProductMarques,
} from "../../../_App/Redux/Slices/product_marque/product_marque";
import { ProductMarqueType } from "../../../_App/Types/Entites/ProductMarqueType";
import { SousCategorie } from "../../../_App/Types/Entites/SousCategorie";
import { ProductCaracteristiqueType } from "../../../_App/Types/Entites/ProductCaracteristiqueType";
import {
  ListTaxTva,
  findTaxTvaApi,
} from "../../../_App/Redux/Slices/tax/taxSlice";
import Matrice from "./ProductsForm/Matrice";
import {
  Listmatrice,
  findMatriceBySocieteApi,
} from "../../../_App/Redux/Slices/matrice/matriceSlice";
import MatriceType from "../../../_App/Types/Entites/MatriceType";
import {
  calculHt,
  calculTTc,
  generateRandomEAN,
  handleKeyPress,
} from "../../../_App/Helpers/helpers";
import {
  ListArticleByOrganisation,
  ListArticles,
  findArticlebysocieteApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { ArticleType } from "../../../_App/Types/Entites/ArticleType";
import Swal from "sweetalert2";
import {
  findSoucheProduitApi,
  soucheproduct,
} from "../../../_App/Redux/Slices/souche/soucheSlice";
import * as XLSX from "xlsx";

interface ProdcutFormType {
  ShowModal: any;
  setShowModal: any;
  Product: any;
  organisation_id: string;
  societe: string;
  productList: Produit[];
  t: any;
}
export default function ProductsForm({
  t,
  ShowModal,
  setShowModal,
  Product,
  organisation_id,
  societe,
  productList,
}: ProdcutFormType) {
  //init state
  const [loading, setLoading] = useState(false);
  const listArticle = useAppSelector(ListArticles);
  const [idcarte, setidcarte] = useState("");
  const [idcategorie, setidcategorie] = useState("");
  const [selectedOrganisation, setselectedOrganisation] = useState(true);
  const [ShowModalProductCaracteristique, setShowModalProductCaracteristique] =
    useState(false);
  const [ShowModalProductMarque, setShowModalProductMarque] = useState(false);
  const [showModalCategorie, setShowModalCategorie] = useState(false);
  const [showModalSousCategorie, setShowModalSousCategorie] = useState(false);
  const [showModalFournisseur, setshowModalFournisseur] = useState(false);
  const [showModalCarte, setShowModalCarte] = useState(false);
  const [ShowModalMatrice, setShowModalMatrice] = useState(false);
  const [file, setfile] = useState<File | undefined>();
  const [indexcat, setindexindexcat] = useState(0);
  const [indexarticle, setindexarticle] = useState(0);

  /****state lists */
  const [organisation, setorganisation] = useState("");

  const [list_caracteristiques, setlist_caracteristiques] = useState<any[]>([]);

  const [matrice, setmatrice] = useState(false);
  const matricedata: MatriceType = {
    societe: "",
    dimension1: "",
    dimension2: "",
    dimension3: "",
    list_dimension1: [],
    list_dimension2: [],
    list_dimension3: [],
    checked: [],
    name_matrice: "",
  };
  const [matriceselected, setmatriceselected] = useState(matricedata);
  const [list_PCaracteristiques, setlist_PCaracteristiques] = useState<
    ProductCaracteristiqueType[]
  >([]);
  const [eanValue, setEanValue] = useState("");
  const [refValue, setrefValue] = useState("");
  const [list_cartes, setlist_cartes] = useState<CarteType[]>([]);
  const [list_marque, setlist_marque] = useState<ProductMarqueType[]>([]);
  const [list_fournisseur, setlist_fournisseur] = useState<FournisseurType[]>(
    []
  );
  const [list_filtermatrice, setlist_filtermatrice] = useState<MatriceType[]>(
    []
  );
  const [options, setOptions] = useState<any[]>();
  const [list_article, setlist_article] = useState<any[]>();
  const [tva_founisseur, settva_founisseur] = useState("");
  const [prixttc, setprixttc] = useState(0);
  const [prixht, setprixht] = useState(0);
  const [updateall, setupdateall] = useState(true);

  const dispatch = useAppDispatch();
  // inti form
  const formOptions = {
    resolver: yupResolver(produitSchema) as unknown as Resolver<Produit>,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState,
  } = useForm<Produit>(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (Product) {
      Product.organisation_id !== null
        ? setselectedOrganisation(true)
        : setselectedOrganisation(false);
      setorganisation(Product.organisation_id);

      setrefValue(Product.refProduit);
      setEanValue(Product.code_a_barreProduit);
      reset({
        _id: Product._id,
        refProduit: Product.refProduit,
        code_a_barreProduit: Product.code_a_barreProduit,
        organisation_id: Product.organisation_id,
        titre: {
          fr: Product.titre?.fr,
          en: Product.titre?.en,
          ar: Product.titre?.ar,
        },
        description: {
          fr: Product.description?.fr,
          en: Product.description?.en,
          ar: Product.description?.ar,
        },
        carte_id: Product.carte_id,
        category_id: Product.category_id,
        SousCategory_id: Product.SousCategory_id,
        marque_id: Product.marque_id,
        fournisseurs: Product.fournisseurs,
        tva: Product.tva,
        image: Product.image,
        prix_achat_ttc: Product.prix_achat_ttc,
        prix_achat_ht: Product.prix_achat_ht,
        matrice: Product.matrice,
        type: Product.type,
        article_id: Product?.article_id?._id,
        produitparent: Product.type === "parent" ? null : Product.produitparent,
        caracteristiques:
          Product.type === "parent" || Product.type === "import"
            ? null
            : Product.caracteristiques,
      });
      setmatrice(Product.matrice);
      setmatriceselected(
        listmatrice.find((item) => item._id === Product.matrice) || matricedata
      );
      setprixht(Product.prix_achat_ttc);
      setprixttc(Product.prix_achat_ht);
    } else {
      setmatriceselected(matricedata);

      setmatrice(false);
      setrefValue("");
      setEanValue("");
      setselectedOrganisation(true);
      reset({
        _id: "",
        refProduit: "",
        code_a_barreProduit: "",
        fournisseurs: "",
        organisation_id: "",
        titre: {
          fr: "",
          en: "",
          ar: "",
        },
        description: {
          fr: "",
          en: "",
          ar: "",
        },
        carte_id: "",
        category_id: "",
        SousCategory_id: "",
        matrice: "",
        marque_id: "",
        prix_achat_ht: 0,
        prix_achat_ttc: 0,
        image: undefined,
      });
      setprixht(0);
      setprixttc(0);
      setorganisation(organisation_id);
    }
    setlist_filtermatrice([]);
  }, [Product, ShowModal, reset]);

  useEffect(() => {
    if (societe) {
      dispatch(findSoucheProduitApi(societe));
      dispatch(findProductsMarquesApiBySociete(societe));
      dispatch(findProductsCaracteristiquesApiBySociete(societe));
      dispatch(findCarteBySocieteApi(societe));
      dispatch(findSousCategorieBySocieteApi({ societe: societe, index: 0 }));
      dispatch(findArticlebysocieteApi({ id: societe, index: 1 }));

      dispatch(findFourinsseurBySocieteApi(societe));
      dispatch(findTaxTvaApi(societe));
      dispatch(findMatriceBySocieteApi(societe));
    }
  }, [ societe, ShowModalMatrice]);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenArticle, setIsOpenArticle] = useState(false);

  // useAppSelector
  const souche_product = useAppSelector(soucheproduct);

  const listOrgaisations = useAppSelector(SelectedOrganisations);
  const ListProductMarque = useAppSelector(ListProductMarques);
  const ListCarte = useAppSelector(ListCartes);
  const listCategories = useAppSelector(ListCategories);
  const listSousCategorie = useAppSelector(ListSousCategories);
  const listtaxtva = useAppSelector(ListTaxTva);
  const listmatrice = useAppSelector(Listmatrice);
  const listSousCategories = useAppSelector(ListSousCategories);

  const ListFournisseur = useAppSelector(ListFournisseurs);
  const listCarteParOrganisation = useAppSelector(ListCarteParOrganisation);
  const listFournisseurParOrganisation = useAppSelector(
    ListFournisseurParOrganisation
  );
  const listMarqueParOrganisation = useAppSelector(ListMarqueParOrganisation);
  const listProductCaracteristiques = useAppSelector(
    ListProductCaracteristiques
  );
  const listProductCaracteristiquesParOrganisation = useAppSelector(
    ListProductCaracteristiquesParOrganisation
  );

  useEffect(() => {
    if (!ShowModalMatrice && matriceselected.name_matrice !== "")
      setlist_caracteristiques([]);
  }, [ShowModalMatrice]);
  //useEffect
  useEffect(() => {
    if (organisation === "null" || organisation === null) {
      setlist_cartes(ListCarte);
      setlist_fournisseur(ListFournisseur);
      setlist_PCaracteristiques(listProductCaracteristiques);
      setlist_marque(ListProductMarque);
    } else if (organisation && organisation !== "null") {
      const carteslist = listCarteParOrganisation[organisation];
      carteslist ? setlist_cartes(carteslist) : setlist_cartes([]);
      const list = listProductCaracteristiquesParOrganisation[organisation];
      list ? setlist_PCaracteristiques(list) : setlist_PCaracteristiques([]);

      setlist_PCaracteristiques(
        listProductCaracteristiquesParOrganisation[organisation]
      );
      const Fournisseurslist = listFournisseurParOrganisation[organisation];
      Fournisseurslist
        ? setlist_fournisseur(Fournisseurslist)
        : setlist_fournisseur([]);

      const Marquelist = listMarqueParOrganisation[organisation];
      Marquelist ? setlist_marque(Marquelist) : setlist_marque([]);
    }
  }, [
    ShowModal,
    selectedOrganisation,
    organisation,
    listFournisseurParOrganisation,
    listProductCaracteristiquesParOrganisation,
    listMarqueParOrganisation,
    listCarteParOrganisation,
  ]);

  useEffect(() => {
    const option = list_article?.map((item: any) => ({
      value: item._id,
      label: item.titre.fr,
    }));
    setOptions(option);
  }, [list_article, ShowModal]);
  const onselectcaracteristique = (item: any) => {
    // check item is checked or not
    const checked = list_caracteristiques.find(
      (element: any) => element === item._id
    );
    if (checked) {
      //remove
      const newcarateristique = list_caracteristiques.filter(
        (element: any) => element !== item._id
      );
      setlist_caracteristiques(newcarateristique);
    } else {
      if (list_caracteristiques.length === 3) {
        ToastWarning("la matrice maximum a 3 dimension ");
      } else {
        setlist_caracteristiques((prev: any) => [...prev, item._id]);
        const listmatriceselected = listmatrice.filter(
          (item) =>
            item.dimension1 === list_caracteristiques[0] ||
            item.dimension2 === list_caracteristiques[1] ||
            item?.dimension3 === list_caracteristiques[2]
        );
        setlist_filtermatrice(listmatriceselected);
      }
    }

    console.log("list_caracteristiques", list_caracteristiques);
  };
  const selectmatrice = (id: any) => {
    const item = listmatrice.find((element) => element._id === id);
    if (item) {
      setmatriceselected(item);
      setlist_caracteristiques([
        item.dimension1,
        item.dimension2,
        item.dimension3,
      ]);
      setShowModalMatrice(true);
    } else {
      ToastWarning("element n'existe pas");
    }
  };

  const showmatrice = () => {
    if (list_caracteristiques.length < 2) {
      ToastWarning("matrice doit au moin comprends 2 dimmension");
    } else {
      const matricedata: MatriceType = {
        societe: "",
        dimension1: "",
        dimension2: "",
        dimension3: "",
        list_dimension1: [],
        list_dimension2: [],
        list_dimension3: [],
        checked: [],
        name_matrice: "",
      };
      setmatriceselected(matricedata);
      setShowModalMatrice(true);
    }
  };

  const showsouscategories = async (value: any) => {
    if (value === "more") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societe,
          index: indexcat + 12,
        })
      );
      setindexindexcat(indexcat + 12);
    } else if (value === "up") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societe,
          index: indexcat - 12,
        })
      );
      setindexindexcat(indexcat - 12);
    } else {
      setValue(`SousCategory_id`, value);
      setIsOpen(false);
    }
  };
  const showarticles = async (value: any) => {
    if (value === "more") {
      await dispatch(
        findArticlebysocieteApi({
          id: societe,
          index: indexarticle + 12,
        })
      );
      setindexarticle(indexarticle + 12);
    } else if (value === "up") {
      await dispatch(
        findArticlebysocieteApi({
          id: societe,
          index: indexarticle - 12,
        })
      );
      setindexarticle(indexarticle - 12);
    } else {
      setValue(`article_id`, value);
      setIsOpen(false);
    }
  };

  const handleExcelImport = async () => {
    // Show SweetAlert with loading message
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await dispatch(insertListProductApi(file));
      if (response.payload.success) {
        window.location.reload();
        ToastSuccess(t(response.payload.message));
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    } catch (error) {
      console.error("Error:", error);
      ToastWarning("An error occurred.");
    } finally {
      Swal.close();
    }
  };

  const handleInputChange = (e: any) => {
    setEanValue(e.target.value);
    setValue("code_a_barreProduit", e.target.value);
  };
  const handleIcon_ref_Click = () => {
    const Ref = `P_${String(souche_product?.souche).padStart(5, "0")}`;
    const codeabarre = String(souche_product?.souche).padStart(12, "0");
    setEanValue(codeabarre);
    setValue("code_a_barreProduit", codeabarre);
    setrefValue(Ref);
    setValue("refProduit", Ref);
  };
  const handleInput_ref_Change = (e: any) => {
    setrefValue(e.target.value);
    setValue("refProduit", e.target.value);
  };
  const swarlfunction = (data: any) => {
    return Swal.fire({
      title: "Vous voulez associer ce produit avec article ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OUI",
      cancelButtonText: "Non",
    }).then((result) => {
      if (result.isConfirmed) {
        data.article_id = "oui";
      } else {
        data.article_id = "non";
      }
    });
  };
  const verifcationMsg = () => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: `${t("vous voulez")} ${
        Product?._id ? t(`modifier`) : t(`enregistrer`)
      }${t("ce produit")} ${
        Product?._id && updateall ? t(`ainsi ses fils `) : ``
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const onSubmit = async (data: any) => {
    let response;
    const newdata = { ...data };

    if (selectedOrganisation) {
      newdata.organisation_id = organisation_id;
    } else {
      newdata.organisation_id = null;
    }

    if (matrice && !matriceselected?._id) {
      setmatriceselected(listmatrice[0]);
    }

    if (!matrice) {
      newdata.matrice = null;
    } else {
      newdata.matrice = matriceselected._id;
    }

    newdata.societe_id = societe;

    if (!newdata.matrice) {
      newdata.matrice = null;
    }
    newdata.SousCategory_id =
      newdata.SousCategory_id !== null ? newdata.SousCategory_id._id : null;

    if (data._id) {
      newdata.changeall = newdata.type === "fils" ? false : updateall;
      if (!(await verifcationMsg())) return;

      response = await dispatch(updateProductApi(newdata));
    } else {
      if (newdata.article_id) {
        newdata.article_id =  newdata.article_id !== null ? newdata.article_id._id : null;
      } else {
        await swarlfunction(newdata);
      }
      if (!(await verifcationMsg())) return;
      response = await dispatch(createProductApi(newdata));
    }

    if (response.payload.success) {
      ToastSuccess(t(response.payload.message));

      reset({
        _id: "",
        refProduit: "",
        code_a_barreProduit: "",
        organisation_id: "",
        titre: {
          fr: "",
          en: "",
          ar: "",
        },
        description: {
          fr: "",
          en: "",
          ar: "",
        },
        carte_id: "",
        category_id: "",
        image: [],
        SousCategory_id: "",

        matrice: "",

        marque_id: "",
        article_id: "",
      });
      setShowModal(false);
      window.location.reload();
    } else {
      ToastWarning(t(response.payload.message));
    }
  };

  const selectfounisseur = (id: string) => {
    const fournisseur = list_fournisseur.find((item) => item._id === id);
    if (fournisseur) {
      settva_founisseur(fournisseur.code_tva);
      setValue("tva", fournisseur.code_tva);
      const tva = listtaxtva.find(
        (item) => item._id === fournisseur.code_tva
      )?.taux;
      if (tva) {
        const ttc = parseFloat(calculTTc(prixht, tva).toFixed(3));
        setprixttc(ttc);
        setValue("prix_achat_ttc", ttc);
      }
    }
  };
  const changeprix = (valeur: any, type: string) => {
    const value = parseFloat(valeur);
    const selectedtva = getValues("tva");
    const tva = listtaxtva.find((item) => item._id === selectedtva)?.taux;
    if (type === "prixttc") {
      if (selectedtva && tva) {
        const ht = parseFloat(calculHt(value, tva).toFixed(3));
        setprixht(ht);
        setValue("prix_achat_ht", ht);
      }
    } else {
      if (selectedtva && tva) {
        const ttc = parseFloat(calculTTc(value, tva).toFixed(3));
        // setprixttc(ttc);
        setValue("prix_achat_ttc", ttc);
      }
    }
  };
  const onselecttva = (tva_id: string) => {
    const tva = listtaxtva.find((item) => item._id === tva_id)?.taux;
    if (tva) {
      const ttc = parseFloat(
        calculTTc(getValues("prix_achat_ht"), tva).toFixed(3)
      );
      setValue("prix_achat_ttc", ttc);
    }
  };

  return (
    <>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-70w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {Product ? (
              <div className="row">
                <p className="c-primary col-6">
                  {t("Modifier")} {Product?.titre?.fr}
                </p>
                {Product.type === "fils" ? (
                  <span className="col-6">
                    {t("son produit parent")}{" "}
                    {
                      productList.find(
                        (item) => item._id === Product.produitparent
                      )?.titre?.fr
                    }{" "}
                  </span>
                ) : null}
              </div>
            ) : (
              <>
                <p className="c-primary">{t("Ajouter Produit")}</p>
                <p className="  form-control">
                  <input
                    type="file"
                    onChange={(e) => setfile(e.target.files?.[0])}
                  />
                  <button onClick={handleExcelImport}>
                    <i className="fas fa-file-import"></i>
                  </button>
                </p>
              </>
            )}
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="container mt-4">
              <div className="row mb-4">
                <div className="col-md-3 ">
                  <div className="form-group ">
                    <label htmlFor="refProduit">
                      <span style={{ color: "red" }}>*</span>
                      {t("Reference Produit")}{" "}
                    </label>

                    <div className="wrapper">
                      <div
                        className="icon-input"
                        onClick={handleIcon_ref_Click}
                      >
                        {" "}
                        <i className="fas fa-pen-square"></i>
                      </div>
                      <input
                        type="text"
                        className={` input form-control ${
                          errors?.refProduit ? "is-invalid" : ""
                        }`}
                        {...register("refProduit")}
                        value={refValue}
                        onChange={handleInput_ref_Change}
                        name="refProduit"
                      />
                    </div>
                    <div className="invalid-feedback">
                      {errors.refProduit &&
                        errors.refProduit.message &&
                        errors.refProduit.message.toString()}
                    </div>

                    <label htmlFor="code_a_barre">
                      <span style={{ color: "red" }}>*</span>
                      {t("code a barre")}{" "}
                    </label>
                    <div className="wrapper">
                      <input
                        type="text"
                        className={` input form-control ${
                          errors?.code_a_barreProduit ? "is-invalid" : ""
                        }`}
                        {...register("code_a_barreProduit")}
                        value={eanValue}
                        onChange={handleInputChange}
                        name="code_a_barre"
                      />
                    </div>
                    <div className="invalid-feedback">
                      {errors.code_a_barreProduit &&
                        errors.code_a_barreProduit.message &&
                        errors.code_a_barreProduit.message.toString()}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 ">
                  <div className="form-group ">
                    <label htmlFor="titre.fr">
                      <span style={{ color: "red" }}>*</span>
                      {t("Titre")}{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="titre fançais"
                      className={`form-control mb-3 ${
                        errors?.titre?.fr ? "is-invalid" : ""
                      }`}
                      {...register("titre.fr")}
                    />

                    <div className="invalid-feedback">
                      {errors.titre?.fr &&
                        errors.titre.fr.message &&
                        errors.titre.fr.message.toString()}
                    </div>

                    <label htmlFor="description.fr">
                      <span style={{ color: "red" }}>*</span>
                      {t("Description")}{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="description fançais"
                      className={`form-control mb-3 ${
                        errors?.description?.fr ? "is-invalid" : ""
                      }`}
                      {...register("description.fr")}
                    />
                    <div className="invalid-feedback">
                      {errors.description?.fr &&
                        errors.description.fr.message &&
                        errors.description.fr.message.toString()}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-group ">
                    <label htmlFor="organisation_id">
                      <span style={{ color: "red" }}></span>
                      {t("Organisation")}
                    </label>
                    {selectedOrganisation ? (
                      <select
                        id="organisation_id"
                        className="form-control mb-2"
                        {...register("organisation_id")}
                        onChange={(e) => setorganisation(e.target.value)}
                        value={organisation}
                      >
                        {listOrgaisations?.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.nom}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <select
                        id="organisation_id"
                        className="form-control mb-2"
                        {...register("organisation_id")}
                      >
                        <option value="null">
                          {t("Tout les organisations")}
                        </option>
                      </select>
                    )}

                    <label htmlFor="image">
                      <span style={{ color: "red" }}>*</span>
                      {t("Image")}
                    </label>
                    <input
                      className={`form-control mb-2 ${
                        errors?.image ? "is-invalid" : ""
                      }`}
                      type="file"
                      id="image"
                      {...register("image")}
                    />
                    <div className="invalid-feedback">
                      {errors.image &&
                        errors.image.message &&
                        "image est obligatoire"}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mt-5 ">
                  <div className="row">
                    <div className="n-chk">
                      <label className="new-control new-radio radio-classic-primary">
                        <input
                          type="radio"
                          checked={selectedOrganisation}
                          onChange={(e) => {
                            setselectedOrganisation(true);
                            setorganisation(organisation_id);
                          }}
                          value="connectedOnly"
                          className="new-control-input"
                          name="custom-radio-2"
                        />
                        <span className="new-control-indicator" />
                        <p className="fs-15">
                          {t("l'organisation connecté seulement")}
                        </p>
                      </label>
                    </div>
                    <div className="n-chk">
                      <label className="new-control new-radio radio-classic-primary">
                        <input
                          type="radio"
                          checked={!selectedOrganisation}
                          onChange={(e) => {
                            setselectedOrganisation(false);
                            setorganisation("null");
                          }}
                          value="allOrganizations"
                          className="new-control-input"
                          name="custom-radio-2"
                        />
                        <span className="new-control-indicator" />
                        <p className="fs-15">
                          {t("Tous les organisations de la societe")}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-3">
                  <div className="row ">
                    <label htmlFor="fournisseur">
                      <span style={{ color: "red" }}>*</span>
                      {t("Founisseur")}{" "}
                      <span
                        className="c-primary float-right"
                        onClick={() => setshowModalFournisseur(true)}
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                      </span>
                    </label>
                    <select
                      id="fournisseurs"
                      className={`form-control mb-2 ${
                        errors?.fournisseurs ? "is-invalid" : ""
                      }`}
                      {...register("fournisseurs")}
                      onChange={(e) => selectfounisseur(e.target.value)}
                    >
                      {" "}
                      {list_fournisseur?.map((item: any, index: number) => (
                        <option key={index} value={item._id}>
                          {item.libelle}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {errors.fournisseurs && "choisir fournisseur"}
                    </div>
                  </div>
                  <div className="row">
                    <label htmlFor="tva">{t("TVA")} </label>

                    <select
                      id="tva"
                      className="form-control mb-2"
                      {...register("tva")}
                      defaultValue={tva_founisseur !== "" ? tva_founisseur : ""}
                      onChange={(e) => onselecttva(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        {tva_founisseur !== ""
                          ? tva_founisseur
                          : t("choisir tva ")}
                      </option>
                      {listtaxtva.map((item: any, index) => (
                        <option key={index} value={item._id}>
                          {item.taux}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <label htmlFor="prix_ttc">{t("Prix d'achat TTC")} </label>

                  <input
                    className="form-control mb-2  "
                    type="text"
                    id="prix_ttc"
                    {...register("prix_achat_ttc")}
                    onChange={(e) => changeprix(e.target.value, "prixttc")}
                    onKeyPress={handleKeyPress}
                  />
                  <label htmlFor="prix_ht">{t("Prix d'achat HT")} </label>

                  <input
                    className="form-control mb-2  "
                    type="text"
                    id="prix_ht"
                    {...register("prix_achat_ht")}
                    onChange={(e) => changeprix(e.target.value, "prixht")}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div className="col-md-3 ">
                  <div className="form-group ">
                    <div className="row">
                      <label htmlFor="marques">
                        Marques{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalProductMarque(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <select
                      id="marque_id"
                      className="form-control mb-2"
                      {...register("marque_id")}
                    >
                      <option value="">Select </option>
                      {list_marque.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.libelle.fr}
                        </option>
                      ))}
                    </select>
                    <div className="row">
                      <label htmlFor="Cartes">
                        {t("Cartes")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalCarte(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <select
                      id="carte_id"
                      className="form-control mb-2"
                      {...register("carte_id")}
                      onChange={(e) => setidcarte(e.target.value)}
                    >
                      <option value="">{t("Select")} </option>
                      {list_cartes.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item?.libelle?.fr}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-3 ">
                  <div className="form-group ">
                    <div className="row">
                      <label htmlFor="Categories">
                        {t("Categories")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalCategorie(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>
                    <select
                      className="form-control mb-2"
                      {...register("category_id")}
                      onChange={(e) => setidcategorie(e.target.value)}
                    >
                      <option value="">Select </option>
                      {listCategories?.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.libelle.fr}
                        </option>
                      ))}
                    </select>
                    <div className="row">
                      <label htmlFor="Sous Categories">
                        {t("Sous Categories")}{" "}
                        <span
                          className="c-primary float-right"
                          onClick={() => setShowModalSousCategorie(true)}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>
                        </span>
                      </label>
                    </div>

                    <div className="custom-dropdown">
                      <div
                        className="form-control"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        {watch(`SousCategory_id`) !== null
                          ? watch(`SousCategory_id`)?.libelle?.fr
                          : "Select category"}
                      </div>
                      {isOpen && (
                        <div className="custom-dropdown-options">
                          {indexcat > 0 && (
                            <div
                              className="custom-dropdown-option"
                              onClick={() => showsouscategories("up")}
                            >
                              <i className="fas fa-arrow-up"></i>
                            </div>
                          )}
                          {listSousCategories.map((option, i) => (
                            <div
                              key={i}
                              className="custom-dropdown-option"
                              onClick={() => showsouscategories(option)}
                            >
                              {option.libelle.fr}
                            </div>
                          ))}

                          <div
                            className="custom-dropdown-option"
                            onClick={() => showsouscategories("more")}
                          >
                            <i className="fas fa-arrow-down"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                {!Product || Product.type === "import" ? (
                  <div className="col-md-3">
                    <div className="n-chk">
                      <span
                        className="c-primary float-right"
                        onClick={() => setShowModalProductCaracteristique(true)}
                      >
                        <i className="fas fa-plus-circle fa-lg"></i>
                      </span>
                      <label className="new-control new-checkbox new-checkbox-rounded checkbox-primary">
                        <input
                          type="checkbox"
                          className="new-control-input"
                          {...register("matrice")}
                          onChange={(e) => {
                            setmatrice(e.target.checked);
                          }}
                        />
                        <span className="new-control-indicator" />
                        {t("Matrice")}
                      </label>
                    </div>
                    {matrice ? (
                      <div>
                        {list_PCaracteristiques?.map((item, index) => {
                          return (
                            <div className="n-chk" key={index}>
                              <label className="new-control new-checkbox checkbox-primary">
                                <input
                                  type="checkbox"
                                  className="new-control-input"
                                  onChange={() => onselectcaracteristique(item)}
                                  disabled={
                                    !list_caracteristiques.find(
                                      (element) => element === item._id
                                    ) && list_caracteristiques.length === 3
                                  }
                                  checked={list_caracteristiques.find(
                                    (element) => element === item._id
                                  )}
                                />
                                <span className="new-control-indicator" />
                                {item.libelle}
                              </label>
                            </div>
                          );
                        })}
                        <span
                          className="c-primary pointer"
                          onClick={showmatrice}
                        >
                          <i className="fas fa-plus-circle fa-lg"></i>{" "}
                          {t("Ajouter matrice")}
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="col-md-3">
                  {list_filtermatrice.length !== 0 ? (
                    <div className="row ">
                      <label htmlFor="matrice">
                        <span style={{ color: "red" }}>*</span>
                        {t("list matrice")}{" "}
                      </label>
                      <select
                        id="matrices"
                        className="form-control"
                        onChange={(e) => selectmatrice(e.target.value)}
                      >
                        {list_filtermatrice?.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.name_matrice}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  {matriceselected?._id ? (
                    <div className="row">
                      {!Product ? (
                        <>
                          <span onClick={() => setmatriceselected(matricedata)}>
                            <i className="fas fa-minus-circle c-primary"></i>{" "}
                          </span>
                          <>
                            <label>{t("matrice selectionné")}</label>
                            <p
                              className="lien-bleu-souligne"
                              onClick={(e) =>
                                selectmatrice(matriceselected._id)
                              }
                            >
                              {" "}
                              {matriceselected.name_matrice}
                            </p>
                          </>
                        </>
                      ) : Product?.type === "parent" ||
                        Product?.type === "import" ? (
                        <>
                          <label>{t("matrice selectionné")}</label>
                          <p
                            className="lien-bleu-souligne"
                            onClick={(e) => selectmatrice(matriceselected._id)}
                          >
                            {matriceselected.name_matrice}
                          </p>
                        </>
                      ) : (
                        Product.type === "fils" &&
                        Product.caracteristiques?.length !== 0 && (
                          <div>
                            <strong>{t("les caracteristique")}</strong>
                            {Product.caracteristiques?.map(
                              (item: any, index: number) => {
                                const characteristic =
                                  listProductCaracteristiques.find(
                                    (e) => e._id === item.caracteristique_id
                                  );
                                if (characteristic) {
                                  return (
                                    <div key={index}>
                                      <p>
                                        {characteristic.libelle ||
                                          item.caracteristique_id}{" "}
                                        :{" "}
                                        {characteristic.color ? (
                                          <input
                                            type="color"
                                            value={item.value}
                                            disabled
                                            readOnly
                                          />
                                        ) : (
                                          item.value
                                        )}
                                      </p>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <p key={index}>
                                      {item.caracteristique_id} : {item.value}
                                    </p>
                                  );
                                }
                              }
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
                {Product ? (
                  <>
                    <div className="col-md-3">
                      <label>{t("Article")} </label>
                      <p>
                        {Product.article_id?.titre?.fr
                        }
                      </p>

                      <label>{t(" Code Article")} </label>
                      <p>
                        {
                          listArticle.find(
                            (item) => item._id === Product.article_id
                          )?.code_article
                        }
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="col-md-3">
                    <label>{t("Article")} </label>
                    <div className="custom-dropdown">
                      <div
                        className="form-control"
                        onClick={() => setIsOpenArticle(!isOpenArticle)}
                      >
                        {watch(`article_id`) !== null
                          ? watch(`article_id`)?.titre?.fr
                          : "Select category"}
                      </div>

                    {isOpenArticle && (
                      <div className="custom-dropdown-options">
                        {indexarticle > 0 && (
                          <div
                            className="custom-dropdown-option"
                            onClick={() => showarticles("up")}
                          >
                            <i className="fas fa-arrow-up"></i>
                          </div>
                        )}
                        {listArticle.map((option, i) => (
                          <div
                            key={i}
                            className="custom-dropdown-option"
                            onClick={() => showarticles(option)}
                          >
                            {option.titre.fr}
                          </div>
                        ))}

                        <div
                          className="custom-dropdown-option"
                          onClick={() => showarticles("more")}
                        >
                          <i className="fas fa-arrow-down"></i>
                        </div>
                      </div>
                    )}
                  </div>
                  </div>
                )}

                <div className="col-md-3 ">
                  <div className="form-group ">
                    {Product &&
                    (Product.type === "parent" || Product.type === "import") ? (
                      <div>
                        <div className="n-chk">
                          <label className="new-control new-radio radio-classic-primary">
                            <input
                              type="radio"
                              checked={updateall}
                              onChange={(e) => {
                                setupdateall(true);
                              }}
                              className="new-control-input"
                            />
                            <span className="new-control-indicator" />
                            <p className="fs-15">
                              {t("Modifier ce produit ainsi ses fils")}
                            </p>
                          </label>
                        </div>
                        <div className="n-chk">
                          <label className="new-control new-radio radio-classic-primary">
                            <input
                              type="radio"
                              checked={!updateall}
                              onChange={(e) => {
                                setupdateall(false);
                              }}
                              className="new-control-input"
                            />
                            <span className="new-control-indicator" />
                            <p className="fs-15">
                              {t("Modifier ce produit seulement")}
                            </p>
                          </label>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t("Annuler")}
            </Button>
            <Button type="submit" className="btn btn-primary-app">
              {t("Enregistrer")}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <ProductCaracteristiquesForm
        ShowModal={ShowModalProductCaracteristique}
        setShowModal={setShowModalProductCaracteristique}
        ProductCaracteristique={null}
        organisation_id={organisation_id}
        societe={societe}
      />
      <ProductMarquesForm
        ShowModal={ShowModalProductMarque}
        setShowModal={setShowModalProductMarque}
        ProductMarque={null}
        organisation_id={organisation_id}
        societe={societe}
      />
      <CategorieForm
        ShowModal={showModalCategorie}
        setShowModal={setShowModalCategorie}
        categorie={null}
        carteId={idcarte}
        currentOrganisation={organisation_id}
        societe={societe}
      />
      <CarteForm
        ShowModal={showModalCarte}
        setShowModal={setShowModalCarte}
        carte={null}
        currentOrganisation={organisation_id}
        societe={societe}
      />
      <SousCategorieForm
        ShowModal={showModalSousCategorie}
        setShowModal={setShowModalSousCategorie}
        categorie={null}
        currentOrganisation={organisation_id}
        societe={societe}
      />
      <FournisseurForm
        ShowModal={showModalFournisseur}
        setShowModal={setshowModalFournisseur}
        categorie={null}
        organisation_id={organisation_id}
        societe={societe}
      />
      <Matrice
        setmatriceselected={setmatriceselected}
        ShowModal={ShowModalMatrice}
        setShowModal={setShowModalMatrice}
        caracteristique={list_caracteristiques}
        matrice={matriceselected}
      />
    </>
  );
}
/* <div className="icon-input" onClick={handleIconClick}>
                        {" "}
                        <i className="fas fa-barcode mr-2"></i>
                      </div>*/
/*  useEffect(() => {
    if (Product && listfournisseurparproduit.length !== 0) {
      const list_Prod_Fournisseur = [
        {
          libelle: "",
          fournisseur_id: "",
          product_id: "",
          reference: "",
          code_a_barre: "",
          tva: "",
          prix_achat_ttc: 0,
          prix_achat_ht: 0,
        },
      ];
      listfournisseurparproduit.forEach((element) => {
        const fournisseur = ListFournisseur.find(
          (item) => item._id === element.fournisseur_id
        );
        if (fournisseur) {
          list_Prod_Fournisseur.push({
            libelle: fournisseur.libelle,
            fournisseur_id: element.fournisseur_id,
            product_id: element.product_id,
            reference: element.reference,
            code_a_barre: element.code_a_barre,
            tva: element.tva,
            prix_achat_ttc: element.prix_achat_ttc,
            prix_achat_ht: element.prix_achat_ht,
          });
        }
      });
      //setdataFournissuer(list_Prod_Fournisseur);
    }
  }, [Product, listfournisseurparproduit]);
*/
/*const handleIconClick = () => {
    const randomEAN = generateRandomEAN(12);
    setEanValue(randomEAN);
    setValue("code_a_barreProduit", randomEAN);
  };*/
/* const handleExcelImport = async () => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = event?.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const importedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const header: string[] = importedData[0] as string[];
      const dataArray = importedData.slice(1);

      const formattedData = dataArray.map((dataRow: any) =>
        header.reduce((obj: any, key: string, index: number) => {
          obj[key] = dataRow[index];
          return obj;
        }, {})
      );
      const response = await dispatch(insertListProductApi(formattedData));
      if (response.payload.success) {
        window.location.reload()
        ToastSuccess(t(response.payload.message));
  
     
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    };

    if (file) reader.readAsBinaryString(file);
  };*/
