import { Resolver, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SoucheSchema } from "../../_App/Schemas/SoucheSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import SoucheType from "../../_App/Types/Entites/SoucheType";
import Swal from "sweetalert2";
import { updateSoucheApi } from "../../_App/Redux/Slices/souche/soucheSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function SoucheForm({
  ShowModal,
  setShowModal,
  souche,
  t,
  listOrganisations,
  currentOrganisation,
}: any) {
  // init form hooks
  const dispatch= useAppDispatch()
  const formOptions = {
    resolver: yupResolver(SoucheSchema) as unknown as Resolver<SoucheType>,
  };
  const [resetSouche, setresetSouche] = useState(false);
  const { register, handleSubmit, reset, formState } =
    useForm<SoucheType>(formOptions);
  const { errors } = formState;
  useEffect(() => {
    
  }, []);
  useEffect(() => {
    if (souche) {
      reset({
        _id: souche._id,
        type: souche.type,
        souche: souche.souche,
        seq: souche.seq,
        organisation_id: souche.organisation_id,
      });
    } else {
      reset({
        _id: "",
        type: "",
        souche: 1,
        seq: "",
        organisation_id: currentOrganisation,
      });
    }
  }, [souche]);


 
  const verifcation = () => {
    return Swal.fire({
      title: "vous-êtes sûr?", 
      text: `vous voulez modifier souche`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmer!",
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const onSubmit = async (data: any) => {
    if( !await verifcation()) return

    const dataObject ={souche:data,reset:resetSouche}
    const response = await dispatch(updateSoucheApi(dataObject))
      if (response&&response.payload.success) {
          ToastSuccess(t(response.payload.message));
          reset({
            _id: "",
            type: "",
            souche: 1,
            seq: "",
            organisation_id: currentOrganisation,
          });
          setShowModal(false);
          setresetSouche(false)
        } else if(response){
          ToastWarning(t(response.payload.message));
        }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-1"
      dialogClassName="modal-40w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">{t("Ajouter Depots")}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="type">
                  {" "}
                  <span style={{ color: "red" }}>*</span>
                  {t("Type ")}
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.type ? "is-invalid" : ""}`}
                  disabled
                  id="type"
                  {...register("type")}
                />

                <div className="invalid-feedback">
                  {errors.type &&
                    errors.type.message &&
                    errors.type.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="image">{t("Organisation")}</label>
              <div className="input-group mb-3">
                <label htmlFor="organisation_id"></label>
                <select
                  disabled
                  className={`form-control ${
                    errors.organisation_id ? "is-invalid" : ""
                  }`}
                  {...register("organisation_id")}
                >
                  <option value="null">
                    {t("aucune organisation spécifique")}
                  </option>
                  {listOrganisations?.map((item: any, index: number) => (
                    <>
                      <option key={index} value={item._id}>
                        {item?.nom}
                      </option>
                    </>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="seq"> {t("Sequence")}</label>
                <input
                  type="text"
                  className={`form-control ${errors.seq ? "is-invalid" : ""}`}
                  id="seq"
                  {...register("seq")}
                />

                <div className="invalid-feedback">
                  {errors.seq &&
                    errors.seq.message &&
                    errors.seq.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label htmlFor="souche"> {t("Souche")}</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.souche ? "is-invalid" : ""
                  }`}
                  id="souche"
                  {...register("souche")}
                  disabled
                />

                <div className="invalid-feedback">
                  {errors.souche &&
                    errors.souche.message &&
                    errors.souche.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-3 mt-5">
              <div className="form-group">
                <Form.Check
                  type="checkbox"
                  id="custom-switch"
                  checked={resetSouche}
                  onChange={() => setresetSouche(!resetSouche)}
                />
                <p > {t("Reset souche a 1")}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" className="btn btn-primary-app">
            {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
