import "./auth.css";
import { yupResolver } from "@hookform/resolvers/yup";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-simple-keyboard/build/css/index.css";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  loginApi,
  selectIsLoggedIn,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { LoginSchema } from "../../_App/Schemas/LoginSchema";
import { LoginDataType } from "../../_App/Types/Forms/LoginDataType";
import PasswordResetForm from "./PasswordInitialResetForm";
import NumericKeypad from "./PinKeyboard";
interface LoginProps {
  setBadge:any,
  badge:any
}
const Login:  React.FC<LoginProps> = ({
  setBadge,
  badge
})=> {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [pin, setPin] = useState("");

  const handlePinComplete = (input: string) => {
    setPin(input);
  };

  const [pinInput, setPinInput] = useState("");

  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(LoginSchema) };
  const { register, handleSubmit, formState, watch } =
    useForm<LoginDataType>(formOptions);
  const { errors } = formState;

  const watched_login = watch("email");
  const watched_loginP = watch("password");

  const onSubmit = async (data: LoginDataType) => {
    const response = await dispatch(loginApi(data));

    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      setShowPinModal(true);
    } else {
      ToastWarning(response.payload.message);
    }
  };


  const clearPin = () => {
    setPinInput("");
  };

  return (
    <div className="form">
      <div className="form-container">
        <div className="form-form">
          <div className="form-form-wrap">
            <div className="form-container">
              <div className="form-content">
                <Modal
                  show={showPasswordResetModal}
                  onHide={() => setShowPasswordResetModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <div className="header-message">
                      Réinitialisation du Mot de Passe
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <PasswordResetForm />
                  </Modal.Body>
                </Modal>
                <h1 className="">
                  Login <span>caisse</span>
                </h1>
                <form className="text-left" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                      <input
                        className={`form-control`}
                        placeholder="Email ou Numéro de téléphone"
                        {...register("email")}
                      />
                      <div className="pre-icon os-icon os-icon-user-male-circle"></div>
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                    <div
                      id="password-field"
                      className="field-wrapper input mb-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-lock"
                      >
                        <rect
                          x="3"
                          y="11"
                          width="18"
                          height="11"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                      </svg>
                      <input
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder="Mot de Passe"
                        type="password"
                        {...register("password")}
                      />
                      <div className="pre-icon os-icon os-icon-fingerprint"></div>
                      <div className="invalid-feedback">
                        {errors.password?.message}
                      </div>
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper">
                        <button
                          style={{ marginLeft: "300px" ,marginTop:"20px" }}
                          type="submit"
                          className="btn custom-btn"
                          value=""
                        >
                          Login
                        </button>
                      </div>
                    </div>

                    <div className="field-wrapper">
                      <p
                        className="reset-password"
                        onClick={() => setShowPasswordResetModal(true)}
                      >
                        <a className="forgot-pass-link">Mot de passe oublié ?</a>
                      </p>
                    </div>
                  </div>
                </form>
                <p className="terms-conditions">
                 
                 <span className="lien-bleu-souligne pointer" onClick={()=>setBadge(badge==="Badge" ? "mail":"Badge")}>Login par Badge </span>
               </p>
                <p className="terms-conditions">
                  © 2023 All Rights Reserved{" "}
                  <a href="https://www.nomadis.online">Clediss</a> 
                 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-image">
          <div className="l-image"></div>
        </div>
        <Modal
          show={showPinModal}
          onHide={() => setShowPinModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <div className="header-message">
              Le code PIN a été envoyé par mail et SMS{" "}
            </div>
          </Modal.Header>
          <Modal.Body>
            <Row className="text-center mt-2">
              <Col className="mb-2">
                <NumericKeypad
                  onComplete={handlePinComplete}
                  email={watched_login}
                  password={watched_loginP}
                  bagde={badge}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Login;
