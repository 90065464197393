import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  logoutApi,
  selectJournalCaisse,
  selectUser,
  selectedAutorisations,
  selectedSessionJournal,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { clotureSessionApi } from "../../_App/Redux/Slices/sessionJournal/sessionJournalSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";

const StyledLabel = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  color: black;
  display: inline-block;
  width: 120px;
  margin-right: 40px;
`;

const StyledInput = styled.input`
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 16px;
  width: 200px;
  display: inline-block;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 4px;
`;
const Asterisk = styled.span`
  color: red;
  margin-right: 4px;
`;
const RedText = styled.p`
  color: red;
  font-weight: bold;
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const CustomCheckbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    border: 2px solid #007bff;
  }
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  color: black;
`;

const SessionClotureForm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const selectedSessionJournalData = useAppSelector(selectedSessionJournal);
  const session_id = selectedSessionJournalData?._id;
  const [fontFinal, setFontFinal] = useState("");
  const [gardeCaisse, setGardeCaisse] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const selectedJournalData = useAppSelector(selectJournalCaisse);
  const fondCourant = selectedJournalData?.fond_courant;
  const [printSessionTicket, setPrintSessionTicket] = useState(false);
  const user = useAppSelector(selectUser);
  const journalCaisse = useAppSelector(selectJournalCaisse);

  const navigate = useNavigate();

  const handleCheckboxSessionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrintSessionTicket(e.target.checked);
  };

  const handleFontFinalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateFondFinal(e.target.value);
    setFontFinal(e.target.value);
  };

  const validateFondFinal = (value: string) => {
    value = value.replace(/[^0-9.]/g, "");

    if (!/^\d+(\.\d*)?$/.test(value)) {
      setErrorMessage("Veuillez saisir un montant valide");
    } else {
      setErrorMessage("");
    }
  };

  const handleGardeCaisseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateFondFinal(e.target.value);
    setGardeCaisse(e.target.value);
  };

  const handleCloture = async () => {
    try {
      if (!fontFinal.trim()) {
        setErrorMessage("Veuillez saisir un montant valide");
        return;
      }

      const clotureData: {
        id: string;
        fond_final: number;
        garde_caisse?: number;
      } = {
        id: session_id || "",
        fond_final: parseFloat(fontFinal),
      };

      if (gardeCaisse.trim() !== "") {
        clotureData.garde_caisse = parseFloat(gardeCaisse);
      }

      if (selectedSessionJournalData && session_id) {
        const response = await dispatch(clotureSessionApi(clotureData));

        if (response.payload && response.payload.success) {
          onClose();
          if (printSessionTicket) {
            printClotureSessionTicket(response.payload.data);
          }
          if (user?.role_id?.libelle === "Caissier") {
            await dispatch(logoutApi());
          }
          navigate("/HomeCaisse");
        }
      } else {
        console.error("ID de session journal manquant ou invalide");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const autorisations = useAppSelector(selectedAutorisations);
  const canUseGardeCaisse = autorisations?.CAN_USE_GARDE_CAISSE;

  const printClotureSessionTicket = (data: any) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

    const printWindow = window.open("", "cloture", "width=900,height=500");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
          <title>Ticket de cloture  de session</title>            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color: #000;
                text-align: center;
              }
              h3 {
                font-size: 11px;
                color: #000;
                margin-bottom: 10px;
                text-align: center;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
              
            </style>
          </head>
          <body>
            <h2>Cloture session journal</h2>
            <h3>${formattedDate}</h3>
            <hr />
            <p>Organisation: ${user?.selected_organisation?.nom}</p>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Fond initial: ${data.fond_initial}</p>
            <p>Fond final: ${data.fond_final}</p>
            ${
              data.garde_caisse !== undefined
                ? `<p>Maintenus en caisse: ${data.garde_caisse}</p>`
                : ""
            }
            <p>Code Journal : ${journalCaisse?.code_journal}</p>
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };

  const handleConfirmationModalClose = async (confirmed: boolean) => {
    setConfirmationModal(false);

    if (confirmed) {
      handleCloture();
      if (user?.role_id?.libelle === "Caissier") {
        await dispatch(logoutApi());
      }
    }
  };

  const { t } = useTranslation();

  return (
    <>
      {confirmationModal && (
        <Modal show={true} onHide={() => handleConfirmationModalClose(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Confirmation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ whiteSpace: "normal" }}>
              {t(
                "Merci de vérifier le fond final car il y a une différence. Cliquez sur Enregistrer si vous êtes sûr que le fond final est :"
              )}
              <RedText>{fontFinal.trim()} TND</RedText>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary-app"
              onClick={() => handleConfirmationModalClose(false)}
            >
              {t("Annuler")}
            </Button>
            <Button
              className="btn btn-primary-app"
              onClick={() => handleConfirmationModalClose(true)}
            >
              {t("Enregistrer")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={!confirmationModal} onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Clôturer Session journal")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <StyledLabel>
              {t("Fond finale")} <Asterisk>*</Asterisk>
            </StyledLabel>
            <StyledInput
              type="number"
              value={fontFinal}
              onChange={handleFontFinalChange}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </div>
          {canUseGardeCaisse && (
            <div>
              <StyledLabel>{t("Maintenus en caisse")}</StyledLabel>
              <StyledInput
                type="number"
                value={gardeCaisse}
                onChange={handleGardeCaisseChange}
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>
          )}

          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="printTicketCheckbox"
              checked={printSessionTicket}
              onChange={handleCheckboxSessionChange}
            />
            <CheckboxLabel htmlFor="printTicketCheckbox">
              {t("Imprimer le ticket de clôture session caisse")}
            </CheckboxLabel>
          </CheckboxContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary-app"
            onClick={() => {
              if (parseFloat(fontFinal) !== fondCourant) {
                setConfirmationModal(true);
              } else {
                handleCloture();
              }
            }}
          >
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SessionClotureForm;
