import React, { useEffect, useState } from "react";
import Stocklist from "../../Components/Stock/Stocklist";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListArticles,
  Liststock,
  Stocks,
  StocksbyOrganisation,
  totalPages,
  currentpage,
  findArticleApi,
  findStockBySocieteApi,
  findStockByarticleApi,
  stockfilterApi,
 
} from "../../_App/Redux/Slices/article/articleSlice";
import _ from "lodash";
import StockType from "../../_App/Types/Entites/StockType";
import { useTranslation } from "react-i18next";
import Navbar from "../../_Layouts/Navbar/Navbar";
import StockTable from "../../Components/Stock/StockTable";
import StockTableArticle from "../../Components/Stock/StockTableArticle";
import {
  ListDepotByOrganisation,
  ListDepots,
  findDepotsBySocieteApi,
} from "../../_App/Redux/Slices/depot/depotSlice";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { allOrganisationsApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import { Form, Modal, Spinner } from "react-bootstrap";
import ValStockTableArticle from "../../Components/Stock/ValStockTableArticle";
import RapportVenteAchat from "../../Components/Rapport/RapportVenteAchat";
import { ListValstock, TotalPrix, TotalQte, ValstockfilterApi } from "../../_App/Redux/Slices/rapport/rapportSlice";

export default function ValorisationStockPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const listValstock = useAppSelector(ListValstock);
  const totalPrix = useAppSelector(TotalPrix);
  const totalQte = useAppSelector(TotalQte);

  const listDepots = useAppSelector(ListDepotByOrganisation);
  const listarticle = useAppSelector(ListArticles);
  const [keyword, setkeyword] = useState("");
  const [spinner, setspinner] = useState(false);
  const current_page = useAppSelector(currentpage)
  const totalpages = useAppSelector(totalPages)
  const [index, setindex] = useState(!current_page  ? 1 : current_page);

  type datafilterType = {
    carte: any;
    categories: any;
    sousCategories: any;
    carateristique: any;
    index: number;
    ref1:any;
    ref2:any;
    prix1:any;
    prix2:any

  };
  const [datafilter, setdatafilter] = useState<datafilterType>();
  useEffect(()=>{
  

   if(societe) dispatch(allOrganisationsApi(societe))
  },[])
 
 
  
  useEffect(()=>{
    if(totalQte&&totalPrix){
      setspinner(false)
    }
  },[totalQte,totalPrix])


  return (
    <div>
      <Navbar />
      
      <ValStockTableArticle
        t={t}
        listDepots={listDepots}
        stock={listValstock}
        setindex={setindex}
        index={index}
        listarticle={listarticle}
        setkeyword={setkeyword}
        keyword={keyword}
        spinner={spinner}
        setdatafilter={setdatafilter}
        setspinner={setspinner}
        dispatch={dispatch}
        societe={societe}
        totalQte={totalQte}
        totalPrix={totalPrix}
      />
       {/*<RapportVenteAchat t={t}/>*/}
      
    </div>
  );
}
/*
      
  <Stocklist t={t} stockOrganisation={stock_Organisation}/>

<StockTableArticle  
t={t} 
listDepots={listDepots} 
stock={selectedstocks} 
stock_Organisation={stock_Organisation}
setindex={setindex}
index={index}
 />*/
