import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import Swal from "sweetalert2";

export default function Reservationpopup({
  showModal,
  setshowModal,
  t,
  listClient,
  cart,
  confirmreservation,
  onAmountChange,
  enteredAmountCash,
  givenAmout,
  dureeAvance,
  action,
  AjouterAvance,
  setEnteredAmountCash
}: any) {
  type clientreservationType = {
    nom: string;
    mobile: number;
    duree: number;
    date_echeance: any;
    client: any;
  };
  const [clientreservationdata, setclientreservationdata] = useState<
    clientreservationType | undefined
  >();

  useEffect(() => {

    if (cart.client_id && showModal) {
      const foundclient = listClient.find(
        (item: any) => item._id === cart.client_id
      );
      if (foundclient) {
        const today = new Date();
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + 7);
        setclientreservationdata({
          client: foundclient,
          nom: foundclient.defaultClient
            ? foundclient.nom
            : foundclient.nom + foundclient.prenom,
          mobile: !foundclient.defaultClient ? foundclient.mobile : "",
          duree: 7,
          date_echeance: formatDateToInputValue(futureDate),
        });
      }
    }
  }, [listClient, cart.client_id, showModal]);
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Allow the input if it's a valid number or just a dot.
    if (/^\d*\.?\d*$/.test(value)) {
      setEnteredAmountCash(value); // Store the raw input value
      if (value !== "") {
        // Only parse and send the value if it's a valid number
        const parsedValue = value;
        onAmountChange(!isNaN(Number(parsedValue)) ? parsedValue : null);
      } else {
        onAmountChange(null); // Handle empty input
      }
    }
  };
  const formatDateToInputValue = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const changedata = (name: string, e: any) => {
    const { value } = e.target;
    if (name === "duree") {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + parseInt(value) || 0);
      const newdate_echance = formatDateToInputValue(futureDate);
      setclientreservationdata((prev: any) => {
        if (prev) {
          return {
            ...prev,
            date_echeance: newdate_echance,
            duree: parseInt(value) || 0,
          };
        } else {
          return { nom: value };
        }
      });
    } else if (name === "date_echeance") {
      const date1 = new Date();
      const date2 = new Date(value);
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      const duree_cal = parseInt((differenceInDays + 1).toString());

      setclientreservationdata((prev: any) => {
        if (prev) {
          return {
            ...prev,
            date_echeance: value,
            duree: duree_cal,
          };
        } else {
          return { nom: value };
        }
      });
    } else {
      setclientreservationdata((prev: any) => {
        if (prev) {
          return { ...prev, [name]: value };
        } else {
          return { nom: value };
        }
      });
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      animation={false}
    >
      <Modal.Header closeButton>
        {" "}
        <Modal.Title className="c-primary">
          {t("Information Avance ")}
        </Modal.Title>
      </Modal.Header>
      {action === "reservation" ? (
        <Modal.Body>
          <div className="row mt-2">
            <strong className=" col-4">{t("Total a payer ")}</strong>
            <strong className=" col-4">{t("Avance ")}</strong>
            <strong className=" col-4">{t("Reste a payer ")}</strong>
          </div>
          <div className="row mt-2">
            <strong className=" col-4">{cart?.totalNet.toFixed(3)}</strong>
            <strong className=" col-4">
              <input
                type="text "
                className="form-control col-12"
                value={
                  givenAmout !== null
                    ? givenAmout
                    : (cart?.totalNet-(givenAmout+cart?.avance)).toFixed(3)
                }
                onChange={handleAmountChange}
              />
            </strong>
            <strong className=" col-4">
              {(cart?.totalNet - givenAmout).toFixed(3)}
            </strong>
          </div>
          <div className="row mt-2">
            <strong className=" col-12">
              {t("Nom")} {t("Prenom")}
            </strong>

            <input
              type="text "
              className="form-control col-12"
              value={clientreservationdata?.nom}
              onChange={(e) => changedata("nom", e)}
            />
          </div>

          <div className="row mt-2">
            <strong className=" col-12">{t("Numéro de téléphone")}</strong>
            <input
              type="text"
              className="form-control  numberinput col-12"
              value={clientreservationdata?.mobile}
              onChange={(e) => changedata("mobile", e)}
            />
          </div>

          <div className="row mt-2">
            <strong className=" col-12">
              {t("Durée  de reservation en jours")}
            </strong>
            <input
              type="text"
              className="form-control  numberinput col-3"
              value={clientreservationdata?.duree}
              onChange={(e) => changedata("duree", e)}
            />
          </div>
          <div className="row mt-2">
            <strong className=" col-12">{t("date d'écheance")}</strong>
            <input
              type="date"
              className="form-control"
              value={
                clientreservationdata?.date_echeance
                  ? clientreservationdata.date_echeance
                  : formatDateToInputValue(new Date())
              }
              onChange={(e) => changedata("date_echeance", e)}
            />{" "}
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className="row mt-2">
            <strong className=" col-6">{t("Total a payer ")}</strong>
            <strong className=" col-6">{(cart?.totalNet).toFixed(3)}</strong>
          </div>
          <div className="row mt-2">
            <strong className=" col-6">{t("Total avance ")}</strong>
            <strong className=" col-6">{cart?.avance.toFixed(3)}</strong>
          </div>
          <div className="row mt-2">
            <strong className=" col-6">{t("Reste a payer ")}</strong>
            <strong className=" col-6">{(cart?.totalNet-Number(Number(givenAmout)+cart?.avance)).toFixed(3)}</strong>
          </div>
          <div className="row mt-2">
            <strong className=" col-6">{t("Avance ")}</strong>
            <strong className="col-6"> <input
                type="text "
                className="form-control "
                value={
                  givenAmout !== null
                    ? givenAmout
                    : (cart?.totalNet-(givenAmout+cart?.avance)).toFixed(3)
                }
                onChange={handleAmountChange}
              />   </strong>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          onClick={() => action==="reservation" ? confirmreservation(cart, clientreservationdata):AjouterAvance()}
          className="btn btn-primary-app"
        >
          {t("Valider reservation ")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
