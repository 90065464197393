// PanierSlice.js
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import BonReductionType from "../../../Types/Entites/ReductionType";

export const saveBonReductionApi = createAsyncThunk(
  "reductions/saveBonReduction",
  async (data: BonReductionType, { rejectWithValue }) => {
    try {
      const response = await axios.post("reductions/saveBonReduction", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const updateBon = createAsyncThunk(
  "reductions/updateBon",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("/reductions/updateBon", data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);
export const getBonReductionByDepot = createAsyncThunk(
  "reductions/getBonReductionByDepot",
  async (depot: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `reductions/getBonReductionByDepot/${depot}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getBonReductionById = createAsyncThunk(
  "reductions/getBonReductionById",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`reductions/getBonReductionById/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findBonByBarcode = createAsyncThunk(
  "reductions/getBonByBarcode",
  async (barcode: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`reductions/getBonByBarcode/${barcode}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface OrdersState {
  listBonsReduction: { [code: string]: BonReductionType[] };
}

const initialState: OrdersState = {
  listBonsReduction: {},
};

export const ReductionSlice = createSlice({
  name: "reductions",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(saveBonReductionApi.fulfilled, (state, action) => {
      state.listBonsReduction[action.payload.code] = action.payload;
    });
    builder.addCase(getBonReductionByDepot.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        listBonsReduction: groupedByCode,
      };
    });
    builder.addCase(findBonByBarcode.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        listBonsReduction: groupedByCode,
      };
    });
  },
});

export const ListBonsReduction = (state: RootState) =>
  state.reduction.listBonsReduction;

export default ReductionSlice.reducer;
