import React, { useEffect } from "react";
import CaisseCarteItem from "./CaisseCarteItem";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { changeDisplay } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import { ActiveMenu } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import {
  findCartesApi,
  ListCartes,
} from "../../../_App/Redux/Slices/cartes/carteSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";

const CaisseCartesList = ({ display }: any) => {
  const dispatch = useAppDispatch();
  const Menu = useAppSelector(ActiveMenu);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const CartesList = useAppSelector(ListCartes);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentOrganisation) {
          // Dispatch API calls
          await Promise.all([dispatch(findCartesApi(currentOrganisation))]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, currentOrganisation]);

  const handleDisplayChange = async ({
    colLeft,
    containerLeft,
    colRight,
    containerRight,
  }: any) => {
    dispatch(
      changeDisplay({
        colLeft: colLeft,
        containerLeft: containerLeft,
        displayColLeft: "",
        colRight: colRight,
        containerRight: containerRight,
      })
    );
  };
  return (
    <div>
      <div className="row" id="cancel-row">
        <div className="col-lg-12 layout-spacing layout-top-spacing">
          <div
            className="statbox widget box box-shadow"
            style={{ padding: "0px", paddingBottom: "15px" }}
          >
            <div className="widget-header">
              <div className="row">
                <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h6
                    style={{
                      padding: "0px",
                      margin: "33px 0px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Cartes{" "}
                    {/* <i
                      className="fas fa-columns left-side-trigger"
                      onClick={(e) => {
                        handleDisplayChange({
                          colLeft:
                            display &&
                            display.colLeft ===
                              "col-xl-6 col-lg-12 col-md-6 col-sm-12"
                              ? "col-xl-12 col-lg-12 col-md-12 col-sm-12"
                              : "col-xl-6 col-lg-12 col-md-6 col-sm-12",
                          containerLeft:
                            display && display.containerLeft === "col-4"
                              ? "col-2"
                              : "col-4",
                          colRight:
                            display &&
                            display.colRight ===
                              "col-xl-4 col-lg-6 col-md-4 col-sm-6"
                              ? "col-xl-3 col-lg-6 col-md-3 col-sm-6"
                              : "col-xl-4 col-lg-6 col-md-4 col-sm-6",
                          containerRight:
                            display && display.containerRight === "col-8"
                              ? "col-10"
                              : "col-8",
                        });
                      }}
                    ></i> */}
                  </h6>
                </div>
              </div>
              <div
                className="row layout-top-spacing"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  maxHeight:
                    Menu?.menuPosition.position === "left"
                      ? "calc(81vh - 43px)"
                      : "calc(81vh - 135px)",
                  minHeight:
                    Menu?.menuPosition.position === "left"
                      ? "calc(81vh - 43px)"
                      : "calc(81vh - 135px)",
                  alignContent: "baseline",
                }}
              >
                {CartesList &&
                  CartesList.map((item: any) => (
                    <CaisseCarteItem
                      key={item._id}
                      Item={item}
                      col={display?.colLeft}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaisseCartesList;
