import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findSousCategorieByLibelleApi,
  findSousCategorieBySocieteApi,
  ListSousCategories,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { t } from "i18next";

export default function SousCategorieSelecter({listsouscategorie ,setlistsouscategorie}:any) {
  const [indexcat, setIndexcat] = useState(1);
  const sousCategories = useAppSelector(ListSousCategories);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any[]>([]);
  const [souscategoriesOptions, setsouscategoriesdOptions] = useState<any[]>(
    []
  );
  const [isOpen, setIsOpen] = useState(false);
  const [libelle, setLibelle] = useState("");
  const societe = useAppSelector(societeId);

  const dispatch = useAppDispatch();

  const showsouscategories = async (op: any) => {
    if (op === "more") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societe,
          index: indexcat + 12,
        })
      );
      setIndexcat(indexcat + 12);
    } else if (op === "up") {
      await dispatch(
        findSousCategorieBySocieteApi({
          societe: societe,
          index: indexcat - 12,
        })
      );
      setIndexcat(indexcat - 12);
    } else {
      // verif si selected
      const findindex = listsouscategorie.findIndex(
        (item: any) => item._id === op._id
      );
      if (findindex !== -1) {
        setlistsouscategorie(
          listsouscategorie.filter((item: any) => item._id !== op._id)
        );
        setSelectedSousCategorie(
          selectedSousCategorie.filter((item: any) => item !== op._id)
        );
      } else {
        setlistsouscategorie([...listsouscategorie, op]);
        setSelectedSousCategorie([...selectedSousCategorie, op._id]);
      }

      setIsOpen(false);
    }
  };
  const removeitem = (op: any) => {
    const findindex = listsouscategorie.findIndex(
      (item: any) => item._id === op._id
    );
    if (findindex !== -1) {
      setlistsouscategorie(
        listsouscategorie.filter((item: any) => item._id !== op._id)
      );

      setSelectedSousCategorie(
        selectedSousCategorie.filter((item: any) => item !== op._id)
      );
    }
  };
  const findSearch = async () => {
    setIndexcat(0);
    const data = { index: 0, libelle: libelle, societe_id: societe };
    dispatch(findSousCategorieByLibelleApi(data));
  };
  const refresh = async () => {
    setIndexcat(0);
    setLibelle("");
    await dispatch(
      findSousCategorieBySocieteApi({ societe: societe, index: 0 })
    );
  };
  return (
    <div className="form-group">
      <label htmlFor="sous">
        {" "}
        <span style={{ color: "red" }}></span>
        {t("sous categorie")}
      </label>
      <div className="custom-dropdown">
        <div
          className="form-control row"
          onClick={() => setIsOpen(!isOpen)}
          style={{
            fontSize: "10px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }} // Adjust maxWidth as needed
        >
          {listsouscategorie &&
            listsouscategorie.length > 0 &&
            listsouscategorie.map((item: any) => (
              <span className="pointer  " key={item.id}>
                {item?.libelle?.fr}
                <span onClick={() => removeitem(item)}> x </span>
              </span>
            ))}
        </div>

        {isOpen && (
          <div className="custom-dropdown-options">
            <div className="custom-dropdown-option">
              <input
                type="text"
                value={libelle}
                onChange={(e) => setLibelle(e.target.value)}
              />
              <i className="fas fa-search" onClick={findSearch}></i>{" "}
              <i className="fas fa-sync-alt" onClick={refresh}></i>
            </div>
            {indexcat > 1 && (
              <div
                className="custom-dropdown-option"
                onClick={() => showsouscategories("up")}
              >
                <i className="fas fa-arrow-up"></i>
              </div>
            )}
            {sousCategories.map((option, i) => (
              <div
                key={i}
                className="custom-dropdown-option"
                onClick={() => showsouscategories(option)}
                style={{
                  background: selectedSousCategorie.find(
                    (item: any) => item === option._id
                  )
                    ? "#eff1f1"
                    : "white",
                }}
              >
                {option.libelle.fr}
              </div>
            ))}

            <div
              className="custom-dropdown-option"
              onClick={() => showsouscategories("more")}
            >
              <i className="fas fa-arrow-down"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
