import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import {
  ListSousCategories,
  ListSousCategoriesByCategorie,
  currentpage,
  findSousCategorieByLibelleApi,
  findSousCategorieBySocieteApi,
  findSousCategoriesApi,
  findSousCategoriesByCategorieApi,
  totalPages,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import SousCategoriesList from "../../Components/SousCategories/SousCategoriesList";
import { useParams } from "react-router";
import SousCategorieForm from "../../Components/SousCategories/SousCategorieForm";
import {
  SelectedOrganisationId,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import PaginationLayout from "../../_Layouts/Pagination/PaginationLayout";
import { useTranslation } from "react-i18next";
const SousCategoriesPage = () => {
    const { t } = useTranslation();
  
  const { idcategorie } = useParams();
  const [index, setindex] = useState(1);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [showModalSousCategorie, setShowModalSousCategorie] = useState(false);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState(null);
  const dispatch = useAppDispatch();
  const sousCategories = useAppSelector(ListSousCategories);
  const sousCategoriesByCategorie = useAppSelector(
    ListSousCategoriesByCategorie
  );
  const societe = useAppSelector(societeId);
  const current_page = useAppSelector(currentpage);
  const totalpages = useAppSelector(totalPages);
  const [libelle, setLibelle] = useState("");
  useEffect(() => {
    if (libelle !== "") {
      const data = { societe_id: societe, index: index, libelle: libelle };
      dispatch(findSousCategorieByLibelleApi(data));
    } else {
      const data = { societe: societe, index: index };
      dispatch(findSousCategorieBySocieteApi(data));
    }
  }, [societe, index]);

  useEffect(() => {
    if (idcategorie) {
      dispatch(findSousCategoriesByCategorieApi(idcategorie));
      console.log(idcategorie);
    } else if (currentOrganisation) {
      dispatch(findSousCategoriesApi(currentOrganisation));
    }
  }, [dispatch, idcategorie]);

  const openEditForm = (sousCategorie: any) => {
    setSelectedSousCategorie(sousCategorie);
    setShowModalSousCategorie(true);
  };
  const handleSearch = async () => {
    if(libelle !==''){
      const data = { societe_id: societe, index: 1, libelle: libelle };
      dispatch(findSousCategorieByLibelleApi(data))
    }else{
      const data = { societe: societe, index: index };
      dispatch(findSousCategorieBySocieteApi(data));
    }
   ;
    setindex(1);
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className=" btn btn-primary-app col-2"
          onClick={() => setShowModalSousCategorie(true)}
        >
         {t('Ajouter une Famille')}
        </button>
      </div>
      <SousCategorieForm
        ShowModal={showModalSousCategorie}
        setShowModal={setShowModalSousCategorie}
        sousCategorie={selectedSousCategorie}
        categorie_id={idcategorie}
        currentOrganisation={currentOrganisation}
        societe={societe}
      />

      <SousCategoriesList
        sousCategoriesListe={
          idcategorie ? sousCategoriesByCategorie : sousCategories
        }
        onEditClick={openEditForm}
        setLibelle={setLibelle}
        libelle={libelle}
        handleSearch={handleSearch}
      />
      <div className="row layout-spacing">
        <PaginationLayout
          index={index}
          nbrpage={current_page}
          totalpages={totalpages}
          setindex={setindex}
        />
      </div>
    </div>
  );
};

export default SousCategoriesPage;
